import type { IAction } from 'types/cartTypes';
import type { IProduct } from 'types/product';

export function getFilteredByProducts(
  currentAction: IAction,
  products: IProduct[]
) {
  const { action } = currentAction;
  const { gift_select: giftSelect = [] } = action;
  if (giftSelect.length === 0) return currentAction;
  action.gift_select = giftSelect.filter((currentGiftSelect) => {
    const filteredSelect = currentGiftSelect.filter((giftSelectProduct) => {
      return !!products.find((i) => i.id === giftSelectProduct.product_id);
    });
    return filteredSelect.length > 0;
  });
  // currentAction.action = action;
  return { ...currentAction, action };
}

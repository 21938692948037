import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { SmartLink } from 'shared/ui/smart-link';
import { Subtitle } from 'shared/ui/subtitle';
import Emitter from 'src/frontend/Components/NewCartOrder/Emitters';
import styles from './styles.module.scss';

export function CartHeader() {
  const resetCart = () => {
    Emitter.emit('MODAL_CALL', { modal: 'ResetCartModal' });
  };
  const { isExact } = useRouteMatch();
  const { t } = useTranslation();

  const act = isExact ? (
    <button type="button" className={styles.editCart} onClick={resetCart}>
      {t('BlockProducts.reset_cart')}
    </button>
  ) : (
    <SmartLink className={styles.editCart} href="/cart">
      {t('BlockProducts.edit_cart')}
    </SmartLink>
  );
  return (
    <header className={styles.root}>
      <Subtitle>{t('NewCart.your_order')}</Subtitle>
      {act}
    </header>
  );
}

import style from './AuthModal.module.scss';
import RegisterOrAuth from '../../NewCartOrder/Modals/Components/register-auth-modal/RegisterOrAuth';

function AuthModal({ info }) {
  return (
    <div className={style.authModal}>
      <RegisterOrAuth view={info || 'default'} />
    </div>
  );
}

export default AuthModal;

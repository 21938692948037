import style from './Banner.module.scss';

interface IBanner {
  bannerImgDesk: string;
  bannerImgMobile: string;
  link: string;
}

function Banner({
  bannerImgDesk = '',
  bannerImgMobile = '',
  link,
}: IBanner): JSX.Element {
  return (
    <a
      href={link}
      className={style.orderingBanner}
      target={link ? '_blank' : '_self'}
      rel="noreferrer"
    >
      <picture>
        <source
          srcSet={`/img/${bannerImgMobile}`}
          media="(max-width: 1024px)"
        />
        <img
          src={`/img/${bannerImgDesk}`}
          alt="Банер спасибо за заказ!"
          title={
            link
              ? 'Перейти и принять участие!'
              : 'Поздравляем, вы можете поучаствовать в розыгрыше!'
          }
          className={style.orderingBanner__img}
        />
      </picture>
    </a>
  );
}

export default Banner;

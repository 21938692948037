import { createSlice } from '@reduxjs/toolkit';
import { ICountry } from './types';

const initState = { country: {} as ICountry };

export const countriesSlice = createSlice({
  name: 'countries',
  initialState: initState,
  reducers: {},
});

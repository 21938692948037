import Emitter from '../../Emitters';

function openCardModal(e, { product, cartItemId }) {
  const { target: { dataset: { tag } = {} } = {} } = e || {};
  if (tag && tag === 'badge') {
    return;
  }
  e.stopPropagation();
  e.preventDefault();
  Emitter.emit('MODAL_CALL', {
    modal: 'CardModal',
    value: { product, id: cartItemId },
  });
  return false;
}

export { openCardModal };

import { useTranslation } from 'react-i18next';
import './ToCartButton.scss';
import PlusIcon from 'assets/images/icon/plus.svg';
import MinusIcon from 'assets/images/icon/minus.svg';
import animateProduct from 'frontend/helpers/product-flight';
import Button from '../../../Elements/Buttons/Button';
import ChangeQty from '../ChangeQty/ChangeQty';

function ToCartButton({
  product,
  position,
  lng,
  child,
  changeQtyCallback,
  specialStyle,
  specialStyle: { qty: qtyStyle = {}, text_btn } = {},
  product: { decoration = {} } = {},
  cardType,
}) {
  const { t } = useTranslation();
  const currentStyle =
    (specialStyle && specialStyle === 'Black') || specialStyle === 'Red';
  const decortions = specialStyle === false ? {} : decoration;
  const {
    name_color: nameColor = '',
    color_border_cart = '',
    color_border_cart_hover = '',
  } = decortions;

  const color_text_cart = decortions.color_text_cart || 'var(--primary-2)';
  const color_text_cart_hover =
    decortions.color_text_cart_hover || 'var(--(white)';

  return (
    <div className="container-cart-button">
      <ChangeQty
        product={product}
        position={position}
        child={child}
        changeQtyCallback={changeQtyCallback}
        render={({ qty, add, subtract }) => {
          if (qty !== 0) {
            return (
              <div className="plus-minus">
                <Button
                  type="round"
                  onClick={subtract}
                  specialStyle={specialStyle}
                  decoration={decoration}
                  cardType={cardType}
                  borderStyle={color_border_cart}
                  textStyle={color_text_cart}
                  hoverBorder={color_border_cart_hover}
                  hoverText={color_text_cart_hover}
                >
                  <MinusIcon width={20} height={20} />
                </Button>
                <div
                  className={`count ${currentStyle ? 'white-count' : ''}`}
                  style={{ ...qtyStyle, color: nameColor }}
                >
                  {qty}
                </div>
                <Button
                  type="round"
                  onClick={add}
                  specialStyle={specialStyle}
                  decoration={decoration}
                  cardType={cardType}
                  borderStyle={color_border_cart}
                  textStyle={color_text_cart}
                  hoverBorder={color_border_cart_hover}
                  hoverText={color_text_cart_hover}
                >
                  <PlusIcon width={20} height={20} />
                </Button>
              </div>
            );
          }
          return (
            <>
              <div className="desktop">
                <Button
                  onClick={(e) => {
                    add(e);
                    animateProduct(e);
                  }}
                  specialStyle={specialStyle}
                  decoration={decoration}
                  cardType={cardType}
                  borderStyle={color_border_cart}
                  textStyle={color_text_cart}
                  hoverBorder={color_border_cart_hover}
                  hoverText={color_text_cart_hover}
                >
                  <div>
                    {text_btn && lng === 'ru'
                      ? text_btn
                      : t('Card.Add to card')}
                  </div>
                  <div className="svg-container">
                    <PlusIcon width={20} height={20} />
                  </div>
                </Button>
              </div>
              <div className="mobile">
                <Button
                  type="round"
                  onClick={add}
                  specialStyle={specialStyle}
                  decoration={decoration}
                  cardType={cardType}
                  borderStyle={color_border_cart}
                  textStyle={color_text_cart}
                >
                  <PlusIcon width={20} height={20} />
                </Button>
              </div>
            </>
          );
        }}
      />
    </div>
  );
}

export default ToCartButton;

import { Helmet } from 'react-helmet';

export function DefaultHelmet() {
  return (
    <Helmet>
      <link
        rel="apple-touch-icon"
        href="/public/site-icons/apple-touch-icon.png"
      />
      <link rel="icon" href="/public/site-icons/favicon.ico" sizes="any" />
      <link
        rel="icon"
        href="/public/site-icons/icon.svg"
        type="image/svg+xml"
      />
      <link rel="manifest" href="/public/manifest.json" />
      <meta content="/public/site-icons/icon-512.png" property="og:image" />
    </Helmet>
  );
}

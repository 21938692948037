import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function DynamicLocaleImage({ platformName }: { platformName: string }) {
  const [img, setImg] = useState('');
  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    import(
      `assets/images/about/Platforms/${platformName}/platform.${language}.png`
    )
      .then((response) => {
        setImg(response.default);
      })
      .catch(console.warn);
  }, [language, platformName]);
  if (!img) {
    return null;
  }

  return (
    <img
      itemScope
      itemType="https://schema.org/ImageObject"
      className="platform-preloader"
      src={img}
      alt="platform"
    />
  );
}

export default DynamicLocaleImage;

import { getDateInSeconds } from 'src/shared/lib/date';

class SmsTimer {
  static getInstance(key, val) {
    return new SmsTimer(key, val);
  }

  constructor(key, val) {
    this.key = key;
    this.val = val;
    this.repository = localStorage;
    this.listeners = [];
    this._init();
  }

  on(event, callback) {
    if (this.secondsToEnd === undefined) {
      this.secondsToEnd = this.val;
    }

    this.listeners.push({ event, callback });
    this._emit('tick', this.secondsToEnd);
  }

  off(event, callback) {
    const index = this.listeners.findIndex(
      (listener) => listener.event === event && listener.callback === callback
    );
    this.listeners.splice(index, 1);
  }

  _init() {
    if (this.val) {
      const nowInSeconds = getDateInSeconds(new Date());
      const item = this.getInit() === 0 ? nowInSeconds + this.val : 0;
      if (item > nowInSeconds) {
        this.secondsToEnd = item - nowInSeconds;
        this.startTimer();
      }
    } else if (this.getInit()) {
      this.secondsToEnd = this.getInit();
      this.startTimer();
    } else {
      this.secondsToEnd = 0;
      this.repository.setItem(this.key, '0');
    }
  }

  getInit() {
    return +this.repository.getItem(this.key);
  }

  startTimer() {
    this.timerId = setInterval(() => {
      if (this.secondsToEnd < 0) {
        clearInterval(this.timerId);
      } else {
        this._emit('tick', this.secondsToEnd);
      }
      this.secondsToEnd--;
    }, 1000);
  }

  _emit(event, value) {
    this.listeners.forEach((listener) => {
      if (listener.event === event && typeof listener.callback === 'function') {
        listener.callback(value);
      }
    });
  }
}

export default SmsTimer;

import { storeHelper, IStore } from 'src/entities/stores';
import globalStore from 'modules/global-store';
import { IStoreTableRow, IWorkingHours, SortingType } from '../model/types';
import { SchemeType } from '../model/columnScheme';

export const getStoreWorkingTime = (
  store: IStore
): { hours: IWorkingHours | null; isEveryday: boolean } => {
  const todayWorktime = storeHelper.getWorkTime(store);

  if (!todayWorktime) return { hours: null, isEveryday: false };

  const isEveryday = storeHelper.isEverydaySameTime(store);

  const hours = {
    from: todayWorktime.worktime_from,
    to: todayWorktime.worktime_to,
  };
  return { hours, isEveryday };
};

export const sortStoresTable = ({
  stores,
  sorting,
  scheme,
  sortingColumn,
}: {
  stores: IStoreTableRow[];
  sorting: SortingType;
  scheme: SchemeType;
  sortingColumn: keyof typeof scheme | null;
}) => {
  if (
    !sorting ||
    !sortingColumn ||
    sortingColumn === 'review' ||
    !scheme[sortingColumn].sorting
  )
    return stores;

  const sortingNumber = sorting === 'up' ? 1 : -1;

  if (sortingColumn === 'workingHours') {
    return stores.sort((a, b) => {
      const aValue = a[sortingColumn];
      const bValue = b[sortingColumn];

      if (aValue.hours === null || bValue.hours === null) {
        if (aValue.hours === bValue.hours) {
          return 0;
        }

        if (!aValue.hours) {
          return 1 * sortingNumber;
        }
        return -1 * sortingNumber;
      }
      if (aValue.hours.from < bValue.hours.from) return -1 * sortingNumber;
      if (aValue.hours.from > bValue.hours.from) return 1 * sortingNumber;
      return 0;
    });
  }

  return stores.sort((a, b) => {
    const aValue = a[sortingColumn];
    const bValue = b[sortingColumn];
    if (aValue === null || bValue === null) return 0;
    if (aValue < bValue) return -1 * sortingNumber;
    if (aValue > bValue) return 1 * sortingNumber;
    return 0;
  });
};

export const normalizeStoresTable = ({ stores }: { stores: IStore[] }) => {
  const cityPhone = globalStore.get('current_city').phone || '';
  return stores.map((store) => {
    const { isEveryday, hours } = getStoreWorkingTime(store);
    return {
      id: store.id,
      address: storeHelper.getAddress(store),
      metro: store.subway,
      phone: store.phone || cityPhone,
      workingHours: { isEveryday, hours },
    } as const;
  });
};

export default function (props) {
  const _defaultPlaceholder = 'Enter @email';
  const _pattern =
    /^[a-z0-9][a-z0-9_-]{0,63}(?:.[a-z0-9_-]{1,64}){0,5}@[a-z0-9][a-z0-9-]{1,63}(?:.[a-z0-9-]{1,63}){0,5}.[a-z]{2,5}$/i;

  const { onValidate, required = false } = props;
  let { placeholder } = props;

  if (!placeholder) {
    placeholder = _defaultPlaceholder;
  }

  function _onValidate(b) {
    if (onValidate && typeof onValidate === 'function') {
      onValidate(b);
    }
  }

  function validate(event) {
    const {
      target: { value },
    } = event;

    if (!value) {
      if (!required) {
        _onValidate(true);
      }
      return value;
    }

    _onValidate(!!_pattern.test(value));

    return value;
  }

  return {
    validate,
    placeholder,
  };
}

import { useModal } from '@ebay/nice-modal-react';
import { modalIds } from 'shared/config/modal-ids';

export const useSelectAddressModal = () => {
  const modal = useModal(modalIds.SelectAddressModalId);

  return {
    ...modal,
    show: () => modal.show(),
  };
};

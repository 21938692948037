import globalStore from 'modules/global-store';
import debug from '../debug';

function getSession() {
  let session;
  try {
    session = globalStore.get('session', {});
  } catch (e) {
    debug(e.message);
    return null;
  }
  return session;
}

/*
 * get cart items from session cart
 * */
function get(name, defaultValue = null) {
  const session = getSession();
  if (!session) {
    throw new Error('Session not defined');
  }

  if (!name) {
    return session;
  }

  if (name in session) {
    return session[name];
  }

  return defaultValue || null;
}

function cartItems() {
  const { items } = get('cart', { items: [] }) || {};
  return items || [];
}

function set(name, value) {
  const session = getSession();
  if (!session) {
    throw new Error('Session not defined');
  }
  session[name] = value;
}

function del(name) {
  const session = getSession();
  if (!session) {
    throw new Error('Session not defined');
  }
  if (session[name]) {
    delete session[name];
  }
}

export { get, set, del, cartItems };

import { FallbackProps } from 'react-error-boundary';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Error(props: FallbackProps) {
  return (
    <div>
      <h5
        style={{
          color: '#c10000',
          textAlign: 'center',
          fontSize: '42px',
          marginBottom: '20px',
          fontWeight: '300',
        }}
      >
        Что-то пошло не так!
      </h5>
      <p style={{ color: '#c10000', textAlign: 'center', fontSize: '22px' }}>
        Мы работаем над проблемой...
      </p>
    </div>
  );
}

export default Error;

import Emitter from 'frontend/Components/NewCartOrder/Emitters';
import { googleAnalytics } from 'frontend/helpers/schemeGoogleAnalytics';
import api from 'modules/helpers/api';
import globalStore from 'modules/global-store';
import { TFunction } from 'i18next';
import { getProductIds } from 'modules/helpers/cart-helper';
import { ICart, IPromoCodeBonuses } from 'types/cartTypes';
import Api from '../api/Api';

const preparePromoError = (
  message: string,
  currency: string,
  t: TFunction,
  min_summ_order?: number | string,
  remark?: string
) => {
  const getTextValue = () => {
    if (min_summ_order) {
      return `${t('locale.min_summ_order')} ${min_summ_order} ${
        currency || ''
      }`;
    }
    if (message) {
      return message;
    }
    return t('locale.the_promo_code_is_invalid');
  };

  const modalOptions = {
    modal: 'ErrorCartModal',
    value: getTextValue(),
    currency,
    remark,
  };

  return modalOptions;
};

const promocodeResultModal = (
  promo_code: string,
  promo_applied: boolean,
  promo_code_bonuses: IPromoCodeBonuses,
  t: TFunction,
  currency: string
): boolean => {
  if ((!promo_code && !promo_code_bonuses) || (promo_code && promo_applied)) {
    Emitter.emit('MODAL_CALL', {
      modal: 'PromocodeCartModal',
    });
    googleAnalytics.promocodeYesDesire();
    return true;
  }
  const { message, min_summ_order, remark } = promo_code_bonuses;

  const modalOptions = preparePromoError(
    message,
    currency,
    t,
    min_summ_order,
    remark
  );
  Emitter.emit('MODAL_CALL', modalOptions);
  googleAnalytics.promocodeNoDesire();
  return false;
};

export const setPromocode = async (
  value: string,
  t: TFunction,
  currency: string
): Promise<boolean> => {
  const upperCaseValue = value.toUpperCase();
  try {
    const cart: ICart = await Api.setPromocode(upperCaseValue, null);
    const { promo_code, promo_applied, promo_code_bonuses } = cart as ICart;
    const ids = getProductIds(cart);
    try {
      const products = await api('product.get', { id: ids });
      globalStore.set('cart_products', products);
    } catch (error) {
      console.log(error);
    }
    return promocodeResultModal(
      promo_code,
      promo_applied,
      promo_code_bonuses,
      t,
      currency
    );
  } catch (error: any) {
    const { message = '', status = '' }: { message: string; status: string } =
      error;
    const modalOptions = preparePromoError(message, currency, t, status);
    Emitter.emit('MODAL_CALL', modalOptions);
    return false;
  }
};

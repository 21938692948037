import * as React from 'react';
import './discount-form.scss';
import * as session from 'modules/session';
import { useTranslation } from 'react-i18next';
import { analytics } from 'frontend/analytics/analytics';
import { useSelectedCountry } from 'src/entities/countries';
import type { ICart } from 'types/cartTypes';
import * as cartHelper from '../../../../../modules/helpers/cart-helper';
import { userBonusFields } from './scheme';

const handleBlurField = ({ target }: React.FocusEvent<HTMLFormElement>) => {
  cartHelper.sendOrderField(target.name, target.value);
  if (target.value) {
    analytics.deliveryFieldsAction(
      target.name as
        | 'phone'
        | 'apartment'
        | 'comment'
        | 'entrance'
        | 'floor'
        | 'address'
        | 'name'
        | 'email'
    );
  }
};

const fillValues = (
  { promo_code: promocode, bonus_phone: bonus, order }: ICart,
  fields: ReturnType<typeof userBonusFields>
) => {
  return fields.map((field) => {
    let value = (order && (order as any)[field.name]) || '';
    if (field.name === 'promocode' && promocode) value = promocode;
    if (field.name === 'phone' && bonus) value = bonus;
    return {
      value,
      ...field,
    };
  });
};
function PromoPhoneForm() {
  const { t } = useTranslation();
  const cart: ICart = session.get('cart');
  const { isMontenegro } = useSelectedCountry();

  const fields = fillValues(cart, userBonusFields({ isMontenegro, t }));

  const handleChangeForm = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <form
      className="discountForm"
      onKeyDown={handleChangeForm}
      onInput={handleChangeForm}
      onPaste={handleChangeForm}
      onChange={handleChangeForm}
      onBlur={handleBlurField}
    >
      {fields.map(({ Component, ...field }) => {
        if (!Component) return null;
        return (
          <Component
            handleChangeForm={handleChangeForm}
            field={field}
            key={field.name}
          />
        );
      })}
    </form>
  );
}

export default PromoPhoneForm;

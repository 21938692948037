import cn from 'classnames';
import styles from './closeButton.module.scss';

interface ICloseButton
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  className?: string;
}

function CloseButton({ className = '', onClick, ...props }: ICloseButton) {
  const root = cn(styles.root, { [className]: className });
  return (
    <div className={root}>
      <button
        {...props}
        onClick={onClick}
        type="button"
        className={styles.cross}
      >
        {' '}
      </button>
    </div>
  );
}

export default CloseButton;

import { useSelectCountry } from './selectors';

export const useSelectedCountry = () => {
  const { alpha2 } = useSelectCountry();
  return {
    isBelarus: alpha2 === 'BY',
    isRussia: alpha2 === 'RU',
    isMontenegro: alpha2 === 'ME',
    isArmenia: alpha2 === 'HY',
    isUkraine: alpha2 === 'UA' || alpha2 === 'PL', // sushistory
  };
};

export const withSelectedCountry = <T extends object>(
  Component: React.ComponentType<T & ReturnType<typeof useSelectedCountry>>
) =>
  function (props: T) {
    const selectedCountry = useSelectedCountry();
    return <Component {...props} {...selectedCountry} />;
  };

import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { useMaskito } from '@maskito/react';

const orderMask = {
  mask: [
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
  ],
};

export function InputOrder(
  props: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
) {
  const maskedInputRef = useMaskito({ options: orderMask });
  return <input ref={maskedInputRef} {...props} />;
}

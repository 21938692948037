import { memo } from 'react';
import { useResizeWindow } from 'frontend/hooks';
import styles from './trioOnMainPage.module.scss';
import { ITrioItem } from './types/trioOnMainPage';
import TrioItem from './trio-item/TrioItem';
import Carousel from './carousel/Carousel';

function TrioOnMainPage({ stocks }: { stocks: ITrioItem[] }) {
  const { isDesktop } = useResizeWindow();

  if (!stocks || !stocks.length) return null;

  const slicedStocks = stocks.slice(0, 3);

  return (
    <div className={styles.root}>
      {isDesktop ? (
        slicedStocks.map((item) => {
          return <TrioItem item={item} key={item.id} />;
        })
      ) : (
        <Carousel items={slicedStocks} />
      )}
    </div>
  );
}

export default memo(TrioOnMainPage);

import { useEffect, useState } from 'react';

export function OnlyClient({ children }) {
  const [isSsr, setIsSsr] = useState(true);

  useEffect(() => {
    setIsSsr(false);
  });

  return isSsr ? null : children;
}

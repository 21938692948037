/**
 * Created by rebu on 28.04.17.
 */
const specials = [
  // order matters for these
  '-',
  '[',
  ']',
  // order doesn't matter for any of these
  '/',
  '{',
  '}',
  '(',
  ')',
  '*',
  '+',
  '?',
  '.',
  '\\',
  '^',
  '$',
  '|',
];
const regex = RegExp(`[${specials.join('\\')}]`, 'g');

export default function escapeReg(str: string) {
  return str.replace(regex, '\\$&');
}

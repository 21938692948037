import storeHelper from 'src/entities/stores/lib/store-helper';
import { useTranslation } from 'react-i18next';
import { LinkButton } from 'shared/ui/link-button';
import { IStore } from 'src/entities/stores';

interface IPickupFormTitle {
  store: IStore | null;
}

function PickupFormTitle({ store }: IPickupFormTitle) {
  const { t } = useTranslation();
  return (
    <div className="block-form__title-container">
      <h3>{t('BlockForm.exportProcessing')}</h3>
      {store && (
        <div className="block-form__pickup-container">
          <h4>
            {t('BlockForm.at_the_address')} {storeHelper.getAddress(store)}
          </h4>
          <div className="btn-container">
            <LinkButton href="/cart/pickup">
              {t('BlockForm.toChangeTheStorePickup')}
            </LinkButton>
          </div>
        </div>
      )}
    </div>
  );
}

export default PickupFormTitle;

import { useTranslation } from 'react-i18next';

const getStringCalories = (product, t) => {
  const calories_info = {
    calories: product.calories,
    protein: product.protein,
    fat: product.fat,
    carbohydrate: product.carbohydrate,
  };
  return Object.entries(calories_info)
    .map((item) => {
      return item[1] ? `${t(`Card.${item[0]}`)} ${item[1]}` : null;
    })
    .filter((item) => item)
    .join(', ');
};

function Composition({ product, weight = true }) {
  const { t } = useTranslation();
  let { composition = [] } = product;
  const {
    specialStyle: { description = {} } = {},
    decoration: { ingredients_color: descriptionColor = '' } = '',
    weight_type = '',
  } = product;
  composition = composition.map((item) => item.title);
  composition = composition.join(', ');
  const isComposition = composition.length > 0;
  const calories = getStringCalories(product, t);
  return (
    <span
      itemScope
      itemType="https://schema.org/description"
      className="card__ingredients"
      data-id={product.id}
      style={{ ...description, color: descriptionColor }}
    >
      {isComposition ? composition : ' '}
      <span>
        {weight && weight_type
          ? ` ${product.weight} ${product.weight_type}`
          : null}
      </span>
      <br />
      <span className="card__calories">
        {calories && calories.length ? ` ${calories}` : null}
      </span>
    </span>
  );
}

export default Composition;

import client from 'modules/config/client';
import { useCallback, useEffect, useState } from 'react';
import ErrorImage from './assets/no_photo.svg?url';

interface ISizes {
  width: number;
  height: number;
}
export interface IResolutions {
  mobile: ISizes;
  tablet: ISizes;
  desktop: ISizes;
}

interface IBuildResponsiveImageUrl {
  src: string;
  sizes?: IResolutions;
}

export const buildResponsiveImageUrl = ({
  sizes,
  src,
}: IBuildResponsiveImageUrl) => {
  if (!sizes) {
    return;
  }
  const { mobile, tablet, desktop } = sizes;

  return {
    srcSet: `${src}/${mobile.width}x${mobile.height} 430w,
              ${src}/${tablet.width}x${tablet.height} 768w,
              ${src}/${desktop.width}x${desktop.height} 800w`,
    sizes: '(max-width: 430px) 430px, (max-width: 768px) 768px, 800px',
  };
};

const getCdnSrc = (path: string) => {
  const cdnBaseUrl = client('client').cdnUrl;
  return new URL(`/img/${path}`, cdnBaseUrl).toString();
};

interface IState {
  type: 'cdn' | 'old' | 'error';
  href: string;
}
export const useImageSrc = ({
  path,
  mainDomain,
}: {
  path: string;
  mainDomain: string;
}) => {
  const initSrc = getCdnSrc(path);
  const [srcData, setSrcData] = useState<IState>({
    type: 'cdn',
    href: initSrc,
  });

  useEffect(() => {
    setSrcData({
      type: 'cdn',
      href: initSrc,
    });
  }, [initSrc]);

  const switchSrc = useCallback(() => {
    if (srcData.type === 'old') {
      setSrcData({ type: 'error', href: ErrorImage });
      return;
    }
    const fallbackSrc = new URL(
      `/img/${path}`,
      `https://${mainDomain}`
    ).toString();

    setSrcData({ type: 'old', href: fallbackSrc });
  }, [mainDomain, path, srcData.type]);

  return { srcData, switchSrc };
};

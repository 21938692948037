import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

function SwRegister() {
  const { i18n } = useTranslation();

  return (
    <Helmet>
      <script>
        {`
          if ('serviceWorker' in navigator) {
            let workerPath = '/public/service-worker.js';
            if ('${i18n.language}' === 'ua') {
              workerPath = '/public/ss/service-worker-ua.js';
            }
            if ('${i18n.language}' === 'pl') {
              workerPath = '/public/ss/service-worker-pl.js';
            }
            navigator.serviceWorker.register(workerPath).then(
              (registration) => {
                // console.log('Service worker registration succeeded:', registration);
              },
              (error) => {
                console.error('Service worker registration failed:', error);
              }
            );
          } else {
            console.error('Service workers are not supported.');
          }
        `}
      </script>
    </Helmet>
  );
}

export default SwRegister;

import Do from 'frontend/Components/Products/CardWok/wok-constructor/Do';

export default class WokSlider {
  static withLength(aNumber) {
    return new WokSlider(aNumber);
  }

  constructor(aLength) {
    this._maxSlideIndex = aLength - 1;
    this._minSlideIndex = 0;
    this._currentSlideIndex = this._minSlideIndex;
  }

  goToSlideWithIndex(anIndex) {
    if (anIndex > this._maxSlideIndex) {
      this._currentSlideIndex = this._maxSlideIndex;
    } else if (anIndex < this._minSlideIndex) {
      this._currentSlideIndex = this._minSlideIndex;
    } else {
      this._currentSlideIndex = anIndex;
    }
  }

  isNotLastSlideSelected = () => !this.isLastSlideSelected();

  isLastSlideSelected = () => this.isSlideSelected(this._maxSlideIndex);

  isFirstSlideSelected = () => this.isSlideSelected(this._minSlideIndex);

  isSlideSelected = (anIndex) => this._currentSlideIndex === anIndex;

  collectIndexes = (aFunction) =>
    Do(this._maxSlideIndex + 1).collect(aFunction);
}

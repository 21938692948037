import { useCookies } from 'react-cookie';
import client from 'modules/config/client';
import { cityTextIdStorageName } from './storage-names';

export const useCityTextIdMemorizer = () => {
  const [cookie, setCookie] = useCookies([cityTextIdStorageName]);
  return {
    set: (value: string) =>
      setCookie(cityTextIdStorageName, value, {
        path: '/',
        maxAge: client('client').cookieMaxAge,
      }),
    get: () => cookie[cityTextIdStorageName],
  };
};

import { IProduct, ISuperProduct } from 'types/product';

export const enum FavoritesState {
  inserted = 'inserted',
  removed = 'removed',
}
export interface IToggleResponse {
  state: FavoritesState;
  product: IProduct | ISuperProduct;
}

import './CardWokMobile.scss';
import WokRepository from 'frontend/Components/Products/CardWok/wok-constructor/WokRepository';
import bind from 'autobind-decorator';
import { Component } from 'react';
import * as session from 'modules/session';
import { withSelectedCountry } from 'src/entities/countries';
import Button from 'shared/ui/Button/Button';
import { InternalImage } from 'shared/ui/image';
import WokConstructorImage from './assets/wok/wokConstructor.png';
import uaWokConstructorImage from './assets/wok/uaWokConstructor.png';
import Case from '../../Case/Case';
import WokConstructor from './wok-constructor/WokConstructor';
import Emitter from '../../NewCartOrder/Emitters';
import Card from '../Card/Card';
import addStocks from '../CardStock/addStocks';
import styles from './cardWok.module.scss';

class CardWokMobile extends Component {
  constructor(props) {
    super(props);
    this.repository = WokRepository.new(props.products, props.group);
    this.state = { constructorIsOpen: false };
  }

  componentDidMount() {
    Emitter.addListener('CART_CHANGE', this.reRender);
    if (this.repository.hasNotWoks()) {
      document.querySelector('footer').classList.add('hide');
    }
  }

  componentWillUnmount() {
    Emitter.removeListener('CART_CHANGE', this.reRender);
    document.querySelector('footer').classList.remove('hide');
  }

  @bind reRender(callback) {
    this.forceUpdate(callback);
  }

  @bind openConstructor(event) {
    event.preventDefault();

    this.setState({ constructorIsOpen: true });
    document.querySelector('footer').classList.add('hide');
  }

  render() {
    const { constructorIsOpen } = this.state;
    const { products, group, t, isUkraine } = this.props;
    const cartItems = session.cartItems();
    const wokImage = isUkraine ? uaWokConstructorImage : WokConstructorImage;

    const renderedWoks = this.repository.collectWoks((wok) => (
      <Card
        key={wok.id}
        product={wok}
        view_type="grid"
        position="catalog"
        cart_item={cartItems.find((item) => item.id === wok.id)}
      />
    ));
    const woksWithStocks = addStocks({
      CardComponents: renderedWoks,
      itemsGrid: 1,
      menuId: 709,
    });

    return (
      <div className="card-wok">
        <Case of={!constructorIsOpen && this.repository.hasWoks()}>
          <div className="card-wok__products">
            {woksWithStocks}
            <Case of={this.repository.hasWokForConstructor()}>
              <div className="card-wrapper--grid">
                <div className="card--grid card--constructor">
                  {/* <div className="card__image card__image--wok">
                    <img src={WokHuckImage} alt={t('CardWok.wok')} />
                  </div> */}
                  <InternalImage
                    src={wokImage}
                    alt={t('CardWok.wok')}
                    width={400}
                    height={400}
                    className={styles.imgWrapper}
                  />
                  <div className="card__price__and__buybutton card__price__and__buybutton--wok">
                    <Button onClick={this.openConstructor}>
                      {`${t('CardWok.createWok')}!`}
                    </Button>
                  </div>
                </div>
              </div>
            </Case>
            <div className="card-wrapper--grid card-wrapper--grid--empty" />
            <div className="card-wrapper--grid card-wrapper--grid--empty" />
            <div className="card-wrapper--grid card-wrapper--grid--empty" />
            <div className="card-wrapper--grid card-wrapper--grid--empty" />
          </div>
        </Case>
        <Case of={constructorIsOpen || this.repository.hasNotWoks()}>
          <WokConstructor products={products} group={group} mobile />
        </Case>
      </div>
    );
  }
}

export default withSelectedCountry(CardWokMobile);

import {
  withMapDeliveryValidationScheme,
  noMapDeliveryValidationScheme,
} from 'modules/helpers/validaters/validateDeliveryForm';
import { pickupValidationScheme } from 'modules/helpers/validaters/validatePickupForm';

export const getValidationScheme = (
  isPickup: boolean,
  isMapDisabled: boolean
) => {
  if (isPickup) {
    return pickupValidationScheme;
  }
  if (isMapDisabled) {
    return noMapDeliveryValidationScheme;
  }
  return withMapDeliveryValidationScheme;
};

import cn from 'classnames';
import { useLanguage } from '../model/language-handler';
import { getDisplayedLanguage } from '../lib/get-displayed-language';

export function SelectLanguageMobile() {
  const { availableLangs, currentLanguage, changeLanguage } = useLanguage();
  return (
    <li className="mobile-sidebar__langs">
      {availableLangs.map((lang) => {
        return (
          <button
            type="button"
            key={lang}
            onClick={() => {
              changeLanguage(lang);
            }}
            className={cn('mobile-sidebar__langs-lang', {
              'mobile-sidebar__langs-lang--active': currentLanguage === lang,
            })}
          >
            {getDisplayedLanguage(lang)}
          </button>
        );
      })}
    </li>
  );
}

export default SelectLanguageMobile;

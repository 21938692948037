import cn from 'classnames';
import { useAppSelector } from 'shared/lib/store';
import styles from './social-links.module.scss';
import { footerLinksSliceSelectors } from '../model/slice';

export function SocialLinks() {
  const footerSocial = useAppSelector(
    footerLinksSliceSelectors.selectFooterLinks
  );
  const socials = Object.entries(footerSocial);

  return (
    <div className={styles.socialLinksContainer} ref={(div) => div}>
      {socials.map(([socialName, value]) => {
        const social = socialName;
        const { url } = value;
        const key = value.order;
        if (!url) return null;
        const classes = cn(styles.link, {
          [styles.tg]: social === 'tg',
          [styles.vk]: social === 'vk',
          [styles.yt]: social === 'yt',
          [styles.ok]: social === 'ok',
          [styles.in]: social === 'in',
          [styles.fb]: social === 'fb',
          [styles.tw]: social === 'tw',
          [styles.dz]: social === 'dz',
          [styles.tt]: social === 'tt',
        });

        return (
          <a
            style={{ textIndent: '-10000px' }}
            className={classes}
            href={url}
            target="_blank"
            key={key}
            rel="noreferrer"
          >
            {social}
          </a>
        );
      })}
    </div>
  );
}

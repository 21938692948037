import { MouseEvent } from 'react';
import { IProduct } from 'types/product';
import api from 'modules/helpers/api';
import { modalEmitter } from 'modules/event-emitters';
import { useTranslation } from 'react-i18next';
import style from './toppingsButton.module.scss';

export function ToppingsButton({ product }: { product: IProduct }) {
  const { t } = useTranslation();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    api('product.get', { id: product.toppings })
      .then((toppings) => {
        modalEmitter.emit('Modal.Toppings.Open', { product, toppings });
      })
      .catch((e) => console.error(e));
  };

  return (
    <button className={style.root} type="button" onClick={handleClick}>
      {t('Card.toppings')}
    </button>
  );
}

import * as Yup from 'yup';
import { nameValidete } from './lib/nameValidete';

export const withMapDeliveryValidationScheme = Yup.object().shape({
  apartment: Yup.string().required(),
  entrance: Yup.string().required(),
  floor: Yup.string().required(),
  email: Yup.string().email('Неправильно введён e-mail'),
  name: Yup.string().test(nameValidete).required('Поле должно быть заполнено'),
  phone: Yup.string()
    .trim()
    .required('Поле должно быть заполнено')
    .transform((value) => value.replace(/\D/g, ''))
    .min(11, 'Минимально количество должно быть.'),
});

export const noMapDeliveryValidationScheme = Yup.object().shape({
  apartment: Yup.string().required(),
  email: Yup.string().email('Неправильно введён e-mail'),
  name: Yup.string().test(nameValidete).required('Поле должно быть заполнено'),
  phone: Yup.string()
    .trim()
    .required('Поле должно быть заполнено')
    .transform((value) => value.replace(/\D/g, ''))
    .min(11, 'Минимально количество должно быть.'),
});

import { Component } from 'react';
import Modal from 'react-modal';
import { modalEmitter } from 'modules/event-emitters';
import modernModal from '../ModernModal';
import YoutubeWidget from '../../Main/SocialWidgets/YoutubeWidget';

const modalStyles = {
  overlay: {
    zIndex: 20,
    backgroundColor: 'rgba(252, 252, 252, 0.7',
    overflowY: 'auto',
  },
  content: {
    maxWidth: '940px',
    top: '8vw',
    left: '0',
    right: '0',
    bottom: 'auto',
    margin: 'auto',
    overflow: 'visible',
    padding: '44px',
    paddingTop: '40px',
    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 16px 60px 20px',
    boxSizing: 'border-box',
  },
};

class MovieModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      videoLink: '',
    };
  }

  componentDidMount() {
    Modal.setAppElement('body');
    modalEmitter.addListener('Modal.MovieModal.Open', ({ videoLink }) => {
      this.setState({ videoLink });
      this.openModal();
    });
  }

  componentWillUnmount() {
    modalEmitter.removeListener('Modal.MovieModal.Open', ({ videoLink }) => {
      this.setState({ videoLink });
      this.openModal();
    });
  }

  openModal = () => {
    this.setState({
      modalIsOpen: true,
    });
    modernModal.openModal();
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
    modernModal.closeModal();
  };

  render() {
    const { videoLink } = this.state;

    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        style={modalStyles}
        onRequestClose={this.closeModal}
        contentLabel="Movie Modal"
      >
        <YoutubeWidget videoLink={videoLink} />
        <div className="main-movie-close">
          <button
            onClick={this.closeModal}
            className="close-modal-btn"
            type="button"
          />
        </div>
      </Modal>
    );
  }
}

export default MovieModal;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initState: {
  selectedStoreId: number | null;
} = {
  selectedStoreId: null,
};

export const pickupSlice = createSlice({
  name: 'pickupStore',
  initialState: initState,
  reducers: {
    setSelectedStoreId: (state, action: PayloadAction<number>) => {
      state.selectedStoreId = action.payload;
    },
  },
});

export const { setSelectedStoreId } = pickupSlice.actions;

import { useModal } from '@ebay/nice-modal-react';
import { modalIds } from 'shared/config/modal-ids';
import type { ForgetComplectationProps } from '../ui/forget/ForgetComplectationModal';

export const useComplectationInfoModal = () => {
  const modal = useModal(modalIds.complectationInfoModalId);

  return {
    ...modal,
    show: () => modal.show(),
  };
};

export const useForgetComplectationModal = () => {
  const modal = useModal(modalIds.forgetComplectationModalId);

  return {
    ...modal,
    show: (args: ForgetComplectationProps) => modal.show(args),
  };
};

import cn from 'classnames';
import Carousel from 'shared/ui/carousel/Carousel';
import style from './mobileSlider.module.scss';
import { IMineSlider } from '../../model/types';
import { SliderItem } from '../SliderItem';

const responsive = {
  mobile: { breakpoint: { max: 768, min: 0 }, items: 1 },
  tablet: { breakpoint: { max: 1023, min: 769 }, items: 2 },
};

interface ControlArrowButtonProps {
  direction: 'left' | 'right';
  onClick?: () => void;
}

interface IMobileSliderProps {
  banners: IMineSlider[];
  className: string;
}

function ControlArrowButton({ direction, onClick }: ControlArrowButtonProps) {
  return (
    <button
      onClick={onClick}
      type="button"
      aria-label={direction === 'left' ? 'предыдущий' : 'следующий'}
      className={cn(style.arrow, {
        [style.left]: direction === 'left',
        [style.right]: direction === 'right',
      })}
    />
  );
}

export function MobileSlider({ banners, className }: IMobileSliderProps) {
  return (
    <Carousel
      responsive={responsive}
      containerClass={className}
      itemClass={style.sliderItem}
      customRightArrow={<ControlArrowButton direction="right" />}
      customLeftArrow={<ControlArrowButton direction="left" />}
      draggable={false}
      infinite
      ssr
    >
      {banners.map((banner) => (
        <SliderItem slide={banner} key={banner.id} />
      ))}
    </Carousel>
  );
}

import { useTranslation } from 'react-i18next';
import style from './SuccessResult.module.scss';

function SuccessResult({ text }: { text?: string }) {
  const { t } = useTranslation();
  return (
    <div className={style.qualityThanks}>
      <div className={style.icon} />
      <div className={style.title}>{t('SuccessResult.thanks')}!</div>
      {!!text && <p className={style.text}>{text}</p>}
    </div>
  );
}

export default SuccessResult;

import { useState, createContext, PropsWithChildren, useMemo } from 'react';

export const SideBarContext = createContext({
  visible: true,
  show: () => {},
  hide: () => {},
});

export function SideBarContextProvider({
  children,
}: PropsWithChildren<unknown>) {
  const [visible, setVisible] = useState(true);

  const show = () => {
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };
  const value = useMemo(
    () => ({
      visible,
      show,
      hide,
    }),
    [visible]
  );
  return (
    <SideBarContext.Provider value={value}>{children}</SideBarContext.Provider>
  );
}

import './superSelect.scss';
import cn from 'classnames';
import { useSelectedCountry } from 'src/entities/countries';

function SuperSelect({ value, onChange, options }) {
  const isUkraine = useSelectedCountry();
  return (
    <div className="super-select-container">
      <ul className={cn('options', { ua: isUkraine })}>
        {options.map((o) => {
          if (!value) value = options[0];
          return (
            <li
              className={cn({ selected: o === value })}
              key={o}
              onClick={() => onChange(o)}
            >
              {o}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default SuperSelect;

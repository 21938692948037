import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import style from './cardIsAction.module.scss';

function CardIsAction({
  t,
  product,
  addComma,
  view_type,
  haveCustomStyles = true,
}) {
  const {
    specialStyle: { action = {} } = {},
    decoration: { additional_text_color: textColor = '' } = {},
    is_action,
  } = product;

  if (!is_action) return null;

  const actionString = `${t('Card.is_action')}`;
  const totalString = actionString + (addComma ? ', ' : ' ');
  const customStyle = haveCustomStyles && { ...action, color: textColor };

  const isActionStyle = cn(style.isAction, {
    [style.isGrid]: view_type === 'grid',
  });

  return (
    <span className={isActionStyle} style={{ ...customStyle, display: 'none' }}>
      {totalString}
    </span>
  );
}

export default withTranslation()(CardIsAction);

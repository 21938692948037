import './Info.scss';
import autobind from 'autobind-decorator';
import { Component } from 'react';
import axios from 'axios';
import * as session from 'modules/session';
import {
  modalEmitter,
  orderEmitter,
  userEmitter,
  userProfileEmitter,
} from 'modules/event-emitters';
import ResponseError from 'modules/response/error';
import { withTranslation } from 'react-i18next';
import UserGifts from 'frontend/Components/UserGifts/UserGifts';
import { analytics } from 'frontend/analytics/analytics';
import { UserPromoCode } from 'src/entities/promocode';
import Button from 'src/shared/ui/Button/Button';
import Radio from 'src/shared/ui/Radio/Radio';
import { formaterBirthday } from 'shared/lib/date';
import { Subtitle } from 'shared/ui/subtitle';
import { BlockContainer } from 'shared/ui/block-container';
import Favorites from '../../Favorites/Favorites';
import OrderTable from '../Order/Order';
import ReorderModal from '../../Modals/ReorderModal/ReorderModal';
import AddressEditModal from './Modals/AddressEdit';
import AddressDeleteModal from './Modals/AddressDelete';
import Emitter from '../../NewCartOrder/Emitters';

class ProfileInfo extends Component {
  constructor(props) {
    super(props);
    const { user } = session.get() || {};
    this.state = {
      user,
      checked: user.address_id,
      isNotEmptyUserGifts: true,
    };
  }

  /// @todo !!!! вынести все в один файл
  componentDidMount() {
    orderEmitter.addListener('Order.Reorder', this.reorder);
    orderEmitter.addListener('Order.Reorder.New', this.reorderNew);
    orderEmitter.addListener('Order.Reorder.Add', this.reorderAdd);
  }

  componentWillReceiveProps(props) {
    const { checked } = this.state;
    const { addresses = [] } = props;

    if (!checked && addresses.length) {
      this.setState({
        checked: props.addresses && props.addresses[0].id,
      });
    }
  }

  componentWillUnmount() {
    orderEmitter.removeListener('Order.Reorder', this.reorder);
    orderEmitter.removeListener('Order.Reorder.New', this.reorderNew);
    orderEmitter.removeListener('Order.Reorder.Add', this.reorderAdd);
  }

  @autobind
  editHandler() {
    userProfileEmitter.emit('User.Profile.ChangeTypeAction', 'edit');
  }

  logoutHandler() {
    axios
      .post('/auth/logout')
      .then(() => {
        session.set('user', undefined);
        userEmitter.emit('User.Change');
        userEmitter.emit('User.Logout.Event');
      })
      .catch((error) => {
        console.log('logout request failed');
        console.log(error);
        return false;
      });
    userEmitter.emit('Profile.MenuMobile.logout');
    return true;
  }

  @autobind
  checkedHandler({ target }) {
    const { id } = target;
    const { user = {} } = session.get();

    this.setState({
      checked: id,
    });

    axios
      .post('/user/set_addresses', { id })
      .then(() => {
        user.address_id = id;
        this.setState({ user });
        analytics.addressAction();
      })
      .catch(() => {
        if (user.address_id) delete user.address_id;
        this.setState({ user });
      });
  }

  @autobind
  addressEdit(address, e) {
    e.preventDefault();
    userProfileEmitter.emit('User.Address.Edit', address);
  }

  @autobind
  setIsNotEmptyUserGifts(isNotEmptyUserGifts) {
    this.setState({ isNotEmptyUserGifts });
  }

  @autobind
  addressDelete(address, e) {
    e.preventDefault();
    userProfileEmitter.emit('User.Address.Delete', address);
  }

  // reorder

  @autobind
  reorder(order) {
    this.reorderHandler(order);
  }

  @autobind
  reorderNew(order) {
    this.reorderHandler(order, true);
  }

  @autobind
  reorderAdd(order) {
    this.reorderHandler(order, false);
  }

  reorderHandler(order, isNew = null) {
    if (this.state.inProcess) {
      return true;
    }

    const form = {
      order_hash: order.hash,
      order_salt: order.salt,
    };

    if (typeof isNew !== 'undefined' || isNew !== null) {
      form.is_new = isNew;
    }

    this.inProcess(true);
    axios
      .post('/order/reorder', form)
      .then(() => {
        this.inProcess(false);
        window.location.href = '/cart';
      })
      .catch((error) => {
        modalEmitter.emit('Reorder.Modal.Close');
        this.inProcess(false);
        const responseError = new ResponseError(error);
        modalEmitter.emit('Modal.Error.Open', responseError.getMessage());
      });
  }

  inProcess(toggle) {
    this.setState({
      inProcess: !!toggle,
    });
  }

  openDeleteUserModal() {
    Emitter.emit('MODAL_CALL', { modal: 'DeleteUserModal' });
  }

  render() {
    const { user, checked } = this.state;
    const { updateAddress, addresses = [], t } = this.props;
    const orders = this.props.orders ? this.props.orders.slice(0, 5) : [];
    const { isNotEmptyUserGifts } = this.state;
    return (
      <>
        <BlockContainer childrenPosition="start">
          <div className="profile__name-and-birthday">
            <div className="input-container">
              <label>{t('Info.name')}</label>
              <p className="classToParagraph">
                {user.name ? user.name : t('Info.name_not_specified')}
              </p>
            </div>
            <div className="input-container">
              <label>{t('Info.birthday')}</label>
              <p className="classToParagraph">
                {user.birth_day
                  ? formaterBirthday(user.birth_day)
                  : t('Info.birthday_not_specified')}
              </p>
            </div>
          </div>
          <Button onClick={this.editHandler}>{t('Info.edit')}</Button>
        </BlockContainer>
        {isNotEmptyUserGifts && (
          <BlockContainer>
            <Subtitle>{t('BlockProducts.user_gift')}</Subtitle>
            <UserGifts
              isExact
              setIsNotEmptyUserGifts={this.setIsNotEmptyUserGifts}
            />
          </BlockContainer>
        )}
        <UserPromoCode />
        {addresses.length > 0 && (
          <BlockContainer>
            <label className="user-profile__address-label">
              {t('Info.main_address')}
            </label>
            <div>
              {addresses.map((address) => (
                <Radio
                  id={address.id}
                  key={address.id}
                  addClass="user-profile__radio"
                  checked={+address.id === +checked}
                  name={address.id}
                  onChange={this.checkedHandler}
                >
                  {address.address}
                  {address.title ? (
                    <p className="user-profile__radio__title">
                      {address.title}
                    </p>
                  ) : undefined}
                  <div className="user-profile__radio__edit">
                    <button
                      type="button"
                      onClick={this.addressEdit.bind(this, address)}
                    >
                      {t('Info.edit')}
                    </button>
                    <button
                      type="button"
                      className="user-profile__radio__delete"
                      onClick={this.addressDelete.bind(this, {
                        address,
                        updateAddress,
                      })}
                    >
                      {t('Info.delete')}
                    </button>
                  </div>
                </Radio>
              ))}
            </div>
          </BlockContainer>
        )}

        <div className="profile__action-container">
          <button type="button" className="btn" onClick={this.logoutHandler}>
            {t('Info.exit')}
          </button>
          <button
            type="button"
            className="btn"
            onClick={this.openDeleteUserModal}
          >
            {t('Info.deleteUser')}
          </button>
        </div>
        <BlockContainer>
          <Subtitle>
            {t('Info.last order')}
            {orders.length ? ` (${orders.length})` : ''}{' '}
          </Subtitle>
          <div className="order__container__table">
            {orders.length ? (
              <table className="order-table">
                <thead className="order-table__items-header">
                  <tr>
                    <th className="order-table__num-header">
                      {t('Info.order_number_header')}
                    </th>
                    <th className="order-table__status-header">
                      {t('Info.order_status_header')}
                    </th>
                    <th className="order-table__sum-header">
                      {t('Info.order_sum_header')}
                    </th>
                    <th className="order-table__date-header">
                      {t('Info.order_date_header')}
                    </th>
                  </tr>
                </thead>
                {orders.map((order, index) => (
                  <OrderTable key={`order-${index}`} order={order} />
                ))}
              </table>
            ) : (
              <p className="order__container__default">
                {t('Info.when_you_make')},<br /> {t('Info.order_will_shown')}
              </p>
            )}
          </div>
        </BlockContainer>
        <BlockContainer>
          <Subtitle>{t('Info.Favorites')}</Subtitle>
          <Favorites />
        </BlockContainer>
        <ReorderModal />
        <AddressEditModal />
        <AddressDeleteModal />
      </>
    );
  }
}

export default withTranslation()(ProfileInfo);

export const getDisplayedLanguage = (lang: string) => {
  switch (lang) {
    case 'me':
      return 'mne';
    case 'hy':
      return 'am';
    default:
      return lang;
  }
};

import { useSelectMenu } from 'src/entities/menu';
import { IMineSlider } from './types';

export const useSliderItemUrl = (slide: IMineSlider) => {
  const menu = useSelectMenu();
  const { type, url_param, menu_id } = slide;
  const findMenu = menu.find((item) => item.id === menu_id);

  if (!type || !url_param) {
    return null;
  }
  if (type === 'product' && menu_id && findMenu) {
    return `menu/${findMenu.text_id}/${url_param}`;
  }

  if (type === 'menu') {
    return `menu/${url_param}`;
  }

  if (type === 'action') {
    return `akcii/${url_param}`;
  }

  return null;
};

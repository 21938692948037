import { useEffect } from 'react';
import factory from './Masks/factory';

export default function (props) {
  function getValidator() {
    return factory(props);
  }

  let _validator = getValidator();
  let _keyCode = null;
  const {
    className,
    name,
    defaultValue = '',
    required = false,
    disabled = false,
    placeholder: defaultPlaceholder = '',
    forwardRef,
    id,
  } = props;

  function getPlaceholder() {
    if (_validator && _validator.placeholder) {
      return _validator.placeholder;
    }
    return defaultPlaceholder;
  }

  function onChange(event) {
    if (_keyCode) {
      event.keyCode = _keyCode;
    }
    event.target.value = _validator.validate(event);
    if (props.onChange && typeof props.onChange === 'function') {
      props.onChange(event);
    }
    _keyCode = null;
  }

  function onKeyDown(event) {
    _keyCode = event.keyCode;
  }

  function onClick() {
    if (
      _validator &&
      _validator.onClick &&
      typeof _validator.onClick === 'function'
    ) {
      _validator.onClick(props);
    }
  }

  function onPaste() {
    if (
      _validator &&
      _validator.onPaste &&
      typeof _validator.onPaste === 'function'
    ) {
      _validator.onPaste(props);
    }
  }

  useEffect(() => {
    // didMount
    if (
      _validator &&
      _validator.onMount &&
      typeof _validator.onMount === 'function'
    ) {
      _validator.onMount(props);
    }

    return () => {
      // didUnmount
      if (
        _validator &&
        _validator.onUnmount &&
        typeof _validator.onUnmount === 'function'
      ) {
        _validator.onUnmount(props);
      }
      _validator = null;
      _keyCode = null;
    };
  }, []);

  return (
    <input
      id={id}
      type="text"
      onPaste={onPaste}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onChange={onChange}
      className={className}
      placeholder={getPlaceholder()}
      name={name}
      value={defaultValue}
      ref={forwardRef}
      required={required}
      disabled={disabled}
    />
  );
}

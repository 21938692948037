import store from 'modules/global-store';

const init = () => {
  store.initStore(window.__INITIAL_DATA__ || {});
};

function asyncFill() {
  const { menu: currentCategoryTextId, product } = store.get('params') || {
    menu: '',
    product: null,
  };
  const menuCategories = store.get('menu', []);
  const promises = [];
  menuCategories.forEach((category) => {
    if (category.text_id === currentCategoryTextId && !product) {
      return;
    }
    promises.push(store.fillProductsFromCategory(category));
  });
  return Promise.all(promises);
}

const storeDataManager = {
  init,
  asyncFill,
};

export default storeDataManager;

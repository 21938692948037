import styles from './info.module.scss';
import { useComplectationInfoModal } from '../../model/hooks';

export function InfoButton() {
  const modal = useComplectationInfoModal();

  return (
    <button
      className={styles.infoButton}
      aria-label="compectation info"
      onClick={() => {
        modal.show();
      }}
      type="button"
    />
  );
}

import SortingDirection from 'src/shared/ui/sorting-direction/SortingDirection';
import type { IColumnHead } from '../../model/types';
import styles from './columnHead.module.scss';

function ColumnHead({ sorting, title, onClick }: IColumnHead) {
  return (
    <th scope="col" className={styles.header}>
      <button
        type="button"
        className={styles.columnHeaderButton}
        onClick={onClick}
      >
        {sorting && (
          <div className={styles.sortingContainer}>
            <SortingDirection direction={sorting} />
          </div>
        )}
        <span className={styles.headerText}>{title}</span>
      </button>
    </th>
  );
}

export default ColumnHead;

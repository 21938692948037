import _serialize from 'serialize-javascript';
import productHelper from 'modules/helpers/product-helper';
import menuHelper from 'modules/helpers/menu-helper';
import api from 'modules/helpers/api';
import debug from '../debug';

let _store = {};

const set = (key, value, replace = true) => {
  if (replace) {
    return (_store[key] = value);
  }

  if (_store[key]) {
    debug(`${key} already exist in storage`, false);
  }
  _store[key] = value;
};

// old style
const put = (key, value, replace = true) => {
  set(key, value, replace);
};

const get = (key, defaultValue = null) => {
  if (!key) {
    return defaultValue;
  }
  if (_store[key]) {
    return _store[key];
  }

  return defaultValue;
};

// old style
const take = (key, defaultValue = null) => {
  return get(key, defaultValue);
};

const unlink = (key) => {
  if (_store[key]) {
    delete _store[key];
  }
};

const flush = () => {
  _store = {};
};

const initStore = (data = {}) => {
  _store = data || {};
};

const getStore = () => {
  return _store;
};

const serialize = () => {
  return _serialize(getStore());
};

const getToppingWithId = (id, defaultTopping, type = 'wok') => {
  return (
    get(`${type}_toppings`, []).find((product) => +product.id === +id) ||
    defaultTopping
  );
};

async function fillProductsFromCategory(category) {
  const WOK_CATEGORY_ID = 709;
  const { text_id: textId, id } = category;
  const products = await api('product.list', { menu: textId });

  products.forEach((product) => {
    menuHelper.pushProduct(textId, product);
    productHelper.push(product);
  });

  if (+id === WOK_CATEGORY_ID) {
    const groupWoks = await api('product.group_wok');
    set('group_wok', groupWoks);

    const wokHasToppings = (wok) => (wok.product_type || {}).name === 'wok';
    const allToppings =
      productHelper.getToppingsIdFromProducts(
        products.filter(wokHasToppings)
      ) || [];

    for (const { toppings: itemToppings = [] } of groupWoks) {
      for (const id of itemToppings) {
        if (id in allToppings) {
          continue;
        }
        allToppings.push(id);
      }
    }

    if (allToppings.length) {
      const wokToppings = await api('product.get', {
        id: allToppings,
      });
      set('wok_toppings', wokToppings);
    }
  }
}

function getProductWithId(id) {
  return get('products', {})[+id];
}

const globalStore = {
  set,
  put,
  get,
  take,
  flush,
  initStore,
  getStore,
  serialize,
  unlink,
  getToppingWithId,
  fillProductsFromCategory,
  getProductWithId,
};

export default globalStore;

/**
 * Created by rebu on 07.02.17.
 */

function print(str, isError) {
  if (typeof str === 'object') {
    console.log('DEBUG:');
    console.log(str);
    return;
  }

  if (isError) {
    console.error(`DEBUG: ${str}`);
  } else {
    console.warn(`WARN: ${str}`);
  }
}

function Debug(str, isError = true) {
  if (process.env.NODE_ENV !== 'production' && str) print(str, isError);
}

export default Debug;

export default function (props) {
  const _defaultPlaceholder = 'Default placeholder';
  let _patterns = {
    '#': /[0-9]/,
  };

  let _mask = '';
  // const defaultPlaceholder = '###-###-###-###';
  // const _patterns = {
  //   '#': new RegExp(/[0-9]/),
  // };
  // const _mask = '###-###-###-###';

  const { onValidate, required = false, mask = null, patterns = null } = props;
  let { placeholder } = props;

  if (!placeholder) {
    placeholder = _defaultPlaceholder;
  }

  if (patterns) {
    _patterns = patterns;
  }

  if (mask) {
    _mask = mask;
  }

  function _onValidate(b) {
    if (onValidate && typeof onValidate === 'function') {
      onValidate(b);
    }
  }

  function isFixed(s) {
    return !_patterns[s];
  }

  function isMasked(s, i) {
    if (isFixed(_mask[i])) {
      return s === _mask[i];
    }

    try {
      return _patterns[_mask[i]].test(s);
    } catch (e) {
      return false;
    }
  }

  function validate(event) {
    const {
      target: { value },
    } = event;
    let result = '';

    if (!value) {
      if (!required) {
        _onValidate(true);
      }
      return result;
    }

    let valueIndex = 0;
    let maskIndex = 0;
    while (value[valueIndex]) {
      if (!_mask[maskIndex]) {
        _onValidate(true);
        return result;
      }
      if (isMasked(value[valueIndex], maskIndex)) {
        result += value[valueIndex];
        valueIndex++;
        maskIndex++;
      } else {
        if (!isFixed(_mask[maskIndex])) {
          return result;
        }

        if (event.keyCode === 8) {
          return result;
        }

        while (isFixed(_mask[maskIndex])) {
          result += _mask[maskIndex];
          maskIndex++;
        }
      }
    }

    if (!_mask[maskIndex]) {
      _onValidate(true);
      return result;
    }

    if (event.keyCode !== 8) {
      while (_mask[maskIndex]) {
        if (isFixed(_mask[maskIndex])) {
          result += _mask[maskIndex];
          maskIndex++;
        }
      }
    }

    return result;
  }

  return {
    validate,
    placeholder,
  };
}

import { createSlice } from '@reduxjs/toolkit';
import type { ISocialLinks } from './types';

const initState = {} as ISocialLinks;

export const footerSocialSlice = createSlice({
  name: 'footerSocial',
  initialState: initState,
  reducers: {},
});

const selectFooterLinks = (state: RootState) => {
  return state.footerSocial;
};

export const footerLinksSliceSelectors = {
  selectFooterLinks,
};

import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { getToppings } from 'frontend/Components/NewCartOrder/Helpers';
import { IProductType } from 'types/product';
import style from './TextBlock.module.scss';

interface IChild {
  price: number;
  price_orig: number;
  product_id: string;
  qty: number;
}
interface ITextBlock {
  title: string;
  is_pickup: boolean;
  is_action: boolean;
  is_delivery: boolean;
  child: IChild[];
  productType?: IProductType;
}
function TextBlock({
  title,
  child,
  productType,
  is_pickup,
  is_action,
  is_delivery,
}: ITextBlock) {
  const { t } = useTranslation();
  const toppings = getToppings(child);

  const isShowText = is_action || !is_delivery || !is_pickup || child.length;

  return (
    <div>
      <p className={style.title}>{title}</p>
      {isShowText ? (
        <span className={style.toppings}>
          {is_action && (
            <span>{`${t('TextBlock.stock')}${
              !is_delivery || !is_pickup || child.length ? ', ' : ''
            }`}</span>
          )}
          {!is_delivery && (
            <span>{`${t('TextBlock.pickup')}${
              !is_pickup || child.length ? ', ' : ''
            }`}</span>
          )}
          {!is_pickup && (
            <span>{`${t('TextBlock.delivery')}${
              child.length ? ', ' : ''
            }`}</span>
          )}
          {Boolean(child.length && productType?.name !== 'wok') &&
            `${'\n'}${t('TextBlock.with_toppings')}`}
          {toppings}
        </span>
      ) : null}
    </div>
  );
}

export default memo(TextBlock);

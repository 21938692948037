import { useEffect, useState } from 'react';
import api from 'modules/helpers/api';
import { route } from 'modules/route';
import * as session from 'modules/session';
import globalStore from 'modules/global-store';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
/* import * as cartHelper from 'modules/helpers/cart-helper'; */
import Button from 'src/shared/ui/Button/Button';
import cn from 'classnames';
import Emitter from 'frontend/Components/NewCartOrder/Emitters';
import { getItemsFromCart } from 'frontend/Components/NewCartOrder/Helpers/index';
import { IProduct } from 'types/product';
import { IItems } from 'types/items';
import { useAppDispatch } from 'shared/lib/store';
import { removeFreeItems } from 'src/entities/complectation';
import { useGetProducts } from 'src/entities/product';
import styles from './stopListModal.module.scss';
import { getActions, getUserGifts } from '../../helpers';
import Card from '../card/Card';

type IProductIds = {
  product_ids: number[];
};

interface IStopListsModal {
  value: IProductIds;
}

interface ICategories {
  text_id: string;
  products: number[];
}

function StopListsModal({ value: { product_ids } }: IStopListsModal) {
  const { t } = useTranslation();
  const history = useHistory();
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const dispatch = useAppDispatch();
  const products = useGetProducts(product_ids);

  useEffect(() => {
    const unlisten = history.listen(({ pathname }) => {
      if (pathname.includes('/menu/')) Emitter.emit('MODAL_CLOSE');
    });
    return unlisten;
  }, [history]);

  const getLunchProducts = (items: IProduct[] = []) => {
    return items.filter((item) => {
      return +item.menu_id === 716;
    });
  };

  const disableAction = async () => {
    const { cart } = session.get();
    const actionsIds = getActions(product_ids, cart);
    const { giftSettings } = cart;

    if (actionsIds.length) {
      actionsIds.forEach((id) => {
        if (giftSettings[id]) {
          giftSettings[id].disable = true;
        }
      });
      try {
        const newCart = await api('cart.gifts', { giftSettings });
        session.set('cart', newCart);
        Emitter.emit('CART_CHANGE');
        return newCart;
      } catch (e) {
        console.log(e);
        return {};
      }
    }
    return {};
  };

  const findRemoveProductIds = () => {
    const items: IItems[] = getItemsFromCart();
    const arr: number[] = [];
    product_ids.forEach((product_id) => {
      items.forEach((item) => {
        if (item.child) {
          const found = item.child.find(
            (child) => +child.product_id === +product_id
          );
          if (found) {
            arr.push(item.product_id);
            return false;
          }
        }
        if (+item.product_id === +product_id) {
          arr.push(item.product_id);
        }
      });
    });
    return arr;
  };

  const disableUserGifts = async () => {
    const userGifts = session.get('userGifts');
    const ids = getUserGifts(product_ids, userGifts);

    if (!ids.length) return;

    session.set('userGifts', ids);
    try {
      const cart = await api('cart.remove.user.gifts', { ids });
      session.set('cart', cart);
      Emitter.emit('CART_CHANGE');
      Emitter.emit('USER_GIFTS_CHANGE');
    } catch (e) {
      console.log(e);
    }
  };

  const removeFromComplectation = () => {
    dispatch(removeFreeItems(product_ids));
  };

  const deleteProducts = async () => {
    const ids = findRemoveProductIds();
    try {
      await disableAction();
    } catch (e) {
      console.log(e);
    }
    try {
      const cart = await api('cart.remove', { ids });
      session.set('cart', cart);
      await disableUserGifts();
    } catch (e) {
      console.error(e);
      await disableUserGifts();
    }
    removeFromComplectation();
    return ids;
  };

  const handleDelete = async () => {
    setButtonsDisabled(true);
    try {
      await deleteProducts();
      Emitter.emit('CART_CHANGE');
      Emitter.emit('MODAL_CLOSE');
    } catch (e) {
      console.log(e);
      Emitter.emit('CART_CHANGE');
      setButtonsDisabled(false);
    }
  };

  const handleReplace = async () => {
    setButtonsDisabled(true);
    try {
      const ids = await deleteProducts();
      const categories: ICategories[] = globalStore.get('menu');
      const category = categories.find((cat) => cat.products.includes(ids[0]));
      console.log(category);
      const catUrl = category && category.text_id ? category.text_id : '';
      Emitter.emit('CART_CHANGE');
      history.push(route(`/menu/${catUrl}`));
    } catch (e) {
      console.log(e);
      Emitter.emit('CART_CHANGE');
      setButtonsDisabled(false);
    }
  };

  // const products = findStoppedInCart();
  const lunch_products = getLunchProducts(products);
  const isLunchProducts = !!lunch_products.length;

  const renderProducts = isLunchProducts ? lunch_products : products;
  const btn = cn(`sw-button ${styles.btn}`);

  return (
    <div className={styles.stopListsModal}>
      <div className={styles.titleContainer}>
        {!isLunchProducts && (
          <p>{t('locale.this_store_is_missing_the_following_items')}</p>
        )}
      </div>
      {!!renderProducts.length && (
        <Card
          {...{
            products: renderProducts,
            title: t('locale.not_available'),
          }}
        />
      )}
      <div className={styles.btnsContainer}>
        <Button
          className={btn}
          type="button"
          onClick={handleDelete}
          disabled={buttonsDisabled}
          text={t('locale.remove_items')}
        />
        <Button
          className={btn}
          type="button"
          onClick={handleReplace}
          disabled={buttonsDisabled}
          text={t('locale.replace_items')}
        />
      </div>
    </div>
  );
}

export default StopListsModal;

import BalloonIcon from '../../assets/ballon-icon.svg';
import styles from './addressSell.module.scss';

function AddressSell({
  address,
  onClick,
}: {
  address: string;
  onClick: () => void;
}) {
  return (
    <div className={styles.addressCell}>
      <div className={styles.addressIcon}>
        <BalloonIcon />
      </div>
      <button type="button" className={styles.addressText} onClick={onClick}>
        <span className={styles.address}>{address}</span>
      </button>
    </div>
  );
}

export default AddressSell;

import { memo } from 'react';
import openMovieModal from '../../Modals/MovieModal/utils';
import './vacancyVideo.scss';

function VacancyVideo({ title, videoLink, preview }) {
  return (
    <div
      className="vacancy-video_container"
      onClick={() => {
        openMovieModal({ videoLink });
      }}
    >
      <div
        style={{
          backgroundImage: `url(${preview})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '300px',
          width: '450px',
        }}
        className="vacancy-video_preview"
      >
        <div className="vacancy-video_play-button" />
      </div>
      <p className="vacancy-video_title">{title}</p>
    </div>
  );
}

export default memo(VacancyVideo);

import { combineReducers } from '@reduxjs/toolkit';
import {
  footerLinksSlice,
  footerSocialSlice,
} from 'src/frontend/widgets/footer';
import { countriesSlice } from 'src/entities/countries';
import { complectationSlice } from 'src/entities/complectation';
import { deliverySlice } from 'src/entities/delivery';
import { baseApi } from 'src/shared/api';
import { headerMenuSlice } from 'src/entities/header-menu';
import { pickupSlice } from 'src/entities/pickup';

export const rootReducer = combineReducers({
  [headerMenuSlice.name]: headerMenuSlice.reducer,
  [complectationSlice.name]: complectationSlice.reducer,
  [deliverySlice.name]: deliverySlice.reducer,
  [footerLinksSlice.name]: footerLinksSlice.reducer,
  [footerSocialSlice.name]: footerSocialSlice.reducer,
  [countriesSlice.name]: countriesSlice.reducer,
  [baseApi.reducerPath]: baseApi.reducer,
  [pickupSlice.name]: pickupSlice.reducer,
});

import styles from './styles.module.scss';

export function Caption({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  return (
    <p className={styles.caption}>
      <span className={styles.title}>{title}</span>
      <span className={styles.description}>{description}</span>
    </p>
  );
}

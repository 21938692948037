import { resetCart } from 'features/cart-reset';
import { useEffect } from 'react';
import { useAppDispatch } from 'shared/lib/store';
import { resetComplectation } from 'src/entities/complectation';

export const useResetOrder = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(resetComplectation());
    resetCart();
  }, [dispatch]);
};

import { useEffect, useRef } from 'react';

export const useStickySidebarEffect = () => {
  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const fullPage = document.body;
      const windowHeight = window.innerHeight;
      const fullPageHeight = fullPage.offsetHeight;
      const sidebarHeight = sidebarRef.current?.offsetHeight;

      if (!sidebarHeight) return;
      if (fullPageHeight < sidebarHeight) return;

      const scrollPosition = window.scrollY;

      // Проверяем, достиг ли сайдбар конца страницы
      if (scrollPosition + windowHeight >= sidebarHeight) {
        // Фиксируем сайдбар в нижней части экрана
        sidebarRef.current.style.position = 'fixed';
        sidebarRef.current.style.top = 'unset';
        sidebarRef.current.style.bottom = '0';
      } else {
        // Возвращаем обычное позиционирование сайдбара
        sidebarRef.current.style.position = 'absolute';
        sidebarRef.current.style.top = '0';
        sidebarRef.current.style.bottom = 'unset';
      }
    };

    const windowHeight = window.innerHeight;
    const sidebarHeight = sidebarRef.current?.offsetHeight;

    if (!sidebarHeight) return;

    if (windowHeight < sidebarHeight) {
      sidebarRef.current.style.position = 'absolute';
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return sidebarRef;
};

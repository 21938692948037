import globalStore from 'modules/global-store';
import { useTranslation } from 'react-i18next';
import styles from './time-and-phone-Info.module.scss';

export function TimeAndPhoneInfo() {
  const { t } = useTranslation();
  const city_info = globalStore.get('city_info');
  const { delivery, worktime, emailgate = false } = city_info;

  const current_city = globalStore.get('current_city');
  const isAllDayDelivery = delivery.from === delivery.to;
  const isAllDayWorktime = worktime.from === worktime.to;

  if (current_city.is_call_back) {
    return (
      <div className={styles.timeAndPhoneInfoContainer}>
        <a
          className={styles.phone}
          href={`tel:${current_city.phone.replace(/[^0-9]/g, '')}`}
        >
          {current_city.phone}
        </a>
        <div className={styles.shopTime}>
          {t('Footer.ShopHours')}:{' '}
          {isAllDayWorktime
            ? t('Footer.24 hours')
            : `${worktime.from} – ${worktime.to}`}
        </div>
        {!emailgate && (
          <div className={styles.deliveryTime}>
            {t('Footer.Delivery Hours')}:{' '}
            {isAllDayDelivery
              ? t('Footer.24 hours')
              : `${delivery.from} – ${delivery.to}`}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.timeAndPhoneInfoContainer}>
      <div className={styles.shopTime}>
        {t('Footer.ShopHours')}:{' '}
        {isAllDayWorktime
          ? `${t('Footer.24 hours')}`
          : `${worktime.from} – ${worktime.to}`}
      </div>
      {emailgate && (
        <div className={styles.deliveryTime}>
          {t('Footer.Delivery Hours')}:{' '}
          {isAllDayDelivery
            ? t('Footer.24 hours')
            : `${delivery.from} – ${delivery.to}`}
        </div>
      )}
    </div>
  );
}

import { useTranslation } from 'react-i18next';
import { analytics } from 'frontend/analytics/analytics';
import Emitter from 'frontend/Components/NewCartOrder/Emitters';
import cn from 'classnames';
import Button from 'src/shared/ui/Button/Button';
import modalStyles from 'src/frontend/Components/NewCartOrder/Modals/main-card-modal/mainCartModal.module.scss';
import { resetCart } from '../../api';
import styles from './resetCartModal.module.scss';

export function ResetCartModal() {
  const { t } = useTranslation();
  const closeModal = () => {
    Emitter.emit('MODAL_CLOSE');
  };

  const confirm = () => {
    resetCart().then(() => {
      Emitter.emit('MODAL_CALL', { modal: false });
      analytics.clearCartDesire();
    });
  };

  return (
    <div className={styles.resetCartModal}>
      <div className={modalStyles.titleContainer}>
        <p>{t('locale.clean_the_basket')}</p>
      </div>
      <div className={modalStyles.btnsContainer}>
        <Button
          className={cn('sw-button', styles.swButton)}
          onClick={confirm}
          text={t('locale.clean')}
        />
        <Button
          className={cn('sw-button', styles.swButton)}
          onClick={closeModal}
          text={t('locale.cancel')}
        />
      </div>
    </div>
  );
}

export default ResetCartModal;

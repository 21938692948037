import './NeedPromocodeInfo.scss';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';

class NeedPromocodeInfo extends Component {
  constructor(props) {
    super(props);
    const {
      product: { promocodes },
    } = props;
    this.state = {
      needPromocode:
        Array.isArray(promocodes) && !promocodes.length ? false : promocodes,
    };
  }

  render() {
    const { needPromocode } = this.state;
    const { t } = this.props;
    return needPromocode ? (
      <div className="needPromocode">
        {t('NeedPromocodeInfo.needPromocode')}
      </div>
    ) : null;
  }
}

export default withTranslation()(NeedPromocodeInfo);

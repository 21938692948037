import { memo } from 'react';
import { Media } from 'src/shared/lib/media';
import { Desktop } from './desktop/Desktop';
import { Mobile } from './mobile/Mobile';
import styles from './header.module.scss';
import MobileNavigation from './mobile/mobileNavigation/MobileNavigation';

const Header = memo(() => {
  return (
    <header id="main-header" className={styles.header}>
      <Media greaterThanOrEqual="notebook" className={styles.desktop}>
        <Desktop />
      </Media>
      <Media lessThan="notebook" className={styles.mobile}>
        <Mobile />
        <MobileNavigation />
      </Media>
    </header>
  );
});

export { Header };

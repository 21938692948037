import axios from 'axios';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Button from 'src/shared/ui/Button/Button';
import { userEmitter } from 'src/modules/event-emitters';
import Emitter from 'frontend/Components/NewCartOrder/Emitters';
import * as session from 'src/modules/session';

import styles from './deleteUserModal.module.scss';
import modalStyles from '../../main-card-modal/mainCartModal.module.scss';

function DeleteUserModal() {
  const { t } = useTranslation();
  const closeModal = () => {
    Emitter.emit('MODAL_CLOSE');
  };

  const deleteUser = () => {
    axios
      .post('/user/delete')
      .then(() => {
        userEmitter.emit('User.Change');
        session.set('user', null);
        userEmitter.emit('User.Logout.Event');
        Emitter.emit('MODAL_CALL', { modal: 'AfterDeleteUserModal' });
      })
      .catch((error) => {
        console.log('delete request failed');
        console.log(JSON.stringify(error));
        return false;
      });
  };

  return (
    <div className={styles.deleteUserModal}>
      <div className={modalStyles.textRow}>
        {t('Info.confirmDeleteInfo')}
        <div className={cn(modalStyles.btnsContainer, styles.btnsColumn)}>
          <Button
            className={cn('sw-button', styles.swButton)}
            onClick={deleteUser}
            text={t('Info.confirmDeleteButton')}
          />
          <Button
            className={cn('sw-button', styles.swButton)}
            onClick={closeModal}
            text={t('User.cancelDeleteUser')}
          />
        </div>
      </div>
    </div>
  );
}

export default DeleteUserModal;

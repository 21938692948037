import { errorCodes } from '../api/errorCodes';

export interface IDeliveryCheckParams {
  latitude: string;
  longitude: string;
  city: string;
  lang: string;
  order_sum?: number;
  enabled_discount?: number;
  delivery_date?: string;
  product_ids?: number[];
  promo_code?: string;
  bonus_phone?: string;
}

// cost_with_card - пользователь авторизован + есть карта друга;
// cost - пользователь не авторизован или авторизован, но нет карты друга;
export const enum EParams {
  cost_with_card = 'cost_with_card',
  cost = 'cost',
}

// 1 - увеличение стоимости доставки;
// 2 - уменьшение стоимости доставки;
export const enum ENotifyId {
  upperCostId = 1,
  lowerCostId = 2,
}

export interface IDeliveryCheckNotify {
  params: EParams[];
  notify_id: ENotifyId;
}

export interface IDeliveryCheckOk {
  avg_delivery_time: number;
  base_id: number;
  card_applePay: boolean;
  card_courier: boolean;
  card_googlePay: boolean;
  card_online: boolean;
  cash: boolean;
  cost_with_card: number;
  cost_without_card: number;
  delivery_price: number;
  entity_text: string;
  error: unknown;
  order_min_price: number;
  pickup: boolean;
  store_id: number;
  store_uuid: string;
  zero_min_order: number;
  notify: IDeliveryCheckNotify[];
}

export interface IDeliveryCheckStopList {
  stop_lists?: number[];
}

type DeliveryCheckErrorType = keyof typeof errorCodes;

export interface IDeliveryError {
  type: DeliveryCheckErrorType;
  data?: string | number[];
}

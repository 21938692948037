import cn from 'classnames';
import styles from './styles.module.scss';

export interface IBlockContainerProps {
  children: React.ReactNode;
  childrenPosition?: 'start' | 'end' | 'stretch';
}
export function BlockContainer({
  children,
  childrenPosition = 'stretch',
}: IBlockContainerProps) {
  const className = cn(styles.root, {
    [styles.start]: childrenPosition === 'start',
    [styles.end]: childrenPosition === 'end',
  });

  return <article className={className}>{children}</article>;
}

const allowedCities = [
  'spb',
  'vsevolozsk',
  'vyborg',
  'gatchina',
  'devyatkino',
  'zelengorsk',
  'kirovs',
  'kolpino',
  'kronshtadt',
  'kudrovo',
  'kuzmolovskij',
  'murino',
  'pavlovsk',
  'pargolovo',
  'priozersk',
  'pushkin',
  'roschino',
  'sertolovo',
  'sestroreck',
  'toksovo',
  'yan',
  'bugry',
  'sosnovo',
  'istra',
  'lyubertsy',
  'kommunarka',
  'reutov',
  'vni',
  'msk',
  'dro',
  'apr',
  'kli',
  'golicyno',
  'bob',
  'raz',
  'kraskovo',
  'luk',
  'noginsk',
  'zareche',
  'ded',
  'moskovskiy',
  'putilkovo',
  'podolsk',
  'zvenigorod',
  'solnechnogorsk',
  'stupino',
  'voskresensk',
  'bogorodskoe',
  'pushkino',
  'egorevsk',
  'dzerzhinskiy',
  'lopatino',
  'malakhovka',
  'nak',
  'butovo',
  'naro-fominsk',
  'latirkino',
  'ivanteevka',
  'skhodnya',
  'vatutinki',
  'ruz',
  'zaraysk',
  'sha',
  'gle',
  'kuc',
  'pav',
  'mozhaysk',
  'kor',
  'ramenskoe',
  'mytishchi',
  'troitsk',
  'khimki',
  'chekhov',
  'bykovo',
  'volokolamsk',
  'rzh',
  'elektrostal',
  'shchelkovo',
  'korolev',
  'klin',
  'zhukovskiy',
  'fryazino',
  'ser',
  'zheleznodorozhnyy',
  'zelenograd',
  'krasnogorsk',
  'kolomna',
  'sel',
  'lobnya',
  'shcherbinka',
  'krasnoznamensk',
  'odintsovo',
  'goluboe',
  'molokovo',
  'tuchkovo',
  'razdory',
  'tomilino',
  'brehovo',
  'vnu',
  'gorki-desyat',
  'shakhovskaya',
  'misaylovo',
  'domodedovo',
  'vidnoe',
  'dub',
  'mon',
  'krasnoarmeysk',
  'nemchinovka',
  'orekhovo-zuevo',
  'dolgoprudnyy',
  'dmitrov',
  'mirnyi',
  'balashikha',
  'serpuhov',
  'chlb',
  'magnitogorsk',
  'perm',
  'ufa',
  'ekb',
  'voronezh',
  'barnaul',
  'novosibirsk',
  'tomsk',
];

export { allowedCities };

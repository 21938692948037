import globalStore from 'modules/global-store';
import * as session from 'modules/session';

const set = (viewedProducts) => {
  session.set('viewed_products', viewedProducts);
};

const add = (product) => {
  const viewedProducts = globalStore.get('viewed_products', []);
  const findedIndex = viewedProducts.findIndex((v) => v.id === product.id);
  if (findedIndex !== -1) {
    viewedProducts.splice(findedIndex, 1);
  }
  viewedProducts.push(product);
  globalStore.set('viewed_products', viewedProducts);
};

const get = (excludeProductId, limit) => {
  const sessionViewed = session.get('viewed_products', []);
  const storeViewed = globalStore.get('viewed_products', []);
  let viewedProducts = sessionViewed
    .map(({ product_id }) => {
      return storeViewed.find(({ id }) => id === product_id);
    })
    .filter((x) => x);
  if (excludeProductId) {
    viewedProducts = viewedProducts.filter(
      ({ id = null }) => id !== excludeProductId
    );
  }
  if (limit && viewedProducts.length > limit) {
    viewedProducts = viewedProducts.slice(0, limit);
  }
  return viewedProducts;
};

export default {
  add,
  get,
  set,
};

import { Media } from 'shared/lib/media';
import { SmartLink } from 'shared/ui/smart-link';
import cn from 'classnames';
import { InternalImage } from 'shared/ui/image';
import { useInView } from 'react-intersection-observer';
import { OneLineDraw } from '../one-line-draw/OneLineDraw';
import styles from './styles.module.scss';
import { useBlocksScheme } from './scheme';

function VerticalBlock({ name, link }: { name: string; link: string }) {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: false,
    rootMargin: '1000px 0px 0px 0px',
  });
  const { findbyName } = useBlocksScheme();
  const item = findbyName(name);
  if (!item) return null;

  return (
    <SmartLink className={cn(styles.network, item.className)} href={link}>
      <div className={styles.innerDescr} ref={ref}>
        <div
          className={cn(styles.innerDescrBoximage, styles.animated, {
            [styles.fadeInUp]: inView,
            [styles.fadeOutDown]: !inView,
          })}
        >
          <InternalImage
            width={450}
            height={450}
            src={item.imageSrc}
            alt={item.imageAlt}
            className={styles.desktopImageWrapper}
          />
        </div>
        <div
          className={cn(styles.networkContent, styles.animated, {
            [styles.fadeInUp]: inView,
            [styles.fadeOutDown]: !inView,
          })}
        >
          <div className={styles.networkDescription}>
            <span className={styles.networkContentTitle}>{item.title}</span>
            <span className={styles.networkContentUnder}>
              {item.description}
            </span>
          </div>
          <ul className={styles.networkContentList}>
            {item.listItems.map((element) => (
              <li key={element} className={styles.listItem}>
                {element}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </SmartLink>
  );
}

export function VerticalBlocks({
  data,
}: {
  data: { name: string; link: string }[];
}) {
  return (
    <>
      <Media greaterThanOrEqual="notebook">
        <OneLineDraw />
        <ul style={{ paddingBottom: '9vw' }}>
          {data.map(({ name, link }) => {
            return <VerticalBlock key={name} name={name} link={link} />;
          })}
        </ul>
      </Media>
      <Media lessThan="notebook">
        <ul>
          {data.map(({ name, link }) => {
            return <VerticalBlock key={name} name={name} link={link} />;
          })}
        </ul>
      </Media>
    </>
  );
}

import menuHelper from 'modules/helpers/menu-helper';
import { merge } from 'lodash';
import { TFunction } from 'i18next';

function findLowerCost(id: number, def = 0) {
  let result = def;
  const menu = menuHelper.getById(id);
  if (!menu) {
    result = def;
  }
  const products = menuHelper.getProducts(menu.text_id);
  if (products && products.length === 0) {
    result = def;
  }
  const prices = products.map((p) => p.price);
  result = Math.min(...prices);
  return result.toString();
}

/// @todo make list possible a handler
function getConfig(t: TFunction) {
  return {
    items: [
      {
        number: 'one',
        active: false,
        timer: 16900,
        title: t('Arguments.vkusno_i_raznoobrazno'),
        text: null,
        list: [
          t('Arguments.more_350_positions_in_menu'),
          t('Arguments.only_froesh_ingridients'),
          t('Arguments.reload_menu_4_times_of_year'),
        ],
        link: {
          href: 'menu',
          text: t('Arguments.get_it'),
        },
      },
      {
        number: 'two',
        active: false,
        timer: 12700,
        title: t('Arguments.our_prices'),
        text: null,
        list: [
          t('Arguments.sushi_price_from').replace(
            /\d+/g,
            findLowerCost(708, 59)
          ),
          t('Arguments.soups_price_from').replace(
            /\d+/g,
            findLowerCost(713, 35)
          ),
          t('Arguments.rolls_price_from').replace(/\d+/g, findLowerCost(1, 99)),
          t('Arguments.busines_price_from').replace(
            /\d+/g,
            findLowerCost(716, 175)
          ),
        ],
        link: {
          href: 'menu',
          text: t('Arguments.check'),
        },
      },
      {
        number: 'three',
        active: false,
        timer: 8200,
        title: t('Arguments.actions'),
        text: [t('Arguments.actions_text_1'), t('Arguments.actions_text_2')],
        list: null,
        link: {
          href: 'akcii',
          text: t('Arguments.find_sale'),
        },
      },
      {
        number: 'four',
        active: false,
        timer: 4700,
        title: t('Arguments.standarts'),
        text: null,
        list: [
          t('Arguments.standarts_text_1'),
          t('Arguments.standarts_text_2'),
        ],
        link: {
          href: 'menu',
          text: t('Arguments.see'),
        },
      },
      {
        number: 'five',
        active: false,
        timer: 2100,
        title: t('Arguments.shops_title'),
        text: [t('Arguments.shops_text')],
        list: null,
        link: {
          href: 'addresses',
          text: t('Arguments.shops'),
        },
      },
      {
        number: 'six',
        active: false,
        timer: 500,
        title: t('Arguments.delivery_title'),
        text: null,
        list: [t('Arguments.delivery_text_1'), t('Arguments.delivery_text_2')],
        link: {
          href: 'delivery',
          text: t('Arguments.delivery'),
        },
      },
    ],
  };
}

function getConfigUa(t: TFunction) {
  return {
    items: {
      1: {
        list: [
          t('Arguments.sushi_price_from_ua').replace(
            /\d+/g,
            findLowerCost(708, 17)
          ),
          t('Arguments.soups_price_from_ua').replace(
            /\d+/g,
            findLowerCost(713, 19)
          ),
          t('Arguments.rolls_price_from_ua').replace(
            /\d+/g,
            findLowerCost(1, 25)
          ),
          t('Arguments.busines_price_from_ua').replace(
            /\d+/g,
            findLowerCost(716, 79)
          ),
        ],
      },
      4: {
        text: [t('Arguments.shops_text_ua')],
      },
      5: {
        list: [
          t('Arguments.delivery_text_1_ua'),
          t('Arguments.delivery_text_2_ua'),
        ],
      },
    },
  };
}

export default (t: TFunction, isUa: boolean) => {
  const config = getConfig(t);

  if (isUa) {
    return merge(config, getConfigUa(t));
  }
  return config;
};

import { Promocode as PromocodeApi } from './PromocodeApi';

class Api {
  /**
   * Set promocode.
   * @param promocode
   * @param productId
   * @returns {Promise<cart>}
   */
  static setPromocode(promocode = '', productId = null) {
    return PromocodeApi.setPromocode(promocode, productId);
  }
}

export default Api;

import './VacancyContactsLineMobile.scss';
import { useTranslation } from 'react-i18next';

function VacanciesContactLineMobile({
  vacancyEmail,
}: {
  vacancyEmail: string;
}) {
  const { t } = useTranslation();
  return (
    <div className="vacancies-contacts-line">
      {t('VacancyContactsLine.Vacancies-contacts')}&nbsp;
      <a
        className="vacancies-contacts-line__link"
        href={`mailto:${vacancyEmail}`}
      >
        {vacancyEmail}
      </a>
    </div>
  );
}

export default VacanciesContactLineMobile;

import { useTranslation } from 'react-i18next';
import { useSelectCity } from 'src/entities/cities';
import { useGetStoreInfoQuery } from 'src/entities/stores';
import style from './textInfo.module.scss';

interface ITextInfoProps {
  orderPhone: string;
  isOrderReceived: boolean;
  storeId: number;
}

export function TextInfo({
  orderPhone,
  isOrderReceived,
  storeId,
}: ITextInfoProps) {
  const { t } = useTranslation();
  const { order_info, text_id, phone } = useSelectCity();
  const { data } = useGetStoreInfoQuery(
    { city: text_id, id: storeId },
    { skip: !!phone }
  );

  if (
    order_info !== 'phone' &&
    order_info !== 'sms' &&
    order_info !== 'no_info'
  )
    return null;

  const storePhone = data?.data.store.phone;
  const callCenterPhone = phone || storePhone;
  const isRenderCallCenterInfo = !!callCenterPhone && isOrderReceived;

  return (
    <>
      {order_info !== 'no_info' && (
        <p className="">
          {t(`OrderInfo.call to your ${order_info}`, { orderPhone })}
        </p>
      )}
      {isRenderCallCenterInfo && (
        <p className="">
          {t(`OrderInfo.call to sw ${order_info}`)}&nbsp;
          <a className={style.phoneLink} href={`tel:${callCenterPhone}`}>
            {callCenterPhone}
          </a>
        </p>
      )}
    </>
  );
}

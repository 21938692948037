function Icon({ w, h, icon, ...props }) {
  return (
    <svg
      viewBox={`0 0 ${w} ${h}`}
      className={`icon icon-${icon}`}
      onClick={(event) => {
        if (props.onClick) props.onClick(event);
      }}
    >
      <use xlinkHref={`#icon-${icon}`} />
    </svg>
  );
}

export default Icon;

import { baseApi } from 'shared/api';

export const CODES = {
  SUCCESS: 200,
  ORDER_PAYMENT_ORDER_NOT_FOUND: 107,
  ORDER_PAYMENT_TRANSACTION_EXIST: 108,
  ORDER_PAYMENT_TRANSACTION_REGISTER_ERROR: 109,
};

export const registerTransactionApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    registerTransaction: build.query<
      {
        data?: { paymentFormUrl: string };
        message?: string;
        code: number;
        result: string;
      },
      { order_hash: string; return_url: string }
    >({
      query: (params) => ({
        url: '/endpoint/payment/register_transaction',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const { useRegisterTransactionQuery } = registerTransactionApi;

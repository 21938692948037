export const uaPaymentScheme = [
  {
    id: 'mastercard',
    link: 'https://www.mastercard.ua/uk-ua/personal/get-support/safety-and-security/securecode.html',
  },
  {
    id: 'visa',
    link: '',
    disabled: true,
  },
  {
    id: 'pumb',
    link: 'https://www.pumb.ua/ru',
  },
];

// past link for VISA: https://pps.fuib.com/payment/resources/739C0C071EE75948A22CF1205620361A/RU/help-vbv-RU.html;

import Emitters from 'frontend/Components/NewCartOrder/Emitters';
import { getItemsDisablesForOrderType } from 'frontend/Components/NewCartOrder/Helpers';
import { useForgetComplectationModal } from 'features/comlectation-info';
import { MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import Scroll from 'react-scroll';

const loginHandler = (
  e: MouseEvent<HTMLButtonElement>,
  handleAfterModal: (success: boolean) => void
) => {
  e.preventDefault();
  Emitters.emit('MODAL_CALL', {
    modal: 'RegisterOrAuthModal',
    info: 'default',
    value: { handleAfterModal },
  });
};

const deleteProductsHandler = (
  e: MouseEvent<HTMLButtonElement>,
  isDelivery: boolean,
  handleAfterModal: (success: boolean) => void
) => {
  e.preventDefault();
  Emitters.emit('MODAL_CALL', {
    modal: 'DisabledProductsModal',
    value: {
      product_ids: getItemsDisablesForOrderType(isDelivery),
      isDelivery,
      handleAfterModal,
    },
  });
};

export const useNextHandler = ({
  isLoginNeed,
  isDelivery,
  isDisabledProducts,
}: {
  isLoginNeed: boolean;
  isDelivery: boolean;
  isDisabledProducts: boolean;
}) => {
  const history = useHistory();
  const modal = useForgetComplectationModal();

  const handleAfterModal = (success: boolean) => {
    const scrollToTop = () => {
      Scroll.animateScroll.scrollTo(0, {
        duration: 300,
        delay: 100,
      });
    };

    if (!success) {
      scrollToTop();
      return;
    }

    if (isDelivery) {
      history.push('delivery');
      return;
    }
    if (!isDelivery) {
      history.push('pickup');
      return;
    }
    scrollToTop();
  };

  if (isLoginNeed) {
    return (e: MouseEvent<HTMLButtonElement>) => {
      modal.hide();
      loginHandler(e, handleAfterModal);
    };
  }

  if (isDisabledProducts) {
    return (e: MouseEvent<HTMLButtonElement>) => {
      modal.hide();
      deleteProductsHandler(e, isDelivery, handleAfterModal);
    };
  }
};

export default class Str {
  static new(aString) {
    return new Str(aString);
  }

  constructor(aString) {
    this._str = aString;
  }

  hasNotSubstring(aString) {
    return !this.hasSubstring(aString);
  }

  hasSubstring(aString) {
    return this._str.indexOf(aString) !== -1;
  }

  trimLeft(aString) {
    if (this._str.startsWith(aString)) {
      const trimmedStr = this._str.substr(aString.length);
      return Str.new(trimmedStr).trimLeft(aString);
    }
    return this;
  }

  upperCaseFirstLetter() {
    return Str.new(this._str[0].toUpperCase() + this._str.substring(1));
  }

  toString() {
    return this._str;
  }
}

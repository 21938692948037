import { useTranslation } from 'react-i18next';
import { Suggest } from 'features/address-suggest';
import { DeliveryMap } from 'features/delivery-map';
import { useSelectCity } from 'src/entities/cities';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/lib/store';
import {
  deliverySliceSelectors,
  setDeliveryAddress,
  useGetAddressQuery,
} from 'src/entities/delivery';
import { useCityAndLang } from 'shared/lib/use-city-and-lang';
import { skipToken } from '@reduxjs/toolkit/query';
import styles from './suggestionField.module.scss';

function SuggestionField({ field }: { field: any }) {
  const { t } = useTranslation();

  const {
    city_name: cityName,
    latitude: lat,
    longitude: lng,
  } = useSelectCity();

  const dispatch = useAppDispatch();

  const { fullAddress, addressError } = useAppSelector(
    deliverySliceSelectors.selectFullAddress
  );

  const { city, lang } = useCityAndLang();

  const [newAddress, setNewAddress] = useState<string | number[] | null>(null);

  const params = newAddress
    ? { coordsOrString: newAddress, cityName, cityTextId: city, lang }
    : skipToken;
  const {
    data: newFullAddress,
    isLoading,
    isFetching,
  } = useGetAddressQuery(params, {
    refetchOnMountOrArgChange: true,
  });

  const setAddress = (address: string) => {
    setNewAddress(address);
  };

  const setCurrentCenter = (coors: number[] | null) => {
    setNewAddress(coors);
  };

  useEffect(() => {
    if (newFullAddress) {
      dispatch(setDeliveryAddress(newFullAddress));
    }
  }, [newFullAddress]);

  const { name, required, fieldsetClassName, invalid, value = '' } = field;

  return (
    <div
      className={`input-row ${fieldsetClassName || ''} ${
        invalid ? ' invalid' : ''
      }`}
    >
      <label htmlFor={`field-${name}`} className={required ? 'required' : ''}>
        {t('locale.address')}
      </label>

      <Suggest
        fullAddress={fullAddress}
        setAddress={setAddress}
        cityCoords={{ lat, lng }}
        cityName={cityName}
        lang={lang}
        name={name}
        required={required}
        placeholder={t(`locale.Enter_address`)}
        initialValue={value}
      />
      <label htmlFor={`field-${name}`} className="bottom-label desktop">
        {t('locale.If_you_do_not_found_address')}
      </label>
      <div className={styles.mapContainer}>
        <DeliveryMap
          fullAddress={fullAddress}
          addressError={addressError}
          setCurrentCenter={setCurrentCenter}
          cityCoords={{ lat, lng }}
          isNowFetching={isLoading || isFetching}
        />
      </div>
    </div>
  );
}

export default SuggestionField;

import globalStore from 'modules/global-store';
import { IStock } from 'types/stocksTypes';
import CardStock from './CardStock';

const addStocks = ({
  CardComponents,
  itemsGrid,
  menuId,
}: {
  CardComponents: JSX.Element[];
  itemsGrid: number;
  menuId: string;
}) => {
  const stocks: IStock[] = globalStore.get('stock_catalog') || [];

  const productWitStocks = [...CardComponents];
  const filteredStocks = stocks.filter(
    (elem) => !!elem.menu_id.find((id) => +id === +menuId)
  );

  const filtredStocksTypeOne = filteredStocks.filter(({ type }) => +type === 1);
  const filtredStocksTypeTwo = filteredStocks
    .filter(({ type }) => +type === 2)
    .sort((a1, a2) => a1.position - a2.position);

  filtredStocksTypeOne.forEach((stock) => {
    const cardStock = <CardStock data={stock} key={`stock${stock.id}`} />;
    const pos = stock.position - 1;
    productWitStocks.splice(pos, 0, cardStock);
  });
  filtredStocksTypeTwo.forEach((stock, index) => {
    const inserted_full_width_cards_count = index + 1;
    const pos =
      (stock.position - 1) * itemsGrid + inserted_full_width_cards_count;
    const cardStock = <CardStock data={stock} key={`stock${stock.id}`} />;
    productWitStocks.splice(pos, 0, cardStock);
  });
  return productWitStocks;
};

export default addStocks;

import { Component } from 'react';
import { cardsEmitter, filterPanelEmitter } from 'modules/event-emitters';
import autobind from 'autobind-decorator';
import { replaceQuery, getQueryObject } from 'modules/router-query-utils';
import * as session from 'modules/session';
import { withTranslation } from 'react-i18next';
import SortCheckbox from '../Components/Checkbox/SortCheckbox/SortCheckbox';

class Popular extends Component {
  constructor(props) {
    super(props);
    const query = session.get('query');
    const popular = query.popular ? +query.popular : SortCheckbox.STATE.NONE;
    this.state = {
      sortState: popular,
    };
  }

  componentDidMount() {
    cardsEmitter.addListener(
      'ProductList.sortPrice',
      this.filterByPriceHandler
    );
    filterPanelEmitter.addListener('reset_all', this.reset);
  }

  componentWillUnmount() {
    cardsEmitter.removeListener(
      'ProductList.sortPrice',
      this.filterByPriceHandler
    );
    filterPanelEmitter.removeListener('reset_all', this.reset);
  }

  @autobind
  onTogglePrice({ sortState }) {
    this.setState({ sortState });
    const query = getQueryObject({ popular: sortState });
    delete query.popular;
    replaceQuery(query);
    cardsEmitter.emit('ProductList.sortPopular', sortState);

    // eventBus.emitEvent('product_filter', [
    //   {
    //     type: 'popular',
    //   },
    // ]);
  }

  @autobind
  filterByPriceHandler(sortState) {
    if (sortState !== SortCheckbox.STATE.NONE) {
      this.setState({
        sortState: SortCheckbox.STATE.NONE,
      });
    }
  }

  @autobind
  reset() {
    this.onTogglePrice({ name: 'popular', sortState: SortCheckbox.STATE.NONE });
  }

  render() {
    const { sortState } = this.state;
    const { t } = this.props;
    return (
      <SortCheckbox
        name="popular"
        title={t('Popular.By popular')}
        sortState={sortState}
        onToggle={this.onTogglePrice}
      />
    );
  }
}

export default withTranslation()(Popular);

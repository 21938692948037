import { paymentTypes } from '../constants/paymentTypes';

const gtag = (...args) => {
  if (window) {
    return window.gtag(...args);
  }
};

export const googleAnalytics = {
  orderAllAction: () =>
    gtag('event', 'order_all_action', { event_category: 'form' }), // После отправки любой формы заказа

  orderDeliveryTypeAction: (type) => {
    switch (type) {
      case 'delivery':
        gtag('event', 'order_delivery_action', { event_category: 'form' }); // После отправки формы, в которой была выбрана "доставка"
        break;

      case 'pickup':
        gtag('event', 'order_pickup_action', { event_category: 'form' }); // После отправки формы, в которой был выбран "самовывоз"
        break;

      default:
        break;
    }
  },

  mailingAction: () =>
    gtag('event', 'mailing_action', { event_category: 'form' }), // После отправки формы подписки на рассылку

  appStoreAttention: () =>
    gtag('event', 'appstore_attention', { event_category: 'click' }), // При клике на иконку приложения и переходе в app store

  googlePlayAttention: () =>
    gtag('event', 'googleplay_attention', { event_category: 'click' }), // При клике на иконку приложения и переходе в google play

  akciiAttention: () =>
    gtag('event', 'akcii_attention', { event_category: 'click' }), // При посещении раздела "акции"

  clearCartDesire: () =>
    gtag('event', 'clear_cart_desire', { event_category: 'click' }), // Пользователь кликнул "Очистить корзину"

  productCartDesire: () =>
    gtag('event', 'product_cart_desire', { event_category: 'click' }), // Добавление ЛЮБОГО товара в корзину откуда угодно (срабатывает 1 раз за сеанс)

  addProductAction: (from) => {
    switch (from) {
      case 'catalog':
        gtag('event', 'product_catalog_cart_desire', {
          event_category: 'click',
        }); // Добавление ЛЮБОГО товара в корзину из каталога
        break;
      case 'modal':
        gtag('event', 'product_popup_cart_desire', { event_category: 'click' }); // Добавление ЛЮБОГО товара в корзину с попапа
        break;
      case 'productPage':
        gtag('event', 'product_card_cart_desire', { event_category: 'click' }); // Добавление ЛЮБОГО товара в корзину с карточки товара
        break;
      default:
        break;
    }
  },

  discountDesire: () =>
    gtag('event', 'discount_desire', { event_category: 'click' }), // Пользователь нажал кнопку "Отлично" после ввода проверочного кода

  promocodeYesDesire: () =>
    gtag('event', 'promocode_yes_desire', { event_category: 'click' }), // Промокод удачно активирован

  promocodeNoDesire: () =>
    gtag('event', 'promocode_no_desire', { event_category: 'click' }), // После применения промокода пользователь получил сообщение "УПС! ПОХОЖЕ, ЭТОТ ПРОМОКОД НЕДОСТУПЕН.*"

  deliveryFieldsAction: (field) => {
    switch (field) {
      case 'address':
        gtag('event', 'adress_action', { event_category: 'field' }); // Пользователь ввел данные в поле "Адрес"
        break;
      case 'name':
        gtag('event', 'name_action', { event_category: 'field' }); // Пользователь ввел данные в поле "Имя"
        break;
      case 'email':
        gtag('event', 'email_action', { event_category: 'field' }); // Пользователь ввел данные в поле "email"
        break;
      case 'phone':
        gtag('event', 'phone_action', { event_category: 'field' }); // Пользователь ввел данные в поле "Телефона"
        break;
      case 'entrance':
        gtag('event', 'front_action', { event_category: 'field' }); // Пользователь ввел данные в поле "Парадная"
        break;
      case 'comment':
        gtag('event', 'comment_action', { event_category: 'field' }); // Пользователь ввел данные в поле "комментарий к заказу"
        break;
      case 'apartment':
        gtag('event', 'apartment_action', { event_category: 'field' }); // Пользователь ввел данные в поле "Квартира"
        break;
      case 'floor':
        gtag('event', 'floor_action', { event_category: 'field' }); // Пользователь ввел данные в поле "Этаж"
        break;
      default:
        break;
    }
  },

  phoneAction: (isValidPhone) => {
    if (!isValidPhone) {
      gtag('event', 'phone_error', { event_category: 'field' }); // Ошибка валидации поля "Телефона"
    }
  },

  fasterAction: () =>
    gtag('event', 'faster_action', { event_category: 'form_field' }), // В отправленной форме заказа, пользователь выбрал "Как Можно Скорее"

  slowerAction: () =>
    gtag('event', 'slower_action', { event_category: 'form_field' }), // В отправленной форме заказа, пользователь выбрал "Ко Времени"

  paymentTypeAction: (paymentType) => {
    switch (paymentType) {
      case paymentTypes.CASH:
        gtag('event', 'cash_action', { event_category: 'form_field' }); // В отправленной форме заказа, пользователь выбрал "Картой Курьеру"
        break;
      case paymentTypes.CARD_TO_COURIER:
        gtag('event', 'card_action', { event_category: 'form_field' }); // В отправленной форме заказа, пользователь выбрал "Картой Курьеру"
        break;
      case paymentTypes.CARD_ONLINE:
        gtag('event', 'online_action', { event_category: 'form_field' }); // В отправленной форме заказа, пользователь выбрал "Картой Онлайн"
        break;
      default:
        break;
    }
  },

  errorFieldAction: (name) => {
    switch (name) {
      case 'name':
        gtag('event', 'name_error', { event_category: 'field' }); // Ошибка валидации поля "Имя"
        break;
      case 'phone':
        gtag('event', 'phone_error', { event_category: 'field' }); // Ошибка валидации поля "Телефона"
        break;
      case 'email':
        gtag('event', 'email_error', { event_category: 'field' }); // Ошибка валидации поля "email"
        break;
      case 'address':
        gtag('event', 'adress_error', { event_category: 'field' }); // Ошибка валидации поля "Адрес"
        break;
      case 'apartment':
        gtag('event', 'apartment_error', { event_category: 'field' }); // Ошибка валидации поля "Квартира"
        break;
      case 'entrance':
        gtag('event', 'front_error', { event_category: 'field' }); // Ошибка валидации поля "Парадная"
        break;
      case 'floor':
        gtag('event', 'floor_error', { event_category: 'field' }); // Ошибка валидации поля "Этаж"
        break;
      default:
        break;
    }
  },
};

import { useTranslation } from 'react-i18next';
import styles from './freeDeliveryWarningCheckout.module.scss';

interface IFreeDeliveryWarningCheckout {
  lacks: number;
  currency: string;
}

function FreeDeliveryWarningCheckout({
  lacks,
  currency,
}: IFreeDeliveryWarningCheckout) {
  const { t } = useTranslation();

  return (
    <span className={styles.warningText}>
      {t('BlockProducts.freeDeliveryWarning', {
        lacks: `${lacks}\u00A0${currency}`,
      })}
    </span>
  );
}

export default FreeDeliveryWarningCheckout;

import { useTranslation } from 'react-i18next';
import Button from 'shared/ui/Button/Button';
import Emitter from 'frontend/Components/NewCartOrder/Emitters';
import { SmartLink } from 'shared/ui/smart-link';
import styles from './afterDeleteUserModal.module.scss';
import modalStyles from '../../main-card-modal/mainCartModal.module.scss';

function AfterDeleteUserModal() {
  const { t } = useTranslation();
  const closeModal = () => {
    Emitter.emit('MODAL_CLOSE');
  };

  return (
    <div className={styles.afterDeleteModal}>
      <div className={modalStyles.textRow}>
        <span>
          {t('Info.afterDeleteInfo')}
          <SmartLink className={styles.link} href="/profile">
            {t('Info.afterDeleteInfoLink')}{' '}
          </SmartLink>
          {t('Info.again')}
        </span>
        <div className={modalStyles.btnsContainer}>
          <Button className={styles.swButton} onClick={closeModal}>
            {t('User.OK')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AfterDeleteUserModal;

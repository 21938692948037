import api from 'modules/helpers/api';
import useSWR from 'swr';

const useUserIsExist = () => {
  const { data, error, mutate } = useSWR<{ isUserExist: boolean }>(
    'user.isExist',
    api
  );

  const setUserExist = (value: boolean) => mutate({ isUserExist: value });
  return {
    isUserExist: data?.isUserExist,
    loading: !data && !error,
    setUserExist,
  };
};

export default useUserIsExist;

import { useTranslation } from 'react-i18next';
import { formatToTimeWithDate } from 'shared/lib/date';
import styles from './orderReadyTime.module.scss';

export function OrderReadyTime({
  deliveryDateLast,
  isPickup,
}: {
  deliveryDateLast: string;
  isPickup: boolean;
}) {
  const { t } = useTranslation();

  if (!deliveryDateLast) return null;

  const time = formatToTimeWithDate(deliveryDateLast);

  const orderReadyTimeText = isPickup
    ? t(`OrderInfo.prepareBy`, { time })
    : t(`OrderInfo.deliverAt`, { time });

  return <p className={styles.orderReadyTime}>{orderReadyTimeText}</p>;
}

import { Route, Switch } from 'react-router-dom';

export interface IRoute {
  path?: string;
  exact?: boolean;
  component: React.ComponentType<Record<string, unknown>>;
  routes?: IRoute[];
}
export function RoutesWithSubRoutes({ routes }: { routes: IRoute[] }) {
  return (
    <Switch>
      {routes.map((route, i) => (
        <Route
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          path={route.path}
          exact={route.exact}
          render={(props) => (
            // pass the sub-routes down to keep nesting
            <route.component {...props} routes={route.routes} />
          )}
        />
      ))}
    </Switch>
  );
}

import { useSSR, initReactI18next } from 'react-i18next';
import { options, i18nextInstance } from 'shared/i18n';
import LanguageDetector from 'i18next-browser-languagedetector';

i18nextInstance.use(initReactI18next).use(LanguageDetector).init(options);

export function I18nProvider({ children }: { children: JSX.Element }) {
  useSSR(window.initialI18nStore, window.initialLanguage);
  return children;
}

import api from 'modules/helpers/api';

class Http {
  static setPromocode(promocode, productId) {
    return Http.post('cart.promocode', {
      promo_code: promocode,
      product_id: productId,
    });
  }

  static checkPromocode(promocode, lang) {
    return Http.post('order.check_promo_code', { promo_code: promocode, lang });
  }

  static getRecommendedProducts(products = []) {
    return Http.post('order.get_recommendations', { products, quantity: 10 });
  }

  static post(path, data) {
    return api(path, data);
  }
}

export default Http;

import balls1 from 'assets/images/arguments/elems/balls/1.png';
import balls2 from 'assets/images/arguments/elems/balls/2.png';
import balls3 from 'assets/images/arguments/elems/balls/3.png';
import balls4 from 'assets/images/arguments/elems/balls/4.png';
import balls5 from 'assets/images/arguments/elems/balls/5.png';
import balls6 from 'assets/images/arguments/elems/balls/6.png';
import BackgroundArrow from 'assets/images/arguments/3.png';

function Background() {
  return (
    <svg
      version="1.1"
      id="Слой_1"
      className="bgr"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1380 1335"
      xmlSpace="preserve"
    >
      <pattern
        id="ball1"
        x="0%"
        y="0%"
        height="100%"
        width="100%"
        viewBox="0 0 512 512"
      >
        <image x="0%" y="0%" width="512" height="512" xlinkHref={balls1} />
      </pattern>
      <pattern
        id="ball2"
        x="0%"
        y="0%"
        height="100%"
        width="100%"
        viewBox="0 0 512 512"
      >
        <image x="0%" y="0%" width="512" height="512" xlinkHref={balls2} />
      </pattern>
      <pattern
        id="ball3"
        x="0%"
        y="0%"
        height="100%"
        width="100%"
        viewBox="0 0 512 512"
      >
        <image x="0%" y="0%" width="512" height="512" xlinkHref={balls3} />
      </pattern>
      <pattern
        id="ball4"
        x="0%"
        y="0%"
        height="100%"
        width="100%"
        viewBox="0 0 512 512"
      >
        <image x="0%" y="0%" width="512" height="512" xlinkHref={balls4} />
      </pattern>
      <pattern
        id="ball5"
        x="0%"
        y="0%"
        height="100%"
        width="100%"
        viewBox="0 0 512 512"
      >
        <image x="0%" y="0%" width="512" height="512" xlinkHref={balls5} />
      </pattern>
      <pattern
        id="ball6"
        x="0%"
        y="0%"
        height="100%"
        width="100%"
        viewBox="0 0 512 512"
      >
        <image x="0%" y="0%" width="512" height="512" xlinkHref={balls6} />
      </pattern>
      <image width="1380" height="1335" xlinkHref={BackgroundArrow} />
      <circle id="circle1" r="30" fill="url(#ball3)" />
      <animateMotion
        xlinkHref="#circle1"
        dur="21s"
        begin="indefinite"
        fill="freeze"
      >
        <mpath xlinkHref="#motionPath-6" />
      </animateMotion>
      <circle id="circle2" r="30" fill="url(#ball2)" />
      <animateMotion
        xlinkHref="#circle2"
        dur="19s"
        begin="indefinite"
        fill="freeze"
      >
        <mpath xlinkHref="#motionPath-5" />
      </animateMotion>
      <circle id="circle3" r="30" fill="url(#ball5)" />
      <animateMotion
        xlinkHref="#circle3"
        dur="16s"
        begin="indefinite"
        fill="freeze"
      >
        <mpath xlinkHref="#motionPath-4" />
      </animateMotion>
      <circle id="circle4" r="30" fill="url(#ball1)" />
      <animateMotion
        xlinkHref="#circle4"
        dur="13s"
        begin="indefinite"
        fill="freeze"
      >
        <mpath xlinkHref="#motionPath-3" />
      </animateMotion>
      <circle id="circle5" r="30" fill="url(#ball4)" />
      <animateMotion
        xlinkHref="#circle5"
        dur="10s"
        begin="indefinite"
        fill="freeze"
      >
        <mpath xlinkHref="#motionPath-2" />
      </animateMotion>
      <circle id="circle6" r="30" fill="url(#ball6)" />
      <animateMotion
        xlinkHref="#circle6"
        dur="7s"
        begin="indefinite"
        fill="freeze"
      >
        <mpath xlinkHref="#motionPath" />
      </animateMotion>
      <path
        className="st0"
        id="motionPath"
        d="M463.3,1278h296.6c59.2,0,107.7-44.6,107.7-99.1v0c0-54.5-48.5-99.1-107.7-99.1H611.1c-59.2,0-107.7-44.6-107.7-99.1v0c0-54.5,48.5-99.1,107.7-99.1h88.9h4.3h55.6c59.2,0,107.7-47.2,107.7-104.9v0c0-57.7-48.5-104.9-107.7-104.9h-84.1h-63.6c-59.2,0-107.7-43.5-107.7-96.7v0c0-53.2,48.5-99.7,107.7-99.7h87.9h4.3h55.6c59.2,0,107.7-43.1,107.7-99.4v0c0-56.3-48.5-102.4-107.7-102.4h-6H612.9c-59.2,0-107.7-44.6-107.7-99.1v0c0-54.5,48.5-99.1,107.7-99.1h595.7"
      />
      <path
        className="st0"
        id="motionPath-2"
        d="M396.3,1278h363.4c59.2,0,107.7-44.6,107.7-99.1v0c0-54.5-48.5-99.1-107.7-99.1H610.9c-59.2,0-107.7-44.6-107.7-99.1v0c0-54.5,48.5-99.1,107.7-99.1h88.9h4.3h55.6c59.2,0,107.7-47.2,107.7-104.9v0c0-57.7-48.5-104.9-107.7-104.9h-84.1h-63.6c-59.2,0-107.7-43.5-107.7-96.7v0c0-53.2,48.5-99.7,107.7-99.7h87.9h4.3h55.6c59.2,0,107.7-43.1,107.7-99.4v0c0-56.3-48.5-102.4-107.7-102.4h-6H612.7c-59.2,0-107.7-44.6-107.7-99.1v0c0-54.5,48.5-99.1,107.7-99.1h540.4"
      />
      <path
        className="st0"
        id="motionPath-3"
        d="M334.3,1278h425.4c59.2,0,107.7-44.6,107.7-99.1v0c0-54.5-48.5-99.1-107.7-99.1H610.9c-59.2,0-107.7-44.6-107.7-99.1v0c0-54.5,48.5-99.1,107.7-99.1h88.9h4.3h55.6c59.2,0,107.7-47.2,107.7-104.9v0c0-57.7-48.5-104.9-107.7-104.9h-84.1h-63.6c-59.2,0-107.7-43.5-107.7-96.7v0c0-53.2,48.5-99.7,107.7-99.7h87.9h4.3h55.6c59.2,0,107.7-43.1,107.7-99.4v0c0-56.3-48.5-102.4-107.7-102.4h-6H612.7c-59.2,0-107.7-44.6-107.7-99.1v0c0-54.5,48.5-99.1,107.7-99.1h478.4"
      />
      <path
        className="st0"
        id="motionPath-4"
        d="M273.3,1278h486.4c59.2,0,107.7-44.6,107.7-99.1v0c0-54.5-48.5-99.1-107.7-99.1H610.9c-59.2,0-107.7-44.6-107.7-99.1v0c0-54.5,48.5-99.1,107.7-99.1h88.9h4.3h55.6c59.2,0,107.7-47.2,107.7-104.9v0c0-57.7-48.5-104.9-107.7-104.9h-84.1h-63.6c-59.2,0-107.7-43.5-107.7-96.7v0c0-53.2,48.5-99.7,107.7-99.7h87.9h4.3h55.6c59.2,0,107.7-43.1,107.7-99.4v0c0-56.3-48.5-102.4-107.7-102.4h-6H612.7c-59.2,0-107.7-44.6-107.7-99.1v0c0-54.5,48.5-99.1,107.7-99.1h416.4"
      />
      <path
        className="st0"
        id="motionPath-5"
        d="M211.3,1278h548.4c59.2,0,107.7-44.6,107.7-99.1v0c0-54.5-48.5-99.1-107.7-99.1H610.9c-59.2,0-107.7-44.6-107.7-99.1v0c0-54.5,48.5-99.1,107.7-99.1h88.9h4.3h55.6c59.2,0,107.7-47.2,107.7-104.9v0c0-57.7-48.5-104.9-107.7-104.9h-84.1h-63.6c-59.2,0-107.7-43.5-107.7-96.7v0c0-53.2,48.5-99.7,107.7-99.7h87.9h4.3h55.6c59.2,0,107.7-43.1,107.7-99.4v0c0-56.3-48.5-102.4-107.7-102.4h-6H612.7c-59.2,0-107.7-44.6-107.7-99.1v0c0-54.5,48.5-99.1,107.7-99.1h355.4"
      />
      <path
        className="st0"
        id="motionPath-6"
        d="M156.3,1278h603.4c59.2,0,107.7-44.6,107.7-99.1v0c0-54.5-48.5-99.1-107.7-99.1H610.9c-59.2,0-107.7-44.6-107.7-99.1v0c0-54.5,48.5-99.1,107.7-99.1h88.9h4.3h55.6c59.2,0,107.7-47.2,107.7-104.9v0c0-57.7-48.5-104.9-107.7-104.9h-84.1h-63.6c-59.2,0-107.7-43.5-107.7-96.7v0c0-53.2,48.5-99.7,107.7-99.7h87.9h4.3h55.6c59.2,0,107.7-43.1,107.7-99.4v0c0-56.3-48.5-102.4-107.7-102.4h-6H612.7c-59.2,0-107.7-44.6-107.7-99.1v0c0-54.5,48.5-99.1,107.7-99.1h292.4"
      />
    </svg>
  );
}

export default Background;

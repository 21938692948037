import './VacancyCommentLineMobile.scss';
import { useTranslation } from 'react-i18next';

function VacancyCommentLineMobile({ shortname }: { shortname: 'comment1' }) {
  const { t } = useTranslation();
  return (
    <div className="vacancies-comment-line">
      &laquo;{t(`VacancyCommentLine.Vacancies-text-${shortname}`)}
      &raquo;&nbsp;&ndash;&nbsp;
      <span className="vacancies-comment-line__author">
        {t(`VacancyCommentLine.Vacancies-author-${shortname}`)}
      </span>
    </div>
  );
}

export default VacancyCommentLineMobile;

import { Component } from 'react';
import autobind from 'autobind-decorator';

class Textarea extends Component {
  @autobind
  changeHeight(e) {
    if (this.props.onChange) {
      this.props.onChange(e);
    }

    const currentInput = e.target;

    currentInput.style.height = 'auto';
    currentInput.style.height = `${currentInput.scrollHeight}px`;
  }

  render() {
    const data = this.props;

    return (
      <textarea
        className="form__textarea"
        name={data.name}
        id={data.id}
        onChange={this.changeHeight}
        rows="1"
        value={data.value || ''}
      />
    );
  }
}

export default Textarea;

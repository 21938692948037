/**
 * Errors parse helper
 */

// Exceptions
import RuntimeException from 'modules/exceptions/RuntimeException';

import debug from '../debug';

class ResponseError {
  message = 'Common Response error';

  fields = {};

  code = null;

  data = undefined;

  __error = null;

  constructor(error) {
    this.__error = error;

    if (error instanceof RuntimeException) {
      this.__initFromException(error);
    }

    if (error.response) {
      this.__initFromResponse(error.response);
    }

    if (error.data) {
      this.__initFromData(error.data);
    }
    // instance Error
    if (error instanceof Error) {
      this.message = error.message;
    }
  }

  getMessage() {
    return this.message;
  }

  getFields() {
    return this.fields;
  }

  hasFields() {
    return (
      this.fields !== null &&
      typeof this.fields === 'object' &&
      Object.keys(this.fields).length
    );
  }

  getCode() {
    return this.code;
  }

  getData() {
    return this.data;
  }

  isApiError() {
    return this.code !== null;
  }

  getStack() {
    if (this.__error instanceof Error && this.__error.stack) {
      return this.__error.stack;
    }
    return '';
  }

  getOriginal() {
    return this.__error;
  }

  debug() {
    debug('--- Response error ---');
    debug(`Message: ${this.getMessage()}`);
    if (this.isApiError()) {
      debug(`Api code: ${this.getCode()}`);
      if (this.fields) {
        debug('Fields error:');
        debug(this.getFields());
      }
    }
    debug(this.getStack());
    debug('--- End of Response error ---');
  }

  /**
   *
   * @param RuntimeException error
   * @private
   */
  __initFromException(error) {
    this.message = error.message;
    this.code = error.code;
    this.fields = error.errors;
  }

  /**
   *
   * @param Object response
   * @private
   */
  __initFromResponse(response) {
    if (response.data) {
      if (response.data.message || response.data._message) {
        this.message = response.data.message || response.data._message;
      }

      if (
        response.data.errors ||
        (response.data._data && response.data._data.errors)
      ) {
        this.fields = response.data.errors || response.data._data.errors;
      }

      if (response.data.code || response.data._code) {
        this.code = response.data.code || response.data._code;
      }

      if (response.data.data || response.data._data) {
        this.data = response.data.data || response.data._data;
      }
    }
  }

  __initFromData(data) {
    if (data) {
      this.data = data;

      if (data.message || data._message) {
        this.message = data.message || data._message;
      }

      if (data.errors || (data._data && data._data.errors)) {
        this.fields = data.errors || data._data.errors;
      }

      if (data.code || data._code) {
        this.code = data.code || data._code;
      }
    }
  }
}

export default ResponseError;

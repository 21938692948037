import { useTranslation } from 'react-i18next';
import { useTimer } from '../../lib/useTimer';

interface ITimer {
  classNames: string;
  action_to: string;
  locale: string;
}

export function Timer({ classNames, action_to, locale }: ITimer) {
  const { t } = useTranslation();
  const { timer, timerType } = useTimer({
    action_to,
    locale,
  });

  if (!timer) return null;

  return (
    <div className={classNames}>
      {`${t('Card.badge_offer')} ${timer} ${
        timerType === 'days' ? t('Card.badge_days') : ''
      }`}
    </div>
  );
}

export default [
  '+247-####',
  '+376-###-###',
  '+971-5#-###-####',
  '+971-#-###-####',
  '+93-##-###-####',
  '+1(268)###-####',
  '+1(264)###-####',
  '+355(###)###-###',
  '+374-##-###-###',
  '+599-###-####',
  '+599-###-####',
  '+599-9###-####',
  '+244(###)###-###',
  '+672-1##-###',
  '+54(###)###-####',
  '+1(684)###-####',
  '+43(###)###-####',
  '+61-#-####-####',
  '+297-###-####',
  '+994-##-###-##-##',
  '+387-##-#####',
  '+387-##-####',
  '+1(246)###-####',
  '+880-##-###-###',
  '+32(###)###-###',
  '+226-##-##-####',
  '+359(###)###-###',
  '+973-####-####',
  '+257-##-##-####',
  '+229-##-##-####',
  '+1(441)###-####',
  '+673-###-####',
  '+591-#-###-####',
  '+55-##-####-####',
  '+55-##-#####-####',
  '+1(242)###-####',
  '+975-17-###-###',
  '+975-#-###-###',
  '+267-##-###-###',
  '+375(##)###-##-##',
  '+501-###-####',
  '+243(###)###-###',
  '+236-##-##-####',
  '+242-##-###-####',
  '+41-##-###-####',
  '+225-##-###-###',
  '+682-##-###',
  '+56-#-####-####',
  '+237-####-####',
  '+86(###)####-####',
  '+86(###)####-###',
  '+86-##-#####-#####',
  '+57(###)###-####',
  '+506-####-####',
  '+53-#-###-####',
  '+238(###)##-##',
  '+599-###-####',
  '+357-##-###-###',
  '+420(###)###-###',
  '+49(####)###-####',
  '+49(###)###-####',
  '+49(###)##-####',
  '+49(###)##-###',
  '+49(###)##-##',
  '+49-###-###',
  '+253-##-##-##-##',
  '+45-##-##-##-##',
  '+1(767)###-####',
  '+1(809)###-####',
  '+1(829)###-####',
  '+1(849)###-####',
  '+213-##-###-####',
  '+593-##-###-####',
  '+593-#-###-####',
  '+372-####-####',
  '+372-###-####',
  '+20(###)###-####',
  '+291-#-###-###',
  '+34(###)###-###',
  '+251-##-###-####',
  '+358(###)###-##-##',
  '+679-##-#####',
  '+500-#####',
  '+691-###-####',
  '+298-###-###',
  '+262-#####-####',
  '+33(###)###-###',
  '+508-##-####',
  '+590(###)###-###',
  '+241-#-##-##-##',
  '+1(473)###-####',
  '+995(###)###-###',
  '+594-#####-####',
  '+233(###)###-###',
  '+350-###-#####',
  '+299-##-##-##',
  '+220(###)##-##',
  '+224-##-###-###',
  '+240-##-###-####',
  '+30(###)###-####',
  '+502-#-###-####',
  '+1(671)###-####',
  '+245-#-######',
  '+592-###-####',
  '+852-####-####',
  '+504-####-####',
  '+385-(##)-###-###',
  '+385-(##)-###-####',
  '+385-1-####-###',
  '+509-##-##-####',
  '+36(###)###-###',
  '+62(8##)###-####',
  '+62-##-###-##',
  '+62-##-###-###',
  '+62-##-###-####',
  '+62(8##)###-###',
  '+62(8##)###-##-###',
  '+353(###)###-###',
  '+972-5#-###-####',
  '+972-#-###-####',
  '+91(####)###-###',
  '+246-###-####',
  '+964(###)###-####',
  '+98(###)###-####',
  '+354-###-####',
  '+39(###)####-###',
  '+1(876)###-####',
  '+962-#-####-####',
  '+81-##-####-####',
  '+81(###)###-###',
  '+254-###-######',
  '+996(###)###-###',
  '+855-##-###-###',
  '+686-##-###',
  '+269-##-#####',
  '+1(869)###-####',
  '+850-191-###-####',
  '+850-##-###-###',
  '+850-###-####-###',
  '+850-###-###',
  '+850-####-####',
  '+850-####-#############',
  '+82-##-###-####',
  '+965-####-####',
  '+1(345)###-####',
  '+7(6##)###-##-##',
  '+7(7##)###-##-##',
  '+856(20##)###-###',
  '+856-##-###-###',
  '+961-##-###-###',
  '+961-#-###-###',
  '+1(758)###-####',
  '+423(###)###-####',
  '+94-##-###-####',
  '+231-##-###-###',
  '+266-#-###-####',
  '+370(###)##-###',
  '+352-###-###',
  '+352-####-###',
  '+352-#####-###',
  '+352-######-###',
  '+371-##-###-###',
  '+218-##-###-###',
  '+218-21-###-####',
  '+212-##-####-###',
  '+377(###)###-###',
  '+377-##-###-###',
  '+373-####-####',
  '+382-##-###-###',
  '+261-##-##-#####',
  '+692-###-####',
  '+389-##-###-###',
  '+223-##-##-####',
  '+95-##-###-###',
  '+95-#-###-###',
  '+95-###-###',
  '+976-##-##-####',
  '+853-####-####',
  '+1(670)###-####',
  '+596(###)##-##-##',
  '+222-##-##-####',
  '+1(664)###-####',
  '+356-####-####',
  '+230-###-####',
  '+960-###-####',
  '+265-1-###-###',
  '+265-#-####-####',
  '+52(###)###-####',
  '+52-##-##-####',
  '+60-##-###-####',
  '+60-11-####-####',
  '+60(###)###-###',
  '+60-##-###-###',
  '+60-#-###-###',
  '+258-##-###-###',
  '+264-##-###-####',
  '+687-##-####',
  '+227-##-##-####',
  '+672-3##-###',
  '+234(###)###-####',
  '+234-##-###-###',
  '+234-##-###-##',
  '+234(###)###-####',
  '+505-####-####',
  '+31-##-###-####',
  '+47(###)##-###',
  '+977-##-###-###',
  '+674-###-####',
  '+683-####',
  '+64(###)###-###',
  '+64-##-###-###',
  '+64(###)###-####',
  '+968-##-###-###',
  '+507-###-####',
  '+51(###)###-###',
  '+689-##-##-##',
  '+675(###)##-###',
  '+63(###)###-####',
  '+92(###)###-####',
  '+48(###)###-###',
  '+970-##-###-####',
  '+351-##-###-####',
  '+680-###-####',
  '+595(###)###-###',
  '+974-####-####',
  '+262-#####-####',
  '+40-##-###-####',
  '+381-##-###-####',
  '+7(###)###-##-##',
  '+250(###)###-###',
  '+966-5-####-####',
  '+966-#-###-####',
  '+677-###-####',
  '+677-#####',
  '+248-#-###-###',
  '+249-##-###-####',
  '+46-##-###-####',
  '+65-####-####',
  '+290-####',
  '+290-####',
  '+386-##-###-###',
  '+421(###)###-###',
  '+232-##-######',
  '+378-####-######',
  '+221-##-###-####',
  '+252-##-###-###',
  '+252-#-###-###',
  '+252-#-###-###',
  '+597-###-####',
  '+597-###-###',
  '+211-##-###-####',
  '+239-##-#####',
  '+503-##-##-####',
  '+1(721)###-####',
  '+963-##-####-###',
  '+268-##-##-####',
  '+1(649)###-####',
  '+235-##-##-##-##',
  '+228-##-###-###',
  '+66-##-###-####',
  '+66-##-###-###',
  '+992-##-###-####',
  '+690-####',
  '+670-###-####',
  '+670-77#-#####',
  '+670-78#-#####',
  '+993-#-###-####',
  '+216-##-###-###',
  '+676-#####',
  '+90(###)###-####',
  '+1(868)###-####',
  '+688-90####',
  '+688-2####',
  '+886-#-####-####',
  '+886-####-####',
  '+255-##-###-####',
  '+380(##)###-##-##',
  '+256(###)###-###',
  '+44-##-####-####',
  '+598-#-###-##-##',
  '+998-##-###-####',
  '+39-6-698-#####',
  '+1(784)###-####',
  '+58(###)###-####',
  '+1(284)###-####',
  '+1(340)###-####',
  '+84-##-####-###',
  '+84(###)####-###',
  '+678-##-#####',
  '+678-#####',
  '+681-##-####',
  '+685-##-####',
  '+967-###-###-###',
  '+967-#-###-###',
  '+967-##-###-###',
  '+27-##-###-####',
  '+260-##-###-####',
  '+263-#-######',
  '+1(###)###-####',
  '+7(301)###-##-##',
  '+7(3012)##-##-##',
  '+7(30130)#-##-##',
  '+7(30131)#-##-##',
  '+7(30132)#-##-##',
  '+7(30133)#-##-##',
  '+7(30134)#-##-##',
  '+7(30135)#-##-##',
  '+7(30136)#-##-##',
  '+7(30137)#-##-##',
  '+7(30138)#-##-##',
  '+7(30140)#-##-##',
  '+7(30141)#-##-##',
  '+7(30142)#-##-##',
  '+7(30143)#-##-##',
  '+7(30144)#-##-##',
  '+7(30145)#-##-##',
  '+7(30146)#-##-##',
  '+7(30147)#-##-##',
  '+7(30148)#-##-##',
  '+7(30149)#-##-##',
  '+7(30150)#-##-##',
  '+7(30153)#-##-##',
  '+7(302)###-##-##',
  '+7(3022)##-##-##',
  '+7(30230)#-##-##',
  '+7(30231)#-##-##',
  '+7(30232)#-##-##',
  '+7(30233)#-##-##',
  '+7(30234)#-##-##',
  '+7(30235)#-##-##',
  '+7(30236)#-##-##',
  '+7(30237)#-##-##',
  '+7(30238)#-##-##',
  '+7(30239)#-##-##',
  '+7(30240)#-##-##',
  '+7(30241)#-##-##',
  '+7(30242)#-##-##',
  '+7(30243)#-##-##',
  '+7(30244)#-##-##',
  '+7(30245)#-##-##',
  '+7(30246)#-##-##',
  '+7(30247)#-##-##',
  '+7(30248)#-##-##',
  '+7(30249)#-##-##',
  '+7(30251)#-##-##',
  '+7(30252)#-##-##',
  '+7(30253)#-##-##',
  '+7(30255)#-##-##',
  '+7(30256)#-##-##',
  '+7(30257)#-##-##',
  '+7(30261)#-##-##',
  '+7(30262)#-##-##',
  '+7(30264)#-##-##',
  '+7(30265)#-##-##',
  '+7(30266)#-##-##',
  '+7(341)###-##-##',
  '+7(3412)##-##-##',
  '+7(34126)#-##-##',
  '+7(34130)#-##-##',
  '+7(34132)#-##-##',
  '+7(34133)#-##-##',
  '+7(34134)#-##-##',
  '+7(34136)#-##-##',
  '+7(34138)#-##-##',
  '+7(34139)#-##-##',
  '+7(34141)#-##-##',
  '+7(34145)#-##-##',
  '+7(34147)#-##-##',
  '+7(34150)#-##-##',
  '+7(34151)#-##-##',
  '+7(34152)#-##-##',
  '+7(34153)#-##-##',
  '+7(34154)#-##-##',
  '+7(34155)#-##-##',
  '+7(34157)#-##-##',
  '+7(34158)#-##-##',
  '+7(34159)#-##-##',
  '+7(34161)#-##-##',
  '+7(34162)#-##-##',
  '+7(34163)#-##-##',
  '+7(34164)#-##-##',
  '+7(34166)#-##-##',
  '+7(342)###-##-##',
  '+7(3424)##-##-##',
  '+7(34240)#-##-##',
  '+7(34241)#-##-##',
  '+7(34243)#-##-##',
  '+7(34244)#-##-##',
  '+7(34245)#-##-##',
  '+7(34246)#-##-##',
  '+7(34248)#-##-##',
  '+7(34249)#-##-##',
  '+7(34250)#-##-##',
  '+7(34251)#-##-##',
  '+7(34252)#-##-##',
  '+7(34253)#-##-##',
  '+7(34254)#-##-##',
  '+7(34255)#-##-##',
  '+7(34256)#-##-##',
  '+7(34257)#-##-##',
  '+7(34258)#-##-##',
  '+7(34259)#-##-##',
  '+7(34260)#-##-##',
  '+7(34261)#-##-##',
  '+7(34262)#-##-##',
  '+7(34263)#-##-##',
  '+7(34265)#-##-##',
  '+7(34266)#-##-##',
  '+7(34268)#-##-##',
  '+7(34269)#-##-##',
  '+7(34271)#-##-##',
  '+7(34272)#-##-##',
  '+7(34273)#-##-##',
  '+7(34274)#-##-##',
  '+7(34275)#-##-##',
  '+7(34276)#-##-##',
  '+7(34277)#-##-##',
  '+7(34278)#-##-##',
  '+7(34279)#-##-##',
  '+7(34291)#-##-##',
  '+7(34292)#-##-##',
  '+7(34293)#-##-##',
  '+7(34294)#-##-##',
  '+7(34296)#-##-##',
  '+7(34297)#-##-##',
  '+7(34298)#-##-##',
  '+7(343)###-##-##',
  '+7(34341)#-##-##',
  '+7(34342)#-##-##',
  '+7(34343)#-##-##',
  '+7(34344)#-##-##',
  '+7(34345)#-##-##',
  '+7(34346)#-##-##',
  '+7(34347)#-##-##',
  '+7(34349)#-##-##',
  '+7(3435)##-##-##',
  '+7(34350)#-##-##',
  '+7(34355)#-##-##',
  '+7(34356)#-##-##',
  '+7(34357)#-##-##',
  '+7(34358)#-##-##',
  '+7(34360)#-##-##',
  '+7(34361)#-##-##',
  '+7(34362)#-##-##',
  '+7(34363)#-##-##',
  '+7(34364)#-##-##',
  '+7(34365)#-##-##',
  '+7(34367)#-##-##',
  '+7(34368)#-##-##',
  '+7(34369)#-##-##',
  '+7(34370)#-##-##',
  '+7(34371)#-##-##',
  '+7(34372)#-##-##',
  '+7(34373)#-##-##',
  '+7(34374)#-##-##',
  '+7(34375)#-##-##',
  '+7(34376)#-##-##',
  '+7(34377)#-##-##',
  '+7(34380)#-##-##',
  '+7(34383)#-##-##',
  '+7(34384)#-##-##',
  '+7(34385)#-##-##',
  '+7(34386)#-##-##',
  '+7(34387)#-##-##',
  '+7(34388)#-##-##',
  '+7(34389)#-##-##',
  '+7(3439)##-##-##',
  '+7(34391)#-##-##',
  '+7(34394)#-##-##',
  '+7(34395)#-##-##',
  '+7(34397)#-##-##',
  '+7(34398)#-##-##',
  '+7(345)###-##-##',
  '+7(3452)##-##-##',
  '+7(34531)#-##-##',
  '+7(34533)#-##-##',
  '+7(34535)#-##-##',
  '+7(34537)#-##-##',
  '+7(34539)#-##-##',
  '+7(34541)#-##-##',
  '+7(34542)#-##-##',
  '+7(34543)#-##-##',
  '+7(34544)#-##-##',
  '+7(34545)#-##-##',
  '+7(34546)#-##-##',
  '+7(34547)#-##-##',
  '+7(34550)#-##-##',
  '+7(34551)#-##-##',
  '+7(34553)#-##-##',
  '+7(34554)#-##-##',
  '+7(34555)#-##-##',
  '+7(34556)#-##-##',
  '+7(34557)#-##-##',
  '+7(3456)##-##-##',
  '+7(34561)#-##-##',
  '+7(346)###-##-##',
  '+7(3462)##-##-##',
  '+7(3463)##-##-##',
  '+7(34634)#-##-##',
  '+7(34638)#-##-##',
  '+7(34643)#-##-##',
  '+7(3466)##-##-##',
  '+7(34667)#-##-##',
  '+7(34668)#-##-##',
  '+7(34669)#-##-##',
  '+7(34670)#-##-##',
  '+7(34672)#-##-##',
  '+7(34673)#-##-##',
  '+7(34674)#-##-##',
  '+7(34675)#-##-##',
  '+7(34676)#-##-##',
  '+7(34677)#-##-##',
  '+7(34678)#-##-##',
  '+7(347)###-##-##',
  '+7(3473)##-##-##',
  '+7(34731)#-##-##',
  '+7(34739)#-##-##',
  '+7(34740)#-##-##',
  '+7(34741)#-##-##',
  '+7(34742)#-##-##',
  '+7(34743)#-##-##',
  '+7(34744)#-##-##',
  '+7(34745)#-##-##',
  '+7(34746)#-##-##',
  '+7(34747)#-##-##',
  '+7(34748)#-##-##',
  '+7(34749)#-##-##',
  '+7(34750)#-##-##',
  '+7(34751)#-##-##',
  '+7(34752)#-##-##',
  '+7(34753)#-##-##',
  '+7(34754)#-##-##',
  '+7(34755)#-##-##',
  '+7(34756)#-##-##',
  '+7(34757)#-##-##',
  '+7(34758)#-##-##',
  '+7(34759)#-##-##',
  '+7(34760)#-##-##',
  '+7(34761)#-##-##',
  '+7(34762)#-##-##',
  '+7(34763)#-##-##',
  '+7(34764)#-##-##',
  '+7(34765)#-##-##',
  '+7(34766)#-##-##',
  '+7(34767)#-##-##',
  '+7(34768)#-##-##',
  '+7(34769)#-##-##',
  '+7(34770)#-##-##',
  '+7(34771)#-##-##',
  '+7(34772)#-##-##',
  '+7(34773)#-##-##',
  '+7(34774)#-##-##',
  '+7(34775)#-##-##',
  '+7(34776)#-##-##',
  '+7(34777)#-##-##',
  '+7(34778)#-##-##',
  '+7(34779)#-##-##',
  '+7(34780)#-##-##',
  '+7(34781)#-##-##',
  '+7(34782)#-##-##',
  '+7(34783)#-##-##',
  '+7(34784)#-##-##',
  '+7(34785)#-##-##',
  '+7(34786)#-##-##',
  '+7(34787)#-##-##',
  '+7(34788)#-##-##',
  '+7(34789)#-##-##',
  '+7(34791)#-##-##',
  '+7(34792)#-##-##',
  '+7(34794)#-##-##',
  '+7(34795)#-##-##',
  '+7(34796)#-##-##',
  '+7(34797)#-##-##',
  '+7(34798)#-##-##',
  '+7(349)###-##-##',
  '+7(34922)#-##-##',
  '+7(34932)#-##-##',
  '+7(34934)#-##-##',
  '+7(34936)#-##-##',
  '+7(34938)#-##-##',
  '+7(3494)##-##-##',
  '+7(34940)#-##-##',
  '+7(34948)#-##-##',
  '+7(34949)#-##-##',
  '+7(3496)##-##-##',
  '+7(34992)#-##-##',
  '+7(34993)#-##-##',
  '+7(34994)#-##-##',
  '+7(34995)#-##-##',
  '+7(34996)#-##-##',
  '+7(34997)#-##-##',
  '+7(351)###-##-##',
  '+7(351)###-##-##',
  '+7(3513)##-##-##',
  '+7(35130)#-##-##',
  '+7(35131)#-##-##',
  '+7(35133)#-##-##',
  '+7(35134)#-##-##',
  '+7(35138)#-##-##',
  '+7(35139)#-##-##',
  '+7(35140)#-##-##',
  '+7(35141)#-##-##',
  '+7(35142)#-##-##',
  '+7(35143)#-##-##',
  '+7(35144)#-##-##',
  '+7(35145)#-##-##',
  '+7(35146)#-##-##',
  '+7(35147)#-##-##',
  '+7(35148)#-##-##',
  '+7(35149)#-##-##',
  '+7(35150)#-##-##',
  '+7(35151)#-##-##',
  '+7(35152)#-##-##',
  '+7(35153)#-##-##',
  '+7(35154)#-##-##',
  '+7(35155)#-##-##',
  '+7(35156)#-##-##',
  '+7(35157)#-##-##',
  '+7(35158)#-##-##',
  '+7(35159)#-##-##',
  '+7(35160)#-##-##',
  '+7(35161)#-##-##',
  '+7(35163)#-##-##',
  '+7(35164)#-##-##',
  '+7(35165)#-##-##',
  '+7(35166)#-##-##',
  '+7(35167)#-##-##',
  '+7(35168)#-##-##',
  '+7(35169)#-##-##',
  '+7(3519)##-##-##',
  '+7(35191)#-##-##',
  '+7(352)###-##-##',
  '+7(3522)##-##-##',
  '+7(35230)#-##-##',
  '+7(35231)#-##-##',
  '+7(35232)#-##-##',
  '+7(35233)#-##-##',
  '+7(35234)#-##-##',
  '+7(35235)#-##-##',
  '+7(35236)#-##-##',
  '+7(35237)#-##-##',
  '+7(35238)#-##-##',
  '+7(35239)#-##-##',
  '+7(35240)#-##-##',
  '+7(35241)#-##-##',
  '+7(35242)#-##-##',
  '+7(35243)#-##-##',
  '+7(35244)#-##-##',
  '+7(35245)#-##-##',
  '+7(35247)#-##-##',
  '+7(35248)#-##-##',
  '+7(35249)#-##-##',
  '+7(35251)#-##-##',
  '+7(35252)#-##-##',
  '+7(35253)#-##-##',
  '+7(35256)#-##-##',
  '+7(35257)#-##-##',
  '+7(353)###-##-##',
  '+7(3532)##-##-##',
  '+7(35330)#-##-##',
  '+7(35331)#-##-##',
  '+7(35332)#-##-##',
  '+7(35333)#-##-##',
  '+7(35334)#-##-##',
  '+7(35335)#-##-##',
  '+7(35336)#-##-##',
  '+7(35337)#-##-##',
  '+7(35338)#-##-##',
  '+7(35339)#-##-##',
  '+7(35341)#-##-##',
  '+7(35342)#-##-##',
  '+7(35344)#-##-##',
  '+7(35345)#-##-##',
  '+7(35346)#-##-##',
  '+7(35347)#-##-##',
  '+7(35348)#-##-##',
  '+7(35349)#-##-##',
  '+7(35351)#-##-##',
  '+7(35352)#-##-##',
  '+7(35354)#-##-##',
  '+7(35355)#-##-##',
  '+7(35356)#-##-##',
  '+7(35357)#-##-##',
  '+7(35358)#-##-##',
  '+7(35359)#-##-##',
  '+7(35361)#-##-##',
  '+7(35362)#-##-##',
  '+7(35363)#-##-##',
  '+7(35364)#-##-##',
  '+7(35365)#-##-##',
  '+7(35366)#-##-##',
  '+7(35367)#-##-##',
  '+7(35368)#-##-##',
  '+7(3537)##-##-##',
  '+7(35379)#-##-##',
  '+7(381)###-##-##',
  '+7(3812)##-##-##',
  '+7(38141)#-##-##',
  '+7(38150)#-##-##',
  '+7(38151)#-##-##',
  '+7(38152)#-##-##',
  '+7(38153)#-##-##',
  '+7(38154)#-##-##',
  '+7(38155)#-##-##',
  '+7(38156)#-##-##',
  '+7(38157)#-##-##',
  '+7(38158)#-##-##',
  '+7(38159)#-##-##',
  '+7(38160)#-##-##',
  '+7(38161)#-##-##',
  '+7(38162)#-##-##',
  '+7(38163)#-##-##',
  '+7(38164)#-##-##',
  '+7(38165)#-##-##',
  '+7(38166)#-##-##',
  '+7(38167)#-##-##',
  '+7(38168)#-##-##',
  '+7(38169)#-##-##',
  '+7(38170)#-##-##',
  '+7(38171)#-##-##',
  '+7(38172)#-##-##',
  '+7(38173)#-##-##',
  '+7(38174)#-##-##',
  '+7(38175)#-##-##',
  '+7(38176)#-##-##',
  '+7(38177)#-##-##',
  '+7(38178)#-##-##',
  '+7(38179)#-##-##',
  '+7(382)###-##-##',
  '+7(3822)##-##-##',
  '+7(3823)##-##-##',
  '+7(38241)#-##-##',
  '+7(38243)#-##-##',
  '+7(38244)#-##-##',
  '+7(38245)#-##-##',
  '+7(38246)#-##-##',
  '+7(38247)#-##-##',
  '+7(38249)#-##-##',
  '+7(38250)#-##-##',
  '+7(38251)#-##-##',
  '+7(38252)#-##-##',
  '+7(38253)#-##-##',
  '+7(38254)#-##-##',
  '+7(38255)#-##-##',
  '+7(38256)#-##-##',
  '+7(38257)#-##-##',
  '+7(38258)#-##-##',
  '+7(38259)#-##-##',
  '+7(383)###-##-##',
  '+7(38340)#-##-##',
  '+7(38341)#-##-##',
  '+7(38343)#-##-##',
  '+7(38345)#-##-##',
  '+7(38346)#-##-##',
  '+7(38347)#-##-##',
  '+7(38348)#-##-##',
  '+7(38349)#-##-##',
  '+7(38350)#-##-##',
  '+7(38351)#-##-##',
  '+7(38352)#-##-##',
  '+7(38353)#-##-##',
  '+7(38354)#-##-##',
  '+7(38355)#-##-##',
  '+7(38356)#-##-##',
  '+7(38357)#-##-##',
  '+7(38358)#-##-##',
  '+7(38359)#-##-##',
  '+7(38360)#-##-##',
  '+7(38361)#-##-##',
  '+7(38362)#-##-##',
  '+7(38363)#-##-##',
  '+7(38364)#-##-##',
  '+7(38365)#-##-##',
  '+7(38366)#-##-##',
  '+7(38367)#-##-##',
  '+7(38368)#-##-##',
  '+7(38369)#-##-##',
  '+7(38371)#-##-##',
  '+7(38372)#-##-##',
  '+7(38373)#-##-##',
  '+7(384)###-##-##',
  '+7(3842)##-##-##',
  '+7(3843)##-##-##',
  '+7(38441)#-##-##',
  '+7(38442)#-##-##',
  '+7(38443)#-##-##',
  '+7(38444)#-##-##',
  '+7(38445)#-##-##',
  '+7(38446)#-##-##',
  '+7(38447)#-##-##',
  '+7(38448)#-##-##',
  '+7(38449)#-##-##',
  '+7(38451)#-##-##',
  '+7(38452)#-##-##',
  '+7(38453)#-##-##',
  '+7(38454)#-##-##',
  '+7(38455)#-##-##',
  '+7(38456)#-##-##',
  '+7(38459)#-##-##',
  '+7(3846)##-##-##',
  '+7(38463)#-##-##',
  '+7(38464)#-##-##',
  '+7(38471)#-##-##',
  '+7(38472)#-##-##',
  '+7(38473)#-##-##',
  '+7(38474)#-##-##',
  '+7(38475)#-##-##',
  '+7(385)###-##-##',
  '+7(3852)##-##-##',
  '+7(38530)#-##-##',
  '+7(38531)#-##-##',
  '+7(38532)#-##-##',
  '+7(38533)#-##-##',
  '+7(38534)#-##-##',
  '+7(38535)#-##-##',
  '+7(38536)#-##-##',
  '+7(38537)#-##-##',
  '+7(38538)#-##-##',
  '+7(38539)#-##-##',
  '+7(3854)##-##-##',
  '+7(38550)#-##-##',
  '+7(38551)#-##-##',
  '+7(38552)#-##-##',
  '+7(38553)#-##-##',
  '+7(38554)#-##-##',
  '+7(38555)#-##-##',
  '+7(38556)#-##-##',
  '+7(38557)#-##-##',
  '+7(38558)#-##-##',
  '+7(38559)#-##-##',
  '+7(38560)#-##-##',
  '+7(38561)#-##-##',
  '+7(38562)#-##-##',
  '+7(38563)#-##-##',
  '+7(38564)#-##-##',
  '+7(38565)#-##-##',
  '+7(38566)#-##-##',
  '+7(38567)#-##-##',
  '+7(38568)#-##-##',
  '+7(38569)#-##-##',
  '+7(38570)#-##-##',
  '+7(38571)#-##-##',
  '+7(38572)#-##-##',
  '+7(38573)#-##-##',
  '+7(38574)#-##-##',
  '+7(38575)#-##-##',
  '+7(38576)#-##-##',
  '+7(38577)#-##-##',
  '+7(38578)#-##-##',
  '+7(38579)#-##-##',
  '+7(38580)#-##-##',
  '+7(38581)#-##-##',
  '+7(38582)#-##-##',
  '+7(38583)#-##-##',
  '+7(38584)#-##-##',
  '+7(38585)#-##-##',
  '+7(38586)#-##-##',
  '+7(38587)#-##-##',
  '+7(38588)#-##-##',
  '+7(38589)#-##-##',
  '+7(38590)#-##-##',
  '+7(38591)#-##-##',
  '+7(38592)#-##-##',
  '+7(38593)#-##-##',
  '+7(38594)#-##-##',
  '+7(38595)#-##-##',
  '+7(38596)#-##-##',
  '+7(38597)#-##-##',
  '+7(38598)#-##-##',
  '+7(38599)#-##-##',
  '+7(388)###-##-##',
  '+7(3882)##-##-##',
  '+7(38840)#-##-##',
  '+7(38841)#-##-##',
  '+7(38842)#-##-##',
  '+7(38843)#-##-##',
  '+7(38844)#-##-##',
  '+7(38845)#-##-##',
  '+7(38846)#-##-##',
  '+7(38847)#-##-##',
  '+7(38848)#-##-##',
  '+7(38849)#-##-##',
  '+7(390)###-##-##',
  '+7(3902)##-##-##',
  '+7(39031)#-##-##',
  '+7(39032)#-##-##',
  '+7(39033)#-##-##',
  '+7(39034)#-##-##',
  '+7(39035)#-##-##',
  '+7(39036)#-##-##',
  '+7(39041)#-##-##',
  '+7(39042)#-##-##',
  '+7(39044)#-##-##',
  '+7(39045)#-##-##',
  '+7(39046)#-##-##',
  '+7(39047)#-##-##',
  '+7(391)###-##-##',
  '+7(39131)#-##-##',
  '+7(39132)#-##-##',
  '+7(39133)#-##-##',
  '+7(39134)#-##-##',
  '+7(39135)#-##-##',
  '+7(39136)#-##-##',
  '+7(39137)#-##-##',
  '+7(39138)#-##-##',
  '+7(39139)#-##-##',
  '+7(39140)#-##-##',
  '+7(39141)#-##-##',
  '+7(39142)#-##-##',
  '+7(39143)#-##-##',
  '+7(39144)#-##-##',
  '+7(39145)#-##-##',
  '+7(39146)#-##-##',
  '+7(39147)#-##-##',
  '+7(39148)#-##-##',
  '+7(39149)#-##-##',
  '+7(39150)#-##-##',
  '+7(39151)#-##-##',
  '+7(39152)#-##-##',
  '+7(39153)#-##-##',
  '+7(39154)#-##-##',
  '+7(39155)#-##-##',
  '+7(39156)#-##-##',
  '+7(39157)#-##-##',
  '+7(39158)#-##-##',
  '+7(39159)#-##-##',
  '+7(39160)#-##-##',
  '+7(39161)#-##-##',
  '+7(39162)#-##-##',
  '+7(39163)#-##-##',
  '+7(39164)#-##-##',
  '+7(39165)#-##-##',
  '+7(39166)#-##-##',
  '+7(39167)#-##-##',
  '+7(39168)#-##-##',
  '+7(39169)#-##-##',
  '+7(39170)#-##-##',
  '+7(39171)#-##-##',
  '+7(39172)#-##-##',
  '+7(39173)#-##-##',
  '+7(39174)#-##-##',
  '+7(39175)#-##-##',
  '+7(39176)#-##-##',
  '+7(39177)#-##-##',
  '+7(39178)#-##-##',
  '+7(39179)#-##-##',
  '+7(3919)##-##-##',
  '+7(39190)#-##-##',
  '+7(39191)#-##-##',
  '+7(39193)#-##-##',
  '+7(39195)#-##-##',
  '+7(39196)#-##-##',
  '+7(39197)#-##-##',
  '+7(39198)#-##-##',
  '+7(39199)#-##-##',
  '+7(394)###-##-##',
  '+7(3942)##-##-##',
  '+7(39432)#-##-##',
  '+7(39433)#-##-##',
  '+7(39434)#-##-##',
  '+7(39435)#-##-##',
  '+7(39436)#-##-##',
  '+7(39437)#-##-##',
  '+7(39438)#-##-##',
  '+7(39439)#-##-##',
  '+7(39441)#-##-##',
  '+7(39442)#-##-##',
  '+7(39444)#-##-##',
  '+7(39445)#-##-##',
  '+7(39450)#-##-##',
  '+7(39451)#-##-##',
  '+7(395)###-##-##',
  '+7(3952)##-##-##',
  '+7(3953)##-##-##',
  '+7(39530)#-##-##',
  '+7(39535)#-##-##',
  '+7(39536)#-##-##',
  '+7(39537)#-##-##',
  '+7(39538)#-##-##',
  '+7(39539)#-##-##',
  '+7(39540)#-##-##',
  '+7(39541)#-##-##',
  '+7(39542)#-##-##',
  '+7(39543)#-##-##',
  '+7(39544)#-##-##',
  '+7(39545)#-##-##',
  '+7(39546)#-##-##',
  '+7(39548)#-##-##',
  '+7(39549)#-##-##',
  '+7(3955)##-##-##',
  '+7(39550)#-##-##',
  '+7(39551)#-##-##',
  '+7(39552)#-##-##',
  '+7(39553)#-##-##',
  '+7(39554)#-##-##',
  '+7(39557)#-##-##',
  '+7(39558)#-##-##',
  '+7(39559)#-##-##',
  '+7(39560)#-##-##',
  '+7(39561)#-##-##',
  '+7(39562)#-##-##',
  '+7(39563)#-##-##',
  '+7(39564)#-##-##',
  '+7(39565)#-##-##',
  '+7(39566)#-##-##',
  '+7(39567)#-##-##',
  '+7(39568)#-##-##',
  '+7(39569)#-##-##',
  '+7(401)###-##-##',
  '+7(4012)##-##-##',
  '+7(40141)#-##-##',
  '+7(40142)#-##-##',
  '+7(40143)#-##-##',
  '+7(40144)#-##-##',
  '+7(40145)#-##-##',
  '+7(40150)#-##-##',
  '+7(40151)#-##-##',
  '+7(40152)#-##-##',
  '+7(40153)#-##-##',
  '+7(40155)#-##-##',
  '+7(40156)#-##-##',
  '+7(40157)#-##-##',
  '+7(40158)#-##-##',
  '+7(40159)#-##-##',
  '+7(40161)#-##-##',
  '+7(40162)#-##-##',
  '+7(40163)#-##-##',
  '+7(40164)#-##-##',
  '+7(411)###-##-##',
  '+7(4112)##-##-##',
  '+7(41131)#-##-##',
  '+7(41132)#-##-##',
  '+7(41133)#-##-##',
  '+7(41134)#-##-##',
  '+7(41135)#-##-##',
  '+7(41136)#-##-##',
  '+7(41137)#-##-##',
  '+7(41138)#-##-##',
  '+7(41140)#-##-##',
  '+7(41141)#-##-##',
  '+7(41142)#-##-##',
  '+7(41143)#-##-##',
  '+7(41144)#-##-##',
  '+7(41145)#-##-##',
  '+7(41147)#-##-##',
  '+7(41150)#-##-##',
  '+7(41151)#-##-##',
  '+7(41152)#-##-##',
  '+7(41153)#-##-##',
  '+7(41154)#-##-##',
  '+7(41155)#-##-##',
  '+7(41156)#-##-##',
  '+7(41157)#-##-##',
  '+7(41158)#-##-##',
  '+7(41159)#-##-##',
  '+7(41160)#-##-##',
  '+7(41161)#-##-##',
  '+7(41162)#-##-##',
  '+7(41163)#-##-##',
  '+7(41164)#-##-##',
  '+7(41165)#-##-##',
  '+7(41166)#-##-##',
  '+7(41167)#-##-##',
  '+7(41168)#-##-##',
  '+7(41169)#-##-##',
  '+7(413)###-##-##',
  '+7(4132)##-##-##',
  '+7(41341)#-##-##',
  '+7(41342)#-##-##',
  '+7(41343)#-##-##',
  '+7(41344)#-##-##',
  '+7(41345)#-##-##',
  '+7(41346)#-##-##',
  '+7(41347)#-##-##',
  '+7(41348)#-##-##',
  '+7(415)###-##-##',
  '+7(4152)##-##-##',
  '+7(41531)#-##-##',
  '+7(41532)#-##-##',
  '+7(41533)#-##-##',
  '+7(41534)#-##-##',
  '+7(41535)#-##-##',
  '+7(41536)#-##-##',
  '+7(41537)#-##-##',
  '+7(41538)#-##-##',
  '+7(41539)#-##-##',
  '+7(41541)#-##-##',
  '+7(41542)#-##-##',
  '+7(41543)#-##-##',
  '+7(41544)#-##-##',
  '+7(41545)#-##-##',
  '+7(41546)#-##-##',
  '+7(41547)#-##-##',
  '+7(41548)#-##-##',
  '+7(416)###-##-##',
  '+7(4162)##-##-##',
  '+7(41631)#-##-##',
  '+7(41632)#-##-##',
  '+7(41633)#-##-##',
  '+7(41634)#-##-##',
  '+7(41637)#-##-##',
  '+7(41638)#-##-##',
  '+7(41639)#-##-##',
  '+7(41641)#-##-##',
  '+7(41642)#-##-##',
  '+7(41643)#-##-##',
  '+7(41644)#-##-##',
  '+7(41645)#-##-##',
  '+7(41646)#-##-##',
  '+7(41647)#-##-##',
  '+7(41648)#-##-##',
  '+7(41649)#-##-##',
  '+7(41651)#-##-##',
  '+7(41652)#-##-##',
  '+7(41653)#-##-##',
  '+7(41654)#-##-##',
  '+7(41655)#-##-##',
  '+7(41656)#-##-##',
  '+7(41658)#-##-##',
  '+7(421)###-##-##',
  '+7(4212)##-##-##',
  '+7(42135)#-##-##',
  '+7(41636)#-##-##',
  '+7(41636)#-##-##',
  '+7(41636)#-##-##',
  '+7(42137)#-##-##',
  '+7(42138)#-##-##',
  '+7(42141)#-##-##',
  '+7(42142)#-##-##',
  '+7(42143)#-##-##',
  '+7(42144)#-##-##',
  '+7(42146)#-##-##',
  '+7(42147)#-##-##',
  '+7(42149)#-##-##',
  '+7(42151)#-##-##',
  '+7(42153)#-##-##',
  '+7(42154)#-##-##',
  '+7(42155)#-##-##',
  '+7(42156)#-##-##',
  '+7(4217)##-##-##',
  '+7(423)###-##-##',
  '+7(42331)#-##-##',
  '+7(42334)#-##-##',
  '+7(42335)#-##-##',
  '+7(42337)#-##-##',
  '+7(42339)#-##-##',
  '+7(4234)##-##-##',
  '+7(42344)#-##-##',
  '+7(42345)#-##-##',
  '+7(42346)#-##-##',
  '+7(42347)#-##-##',
  '+7(42349)#-##-##',
  '+7(42351)#-##-##',
  '+7(42352)#-##-##',
  '+7(42354)#-##-##',
  '+7(42355)#-##-##',
  '+7(42356)#-##-##',
  '+7(42357)#-##-##',
  '+7(42359)#-##-##',
  '+7(4236)##-##-##',
  '+7(42361)#-##-##',
  '+7(42362)#-##-##',
  '+7(42363)#-##-##',
  '+7(42365)#-##-##',
  '+7(42371)#-##-##',
  '+7(42372)#-##-##',
  '+7(42373)#-##-##',
  '+7(42374)#-##-##',
  '+7(42375)#-##-##',
  '+7(42376)#-##-##',
  '+7(42377)#-##-##',
  '+7(424)###-##-##',
  '+7(4242)##-##-##',
  '+7(42431)#-##-##',
  '+7(42432)#-##-##',
  '+7(42433)#-##-##',
  '+7(42434)#-##-##',
  '+7(42435)#-##-##',
  '+7(42436)#-##-##',
  '+7(42437)#-##-##',
  '+7(42441)#-##-##',
  '+7(42442)#-##-##',
  '+7(42443)#-##-##',
  '+7(42444)#-##-##',
  '+7(42446)#-##-##',
  '+7(42447)#-##-##',
  '+7(42452)#-##-##',
  '+7(42453)#-##-##',
  '+7(42454)#-##-##',
  '+7(42455)#-##-##',
  '+7(426)###-##-##',
  '+7(42622)#-##-##',
  '+7(42632)#-##-##',
  '+7(42663)#-##-##',
  '+7(42665)#-##-##',
  '+7(42666)#-##-##',
  '+7(427)###-##-##',
  '+7(42722)#-##-##',
  '+7(42732)#-##-##',
  '+7(42733)#-##-##',
  '+7(42734)#-##-##',
  '+7(42735)#-##-##',
  '+7(42736)#-##-##',
  '+7(42737)#-##-##',
  '+7(42738)#-##-##',
  '+7(42739)#-##-##',
  '+7(471)###-##-##',
  '+7(4712)##-##-##',
  '+7(47131)#-##-##',
  '+7(47132)#-##-##',
  '+7(47133)#-##-##',
  '+7(47134)#-##-##',
  '+7(47135)#-##-##',
  '+7(47136)#-##-##',
  '+7(47137)#-##-##',
  '+7(47140)#-##-##',
  '+7(47141)#-##-##',
  '+7(47142)#-##-##',
  '+7(47143)#-##-##',
  '+7(47144)#-##-##',
  '+7(47145)#-##-##',
  '+7(47146)#-##-##',
  '+7(47147)#-##-##',
  '+7(47148)#-##-##',
  '+7(47149)#-##-##',
  '+7(47150)#-##-##',
  '+7(47151)#-##-##',
  '+7(47152)#-##-##',
  '+7(47153)#-##-##',
  '+7(47154)#-##-##',
  '+7(47155)#-##-##',
  '+7(47156)#-##-##',
  '+7(47157)#-##-##',
  '+7(47158)#-##-##',
  '+7(47159)#-##-##',
  '+7(472)###-##-##',
  '+7(4722)##-##-##',
  '+7(47231)#-##-##',
  '+7(47232)#-##-##',
  '+7(47233)#-##-##',
  '+7(47234)#-##-##',
  '+7(47235)#-##-##',
  '+7(47236)#-##-##',
  '+7(47237)#-##-##',
  '+7(47238)#-##-##',
  '+7(47241)#-##-##',
  '+7(47242)#-##-##',
  '+7(47243)#-##-##',
  '+7(47244)#-##-##',
  '+7(47245)#-##-##',
  '+7(47246)#-##-##',
  '+7(47247)#-##-##',
  '+7(47248)#-##-##',
  '+7(4725)##-##-##',
  '+7(47261)#-##-##',
  '+7(47262)#-##-##',
  '+7(47263)#-##-##',
  '+7(473)###-##-##',
  '+7(47340)#-##-##',
  '+7(47341)#-##-##',
  '+7(47342)#-##-##',
  '+7(47343)#-##-##',
  '+7(47344)#-##-##',
  '+7(47345)#-##-##',
  '+7(47346)#-##-##',
  '+7(47347)#-##-##',
  '+7(47348)#-##-##',
  '+7(47350)#-##-##',
  '+7(47352)#-##-##',
  '+7(47353)#-##-##',
  '+7(47354)#-##-##',
  '+7(47355)#-##-##',
  '+7(47356)#-##-##',
  '+7(47357)#-##-##',
  '+7(47361)#-##-##',
  '+7(47362)#-##-##',
  '+7(47363)#-##-##',
  '+7(47364)#-##-##',
  '+7(47365)#-##-##',
  '+7(47366)#-##-##',
  '+7(47367)#-##-##',
  '+7(47370)#-##-##',
  '+7(47371)#-##-##',
  '+7(47372)#-##-##',
  '+7(47374)#-##-##',
  '+7(47375)#-##-##',
  '+7(47376)#-##-##',
  '+7(47391)#-##-##',
  '+7(47394)#-##-##',
  '+7(47395)#-##-##',
  '+7(47396)#-##-##',
  '+7(474)###-##-##',
  '+7(4742)##-##-##',
  '+7(47461)#-##-##',
  '+7(47462)#-##-##',
  '+7(47463)#-##-##',
  '+7(47464)#-##-##',
  '+7(47465)#-##-##',
  '+7(47466)#-##-##',
  '+7(47467)#-##-##',
  '+7(47468)#-##-##',
  '+7(47469)#-##-##',
  '+7(47471)#-##-##',
  '+7(47472)#-##-##',
  '+7(47473)#-##-##',
  '+7(47474)#-##-##',
  '+7(47475)#-##-##',
  '+7(47476)#-##-##',
  '+7(47477)#-##-##',
  '+7(47478)#-##-##',
  '+7(475)###-##-##',
  '+7(4752)##-##-##',
  '+7(47531)#-##-##',
  '+7(47532)#-##-##',
  '+7(47533)#-##-##',
  '+7(47534)#-##-##',
  '+7(47535)#-##-##',
  '+7(47536)#-##-##',
  '+7(47537)#-##-##',
  '+7(47541)#-##-##',
  '+7(47542)#-##-##',
  '+7(47543)#-##-##',
  '+7(47544)#-##-##',
  '+7(47545)#-##-##',
  '+7(47546)#-##-##',
  '+7(47548)#-##-##',
  '+7(47551)#-##-##',
  '+7(47552)#-##-##',
  '+7(47553)#-##-##',
  '+7(47554)#-##-##',
  '+7(47555)#-##-##',
  '+7(47556)#-##-##',
  '+7(47557)#-##-##',
  '+7(47558)#-##-##',
  '+7(47559)#-##-##',
  '+7(481)###-##-##',
  '+7(4812)##-##-##',
  '+7(48130)#-##-##',
  '+7(48131)#-##-##',
  '+7(48132)#-##-##',
  '+7(48133)#-##-##',
  '+7(48134)#-##-##',
  '+7(48135)#-##-##',
  '+7(48136)#-##-##',
  '+7(48137)#-##-##',
  '+7(48138)#-##-##',
  '+7(48139)#-##-##',
  '+7(48140)#-##-##',
  '+7(48141)#-##-##',
  '+7(48142)#-##-##',
  '+7(48143)#-##-##',
  '+7(48144)#-##-##',
  '+7(48145)#-##-##',
  '+7(48146)#-##-##',
  '+7(48147)#-##-##',
  '+7(48148)#-##-##',
  '+7(48149)#-##-##',
  '+7(48153)#-##-##',
  '+7(48155)#-##-##',
  '+7(48165)#-##-##',
  '+7(48166)#-##-##',
  '+7(48167)#-##-##',
  '+7(482)###-##-##',
  '+7(4822)##-##-##',
  '+7(48230)#-##-##',
  '+7(48231)#-##-##',
  '+7(48232)#-##-##',
  '+7(48233)#-##-##',
  '+7(48234)#-##-##',
  '+7(48235)#-##-##',
  '+7(48236)#-##-##',
  '+7(48237)#-##-##',
  '+7(48238)#-##-##',
  '+7(48239)#-##-##',
  '+7(48242)#-##-##',
  '+7(48244)#-##-##',
  '+7(48246)#-##-##',
  '+7(48249)#-##-##',
  '+7(48250)#-##-##',
  '+7(48251)#-##-##',
  '+7(48253)#-##-##',
  '+7(48255)#-##-##',
  '+7(48257)#-##-##',
  '+7(48258)#-##-##',
  '+7(48261)#-##-##',
  '+7(48262)#-##-##',
  '+7(48263)#-##-##',
  '+7(48264)#-##-##',
  '+7(48265)#-##-##',
  '+7(48266)#-##-##',
  '+7(48267)#-##-##',
  '+7(48268)#-##-##',
  '+7(48269)#-##-##',
  '+7(48271)#-##-##',
  '+7(48272)#-##-##',
  '+7(48273)#-##-##',
  '+7(48274)#-##-##',
  '+7(48275)#-##-##',
  '+7(48276)#-##-##',
  '+7(483)###-##-##',
  '+7(4832)##-##-##',
  '+7(48330)#-##-##',
  '+7(48331)#-##-##',
  '+7(48332)#-##-##',
  '+7(48333)#-##-##',
  '+7(48334)#-##-##',
  '+7(48335)#-##-##',
  '+7(48336)#-##-##',
  '+7(48338)#-##-##',
  '+7(48339)#-##-##',
  '+7(48340)#-##-##',
  '+7(48341)#-##-##',
  '+7(48342)#-##-##',
  '+7(48343)#-##-##',
  '+7(48344)#-##-##',
  '+7(48345)#-##-##',
  '+7(48346)#-##-##',
  '+7(48347)#-##-##',
  '+7(48348)#-##-##',
  '+7(48349)#-##-##',
  '+7(48351)#-##-##',
  '+7(48352)#-##-##',
  '+7(48353)#-##-##',
  '+7(48354)#-##-##',
  '+7(48355)#-##-##',
  '+7(48356)#-##-##',
  '+7(48358)#-##-##',
  '+7(484)###-##-##',
  '+7(4842)##-##-##',
  '+7(48431)#-##-##',
  '+7(48432)#-##-##',
  '+7(48433)#-##-##',
  '+7(48434)#-##-##',
  '+7(48435)#-##-##',
  '+7(48436)#-##-##',
  '+7(48437)#-##-##',
  '+7(48438)#-##-##',
  '+7(48439)#-##-##',
  '+7(48441)#-##-##',
  '+7(48442)#-##-##',
  '+7(48443)#-##-##',
  '+7(48444)#-##-##',
  '+7(48445)#-##-##',
  '+7(48446)#-##-##',
  '+7(48447)#-##-##',
  '+7(48448)#-##-##',
  '+7(48449)#-##-##',
  '+7(48451)#-##-##',
  '+7(48452)#-##-##',
  '+7(48453)#-##-##',
  '+7(48454)#-##-##',
  '+7(48455)#-##-##',
  '+7(48456)#-##-##',
  '+7(48457)#-##-##',
  '+7(485)###-##-##',
  '+7(4852)##-##-##',
  '+7(48531)#-##-##',
  '+7(48532)#-##-##',
  '+7(48533)#-##-##',
  '+7(48534)#-##-##',
  '+7(48535)#-##-##',
  '+7(48536)#-##-##',
  '+7(48538)#-##-##',
  '+7(48539)#-##-##',
  '+7(48542)#-##-##',
  '+7(48543)#-##-##',
  '+7(48544)#-##-##',
  '+7(48545)#-##-##',
  '+7(48546)#-##-##',
  '+7(48547)#-##-##',
  '+7(48549)#-##-##',
  '+7(4855)##-##-##',
  '+7(486)###-##-##',
  '+7(4862)##-##-##',
  '+7(48640)#-##-##',
  '+7(48642)#-##-##',
  '+7(48643)#-##-##',
  '+7(48644)#-##-##',
  '+7(48645)#-##-##',
  '+7(48646)#-##-##',
  '+7(48647)#-##-##',
  '+7(48648)#-##-##',
  '+7(48649)#-##-##',
  '+7(48661)#-##-##',
  '+7(48662)#-##-##',
  '+7(48663)#-##-##',
  '+7(48664)#-##-##',
  '+7(48665)#-##-##',
  '+7(48666)#-##-##',
  '+7(48667)#-##-##',
  '+7(48672)#-##-##',
  '+7(48673)#-##-##',
  '+7(48674)#-##-##',
  '+7(48675)#-##-##',
  '+7(48676)#-##-##',
  '+7(48677)#-##-##',
  '+7(48678)#-##-##',
  '+7(48679)#-##-##',
  '+7(487)###-##-##',
  '+7(4872)##-##-##',
  '+7(48731)#-##-##',
  '+7(48732)#-##-##',
  '+7(48733)#-##-##',
  '+7(48734)#-##-##',
  '+7(48735)#-##-##',
  '+7(48736)#-##-##',
  '+7(48741)#-##-##',
  '+7(48742)#-##-##',
  '+7(48743)#-##-##',
  '+7(48744)#-##-##',
  '+7(48745)#-##-##',
  '+7(48746)#-##-##',
  '+7(48751)#-##-##',
  '+7(48752)#-##-##',
  '+7(48753)#-##-##',
  '+7(48754)#-##-##',
  '+7(48755)#-##-##',
  '+7(48756)#-##-##',
  '+7(48761)#-##-##',
  '+7(48762)#-##-##',
  '+7(48763)#-##-##',
  '+7(48766)#-##-##',
  '+7(48767)#-##-##',
  '+7(48768)#-##-##',
  '+7(491)###-##-##',
  '+7(4912)##-##-##',
  '+7(49130)#-##-##',
  '+7(49131)#-##-##',
  '+7(49132)#-##-##',
  '+7(49133)#-##-##',
  '+7(49135)#-##-##',
  '+7(49136)#-##-##',
  '+7(49137)#-##-##',
  '+7(49138)#-##-##',
  '+7(49139)#-##-##',
  '+7(49141)#-##-##',
  '+7(49142)#-##-##',
  '+7(49143)#-##-##',
  '+7(49144)#-##-##',
  '+7(49145)#-##-##',
  '+7(49146)#-##-##',
  '+7(49147)#-##-##',
  '+7(49148)#-##-##',
  '+7(49151)#-##-##',
  '+7(49152)#-##-##',
  '+7(49153)#-##-##',
  '+7(49154)#-##-##',
  '+7(49155)#-##-##',
  '+7(49156)#-##-##',
  '+7(49157)#-##-##',
  '+7(49158)#-##-##',
  '+7(492)###-##-##',
  '+7(4922)##-##-##',
  '+7(49231)#-##-##',
  '+7(49232)#-##-##',
  '+7(49233)#-##-##',
  '+7(49234)#-##-##',
  '+7(49235)#-##-##',
  '+7(49236)#-##-##',
  '+7(49237)#-##-##',
  '+7(49238)#-##-##',
  '+7(49241)#-##-##',
  '+7(49242)#-##-##',
  '+7(49243)#-##-##',
  '+7(49244)#-##-##',
  '+7(49245)#-##-##',
  '+7(49246)#-##-##',
  '+7(49247)#-##-##',
  '+7(49248)#-##-##',
  '+7(49254)#-##-##',
  '+7(493)###-##-##',
  '+7(4932)##-##-##',
  '+7(49331)#-##-##',
  '+7(49333)#-##-##',
  '+7(49334)#-##-##',
  '+7(49336)#-##-##',
  '+7(49337)#-##-##',
  '+7(49339)#-##-##',
  '+7(49341)#-##-##',
  '+7(49343)#-##-##',
  '+7(49344)#-##-##',
  '+7(49345)#-##-##',
  '+7(49346)#-##-##',
  '+7(49347)#-##-##',
  '+7(49349)#-##-##',
  '+7(49351)#-##-##',
  '+7(49352)#-##-##',
  '+7(49353)#-##-##',
  '+7(49354)#-##-##',
  '+7(49355)#-##-##',
  '+7(49356)#-##-##',
  '+7(49357)#-##-##',
  '+7(494)###-##-##',
  '+7(4942)##-##-##',
  '+7(49430)#-##-##',
  '+7(49431)#-##-##',
  '+7(49432)#-##-##',
  '+7(49433)#-##-##',
  '+7(49434)#-##-##',
  '+7(49435)#-##-##',
  '+7(49436)#-##-##',
  '+7(49437)#-##-##',
  '+7(49438)#-##-##',
  '+7(49439)#-##-##',
  '+7(49440)#-##-##',
  '+7(49441)#-##-##',
  '+7(49442)#-##-##',
  '+7(49443)#-##-##',
  '+7(49444)#-##-##',
  '+7(49445)#-##-##',
  '+7(49446)#-##-##',
  '+7(49447)#-##-##',
  '+7(49448)#-##-##',
  '+7(49449)#-##-##',
  '+7(49450)#-##-##',
  '+7(49451)#-##-##',
  '+7(49452)#-##-##',
  '+7(49453)#-##-##',
  '+7(495)###-##-##',
  '+7(496)###-##-##',
  '+7(498)###-##-##',
  '+7(499)###-##-##',
  '+7(811)###-##-##',
  '+7(8112)##-##-##',
  '+7(81131)#-##-##',
  '+7(81132)#-##-##',
  '+7(81133)#-##-##',
  '+7(81134)#-##-##',
  '+7(81135)#-##-##',
  '+7(81136)#-##-##',
  '+7(81137)#-##-##',
  '+7(81138)#-##-##',
  '+7(81139)#-##-##',
  '+7(81140)#-##-##',
  '+7(81141)#-##-##',
  '+7(81142)#-##-##',
  '+7(81143)#-##-##',
  '+7(81144)#-##-##',
  '+7(81145)#-##-##',
  '+7(81146)#-##-##',
  '+7(81147)#-##-##',
  '+7(81148)#-##-##',
  '+7(81149)#-##-##',
  '+7(81150)#-##-##',
  '+7(81151)#-##-##',
  '+7(81152)#-##-##',
  '+7(81153)#-##-##',
  '+7(812)###-##-##',
  '+7(813)###-##-##',
  '+7(81361)#-##-##',
  '+7(81362)#-##-##',
  '+7(81363)#-##-##',
  '+7(81364)#-##-##',
  '+7(81365)#-##-##',
  '+7(81366)#-##-##',
  '+7(81367)#-##-##',
  '+7(81368)#-##-##',
  '+7(81369)#-##-##',
  '+7(81370)#-##-##',
  '+7(81371)#-##-##',
  '+7(81372)#-##-##',
  '+7(81373)#-##-##',
  '+7(81374)#-##-##',
  '+7(81375)#-##-##',
  '+7(81376)#-##-##',
  '+7(81378)#-##-##',
  '+7(81379)#-##-##',
  '+7(814)###-##-##',
  '+7(8142)##-##-##',
  '+7(81430)#-##-##',
  '+7(81431)#-##-##',
  '+7(81433)#-##-##',
  '+7(81434)#-##-##',
  '+7(81436)#-##-##',
  '+7(81437)#-##-##',
  '+7(81439)#-##-##',
  '+7(81450)#-##-##',
  '+7(81451)#-##-##',
  '+7(81452)#-##-##',
  '+7(81454)#-##-##',
  '+7(81455)#-##-##',
  '+7(81456)#-##-##',
  '+7(81457)#-##-##',
  '+7(81458)#-##-##',
  '+7(81459)#-##-##',
  '+7(815)###-##-##',
  '+7(8152)##-##-##',
  '+7(81530)#-##-##',
  '+7(81531)#-##-##',
  '+7(81532)#-##-##',
  '+7(81533)#-##-##',
  '+7(81535)#-##-##',
  '+7(81536)#-##-##',
  '+7(81537)#-##-##',
  '+7(81538)#-##-##',
  '+7(81539)#-##-##',
  '+7(81551)#-##-##',
  '+7(81552)#-##-##',
  '+7(81553)#-##-##',
  '+7(81554)#-##-##',
  '+7(81555)#-##-##',
  '+7(81556)#-##-##',
  '+7(81558)#-##-##',
  '+7(81559)#-##-##',
  '+7(816)###-##-##',
  '+7(8162)##-##-##',
  '+7(81650)#-##-##',
  '+7(81651)#-##-##',
  '+7(81652)#-##-##',
  '+7(81653)#-##-##',
  '+7(81654)#-##-##',
  '+7(81655)#-##-##',
  '+7(81656)#-##-##',
  '+7(81657)#-##-##',
  '+7(81658)#-##-##',
  '+7(81659)#-##-##',
  '+7(81660)#-##-##',
  '+7(81661)#-##-##',
  '+7(81662)#-##-##',
  '+7(81663)#-##-##',
  '+7(81664)#-##-##',
  '+7(81665)#-##-##',
  '+7(81666)#-##-##',
  '+7(81667)#-##-##',
  '+7(81668)#-##-##',
  '+7(81669)#-##-##',
  '+7(817)###-##-##',
  '+7(8172)##-##-##',
  '+7(81732)#-##-##',
  '+7(81733)#-##-##',
  '+7(81737)#-##-##',
  '+7(81738)#-##-##',
  '+7(81739)#-##-##',
  '+7(81740)#-##-##',
  '+7(81741)#-##-##',
  '+7(81742)#-##-##',
  '+7(81743)#-##-##',
  '+7(81744)#-##-##',
  '+7(81745)#-##-##',
  '+7(81746)#-##-##',
  '+7(81747)#-##-##',
  '+7(81748)#-##-##',
  '+7(81749)#-##-##',
  '+7(81751)#-##-##',
  '+7(81752)#-##-##',
  '+7(81753)#-##-##',
  '+7(81754)#-##-##',
  '+7(81755)#-##-##',
  '+7(81756)#-##-##',
  '+7(81757)#-##-##',
  '+7(81758)#-##-##',
  '+7(81759)#-##-##',
  '+7(818)###-##-##',
  '+7(81830)#-##-##',
  '+7(81831)#-##-##',
  '+7(81832)#-##-##',
  '+7(81833)#-##-##',
  '+7(81834)#-##-##',
  '+7(81835)#-##-##',
  '+7(81836)#-##-##',
  '+7(81837)#-##-##',
  '+7(81838)#-##-##',
  '+7(81839)#-##-##',
  '+7(81840)#-##-##',
  '+7(81841)#-##-##',
  '+7(81842)#-##-##',
  '+7(81843)#-##-##',
  '+7(81848)#-##-##',
  '+7(81850)#-##-##',
  '+7(81851)#-##-##',
  '+7(81852)#-##-##',
  '+7(81853)#-##-##',
  '+7(81854)#-##-##',
  '+7(81855)#-##-##',
  '+7(81856)#-##-##',
  '+7(81858)#-##-##',
  '+7(81859)#-##-##',
  '+7(820)###-##-##',
  '+7(8202)##-##-##',
  '+7(821)###-##-##',
  '+7(8212)##-##-##',
  '+7(82130)#-##-##',
  '+7(82131)#-##-##',
  '+7(82132)#-##-##',
  '+7(82133)#-##-##',
  '+7(82134)#-##-##',
  '+7(82135)#-##-##',
  '+7(82136)#-##-##',
  '+7(82137)#-##-##',
  '+7(82138)#-##-##',
  '+7(82139)#-##-##',
  '+7(82140)#-##-##',
  '+7(82141)#-##-##',
  '+7(82142)#-##-##',
  '+7(82144)#-##-##',
  '+7(82145)#-##-##',
  '+7(82146)#-##-##',
  '+7(82149)#-##-##',
  '+7(82151)#-##-##',
  '+7(8216)##-##-##',
  '+7(831)###-##-##',
  '+7(8313)##-##-##',
  '+7(83130)#-##-##',
  '+7(83134)#-##-##',
  '+7(83136)#-##-##',
  '+7(83137)#-##-##',
  '+7(83138)#-##-##',
  '+7(83139)#-##-##',
  '+7(83140)#-##-##',
  '+7(83144)#-##-##',
  '+7(83145)#-##-##',
  '+7(83147)#-##-##',
  '+7(83148)#-##-##',
  '+7(83149)#-##-##',
  '+7(83150)#-##-##',
  '+7(83151)#-##-##',
  '+7(83152)#-##-##',
  '+7(83153)#-##-##',
  '+7(83154)#-##-##',
  '+7(83155)#-##-##',
  '+7(83156)#-##-##',
  '+7(83157)#-##-##',
  '+7(83158)#-##-##',
  '+7(83159)#-##-##',
  '+7(83160)#-##-##',
  '+7(83161)#-##-##',
  '+7(83162)#-##-##',
  '+7(83163)#-##-##',
  '+7(83164)#-##-##',
  '+7(83165)#-##-##',
  '+7(83166)#-##-##',
  '+7(83167)#-##-##',
  '+7(83168)#-##-##',
  '+7(83169)#-##-##',
  '+7(83170)#-##-##',
  '+7(83171)#-##-##',
  '+7(83172)#-##-##',
  '+7(83173)#-##-##',
  '+7(83174)#-##-##',
  '+7(83175)#-##-##',
  '+7(83176)#-##-##',
  '+7(83177)#-##-##',
  '+7(83178)#-##-##',
  '+7(83179)#-##-##',
  '+7(83190)#-##-##',
  '+7(83191)#-##-##',
  '+7(83192)#-##-##',
  '+7(83193)#-##-##',
  '+7(83194)#-##-##',
  '+7(83195)#-##-##',
  '+7(83196)#-##-##',
  '+7(83197)#-##-##',
  '+7(833)###-##-##',
  '+7(8332)##-##-##',
  '+7(83330)#-##-##',
  '+7(83331)#-##-##',
  '+7(83332)#-##-##',
  '+7(83333)#-##-##',
  '+7(83334)#-##-##',
  '+7(83335)#-##-##',
  '+7(83336)#-##-##',
  '+7(83337)#-##-##',
  '+7(83338)#-##-##',
  '+7(83339)#-##-##',
  '+7(83340)#-##-##',
  '+7(83341)#-##-##',
  '+7(83342)#-##-##',
  '+7(83343)#-##-##',
  '+7(83344)#-##-##',
  '+7(83345)#-##-##',
  '+7(83346)#-##-##',
  '+7(83347)#-##-##',
  '+7(83348)#-##-##',
  '+7(83349)#-##-##',
  '+7(83350)#-##-##',
  '+7(83351)#-##-##',
  '+7(83352)#-##-##',
  '+7(83353)#-##-##',
  '+7(83354)#-##-##',
  '+7(83355)#-##-##',
  '+7(83357)#-##-##',
  '+7(83358)#-##-##',
  '+7(83359)#-##-##',
  '+7(83361)#-##-##',
  '+7(83362)#-##-##',
  '+7(83363)#-##-##',
  '+7(83364)#-##-##',
  '+7(83365)#-##-##',
  '+7(83366)#-##-##',
  '+7(83367)#-##-##',
  '+7(83368)#-##-##',
  '+7(83369)#-##-##',
  '+7(83375)#-##-##',
  '+7(834)###-##-##',
  '+7(8342)##-##-##',
  '+7(83431)#-##-##',
  '+7(83432)#-##-##',
  '+7(83433)#-##-##',
  '+7(83434)#-##-##',
  '+7(83436)#-##-##',
  '+7(83437)#-##-##',
  '+7(83438)#-##-##',
  '+7(83439)#-##-##',
  '+7(83441)#-##-##',
  '+7(83442)#-##-##',
  '+7(83443)#-##-##',
  '+7(83444)#-##-##',
  '+7(83445)#-##-##',
  '+7(83446)#-##-##',
  '+7(83447)#-##-##',
  '+7(83448)#-##-##',
  '+7(83449)#-##-##',
  '+7(83451)#-##-##',
  '+7(83453)#-##-##',
  '+7(83454)#-##-##',
  '+7(83456)#-##-##',
  '+7(83457)#-##-##',
  '+7(83458)#-##-##',
  '+7(835)###-##-##',
  '+7(8352)##-##-##',
  '+7(83530)#-##-##',
  '+7(83531)#-##-##',
  '+7(83532)#-##-##',
  '+7(83533)#-##-##',
  '+7(83534)#-##-##',
  '+7(83535)#-##-##',
  '+7(83536)#-##-##',
  '+7(83537)#-##-##',
  '+7(83538)#-##-##',
  '+7(83539)#-##-##',
  '+7(83540)#-##-##',
  '+7(83541)#-##-##',
  '+7(83542)#-##-##',
  '+7(83543)#-##-##',
  '+7(83544)#-##-##',
  '+7(83545)#-##-##',
  '+7(83546)#-##-##',
  '+7(83547)#-##-##',
  '+7(83548)#-##-##',
  '+7(83549)#-##-##',
  '+7(83551)#-##-##',
  '+7(836)###-##-##',
  '+7(8362)##-##-##',
  '+7(83631)#-##-##',
  '+7(83632)#-##-##',
  '+7(83633)#-##-##',
  '+7(83634)#-##-##',
  '+7(83635)#-##-##',
  '+7(83636)#-##-##',
  '+7(83637)#-##-##',
  '+7(83638)#-##-##',
  '+7(83639)#-##-##',
  '+7(83641)#-##-##',
  '+7(83643)#-##-##',
  '+7(83644)#-##-##',
  '+7(83645)#-##-##',
  '+7(840)###-##-##',
  '+7(841)###-##-##',
  '+7(8412)##-##-##',
  '+7(84140)#-##-##',
  '+7(84141)#-##-##',
  '+7(84142)#-##-##',
  '+7(84143)#-##-##',
  '+7(84144)#-##-##',
  '+7(84145)#-##-##',
  '+7(84146)#-##-##',
  '+7(84147)#-##-##',
  '+7(84148)#-##-##',
  '+7(84150)#-##-##',
  '+7(84151)#-##-##',
  '+7(84152)#-##-##',
  '+7(84153)#-##-##',
  '+7(84154)#-##-##',
  '+7(84155)#-##-##',
  '+7(84156)#-##-##',
  '+7(84157)#-##-##',
  '+7(84158)#-##-##',
  '+7(84159)#-##-##',
  '+7(84161)#-##-##',
  '+7(84162)#-##-##',
  '+7(84163)#-##-##',
  '+7(84164)#-##-##',
  '+7(84165)#-##-##',
  '+7(84167)#-##-##',
  '+7(84168)#-##-##',
  '+7(84169)#-##-##',
  '+7(842)###-##-##',
  '+7(8422)##-##-##',
  '+7(84230)#-##-##',
  '+7(84231)#-##-##',
  '+7(84232)#-##-##',
  '+7(84233)#-##-##',
  '+7(84234)#-##-##',
  '+7(84235)#-##-##',
  '+7(84237)#-##-##',
  '+7(84238)#-##-##',
  '+7(84239)#-##-##',
  '+7(84240)#-##-##',
  '+7(84241)#-##-##',
  '+7(84242)#-##-##',
  '+7(84243)#-##-##',
  '+7(84244)#-##-##',
  '+7(84245)#-##-##',
  '+7(84246)#-##-##',
  '+7(84247)#-##-##',
  '+7(84248)#-##-##',
  '+7(84249)#-##-##',
  '+7(84253)#-##-##',
  '+7(84254)#-##-##',
  '+7(84255)#-##-##',
  '+7(843)###-##-##',
  '+7(84341)#-##-##',
  '+7(84342)#-##-##',
  '+7(84344)#-##-##',
  '+7(84345)#-##-##',
  '+7(84346)#-##-##',
  '+7(84347)#-##-##',
  '+7(84348)#-##-##',
  '+7(84360)#-##-##',
  '+7(84361)#-##-##',
  '+7(84362)#-##-##',
  '+7(84364)#-##-##',
  '+7(84365)#-##-##',
  '+7(84366)#-##-##',
  '+7(84367)#-##-##',
  '+7(84368)#-##-##',
  '+7(84369)#-##-##',
  '+7(84370)#-##-##',
  '+7(84371)#-##-##',
  '+7(84373)#-##-##',
  '+7(84374)#-##-##',
  '+7(84375)#-##-##',
  '+7(84376)#-##-##',
  '+7(84377)#-##-##',
  '+7(84378)#-##-##',
  '+7(84379)#-##-##',
  '+7(84396)#-##-##',
  '+7(844)###-##-##',
  '+7(8443)##-##-##',
  '+7(84442)#-##-##',
  '+7(84443)#-##-##',
  '+7(84444)#-##-##',
  '+7(84445)#-##-##',
  '+7(84446)#-##-##',
  '+7(84447)#-##-##',
  '+7(84452)#-##-##',
  '+7(84453)#-##-##',
  '+7(84454)#-##-##',
  '+7(84455)#-##-##',
  '+7(84456)#-##-##',
  '+7(84457)#-##-##',
  '+7(84458)#-##-##',
  '+7(84461)#-##-##',
  '+7(84462)#-##-##',
  '+7(84463)#-##-##',
  '+7(84464)#-##-##',
  '+7(84465)#-##-##',
  '+7(84466)#-##-##',
  '+7(84467)#-##-##',
  '+7(84468)#-##-##',
  '+7(84472)#-##-##',
  '+7(84473)#-##-##',
  '+7(84474)#-##-##',
  '+7(84475)#-##-##',
  '+7(84476)#-##-##',
  '+7(84477)#-##-##',
  '+7(84478)#-##-##',
  '+7(84479)#-##-##',
  '+7(84492)#-##-##',
  '+7(84493)#-##-##',
  '+7(84494)#-##-##',
  '+7(84495)#-##-##',
  '+7(845)###-##-##',
  '+7(8452)##-##-##',
  '+7(8453)##-##-##',
  '+7(84540)#-##-##',
  '+7(84542)#-##-##',
  '+7(84543)#-##-##',
  '+7(84544)#-##-##',
  '+7(84545)#-##-##',
  '+7(84548)#-##-##',
  '+7(84549)#-##-##',
  '+7(84550)#-##-##',
  '+7(84551)#-##-##',
  '+7(84552)#-##-##',
  '+7(84554)#-##-##',
  '+7(84555)#-##-##',
  '+7(84557)#-##-##',
  '+7(84558)#-##-##',
  '+7(84560)#-##-##',
  '+7(84561)#-##-##',
  '+7(84562)#-##-##',
  '+7(84563)#-##-##',
  '+7(84564)#-##-##',
  '+7(84565)#-##-##',
  '+7(84566)#-##-##',
  '+7(84567)#-##-##',
  '+7(84568)#-##-##',
  '+7(84573)#-##-##',
  '+7(84574)#-##-##',
  '+7(84575)#-##-##',
  '+7(84576)#-##-##',
  '+7(84577)#-##-##',
  '+7(84578)#-##-##',
  '+7(84579)#-##-##',
  '+7(84591)#-##-##',
  '+7(84592)#-##-##',
  '+7(84593)#-##-##',
  '+7(84595)#-##-##',
  '+7(84596)#-##-##',
  '+7(846)###-##-##',
  '+7(84630)#-##-##',
  '+7(84635)#-##-##',
  '+7(84639)#-##-##',
  '+7(8464)##-##-##',
  '+7(84646)#-##-##',
  '+7(84647)#-##-##',
  '+7(84648)#-##-##',
  '+7(84650)#-##-##',
  '+7(84651)#-##-##',
  '+7(84652)#-##-##',
  '+7(84653)#-##-##',
  '+7(84654)#-##-##',
  '+7(84655)#-##-##',
  '+7(84656)#-##-##',
  '+7(84657)#-##-##',
  '+7(84658)#-##-##',
  '+7(84660)#-##-##',
  '+7(84661)#-##-##',
  '+7(84663)#-##-##',
  '+7(84664)#-##-##',
  '+7(84666)#-##-##',
  '+7(84667)#-##-##',
  '+7(84670)#-##-##',
  '+7(84671)#-##-##',
  '+7(84672)#-##-##',
  '+7(84673)#-##-##',
  '+7(84674)#-##-##',
  '+7(84675)#-##-##',
  '+7(84676)#-##-##',
  '+7(84677)#-##-##',
  '+7(847)###-##-##',
  '+7(84722)#-##-##',
  '+7(84731)#-##-##',
  '+7(84732)#-##-##',
  '+7(84733)#-##-##',
  '+7(84734)#-##-##',
  '+7(84735)#-##-##',
  '+7(84736)#-##-##',
  '+7(84741)#-##-##',
  '+7(84742)#-##-##',
  '+7(84743)#-##-##',
  '+7(84744)#-##-##',
  '+7(84745)#-##-##',
  '+7(84746)#-##-##',
  '+7(84747)#-##-##',
  '+7(848)###-##-##',
  '+7(8482)##-##-##',
  '+7(84862)#-##-##',
  '+7(851)###-##-##',
  '+7(8512)##-##-##',
  '+7(85140)#-##-##',
  '+7(85141)#-##-##',
  '+7(85142)#-##-##',
  '+7(85143)#-##-##',
  '+7(85144)#-##-##',
  '+7(85145)#-##-##',
  '+7(85146)#-##-##',
  '+7(85147)#-##-##',
  '+7(85148)#-##-##',
  '+7(85149)#-##-##',
  '+7(85171)#-##-##',
  '+7(85172)#-##-##',
  '+7(855)###-##-##',
  '+7(8552)##-##-##',
  '+7(8553)##-##-##',
  '+7(85549)#-##-##',
  '+7(8555)##-##-##',
  '+7(85551)#-##-##',
  '+7(85552)#-##-##',
  '+7(85555)#-##-##',
  '+7(85556)#-##-##',
  '+7(85557)#-##-##',
  '+7(85558)#-##-##',
  '+7(85559)#-##-##',
  '+7(85563)#-##-##',
  '+7(85569)#-##-##',
  '+7(8557)##-##-##',
  '+7(85592)#-##-##',
  '+7(85593)#-##-##',
  '+7(85594)#-##-##',
  '+7(85595)#-##-##',
  '+7(861)###-##-##',
  '+7(86130)#-##-##',
  '+7(86131)#-##-##',
  '+7(86132)#-##-##',
  '+7(86133)#-##-##',
  '+7(86135)#-##-##',
  '+7(86137)#-##-##',
  '+7(86138)#-##-##',
  '+7(86140)#-##-##',
  '+7(86141)#-##-##',
  '+7(86142)#-##-##',
  '+7(86143)#-##-##',
  '+7(86144)#-##-##',
  '+7(86145)#-##-##',
  '+7(86146)#-##-##',
  '+7(86147)#-##-##',
  '+7(86148)#-##-##',
  '+7(86149)#-##-##',
  '+7(86150)#-##-##',
  '+7(86151)#-##-##',
  '+7(86152)#-##-##',
  '+7(86153)#-##-##',
  '+7(86154)#-##-##',
  '+7(86155)#-##-##',
  '+7(86156)#-##-##',
  '+7(86157)#-##-##',
  '+7(86158)#-##-##',
  '+7(86159)#-##-##',
  '+7(86160)#-##-##',
  '+7(86161)#-##-##',
  '+7(86162)#-##-##',
  '+7(86163)#-##-##',
  '+7(86164)#-##-##',
  '+7(86165)#-##-##',
  '+7(86166)#-##-##',
  '+7(86167)#-##-##',
  '+7(86168)#-##-##',
  '+7(86169)#-##-##',
  '+7(8617)##-##-##',
  '+7(86191)#-##-##',
  '+7(86192)#-##-##',
  '+7(86193)#-##-##',
  '+7(86195)#-##-##',
  '+7(86196)#-##-##',
  '+7(862)###-##-##',
  '+7(863)###-##-##',
  '+7(8634)##-##-##',
  '+7(86340)#-##-##',
  '+7(86341)#-##-##',
  '+7(86342)#-##-##',
  '+7(86345)#-##-##',
  '+7(86347)#-##-##',
  '+7(86348)#-##-##',
  '+7(86349)#-##-##',
  '+7(8635)##-##-##',
  '+7(86350)#-##-##',
  '+7(86351)#-##-##',
  '+7(86353)#-##-##',
  '+7(86354)#-##-##',
  '+7(86355)#-##-##',
  '+7(86356)#-##-##',
  '+7(86357)#-##-##',
  '+7(86358)#-##-##',
  '+7(86359)#-##-##',
  '+7(8636)##-##-##',
  '+7(86360)#-##-##',
  '+7(86361)#-##-##',
  '+7(86363)#-##-##',
  '+7(86364)#-##-##',
  '+7(86365)#-##-##',
  '+7(86367)#-##-##',
  '+7(86368)#-##-##',
  '+7(86369)#-##-##',
  '+7(86370)#-##-##',
  '+7(86371)#-##-##',
  '+7(86372)#-##-##',
  '+7(86373)#-##-##',
  '+7(86374)#-##-##',
  '+7(86375)#-##-##',
  '+7(86376)#-##-##',
  '+7(86377)#-##-##',
  '+7(86378)#-##-##',
  '+7(86379)#-##-##',
  '+7(86382)#-##-##',
  '+7(86383)#-##-##',
  '+7(86384)#-##-##',
  '+7(86385)#-##-##',
  '+7(86386)#-##-##',
  '+7(86387)#-##-##',
  '+7(86388)#-##-##',
  '+7(86389)#-##-##',
  '+7(8639)##-##-##',
  '+7(86391)#-##-##',
  '+7(86393)#-##-##',
  '+7(86394)#-##-##',
  '+7(86395)#-##-##',
  '+7(86396)#-##-##',
  '+7(86397)#-##-##',
  '+7(865)###-##-##',
  '+7(8652)##-##-##',
  '+7(86540)#-##-##',
  '+7(86541)#-##-##',
  '+7(86542)#-##-##',
  '+7(86543)#-##-##',
  '+7(86544)#-##-##',
  '+7(86545)#-##-##',
  '+7(86546)#-##-##',
  '+7(86547)#-##-##',
  '+7(86548)#-##-##',
  '+7(86549)#-##-##',
  '+7(86550)#-##-##',
  '+7(86552)#-##-##',
  '+7(86553)#-##-##',
  '+7(86554)#-##-##',
  '+7(86555)#-##-##',
  '+7(86556)#-##-##',
  '+7(86557)#-##-##',
  '+7(86558)#-##-##',
  '+7(86559)#-##-##',
  '+7(86560)#-##-##',
  '+7(86563)#-##-##',
  '+7(86565)#-##-##',
  '+7(866)###-##-##',
  '+7(8662)##-##-##',
  '+7(86630)#-##-##',
  '+7(86631)#-##-##',
  '+7(86632)#-##-##',
  '+7(86633)#-##-##',
  '+7(86634)#-##-##',
  '+7(86635)#-##-##',
  '+7(86636)#-##-##',
  '+7(86637)#-##-##',
  '+7(86638)#-##-##',
  '+7(867)###-##-##',
  '+7(8672)##-##-##',
  '+7(86731)#-##-##',
  '+7(86732)#-##-##',
  '+7(86733)#-##-##',
  '+7(86734)#-##-##',
  '+7(86735)#-##-##',
  '+7(86736)#-##-##',
  '+7(86737)#-##-##',
  '+7(86738)#-##-##',
  '+7(86739)#-##-##',
  '+7(871)###-##-##',
  '+7(8712)##-##-##',
  '+7(87132)#-##-##',
  '+7(87134)#-##-##',
  '+7(87135)#-##-##',
  '+7(87136)#-##-##',
  '+7(87142)#-##-##',
  '+7(87143)#-##-##',
  '+7(87145)#-##-##',
  '+7(87146)#-##-##',
  '+7(87147)#-##-##',
  '+7(87148)#-##-##',
  '+7(87152)#-##-##',
  '+7(87154)#-##-##',
  '+7(87155)#-##-##',
  '+7(87156)#-##-##',
  '+7(87164)#-##-##',
  '+7(872)###-##-##',
  '+7(8722)##-##-##',
  '+7(87230)#-##-##',
  '+7(87231)#-##-##',
  '+7(87232)#-##-##',
  '+7(87233)#-##-##',
  '+7(87234)#-##-##',
  '+7(87235)#-##-##',
  '+7(87236)#-##-##',
  '+7(87237)#-##-##',
  '+7(87238)#-##-##',
  '+7(87239)#-##-##',
  '+7(87240)#-##-##',
  '+7(87242)#-##-##',
  '+7(87243)#-##-##',
  '+7(87244)#-##-##',
  '+7(87245)#-##-##',
  '+7(87246)#-##-##',
  '+7(87247)#-##-##',
  '+7(87248)#-##-##',
  '+7(87249)#-##-##',
  '+7(87252)#-##-##',
  '+7(87254)#-##-##',
  '+7(87255)#-##-##',
  '+7(87256)#-##-##',
  '+7(87257)#-##-##',
  '+7(87258)#-##-##',
  '+7(87259)#-##-##',
  '+7(87260)#-##-##',
  '+7(87261)#-##-##',
  '+7(87262)#-##-##',
  '+7(87263)#-##-##',
  '+7(87264)#-##-##',
  '+7(87265)#-##-##',
  '+7(87266)#-##-##',
  '+7(87267)#-##-##',
  '+7(87268)#-##-##',
  '+7(87271)#-##-##',
  '+7(87272)#-##-##',
  '+7(87273)#-##-##',
  '+7(87274)#-##-##',
  '+7(87275)#-##-##',
  '+7(87276)#-##-##',
  '+7(87279)#-##-##',
  '+7(873)###-##-##',
  '+7(8732)##-##-##',
  '+7(87341)#-##-##',
  '+7(87342)#-##-##',
  '+7(87343)#-##-##',
  '+7(87344)#-##-##',
  '+7(87345)#-##-##',
  '+7(877)###-##-##',
  '+7(8772)##-##-##',
  '+7(87770)#-##-##',
  '+7(87771)#-##-##',
  '+7(87772)#-##-##',
  '+7(87773)#-##-##',
  '+7(87777)#-##-##',
  '+7(87778)#-##-##',
  '+7(87779)#-##-##',
  '+7(878)###-##-##',
  '+7(8782)##-##-##',
  '+7(87870)#-##-##',
  '+7(87872)#-##-##',
  '+7(87873)#-##-##',
  '+7(87874)#-##-##',
  '+7(87875)#-##-##',
  '+7(87876)#-##-##',
  '+7(87877)#-##-##',
  '+7(87878)#-##-##',
  '+7(87879)#-##-##',
  '+7(879)###-##-##',
  '+7(87922)#-##-##',
  '+7(8793)##-##-##',
  '+7(87932)#-##-##',
  '+7(87934)#-##-##',
  '+7(87935)#-##-##',
  '+7(87937)#-##-##',
  '+7(87938)#-##-##',
  '+7(87951)#-##-##',
  '+7(87961)#-##-##',
  '+7(87964)#-##-##',
];

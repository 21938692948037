import { createMedia } from '@artsy/fresnel';

const AppMedia = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 431,
    laptop: 769,
    notebook: 1025,
    desktop: 1281,
  },
});
export const mediaStyles = AppMedia.createMediaStyle();

export const { Media, MediaContextProvider } = AppMedia;

import Counter from 'src/shared/ui/Counter/Counter';
import ChangeQty from '../../../Products/Components/ChangeQty/ChangeQty';
import styles from './plusMinusButton.module.scss';

function PlusMinusButton({ product, position, child, changeQtyCallback }) {
  return (
    <ChangeQty
      product={product}
      position={position}
      child={child}
      changeQtyCallback={changeQtyCallback}
      render={({ qty, add, subtract }) => {
        return (
          <Counter
            qty={qty}
            className={styles.qtyContainer}
            step={1}
            setQty={(count, e) => {
              if (count > 0) {
                add(e);
              } else {
                subtract(e, -count);
              }
            }}
          />
        );
      }}
    />
  );
}

export default PlusMinusButton;

import { useTranslation } from 'react-i18next';
import { useSelectedCountry } from 'src/entities/countries';
import styles from './copyright.module.scss';

export function Copyright() {
  const { t } = useTranslation();
  const now = new Date();
  const year = now.getFullYear();
  const { isUkraine, isArmenia } = useSelectedCountry();
  return (
    <div
      itemScope
      itemType="https://schema.org/Organization"
      className={styles.copyrightContainer}
    >
      {t('Footer.Chain stores')} &laquo;
      {isArmenia ? 'SW' : t('localization.companyName')}&raquo;
      {isUkraine || isArmenia ? (
        <span> &#169; {year}</span>
      ) : (
        <span> &#169; 2011 - {year}</span>
      )}
    </div>
  );
}

import * as session from 'modules/session';
import globalStore from 'modules/global-store';
import CustomHelmet from 'src/frontend/Components/CustomHelmet';
import { useSelectCountry } from 'src/entities/countries';

function MainHelmet() {
  const { city, protocol, location } = session.get();
  const { city_name, phone = '' } = globalStore.get('current_city');
  const { worktime } = globalStore.get('city_info');
  const countryName = useSelectCountry().name;
  const seoScript = {
    '@context': 'http://schema.org/',
    '@type': 'FastFoodRestaurant',
    url: `${protocol}://${location}/${city}/`, // главная Города
    image: `${protocol}://${location}assets/images/icon/logo.svg`, // лого
    name: 'Суши Wok',
    acceptsReservations: 'no',
    menu: `${protocol}://${location}/${city}/menu/`, // меню города
    servesCuisine: 'японская, паназиатская, итальянская, китайская, смешанная',
    currenciesAccepted: 'Рубли',
    openingHours: `Mo-Su ${worktime.from}-${worktime.to}`, // дни/часы
    paymentAccepted: 'наличные, карты',
    priceRange: '150-1000',
    address: {
      '@type': 'PostalAddress',
      addressCountry: countryName, // страна
      addressLocality: city_name, // город
      telephone: phone.replace(/[^0-9]/g, ''), // телефон
      alternateName: 'Sushi Wok',
      description: 'Самовывоз и доставка суши, роллов, лапша Wok, pizza',
    },
    areaServed: [
      `${protocol}://${location}/${city}/delivery/`, // страница доставки в городе
    ],
    serviceArea: `${protocol}://${location}/${city}/delivery/`, // страница доставки в городе
    foundingDate: '2011',
    logo: {
      '@type': 'ImageObject',
      caption: 'Суши Wok',
      contentUrl: `${protocol}://${location}assets/images/icon/logo.svg`, // ссылка на лого
    },
  };

  return (
    <CustomHelmet
      type="website"
      script={[
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify(seoScript),
        },
      ]}
    />
  );
}

export default MainHelmet;

import './YoutubeWidget.scss';

function YoutubeWidget({ videoLink }) {
  return (
    <div className="youtubeWrapper">
      <iframe
        title={`${videoLink} video`}
        id="player"
        width="1200"
        height="360"
        src={videoLink}
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
}

export default YoutubeWidget;

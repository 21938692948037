import { useEffect, useState } from 'react';

interface IDynamicImageArguments {
  urlChunk: string;
  alt: string;
  className?: string;
}

function DynamicImageArguments({
  urlChunk,
  alt,
  ...props
}: IDynamicImageArguments) {
  const [src, setSrc] = useState('');
  useEffect(() => {
    import(`assets/images/arguments/elems/${urlChunk}.png`).then((response) => {
      setSrc(response.default);
    });
  }, [urlChunk]);

  if (!src) {
    return null;
  }
  return <img src={src} alt={alt} {...props} />;
}

export default DynamicImageArguments;

export const nameValidete = (text?: string) => {
  if (!text) return false;
  const checkingLetters = /^[a-zA-Zа-яА-ЯёЁієїЄІЇ]+$/;
  const examination =
    /^(([a-zA-Zа-яА-ЯёЁієїЄІЇ]{1,}([-]| |[a-zA-Zа-яА-ЯёЁієїЄІЇ]))+[a-zA-Zа-яА-ЯёЁієїЄІЇ]+$)/;
  if (text.length <= 2) {
    return checkingLetters.test(text);
  }
  return examination.test(text);
};

import { formatHelper, formaterDate } from 'src/shared/lib/date';
import { TFunction } from 'i18next';
import { IDeliveryError } from 'src/entities/delivery';
import Emitter from '../../../Emitters';

export const showDeliveryErrorModal = (
  error: IDeliveryError,
  t: TFunction,
  currency: string
) => {
  const { type, data: errorData } = error;
  if (type === 'open_at') {
    const openTime = formaterDate(errorData as string, formatHelper.time);
    Emitter.emit('MODAL_CALL', {
      modal: 'ErrorCartModal',
      value: `${t('BlockForm.delivery_open_at')} ${openTime}`,
    });
  }
  if (type === 'min_order') {
    Emitter.emit('MODAL_CALL', {
      modal: 'ErrorCartModal',
      value: `${t(
        'BlockForm.minimumOrderForDelivery'
      )} ${errorData}${currency}`,
    });
  }
  if (type === 'not_delivery') {
    Emitter.emit('MODAL_CALL', {
      modal: 'ErrorCartModal',
      value: t('BlockForm.at_this_address_shipping_is_not_carried_out'),
    });
  }
  if (type === 'product_on_stop') {
    Emitter.emit('MODAL_CALL', {
      modal: 'StopListsModal',
      value: {
        product_ids: (errorData as number[]) || [],
      },
    });
  }
};

import { useSelectCity } from 'src/entities/cities';
import { useSelectCityModal } from '../../model';
import styles from './current-city-btn.module.scss';

export function CurrentCityBtn() {
  const modal = useSelectCityModal();
  const city = useSelectCity();

  return (
    <button type="button" className={styles.city} onClick={() => modal.show()}>
      {city.city_name}
    </button>
  );
}

export default [
  '+247-####',
  '+376-###-###',
  '+971-5#-###-####',
  '+971-#-###-####',
  '+93-##-###-####',
  '+1(268)###-####',
  '+1(264)###-####',
  '+355(###)###-###',
  '+374-##-###-###',
  '+599-###-####',
  '+599-###-####',
  '+599-9###-####',
  '+244(###)###-###',
  '+672-1##-###',
  '+54(###)###-####',
  '+1(684)###-####',
  '+43(###)###-####',
  '+61-#-####-####',
  '+297-###-####',
  '+994-##-###-##-##',
  '+387-##-#####',
  '+387-##-####',
  '+1(246)###-####',
  '+880-##-###-###',
  '+32(###)###-###',
  '+226-##-##-####',
  '+359(###)###-###',
  '+973-####-####',
  '+257-##-##-####',
  '+229-##-##-####',
  '+1(441)###-####',
  '+673-###-####',
  '+591-#-###-####',
  '+55-##-####-####',
  '+55-##-#####-####',
  '+1(242)###-####',
  '+975-17-###-###',
  '+975-#-###-###',
  '+267-##-###-###',
  '+375(##)###-##-##',
  '+501-###-####',
  '+243(###)###-###',
  '+236-##-##-####',
  '+242-##-###-####',
  '+41-##-###-####',
  '+225-##-###-###',
  '+682-##-###',
  '+56-#-####-####',
  '+237-####-####',
  '+86(###)####-####',
  '+86(###)####-###',
  '+86-##-#####-#####',
  '+57(###)###-####',
  '+506-####-####',
  '+53-#-###-####',
  '+238(###)##-##',
  '+599-###-####',
  '+357-##-###-###',
  '+420(###)###-###',
  '+49(####)###-####',
  '+49(###)###-####',
  '+49(###)##-####',
  '+49(###)##-###',
  '+49(###)##-##',
  '+49-###-###',
  '+253-##-##-##-##',
  '+45-##-##-##-##',
  '+1(767)###-####',
  '+1(809)###-####',
  '+1(829)###-####',
  '+1(849)###-####',
  '+213-##-###-####',
  '+593-##-###-####',
  '+593-#-###-####',
  '+372-####-####',
  '+372-###-####',
  '+20(###)###-####',
  '+291-#-###-###',
  '+34(###)###-###',
  '+251-##-###-####',
  '+358(###)###-##-##',
  '+679-##-#####',
  '+500-#####',
  '+691-###-####',
  '+298-###-###',
  '+262-#####-####',
  '+33(###)###-###',
  '+508-##-####',
  '+590(###)###-###',
  '+241-#-##-##-##',
  '+1(473)###-####',
  '+995(###)###-###',
  '+594-#####-####',
  '+233(###)###-###',
  '+350-###-#####',
  '+299-##-##-##',
  '+220(###)##-##',
  '+224-##-###-###',
  '+240-##-###-####',
  '+30(###)###-####',
  '+502-#-###-####',
  '+1(671)###-####',
  '+245-#-######',
  '+592-###-####',
  '+852-####-####',
  '+504-####-####',
  '+385-(##)-###-###',
  '+385-(##)-###-####',
  '+385-1-####-###',
  '+509-##-##-####',
  '+36(###)###-###',
  '+62(8##)###-####',
  '+62-##-###-##',
  '+62-##-###-###',
  '+62-##-###-####',
  '+62(8##)###-###',
  '+62(8##)###-##-###',
  '+353(###)###-###',
  '+972-5#-###-####',
  '+972-#-###-####',
  '+91(####)###-###',
  '+246-###-####',
  '+964(###)###-####',
  '+98(###)###-####',
  '+354-###-####',
  '+39(###)####-###',
  '+1(876)###-####',
  '+962-#-####-####',
  '+81-##-####-####',
  '+81(###)###-###',
  '+254-###-######',
  '+996(###)###-###',
  '+855-##-###-###',
  '+686-##-###',
  '+269-##-#####',
  '+1(869)###-####',
  '+850-191-###-####',
  '+850-##-###-###',
  '+850-###-####-###',
  '+850-###-###',
  '+850-####-####',
  '+850-####-#############',
  '+82-##-###-####',
  '+965-####-####',
  '+1(345)###-####',
  '+7(6##)###-##-##',
  '+7(7##)###-##-##',
  '+856(20##)###-###',
  '+856-##-###-###',
  '+961-##-###-###',
  '+961-#-###-###',
  '+1(758)###-####',
  '+423(###)###-####',
  '+94-##-###-####',
  '+231-##-###-###',
  '+266-#-###-####',
  '+370(###)##-###',
  '+352-###-###',
  '+352-####-###',
  '+352-#####-###',
  '+352-######-###',
  '+371-##-###-###',
  '+218-##-###-###',
  '+218-21-###-####',
  '+212-##-####-###',
  '+377(###)###-###',
  '+377-##-###-###',
  '+373-####-####',
  '+382-##-###-###',
  '+261-##-##-#####',
  '+692-###-####',
  '+389-##-###-###',
  '+223-##-##-####',
  '+95-##-###-###',
  '+95-#-###-###',
  '+95-###-###',
  '+976-##-##-####',
  '+853-####-####',
  '+1(670)###-####',
  '+596(###)##-##-##',
  '+222-##-##-####',
  '+1(664)###-####',
  '+356-####-####',
  '+230-###-####',
  '+960-###-####',
  '+265-1-###-###',
  '+265-#-####-####',
  '+52(###)###-####',
  '+52-##-##-####',
  '+60-##-###-####',
  '+60-11-####-####',
  '+60(###)###-###',
  '+60-##-###-###',
  '+60-#-###-###',
  '+258-##-###-###',
  '+264-##-###-####',
  '+687-##-####',
  '+227-##-##-####',
  '+672-3##-###',
  '+234(###)###-####',
  '+234-##-###-###',
  '+234-##-###-##',
  '+234(###)###-####',
  '+505-####-####',
  '+31-##-###-####',
  '+47(###)##-###',
  '+977-##-###-###',
  '+674-###-####',
  '+683-####',
  '+64(###)###-###',
  '+64-##-###-###',
  '+64(###)###-####',
  '+968-##-###-###',
  '+507-###-####',
  '+51(###)###-###',
  '+689-##-##-##',
  '+675(###)##-###',
  '+63(###)###-####',
  '+92(###)###-####',
  '+48(###)###-###',
  '+970-##-###-####',
  '+351-##-###-####',
  '+680-###-####',
  '+595(###)###-###',
  '+974-####-####',
  '+262-#####-####',
  '+40-##-###-####',
  '+381-##-###-####',
  '+7(###)###-##-##',
  '+250(###)###-###',
  '+966-5-####-####',
  '+966-#-###-####',
  '+677-###-####',
  '+677-#####',
  '+248-#-###-###',
  '+249-##-###-####',
  '+46-##-###-####',
  '+65-####-####',
  '+290-####',
  '+290-####',
  '+386-##-###-###',
  '+421(###)###-###',
  '+232-##-######',
  '+378-####-######',
  '+221-##-###-####',
  '+252-##-###-###',
  '+252-#-###-###',
  '+252-#-###-###',
  '+597-###-####',
  '+597-###-###',
  '+211-##-###-####',
  '+239-##-#####',
  '+503-##-##-####',
  '+1(721)###-####',
  '+963-##-####-###',
  '+268-##-##-####',
  '+1(649)###-####',
  '+235-##-##-##-##',
  '+228-##-###-###',
  '+66-##-###-####',
  '+66-##-###-###',
  '+992-##-###-####',
  '+690-####',
  '+670-###-####',
  '+670-77#-#####',
  '+670-78#-#####',
  '+993-#-###-####',
  '+216-##-###-###',
  '+676-#####',
  '+90(###)###-####',
  '+1(868)###-####',
  '+688-90####',
  '+688-2####',
  '+886-#-####-####',
  '+886-####-####',
  '+255-##-###-####',
  '+380(##)###-##-##',
  '+256(###)###-###',
  '+44-##-####-####',
  '+598-#-###-##-##',
  '+998-##-###-####',
  '+39-6-698-#####',
  '+1(784)###-####',
  '+58(###)###-####',
  '+1(284)###-####',
  '+1(340)###-####',
  '+84-##-####-###',
  '+84(###)####-###',
  '+678-##-#####',
  '+678-#####',
  '+681-##-####',
  '+685-##-####',
  '+967-###-###-###',
  '+967-#-###-###',
  '+967-##-###-###',
  '+27-##-###-####',
  '+260-##-###-####',
  '+263-#-######',
  '+1(###)###-####',
  '+7(301)###-##-##',
  '+7(3012)##-##-##',
  '+7(30130)#-##-##',
  '+7(30131)#-##-##',
  '+7(30132)#-##-##',
  '+7(30133)#-##-##',
  '+7(30134)#-##-##',
  '+7(30135)#-##-##',
  '+7(30136)#-##-##',
  '+7(30137)#-##-##',
  '+7(30138)#-##-##',
  '+7(30140)#-##-##',
  '+7(30141)#-##-##',
  '+7(30142)#-##-##',
  '+7(30143)#-##-##',
  '+7(30144)#-##-##',
  '+7(30145)#-##-##',
  '+7(30146)#-##-##',
  '+7(30147)#-##-##',
  '+7(30148)#-##-##',
  '+7(30149)#-##-##',
  '+7(30150)#-##-##',
  '+7(30153)#-##-##',
  '+7(302)###-##-##',
  '+7(3022)##-##-##',
  '+7(30230)#-##-##',
  '+7(30231)#-##-##',
  '+7(30232)#-##-##',
  '+7(30233)#-##-##',
  '+7(30234)#-##-##',
  '+7(30235)#-##-##',
  '+7(30236)#-##-##',
  '+7(30237)#-##-##',
  '+7(30238)#-##-##',
  '+7(30239)#-##-##',
  '+7(30240)#-##-##',
  '+7(30241)#-##-##',
  '+7(30242)#-##-##',
  '+7(30243)#-##-##',
  '+7(30244)#-##-##',
  '+7(30245)#-##-##',
  '+7(30246)#-##-##',
  '+7(30247)#-##-##',
  '+7(30248)#-##-##',
  '+7(30249)#-##-##',
  '+7(30251)#-##-##',
  '+7(30252)#-##-##',
  '+7(30253)#-##-##',
  '+7(30255)#-##-##',
  '+7(30256)#-##-##',
  '+7(30257)#-##-##',
  '+7(30261)#-##-##',
  '+7(30262)#-##-##',
  '+7(30264)#-##-##',
  '+7(30265)#-##-##',
  '+7(30266)#-##-##',
  '+7(341)###-##-##',
  '+7(3412)##-##-##',
  '+7(34126)#-##-##',
  '+7(34130)#-##-##',
  '+7(34132)#-##-##',
  '+7(34133)#-##-##',
  '+7(34134)#-##-##',
  '+7(34136)#-##-##',
  '+7(34138)#-##-##',
  '+7(34139)#-##-##',
  '+7(34141)#-##-##',
  '+7(34145)#-##-##',
  '+7(34147)#-##-##',
  '+7(34150)#-##-##',
  '+7(34151)#-##-##',
  '+7(34152)#-##-##',
  '+7(34153)#-##-##',
  '+7(34154)#-##-##',
  '+7(34155)#-##-##',
  '+7(34157)#-##-##',
  '+7(34158)#-##-##',
  '+7(34159)#-##-##',
  '+7(34161)#-##-##',
  '+7(34162)#-##-##',
  '+7(34163)#-##-##',
  '+7(34164)#-##-##',
  '+7(34166)#-##-##',
  '+7(342)###-##-##',
  '+7(342)2##-##-##',
  '+7(342)3##-##-##',
  '+7(3424)2#-##-##',
  '+7(34240)#-##-##',
  '+7(34241)#-##-##',
  '+7(34243)#-##-##',
  '+7(34244)#-##-##',
  '+7(34245)#-##-##',
  '+7(34246)#-##-##',
  '+7(34248)#-##-##',
  '+7(34249)#-##-##',
  '+7(34250)#-##-##',
  '+7(34251)#-##-##',
  '+7(34252)#-##-##',
  '+7(34253)#-##-##',
  '+7(34254)#-##-##',
  '+7(34255)#-##-##',
  '+7(34256)#-##-##',
  '+7(34257)#-##-##',
  '+7(34258)#-##-##',
  '+7(34259)#-##-##',
  '+7(34260)#-##-##',
  '+7(34261)#-##-##',
  '+7(34262)#-##-##',
  '+7(34263)#-##-##',
  '+7(34265)#-##-##',
  '+7(34266)#-##-##',
  '+7(34268)#-##-##',
  '+7(34269)#-##-##',
  '+7(34271)#-##-##',
  '+7(34272)#-##-##',
  '+7(34273)#-##-##',
  '+7(34274)#-##-##',
  '+7(34275)#-##-##',
  '+7(34276)#-##-##',
  '+7(34277)#-##-##',
  '+7(34278)#-##-##',
  '+7(34279)#-##-##',
  '+7(34291)#-##-##',
  '+7(34292)#-##-##',
  '+7(34293)#-##-##',
  '+7(34294)#-##-##',
  '+7(34296)#-##-##',
  '+7(34297)#-##-##',
  '+7(34298)#-##-##',
  '+7(343)###-##-##',
  '+7(343)2##-##-##',
  '+7(343)3##-##-##',
  '+7(34341)#-##-##',
  '+7(34342)2-##-##',
  '+7(34342)3-##-##',
  '+7(34342)5-##-##',
  '+7(34342)6-##-##',
  '+7(34343)#-##-##',
  '+7(34344)#-##-##',
  '+7(34345)#-##-##',
  '+7(34346)#-##-##',
  '+7(34347)#-##-##',
  '+7(34349)#-##-##',
  '+7(3435)##-##-##',
  '+7(34350)#-##-##',
  '+7(34355)#-##-##',
  '+7(34356)#-##-##',
  '+7(34357)#-##-##',
  '+7(34358)#-##-##',
  '+7(34360)#-##-##',
  '+7(34361)#-##-##',
  '+7(34362)#-##-##',
  '+7(34363)#-##-##',
  '+7(34364)#-##-##',
  '+7(34365)#-##-##',
  '+7(34367)#-##-##',
  '+7(34368)#-##-##',
  '+7(34369)#-##-##',
  '+7(34370)#-##-##',
  '+7(34371)#-##-##',
  '+7(34372)#-##-##',
  '+7(34373)#-##-##',
  '+7(34374)#-##-##',
  '+7(34375)#-##-##',
  '+7(34376)#-##-##',
  '+7(34377)#-##-##',
  '+7(34380)#-##-##',
  '+7(34383)#-##-##',
  '+7(34383)5-##-##',
  '+7(34384)#-##-##',
  '+7(34385)#-##-##',
  '+7(34386)#-##-##',
  '+7(34387)#-##-##',
  '+7(34388)#-##-##',
  '+7(34389)#-##-##',
  '+7(3439)2#-##-##',
  '+7(3439)3#-##-##',
  '+7(3439)54-##-##',
  '+7(3439)6#-##-##',
  '+7(34391)#-##-##',
  '+7(34394)#-##-##',
  '+7(34395)#-##-##',
  '+7(34397)#-##-##',
  '+7(34398)#-##-##',
  '+7(345)###-##-##',
  '+7(3452)##-##-##',
  '+7(34531)#-##-##',
  '+7(34533)#-##-##',
  '+7(34535)#-##-##',
  '+7(34537)#-##-##',
  '+7(34539)#-##-##',
  '+7(34541)#-##-##',
  '+7(34542)#-##-##',
  '+7(34543)#-##-##',
  '+7(34544)#-##-##',
  '+7(34545)#-##-##',
  '+7(34546)#-##-##',
  '+7(34547)#-##-##',
  '+7(34550)#-##-##',
  '+7(34551)#-##-##',
  '+7(34553)#-##-##',
  '+7(34554)#-##-##',
  '+7(34555)#-##-##',
  '+7(34556)#-##-##',
  '+7(34557)#-##-##',
  '+7(3456)##-##-##',
  '+7(34561)#-##-##',
  '+7(346)###-##-##',
  '+7(3462)##-##-##',
  '+7(3463)##-##-##',
  '+7(34634)#-##-##',
  '+7(34638)#-##-##',
  '+7(34643)#-##-##',
  '+7(3466)##-##-##',
  '+7(34667)#-##-##',
  '+7(34668)#-##-##',
  '+7(34669)#-##-##',
  '+7(34670)#-##-##',
  '+7(34672)#-##-##',
  '+7(34673)#-##-##',
  '+7(34674)#-##-##',
  '+7(34675)#-##-##',
  '+7(34676)#-##-##',
  '+7(34677)#-##-##',
  '+7(34678)#-##-##',
  '+7(347)###-##-##',
  '+7(347)2##-##-##',
  '+7(3473)##-##-##',
  '+7(34731)#-##-##',
  '+7(34739)#-##-##',
  '+7(34740)#-##-##',
  '+7(34741)#-##-##',
  '+7(34742)#-##-##',
  '+7(34743)#-##-##',
  '+7(34744)#-##-##',
  '+7(34745)#-##-##',
  '+7(34746)#-##-##',
  '+7(34747)#-##-##',
  '+7(34748)#-##-##',
  '+7(34749)#-##-##',
  '+7(34750)#-##-##',
  '+7(34751)#-##-##',
  '+7(34752)#-##-##',
  '+7(34753)#-##-##',
  '+7(34754)#-##-##',
  '+7(34755)#-##-##',
  '+7(34756)#-##-##',
  '+7(34757)#-##-##',
  '+7(34758)#-##-##',
  '+7(34759)#-##-##',
  '+7(34760)#-##-##',
  '+7(34761)#-##-##',
  '+7(34762)#-##-##',
  '+7(34763)#-##-##',
  '+7(34764)#-##-##',
  '+7(34765)#-##-##',
  '+7(34766)#-##-##',
  '+7(34767)#-##-##',
  '+7(34768)#-##-##',
  '+7(34769)#-##-##',
  '+7(34770)#-##-##',
  '+7(34771)#-##-##',
  '+7(34772)#-##-##',
  '+7(34773)#-##-##',
  '+7(34774)#-##-##',
  '+7(34775)#-##-##',
  '+7(34776)#-##-##',
  '+7(34777)#-##-##',
  '+7(34778)#-##-##',
  '+7(34779)#-##-##',
  '+7(34780)#-##-##',
  '+7(34781)#-##-##',
  '+7(34782)#-##-##',
  '+7(34783)#-##-##',
  '+7(34784)#-##-##',
  '+7(34785)#-##-##',
  '+7(34786)#-##-##',
  '+7(34787)#-##-##',
  '+7(34788)#-##-##',
  '+7(34789)#-##-##',
  '+7(34791)#-##-##',
  '+7(34792)#-##-##',
  '+7(34794)#-##-##',
  '+7(34795)#-##-##',
  '+7(34796)#-##-##',
  '+7(34797)#-##-##',
  '+7(34798)#-##-##',
  '+7(349)###-##-##',
  '+7(34922)#-##-##',
  '+7(34932)#-##-##',
  '+7(34934)#-##-##',
  '+7(34936)#-##-##',
  '+7(34938)#-##-##',
  '+7(3494)##-##-##',
  '+7(34940)#-##-##',
  '+7(34948)#-##-##',
  '+7(34949)#-##-##',
  '+7(3496)##-##-##',
  '+7(34992)#-##-##',
  '+7(34993)#-##-##',
  '+7(34994)#-##-##',
  '+7(34995)#-##-##',
  '+7(34996)#-##-##',
  '+7(34997)#-##-##',
  '+7(351)###-##-##',
  '+7(351)2##-##-##',
  '+7(351)301-##-##',
  '+7(351)7##-##-##',
  '+7(351)90#-##-##',
  '+7(3513)2#-##-##',
  '+7(3513)5#-##-##',
  '+7(3513)6#-##-##',
  '+7(3513)7#-##-##',
  '+7(35130)2-##-##',
  '+7(35130)4-##-##',
  '+7(35130)6-##-##',
  '+7(35130)7-##-##',
  '+7(35130)9-##-##',
  '+7(35131)#-##-##',
  '+7(35133)#-##-##',
  '+7(35134)#-##-##',
  '+7(35138)#-##-##',
  '+7(35139)#-##-##',
  '+7(35140)#-##-##',
  '+7(35141)#-##-##',
  '+7(35142)#-##-##',
  '+7(35143)#-##-##',
  '+7(35144)#-##-##',
  '+7(35145)#-##-##',
  '+7(35146)#-##-##',
  '+7(35147)#-##-##',
  '+7(35148)#-##-##',
  '+7(35149)#-##-##',
  '+7(35150)#-##-##',
  '+7(35151)#-##-##',
  '+7(35152)#-##-##',
  '+7(35153)#-##-##',
  '+7(35154)#-##-##',
  '+7(35155)#-##-##',
  '+7(35156)#-##-##',
  '+7(35157)#-##-##',
  '+7(35158)#-##-##',
  '+7(35159)#-##-##',
  '+7(35160)#-##-##',
  '+7(35161)#-##-##',
  '+7(35163)#-##-##',
  '+7(35164)#-##-##',
  '+7(35165)#-##-##',
  '+7(35166)#-##-##',
  '+7(35167)#-##-##',
  '+7(35168)#-##-##',
  '+7(35169)#-##-##',
  '+7(3519)##-##-##',
  '+7(35191)#-##-##',
  '+7(352)###-##-##',
  '+7(3522)##-##-##',
  '+7(35230)#-##-##',
  '+7(35231)#-##-##',
  '+7(35232)#-##-##',
  '+7(35233)#-##-##',
  '+7(35234)#-##-##',
  '+7(35235)#-##-##',
  '+7(35236)#-##-##',
  '+7(35237)#-##-##',
  '+7(35238)#-##-##',
  '+7(35239)#-##-##',
  '+7(35240)#-##-##',
  '+7(35241)#-##-##',
  '+7(35242)#-##-##',
  '+7(35243)#-##-##',
  '+7(35244)#-##-##',
  '+7(35245)#-##-##',
  '+7(35247)#-##-##',
  '+7(35248)#-##-##',
  '+7(35249)#-##-##',
  '+7(35251)#-##-##',
  '+7(35252)#-##-##',
  '+7(35253)#-##-##',
  '+7(35256)#-##-##',
  '+7(35257)#-##-##',
  '+7(353)###-##-##',
  '+7(3532)##-##-##',
  '+7(35330)#-##-##',
  '+7(35331)#-##-##',
  '+7(35332)#-##-##',
  '+7(35333)#-##-##',
  '+7(35334)#-##-##',
  '+7(35335)#-##-##',
  '+7(35336)#-##-##',
  '+7(35337)#-##-##',
  '+7(35338)#-##-##',
  '+7(35339)#-##-##',
  '+7(35341)#-##-##',
  '+7(35342)#-##-##',
  '+7(35344)#-##-##',
  '+7(35345)#-##-##',
  '+7(35346)#-##-##',
  '+7(35347)#-##-##',
  '+7(35348)#-##-##',
  '+7(35349)#-##-##',
  '+7(35351)#-##-##',
  '+7(35352)#-##-##',
  '+7(35354)#-##-##',
  '+7(35355)#-##-##',
  '+7(35356)#-##-##',
  '+7(35357)#-##-##',
  '+7(35358)#-##-##',
  '+7(35359)#-##-##',
  '+7(35361)#-##-##',
  '+7(35362)#-##-##',
  '+7(35363)#-##-##',
  '+7(35364)#-##-##',
  '+7(35365)#-##-##',
  '+7(35366)#-##-##',
  '+7(35367)#-##-##',
  '+7(35368)#-##-##',
  '+7(3537)2#-##-##',
  '+7(3537)3#-##-##',
  '+7(3537)4#-##-##',
  '+7(3537)6#-##-##',
  '+7(35379)#-##-##',
  '+7(381)###-##-##',
  '+7(3812)##-##-##',
  '+7(38141)#-##-##',
  '+7(38150)#-##-##',
  '+7(38151)#-##-##',
  '+7(38152)#-##-##',
  '+7(38153)#-##-##',
  '+7(38154)#-##-##',
  '+7(38155)#-##-##',
  '+7(38156)#-##-##',
  '+7(38157)#-##-##',
  '+7(38158)#-##-##',
  '+7(38159)#-##-##',
  '+7(38160)#-##-##',
  '+7(38161)#-##-##',
  '+7(38162)#-##-##',
  '+7(38163)#-##-##',
  '+7(38164)#-##-##',
  '+7(38165)#-##-##',
  '+7(38166)#-##-##',
  '+7(38167)#-##-##',
  '+7(38168)#-##-##',
  '+7(38169)#-##-##',
  '+7(38170)#-##-##',
  '+7(38171)#-##-##',
  '+7(38172)#-##-##',
  '+7(38173)#-##-##',
  '+7(38174)#-##-##',
  '+7(38175)#-##-##',
  '+7(38176)#-##-##',
  '+7(38177)#-##-##',
  '+7(38178)#-##-##',
  '+7(38179)#-##-##',
  '+7(382)###-##-##',
  '+7(3822)##-##-##',
  '+7(3823)##-##-##',
  '+7(38241)#-##-##',
  '+7(38243)#-##-##',
  '+7(38244)#-##-##',
  '+7(38245)#-##-##',
  '+7(38246)#-##-##',
  '+7(38247)#-##-##',
  '+7(38249)#-##-##',
  '+7(38250)#-##-##',
  '+7(38251)#-##-##',
  '+7(38252)#-##-##',
  '+7(38253)#-##-##',
  '+7(38254)#-##-##',
  '+7(38255)#-##-##',
  '+7(38256)#-##-##',
  '+7(38257)#-##-##',
  '+7(38258)#-##-##',
  '+7(38259)#-##-##',
  '+7(383)###-##-##',
  '+7(38340)#-##-##',
  '+7(38341)#-##-##',
  '+7(38343)#-##-##',
  '+7(38345)#-##-##',
  '+7(38346)#-##-##',
  '+7(38347)#-##-##',
  '+7(38348)#-##-##',
  '+7(38349)#-##-##',
  '+7(38350)#-##-##',
  '+7(38351)#-##-##',
  '+7(38352)#-##-##',
  '+7(38353)#-##-##',
  '+7(38354)#-##-##',
  '+7(38355)#-##-##',
  '+7(38356)#-##-##',
  '+7(38357)#-##-##',
  '+7(38358)#-##-##',
  '+7(38359)#-##-##',
  '+7(38360)#-##-##',
  '+7(38361)#-##-##',
  '+7(38362)#-##-##',
  '+7(38363)#-##-##',
  '+7(38364)#-##-##',
  '+7(38365)#-##-##',
  '+7(38366)#-##-##',
  '+7(38367)#-##-##',
  '+7(38368)#-##-##',
  '+7(38369)#-##-##',
  '+7(38371)#-##-##',
  '+7(38372)#-##-##',
  '+7(38373)#-##-##',
  '+7(384)###-##-##',
  '+7(3842)##-##-##',
  '+7(3843)##-##-##',
  '+7(38441)#-##-##',
  '+7(38442)#-##-##',
  '+7(38443)#-##-##',
  '+7(38444)#-##-##',
  '+7(38445)#-##-##',
  '+7(38446)#-##-##',
  '+7(38447)#-##-##',
  '+7(38448)#-##-##',
  '+7(38449)#-##-##',
  '+7(38451)#-##-##',
  '+7(38452)#-##-##',
  '+7(38453)#-##-##',
  '+7(38454)#-##-##',
  '+7(38455)#-##-##',
  '+7(38456)#-##-##',
  '+7(38459)#-##-##',
  '+7(3846)##-##-##',
  '+7(38463)#-##-##',
  '+7(38464)#-##-##',
  '+7(38471)#-##-##',
  '+7(38472)#-##-##',
  '+7(38473)#-##-##',
  '+7(38474)#-##-##',
  '+7(38475)#-##-##',
  '+7(385)###-##-##',
  '+7(3852)##-##-##',
  '+7(38530)#-##-##',
  '+7(38531)#-##-##',
  '+7(38532)#-##-##',
  '+7(38533)#-##-##',
  '+7(38534)#-##-##',
  '+7(38535)#-##-##',
  '+7(38536)#-##-##',
  '+7(38537)#-##-##',
  '+7(38538)#-##-##',
  '+7(38539)#-##-##',
  '+7(3854)##-##-##',
  '+7(38550)#-##-##',
  '+7(38551)#-##-##',
  '+7(38552)#-##-##',
  '+7(38553)#-##-##',
  '+7(38554)#-##-##',
  '+7(38555)#-##-##',
  '+7(38556)#-##-##',
  '+7(38557)#-##-##',
  '+7(38558)#-##-##',
  '+7(38559)#-##-##',
  '+7(38560)#-##-##',
  '+7(38561)#-##-##',
  '+7(38562)#-##-##',
  '+7(38563)#-##-##',
  '+7(38564)#-##-##',
  '+7(38565)#-##-##',
  '+7(38566)#-##-##',
  '+7(38567)#-##-##',
  '+7(38568)#-##-##',
  '+7(38569)#-##-##',
  '+7(38570)#-##-##',
  '+7(38571)#-##-##',
  '+7(38572)#-##-##',
  '+7(38573)#-##-##',
  '+7(38574)#-##-##',
  '+7(38575)#-##-##',
  '+7(38576)#-##-##',
  '+7(38577)#-##-##',
  '+7(38578)#-##-##',
  '+7(38579)#-##-##',
  '+7(38580)#-##-##',
  '+7(38581)#-##-##',
  '+7(38582)#-##-##',
  '+7(38583)#-##-##',
  '+7(38584)#-##-##',
  '+7(38585)#-##-##',
  '+7(38586)#-##-##',
  '+7(38587)#-##-##',
  '+7(38588)#-##-##',
  '+7(38589)#-##-##',
  '+7(38590)#-##-##',
  '+7(38591)#-##-##',
  '+7(38592)#-##-##',
  '+7(38593)#-##-##',
  '+7(38594)#-##-##',
  '+7(38595)#-##-##',
  '+7(38596)#-##-##',
  '+7(38597)#-##-##',
  '+7(38598)#-##-##',
  '+7(38599)#-##-##',
  '+7(388)###-##-##',
  '+7(3882)##-##-##',
  '+7(38840)#-##-##',
  '+7(38841)#-##-##',
  '+7(38842)#-##-##',
  '+7(38843)#-##-##',
  '+7(38844)#-##-##',
  '+7(38845)#-##-##',
  '+7(38846)#-##-##',
  '+7(38847)#-##-##',
  '+7(38848)#-##-##',
  '+7(38849)#-##-##',
  '+7(390)###-##-##',
  '+7(3902)##-##-##',
  '+7(39031)#-##-##',
  '+7(39032)#-##-##',
  '+7(39033)#-##-##',
  '+7(39034)#-##-##',
  '+7(39035)#-##-##',
  '+7(39036)#-##-##',
  '+7(39041)#-##-##',
  '+7(39042)#-##-##',
  '+7(39044)#-##-##',
  '+7(39045)#-##-##',
  '+7(39046)#-##-##',
  '+7(39047)#-##-##',
  '+7(391)###-##-##',
  '+7(391)2##-##-##',
  '+7(39131)#-##-##',
  '+7(39132)#-##-##',
  '+7(39133)#-##-##',
  '+7(39134)#-##-##',
  '+7(39135)#-##-##',
  '+7(39136)#-##-##',
  '+7(39137)#-##-##',
  '+7(39138)#-##-##',
  '+7(39139)#-##-##',
  '+7(39140)#-##-##',
  '+7(39141)#-##-##',
  '+7(39142)#-##-##',
  '+7(39143)#-##-##',
  '+7(39144)#-##-##',
  '+7(39145)#-##-##',
  '+7(39146)#-##-##',
  '+7(39147)#-##-##',
  '+7(39148)#-##-##',
  '+7(39149)#-##-##',
  '+7(39150)#-##-##',
  '+7(39151)#-##-##',
  '+7(39152)#-##-##',
  '+7(39153)#-##-##',
  '+7(39154)#-##-##',
  '+7(39155)#-##-##',
  '+7(39156)#-##-##',
  '+7(39157)#-##-##',
  '+7(39158)#-##-##',
  '+7(39159)#-##-##',
  '+7(39160)#-##-##',
  '+7(39161)#-##-##',
  '+7(39162)#-##-##',
  '+7(39163)#-##-##',
  '+7(39164)#-##-##',
  '+7(39165)#-##-##',
  '+7(39166)#-##-##',
  '+7(39167)#-##-##',
  '+7(39168)#-##-##',
  '+7(39169)#-##-##',
  '+7(39170)#-##-##',
  '+7(39171)#-##-##',
  '+7(39172)#-##-##',
  '+7(39173)#-##-##',
  '+7(39174)#-##-##',
  '+7(39175)#-##-##',
  '+7(39176)#-##-##',
  '+7(39177)#-##-##',
  '+7(39178)#-##-##',
  '+7(39179)#-##-##',
  '+7(3919)2#-##-##',
  '+7(3919)4#-##-##',
  '+7(39190)#-##-##',
  '+7(39191)#-##-##',
  '+7(39193)#-##-##',
  '+7(39195)#-##-##',
  '+7(39196)#-##-##',
  '+7(39197)#-##-##',
  '+7(39198)#-##-##',
  '+7(39199)#-##-##',
  '+7(394)###-##-##',
  '+7(3942)##-##-##',
  '+7(39432)#-##-##',
  '+7(39433)#-##-##',
  '+7(39434)#-##-##',
  '+7(39435)#-##-##',
  '+7(39436)#-##-##',
  '+7(39437)#-##-##',
  '+7(39438)#-##-##',
  '+7(39439)#-##-##',
  '+7(39441)#-##-##',
  '+7(39442)#-##-##',
  '+7(39444)#-##-##',
  '+7(39445)#-##-##',
  '+7(39450)#-##-##',
  '+7(39451)#-##-##',
  '+7(395)###-##-##',
  '+7(3952)##-##-##',
  '+7(3953)##-##-##',
  '+7(39530)#-##-##',
  '+7(39535)#-##-##',
  '+7(39536)#-##-##',
  '+7(39537)#-##-##',
  '+7(39538)#-##-##',
  '+7(39539)#-##-##',
  '+7(39540)#-##-##',
  '+7(39541)#-##-##',
  '+7(39542)#-##-##',
  '+7(39543)#-##-##',
  '+7(39544)#-##-##',
  '+7(39545)#-##-##',
  '+7(39546)#-##-##',
  '+7(39548)#-##-##',
  '+7(39549)#-##-##',
  '+7(3955)##-##-##',
  '+7(39550)#-##-##',
  '+7(39551)#-##-##',
  '+7(39552)#-##-##',
  '+7(39553)#-##-##',
  '+7(39554)#-##-##',
  '+7(39557)#-##-##',
  '+7(39558)#-##-##',
  '+7(39559)#-##-##',
  '+7(39560)#-##-##',
  '+7(39561)#-##-##',
  '+7(39562)#-##-##',
  '+7(39563)#-##-##',
  '+7(39564)#-##-##',
  '+7(39565)#-##-##',
  '+7(39566)#-##-##',
  '+7(39567)#-##-##',
  '+7(39568)#-##-##',
  '+7(39569)#-##-##',
  '+7(401)###-##-##',
  '+7(4012)##-##-##',
  '+7(40141)#-##-##',
  '+7(40142)#-##-##',
  '+7(40143)#-##-##',
  '+7(40144)#-##-##',
  '+7(40145)#-##-##',
  '+7(40150)#-##-##',
  '+7(40151)#-##-##',
  '+7(40152)#-##-##',
  '+7(40153)#-##-##',
  '+7(40155)#-##-##',
  '+7(40156)#-##-##',
  '+7(40157)#-##-##',
  '+7(40158)#-##-##',
  '+7(40159)#-##-##',
  '+7(40161)#-##-##',
  '+7(40162)#-##-##',
  '+7(40163)#-##-##',
  '+7(40164)#-##-##',
  '+7(411)###-##-##',
  '+7(4112)##-##-##',
  '+7(41131)#-##-##',
  '+7(41132)#-##-##',
  '+7(41133)#-##-##',
  '+7(41134)#-##-##',
  '+7(41135)#-##-##',
  '+7(41136)#-##-##',
  '+7(41137)#-##-##',
  '+7(41138)#-##-##',
  '+7(41140)#-##-##',
  '+7(41141)#-##-##',
  '+7(41142)#-##-##',
  '+7(41143)#-##-##',
  '+7(41144)#-##-##',
  '+7(41145)#-##-##',
  '+7(41147)#-##-##',
  '+7(41150)#-##-##',
  '+7(41151)#-##-##',
  '+7(41152)#-##-##',
  '+7(41153)#-##-##',
  '+7(41154)#-##-##',
  '+7(41155)#-##-##',
  '+7(41156)#-##-##',
  '+7(41157)#-##-##',
  '+7(41158)#-##-##',
  '+7(41159)#-##-##',
  '+7(41160)#-##-##',
  '+7(41161)#-##-##',
  '+7(41162)#-##-##',
  '+7(41163)#-##-##',
  '+7(41164)#-##-##',
  '+7(41165)#-##-##',
  '+7(41166)#-##-##',
  '+7(41167)#-##-##',
  '+7(41168)#-##-##',
  '+7(41169)#-##-##',
  '+7(413)###-##-##',
  '+7(4132)##-##-##',
  '+7(41341)#-##-##',
  '+7(41342)#-##-##',
  '+7(41343)#-##-##',
  '+7(41344)#-##-##',
  '+7(41345)#-##-##',
  '+7(41346)#-##-##',
  '+7(41347)#-##-##',
  '+7(41348)#-##-##',
  '+7(415)###-##-##',
  '+7(4152)##-##-##',
  '+7(41531)#-##-##',
  '+7(41532)#-##-##',
  '+7(41533)#-##-##',
  '+7(41534)#-##-##',
  '+7(41535)#-##-##',
  '+7(41536)#-##-##',
  '+7(41537)#-##-##',
  '+7(41538)#-##-##',
  '+7(41539)#-##-##',
  '+7(41541)#-##-##',
  '+7(41542)#-##-##',
  '+7(41543)#-##-##',
  '+7(41544)#-##-##',
  '+7(41545)#-##-##',
  '+7(41546)#-##-##',
  '+7(41547)#-##-##',
  '+7(41548)#-##-##',
  '+7(416)###-##-##',
  '+7(4162)##-##-##',
  '+7(41631)2-0#-##',
  '+7(41632)3-0#-##',
  '+7(41633)3-0#-##',
  '+7(41634)#-##-##',
  '+7(41637)#-##-##',
  '+7(41638)#-##-##',
  '+7(41639)#-##-##',
  '+7(41641)#-##-##',
  '+7(41642)#-##-##',
  '+7(41643)#-##-##',
  '+7(41644)#-##-##',
  '+7(41645)#-##-##',
  '+7(41646)#-##-##',
  '+7(41647)#-##-##',
  '+7(41648)#-##-##',
  '+7(41649)#-##-##',
  '+7(41651)#-##-##',
  '+7(41652)#-##-##',
  '+7(41653)#-##-##',
  '+7(41654)#-##-##',
  '+7(41655)#-##-##',
  '+7(41656)#-##-##',
  '+7(41658)#-##-##',
  '+7(421)###-##-##',
  '+7(4212)##-##-##',
  '+7(42135)#-##-##',
  '+7(41636)#-##-##',
  '+7(41636)#-##-##',
  '+7(41636)#-##-##',
  '+7(42137)#-##-##',
  '+7(42138)#-##-##',
  '+7(42141)#-##-##',
  '+7(42142)#-##-##',
  '+7(42143)#-##-##',
  '+7(42144)#-##-##',
  '+7(42146)#-##-##',
  '+7(42147)#-##-##',
  '+7(42149)#-##-##',
  '+7(42151)#-##-##',
  '+7(42153)#-##-##',
  '+7(42154)#-##-##',
  '+7(42155)#-##-##',
  '+7(42156)#-##-##',
  '+7(4217)##-##-##',
  '+7(423)###-##-##',
  '+7(42331)#-##-##',
  '+7(42334)#-##-##',
  '+7(42335)#-##-##',
  '+7(42337)#-##-##',
  '+7(42339)#-##-##',
  '+7(4234)##-##-##',
  '+7(42344)#-##-##',
  '+7(42345)#-##-##',
  '+7(42346)#-##-##',
  '+7(42347)#-##-##',
  '+7(42349)#-##-##',
  '+7(42351)#-##-##',
  '+7(42352)#-##-##',
  '+7(42354)#-##-##',
  '+7(42355)#-##-##',
  '+7(42356)#-##-##',
  '+7(42357)#-##-##',
  '+7(42359)#-##-##',
  '+7(4236)##-##-##',
  '+7(42361)#-##-##',
  '+7(42362)#-##-##',
  '+7(42363)#-##-##',
  '+7(42365)#-##-##',
  '+7(42371)#-##-##',
  '+7(42372)#-##-##',
  '+7(42373)#-##-##',
  '+7(42374)#-##-##',
  '+7(42375)#-##-##',
  '+7(42376)#-##-##',
  '+7(42377)#-##-##',
  '+7(424)###-##-##',
  '+7(4242)##-##-##',
  '+7(42431)#-##-##',
  '+7(42432)#-##-##',
  '+7(42433)#-##-##',
  '+7(42434)#-##-##',
  '+7(42435)#-##-##',
  '+7(42436)#-##-##',
  '+7(42437)#-##-##',
  '+7(42441)#-##-##',
  '+7(42442)#-##-##',
  '+7(42443)#-##-##',
  '+7(42444)#-##-##',
  '+7(42446)#-##-##',
  '+7(42447)#-##-##',
  '+7(42452)#-##-##',
  '+7(42453)#-##-##',
  '+7(42454)#-##-##',
  '+7(42455)#-##-##',
  '+7(426)###-##-##',
  '+7(42622)#-##-##',
  '+7(42632)#-##-##',
  '+7(42663)#-##-##',
  '+7(42665)#-##-##',
  '+7(42666)#-##-##',
  '+7(427)###-##-##',
  '+7(42722)#-##-##',
  '+7(42732)#-##-##',
  '+7(42733)#-##-##',
  '+7(42734)#-##-##',
  '+7(42735)#-##-##',
  '+7(42736)#-##-##',
  '+7(42737)#-##-##',
  '+7(42738)#-##-##',
  '+7(42739)#-##-##',
  '+7(471)###-##-##',
  '+7(4712)##-##-##',
  '+7(47131)#-##-##',
  '+7(47132)#-##-##',
  '+7(47133)#-##-##',
  '+7(47134)#-##-##',
  '+7(47135)#-##-##',
  '+7(47136)#-##-##',
  '+7(47137)#-##-##',
  '+7(47140)#-##-##',
  '+7(47141)#-##-##',
  '+7(47142)#-##-##',
  '+7(47143)#-##-##',
  '+7(47144)#-##-##',
  '+7(47145)#-##-##',
  '+7(47146)#-##-##',
  '+7(47147)#-##-##',
  '+7(47148)#-##-##',
  '+7(47149)#-##-##',
  '+7(47150)#-##-##',
  '+7(47151)#-##-##',
  '+7(47152)#-##-##',
  '+7(47153)#-##-##',
  '+7(47154)#-##-##',
  '+7(47155)#-##-##',
  '+7(47156)#-##-##',
  '+7(47157)#-##-##',
  '+7(47158)#-##-##',
  '+7(47159)#-##-##',
  '+7(472)###-##-##',
  '+7(4722)##-##-##',
  '+7(47231)#-##-##',
  '+7(47232)#-##-##',
  '+7(47233)#-##-##',
  '+7(47234)#-##-##',
  '+7(47235)#-##-##',
  '+7(47236)#-##-##',
  '+7(47237)#-##-##',
  '+7(47238)#-##-##',
  '+7(47241)#-##-##',
  '+7(47242)#-##-##',
  '+7(47243)#-##-##',
  '+7(47244)#-##-##',
  '+7(47245)#-##-##',
  '+7(47246)#-##-##',
  '+7(47247)#-##-##',
  '+7(47248)#-##-##',
  '+7(4725)##-##-##',
  '+7(47261)#-##-##',
  '+7(47262)#-##-##',
  '+7(47263)#-##-##',
  '+7(473)###-##-##',
  '+7(47340)#-##-##',
  '+7(47341)#-##-##',
  '+7(47342)#-##-##',
  '+7(47343)#-##-##',
  '+7(47344)#-##-##',
  '+7(47345)#-##-##',
  '+7(47346)#-##-##',
  '+7(47347)#-##-##',
  '+7(47348)#-##-##',
  '+7(47350)#-##-##',
  '+7(47352)#-##-##',
  '+7(47353)#-##-##',
  '+7(47354)#-##-##',
  '+7(47355)#-##-##',
  '+7(47356)#-##-##',
  '+7(47357)#-##-##',
  '+7(47361)#-##-##',
  '+7(47362)#-##-##',
  '+7(47363)#-##-##',
  '+7(47364)#-##-##',
  '+7(47365)#-##-##',
  '+7(47366)#-##-##',
  '+7(47367)#-##-##',
  '+7(47370)#-##-##',
  '+7(47371)#-##-##',
  '+7(47372)#-##-##',
  '+7(47374)#-##-##',
  '+7(47375)#-##-##',
  '+7(47376)#-##-##',
  '+7(47391)#-##-##',
  '+7(47394)#-##-##',
  '+7(47395)#-##-##',
  '+7(47396)#-##-##',
  '+7(474)###-##-##',
  '+7(4742)##-##-##',
  '+7(47461)#-##-##',
  '+7(47462)#-##-##',
  '+7(47463)#-##-##',
  '+7(47464)#-##-##',
  '+7(47465)#-##-##',
  '+7(47466)#-##-##',
  '+7(47467)#-##-##',
  '+7(47468)#-##-##',
  '+7(47469)#-##-##',
  '+7(47471)#-##-##',
  '+7(47472)#-##-##',
  '+7(47473)#-##-##',
  '+7(47474)#-##-##',
  '+7(47475)#-##-##',
  '+7(47476)#-##-##',
  '+7(47477)#-##-##',
  '+7(47478)#-##-##',
  '+7(475)###-##-##',
  '+7(4752)##-##-##',
  '+7(47531)#-##-##',
  '+7(47532)#-##-##',
  '+7(47533)#-##-##',
  '+7(47534)#-##-##',
  '+7(47535)#-##-##',
  '+7(47536)#-##-##',
  '+7(47537)#-##-##',
  '+7(47541)#-##-##',
  '+7(47542)#-##-##',
  '+7(47543)#-##-##',
  '+7(47544)#-##-##',
  '+7(47545)#-##-##',
  '+7(47546)#-##-##',
  '+7(47548)#-##-##',
  '+7(47551)#-##-##',
  '+7(47552)#-##-##',
  '+7(47553)#-##-##',
  '+7(47554)#-##-##',
  '+7(47555)#-##-##',
  '+7(47556)#-##-##',
  '+7(47557)#-##-##',
  '+7(47558)#-##-##',
  '+7(47559)#-##-##',
  '+7(481)###-##-##',
  '+7(4812)##-##-##',
  '+7(48130)#-##-##',
  '+7(48131)#-##-##',
  '+7(48132)#-##-##',
  '+7(48133)#-##-##',
  '+7(48134)#-##-##',
  '+7(48135)#-##-##',
  '+7(48136)#-##-##',
  '+7(48137)#-##-##',
  '+7(48138)#-##-##',
  '+7(48139)#-##-##',
  '+7(48140)#-##-##',
  '+7(48141)#-##-##',
  '+7(48142)#-##-##',
  '+7(48143)#-##-##',
  '+7(48144)#-##-##',
  '+7(48145)#-##-##',
  '+7(48146)#-##-##',
  '+7(48147)#-##-##',
  '+7(48148)#-##-##',
  '+7(48149)#-##-##',
  '+7(48153)#-##-##',
  '+7(48155)#-##-##',
  '+7(48165)#-##-##',
  '+7(48166)#-##-##',
  '+7(48167)#-##-##',
  '+7(482)###-##-##',
  '+7(4822)##-##-##',
  '+7(48230)#-##-##',
  '+7(48231)#-##-##',
  '+7(48232)#-##-##',
  '+7(48233)#-##-##',
  '+7(48234)#-##-##',
  '+7(48235)#-##-##',
  '+7(48236)#-##-##',
  '+7(48237)#-##-##',
  '+7(48238)#-##-##',
  '+7(48239)#-##-##',
  '+7(48242)#-##-##',
  '+7(48244)#-##-##',
  '+7(48246)#-##-##',
  '+7(48249)#-##-##',
  '+7(48250)#-##-##',
  '+7(48251)#-##-##',
  '+7(48253)#-##-##',
  '+7(48255)#-##-##',
  '+7(48257)#-##-##',
  '+7(48258)#-##-##',
  '+7(48261)#-##-##',
  '+7(48262)#-##-##',
  '+7(48263)#-##-##',
  '+7(48264)#-##-##',
  '+7(48265)#-##-##',
  '+7(48266)#-##-##',
  '+7(48267)#-##-##',
  '+7(48268)#-##-##',
  '+7(48269)#-##-##',
  '+7(48271)#-##-##',
  '+7(48272)#-##-##',
  '+7(48273)#-##-##',
  '+7(48274)#-##-##',
  '+7(48275)#-##-##',
  '+7(48276)#-##-##',
  '+7(483)###-##-##',
  '+7(4832)##-##-##',
  '+7(48330)#-##-##',
  '+7(48331)#-##-##',
  '+7(48332)#-##-##',
  '+7(48333)#-##-##',
  '+7(48334)#-##-##',
  '+7(48335)#-##-##',
  '+7(48336)#-##-##',
  '+7(48338)#-##-##',
  '+7(48339)#-##-##',
  '+7(48340)#-##-##',
  '+7(48341)#-##-##',
  '+7(48342)#-##-##',
  '+7(48343)#-##-##',
  '+7(48344)#-##-##',
  '+7(48345)#-##-##',
  '+7(48346)#-##-##',
  '+7(48347)#-##-##',
  '+7(48348)#-##-##',
  '+7(48349)#-##-##',
  '+7(48351)#-##-##',
  '+7(48352)#-##-##',
  '+7(48353)#-##-##',
  '+7(48354)#-##-##',
  '+7(48355)#-##-##',
  '+7(48356)#-##-##',
  '+7(48358)#-##-##',
  '+7(484)###-##-##',
  '+7(4842)##-##-##',
  '+7(48431)#-##-##',
  '+7(48432)#-##-##',
  '+7(48433)#-##-##',
  '+7(48434)#-##-##',
  '+7(48435)#-##-##',
  '+7(48436)#-##-##',
  '+7(48437)#-##-##',
  '+7(48438)2-##-##',
  '+7(48438)4-##-##',
  '+7(48438)6-##-##',
  '+7(48439)#-##-##',
  '+7(48441)#-##-##',
  '+7(48442)#-##-##',
  '+7(48443)#-##-##',
  '+7(48444)#-##-##',
  '+7(48445)#-##-##',
  '+7(48446)#-##-##',
  '+7(48447)#-##-##',
  '+7(48448)#-##-##',
  '+7(48449)#-##-##',
  '+7(48451)#-##-##',
  '+7(48452)#-##-##',
  '+7(48453)#-##-##',
  '+7(48454)#-##-##',
  '+7(48455)#-##-##',
  '+7(48456)#-##-##',
  '+7(48457)#-##-##',
  '+7(485)###-##-##',
  '+7(4852)##-##-##',
  '+7(48531)#-##-##',
  '+7(48532)#-##-##',
  '+7(48533)#-##-##',
  '+7(48534)#-##-##',
  '+7(48535)#-##-##',
  '+7(48536)#-##-##',
  '+7(48538)#-##-##',
  '+7(48539)#-##-##',
  '+7(48542)#-##-##',
  '+7(48543)#-##-##',
  '+7(48544)#-##-##',
  '+7(48545)#-##-##',
  '+7(48546)#-##-##',
  '+7(48547)#-##-##',
  '+7(48549)#-##-##',
  '+7(4855)##-##-##',
  '+7(486)###-##-##',
  '+7(4862)##-##-##',
  '+7(48640)#-##-##',
  '+7(48642)#-##-##',
  '+7(48643)#-##-##',
  '+7(48644)#-##-##',
  '+7(48645)#-##-##',
  '+7(48646)#-##-##',
  '+7(48647)#-##-##',
  '+7(48648)#-##-##',
  '+7(48649)#-##-##',
  '+7(48661)#-##-##',
  '+7(48662)#-##-##',
  '+7(48663)#-##-##',
  '+7(48664)#-##-##',
  '+7(48665)#-##-##',
  '+7(48666)#-##-##',
  '+7(48667)#-##-##',
  '+7(48672)#-##-##',
  '+7(48673)#-##-##',
  '+7(48674)#-##-##',
  '+7(48675)#-##-##',
  '+7(48676)#-##-##',
  '+7(48677)#-##-##',
  '+7(48678)#-##-##',
  '+7(48679)#-##-##',
  '+7(487)###-##-##',
  '+7(4872)##-##-##',
  '+7(48731)#-##-##',
  '+7(48732)#-##-##',
  '+7(48733)#-##-##',
  '+7(48734)#-##-##',
  '+7(48735)#-##-##',
  '+7(48736)#-##-##',
  '+7(48741)#-##-##',
  '+7(48742)#-##-##',
  '+7(48743)#-##-##',
  '+7(48744)#-##-##',
  '+7(48745)#-##-##',
  '+7(48746)#-##-##',
  '+7(48751)#-##-##',
  '+7(48752)#-##-##',
  '+7(48753)#-##-##',
  '+7(48754)#-##-##',
  '+7(48755)#-##-##',
  '+7(48756)#-##-##',
  '+7(48761)#-##-##',
  '+7(48762)#-##-##',
  '+7(48763)#-##-##',
  '+7(48766)#-##-##',
  '+7(48767)#-##-##',
  '+7(48768)#-##-##',
  '+7(491)###-##-##',
  '+7(4912)##-##-##',
  '+7(49130)#-##-##',
  '+7(49131)#-##-##',
  '+7(49132)#-##-##',
  '+7(49133)#-##-##',
  '+7(49135)#-##-##',
  '+7(49136)#-##-##',
  '+7(49137)#-##-##',
  '+7(49138)#-##-##',
  '+7(49139)#-##-##',
  '+7(49141)#-##-##',
  '+7(49142)#-##-##',
  '+7(49143)#-##-##',
  '+7(49144)#-##-##',
  '+7(49145)#-##-##',
  '+7(49146)#-##-##',
  '+7(49147)#-##-##',
  '+7(49148)#-##-##',
  '+7(49151)#-##-##',
  '+7(49152)#-##-##',
  '+7(49153)#-##-##',
  '+7(49154)#-##-##',
  '+7(49155)#-##-##',
  '+7(49156)#-##-##',
  '+7(49157)#-##-##',
  '+7(49158)#-##-##',
  '+7(492)###-##-##',
  '+7(4922)##-##-##',
  '+7(49231)#-##-##',
  '+7(49232)#-##-##',
  '+7(49233)#-##-##',
  '+7(49234)#-##-##',
  '+7(49235)#-##-##',
  '+7(49236)#-##-##',
  '+7(49237)#-##-##',
  '+7(49238)#-##-##',
  '+7(49241)#-##-##',
  '+7(49242)#-##-##',
  '+7(49243)2-##-##',
  '+7(49243)6-##-##',
  '+7(49244)#-##-##',
  '+7(49245)#-##-##',
  '+7(49246)#-##-##',
  '+7(49247)#-##-##',
  '+7(49248)#-##-##',
  '+7(49254)#-##-##',
  '+7(493)###-##-##',
  '+7(4932)##-##-##',
  '+7(49331)#-##-##',
  '+7(49333)#-##-##',
  '+7(49334)#-##-##',
  '+7(49336)#-##-##',
  '+7(49337)#-##-##',
  '+7(49339)#-##-##',
  '+7(49341)#-##-##',
  '+7(49343)#-##-##',
  '+7(49344)#-##-##',
  '+7(49345)#-##-##',
  '+7(49346)#-##-##',
  '+7(49347)#-##-##',
  '+7(49349)#-##-##',
  '+7(49351)#-##-##',
  '+7(49352)#-##-##',
  '+7(49353)#-##-##',
  '+7(49354)#-##-##',
  '+7(49355)#-##-##',
  '+7(49356)#-##-##',
  '+7(49357)#-##-##',
  '+7(494)###-##-##',
  '+7(4942)##-##-##',
  '+7(49430)#-##-##',
  '+7(49431)#-##-##',
  '+7(49432)#-##-##',
  '+7(49433)#-##-##',
  '+7(49434)#-##-##',
  '+7(49435)#-##-##',
  '+7(49436)#-##-##',
  '+7(49437)#-##-##',
  '+7(49438)#-##-##',
  '+7(49439)#-##-##',
  '+7(49440)#-##-##',
  '+7(49441)#-##-##',
  '+7(49442)#-##-##',
  '+7(49443)#-##-##',
  '+7(49444)#-##-##',
  '+7(49445)#-##-##',
  '+7(49446)#-##-##',
  '+7(49447)#-##-##',
  '+7(49448)#-##-##',
  '+7(49449)#-##-##',
  '+7(49450)#-##-##',
  '+7(49451)#-##-##',
  '+7(49452)#-##-##',
  '+7(49453)#-##-##',
  '+7(495)###-##-##',
  '+7(495)323-8#-##',
  '+7(495)323-9#-##',
  '+7(495)338-##-##',
  '+7(495)339-##-##',
  '+7(495)355-9#-##',
  '+7(495)408-##-##',
  '+7(495)439-##-##',
  '+7(495)50#-##-##',
  '+7(495)500-##-##',
  '+7(495)51#-##-##',
  '+7(495)52#-##-##',
  '+7(495)541-##-##',
  '+7(495)542-##-##',
  '+7(495)543-##-##',
  '+7(495)544-##-##',
  '+7(495)545-##-##',
  '+7(495)546-##-##',
  '+7(495)546-1#-##',
  '+7(495)546-6#-##',
  '+7(495)546-8#-##',
  '+7(495)548-0#-##',
  '+7(495)548-1#-##',
  '+7(495)548-4#-##',
  '+7(495)548-5#-##',
  '+7(495)548-6#-##',
  '+7(495)548-7#-##',
  '+7(495)548-8#-##',
  '+7(495)548-9#-##',
  '+7(495)549-##-##',
  '+7(495)55#-##-##',
  '+7(495)552-##-##',
  '+7(495)555-##-##',
  '+7(495)56#-##-##',
  '+7(495)57#-##-##',
  '+7(495)573-##-##',
  '+7(495)576-##-##',
  '+7(495)577-##-##',
  '+7(495)578-##-##',
  '+7(495)579-##-##',
  '+7(495)58#-##-##',
  '+7(495)585-##-##',
  '+7(495)589-##-##',
  '+7(495)59#-##-##',
  '+7(495)597-##-##',
  '+7(496)###-##-##',
  '+7(496)20#-##-##',
  '+7(496)21#-##-##',
  '+7(496)22#-##-##',
  '+7(496)24#-##-##',
  '+7(496)25#-##-##',
  '+7(496)26#-##-##',
  '+7(496)27#-##-##',
  '+7(496)28#-##-##',
  '+7(496)30#-##-##',
  '+7(496)31#-##-##',
  '+7(496)34#-##-##',
  '+7(496)36#-##-##',
  '+7(496)37#-##-##',
  '+7(496)38#-##-##',
  '+7(496)40#-##-##',
  '+7(496)41#-##-##',
  '+7(496)42#-##-##',
  '+7(496)43#-##-##',
  '+7(496)44#-##-##',
  '+7(496)45#-##-##',
  '+7(496)46#-##-##',
  '+7(496)51#-##-##',
  '+7(496)52#-##-##',
  '+7(496)53#-##-##',
  '+7(496)54#-##-##',
  '+7(496)55#-##-##',
  '+7(496)56#-##-##',
  '+7(496)57#-##-##',
  '+7(496)61#-##-##',
  '+7(496)63#-##-##',
  '+7(496)64#-##-##',
  '+7(496)66#-##-##',
  '+7(496)67#-##-##',
  '+7(496)69#-##-##',
  '+7(496)70#-##-##',
  '+7(496)72#-##-##',
  '+7(496)73#-##-##',
  '+7(496)75#-##-##',
  '+7(496)76#-##-##',
  '+7(496)77#-##-##',
  '+7(496)79#-##-##',
  '+7(498)###-##-##',
  '+7(498)48#-##-##',
  '+7(498)54#-##-##',
  '+7(498)617-##-##',
  '+7(498)657-##-##',
  '+7(498)664-##-##',
  '+7(498)68#-##-##',
  '+7(498)713-##-##',
  '+7(498)744-##-##',
  '+7(499)###-##-##',
  '+7(499)39#-##-##',
  '+7(499)50#-##-##',
  '+7(499)755-##-##',
  '+7(811)###-##-##',
  '+7(8112)##-##-##',
  '+7(81131)#-##-##',
  '+7(81132)#-##-##',
  '+7(81133)#-##-##',
  '+7(81134)#-##-##',
  '+7(81135)#-##-##',
  '+7(81136)#-##-##',
  '+7(81137)#-##-##',
  '+7(81138)#-##-##',
  '+7(81139)#-##-##',
  '+7(81140)#-##-##',
  '+7(81141)#-##-##',
  '+7(81142)#-##-##',
  '+7(81143)#-##-##',
  '+7(81144)#-##-##',
  '+7(81145)#-##-##',
  '+7(81146)#-##-##',
  '+7(81147)#-##-##',
  '+7(81148)#-##-##',
  '+7(81149)#-##-##',
  '+7(81150)#-##-##',
  '+7(81151)#-##-##',
  '+7(81152)#-##-##',
  '+7(81153)#-##-##',
  '+7(812)###-##-##',
  '+7(813)###-##-##',
  '+7(81361)#-##-##',
  '+7(81362)#-##-##',
  '+7(81363)#-##-##',
  '+7(81364)#-##-##',
  '+7(81365)#-##-##',
  '+7(81366)#-##-##',
  '+7(81367)#-##-##',
  '+7(81368)#-##-##',
  '+7(81369)#-##-##',
  '+7(81370)#-##-##',
  '+7(81371)#-##-##',
  '+7(81372)#-##-##',
  '+7(81373)#-##-##',
  '+7(81374)#-##-##',
  '+7(81375)#-##-##',
  '+7(81376)#-##-##',
  '+7(81378)#-##-##',
  '+7(81379)#-##-##',
  '+7(814)###-##-##',
  '+7(8142)##-##-##',
  '+7(81430)#-##-##',
  '+7(81430)3-##-##',
  '+7(81430)3-1#-##',
  '+7(81430)3-3#-##',
  '+7(81430)3-4#-##',
  '+7(81430)3-5#-##',
  '+7(81430)3-6#-##',
  '+7(81430)3-7#-##',
  '+7(81431)#-##-##',
  '+7(81431)3-0#-##',
  '+7(81431)3-1#-##',
  '+7(81431)3-2#-##',
  '+7(81431)3-3#-##',
  '+7(81431)3-6#-##',
  '+7(81431)3-7#-##',
  '+7(81431)3-8#-##',
  '+7(81433)#-##-##',
  '+7(81433)2-4#-##',
  '+7(81433)2-5#-##',
  '+7(81433)2-6#-##',
  '+7(81433)2-7#-##',
  '+7(81434)#-##-##',
  '+7(81434)3-3#-##',
  '+7(81434)3-4#-##',
  '+7(81434)3-5#-##',
  '+7(81434)3-8#-##',
  '+7(81434)3-9#-##',
  '+7(81434)4-2#-##',
  '+7(81434)4-3#-##',
  '+7(81434)4-4#-##',
  '+7(81434)4-7#-##',
  '+7(81436)#-##-##',
  '+7(81436)2-3#-##',
  '+7(81436)2-4#-##',
  '+7(81436)2-5#-##',
  '+7(81436)2-6#-##',
  '+7(81436)2-7#-##',
  '+7(81436)2-8#-##',
  '+7(81436)2-9#-##',
  '+7(81437)#-##-##',
  '+7(81437)3-0#-##',
  '+7(81437)3-4#-##',
  '+7(81437)3-5#-##',
  '+7(81437)3-6#-##',
  '+7(81437)3-7#-##',
  '+7(81437)3-8#-##',
  '+7(81437)3-9#-##',
  '+7(81439)#-##-##',
  '+7(81439)2-6#-##',
  '+7(81439)2-7#-##',
  '+7(81439)2-8#-##',
  '+7(81439)3-3#-##',
  '+7(81439)4-1#-##',
  '+7(81439)4-4#-##',
  '+7(81439)45#-##',
  '+7(81450)#-##-##',
  '+7(81450)2-3#-##',
  '+7(81450)2-4#-##',
  '+7(81450)2-6#-##',
  '+7(81451)#-##-##',
  '+7(81451)2-4#-##',
  '+7(81451)3-1#-##',
  '+7(81451)3-2#-##',
  '+7(81451)3-3#-##',
  '+7(81451)3-4#-##',
  '+7(81451)3-5#-##',
  '+7(81451)3-7#-##',
  '+7(81451)3-9#-##',
  '+7(81452)#-##-##',
  '+7(81452)2-3#-##',
  '+7(81452)2-4#-##',
  '+7(81452)2-5#-##',
  '+7(81452)2-6#-##',
  '+7(81452)2-7#-##',
  '+7(81452)2-8#-##',
  '+7(81452)2-9#-##',
  '+7(81454)#-##-##',
  '+7(81454)5-3#-##',
  '+7(81454)5-4#-##',
  '+7(81454)5-7#-##',
  '+7(81455)#-##-##',
  '+7(81455)2-3#-##',
  '+7(81455)2-4#-##',
  '+7(81455)2-5#-##',
  '+7(81455)2-6#-##',
  '+7(81455)2-7#-##',
  '+7(81455)2-8#-##',
  '+7(81455)2-9#-##',
  '+7(81456)#-##-##',
  '+7(81456)2-3#-##',
  '+7(81456)2-4#-##',
  '+7(81456)2-5#-##',
  '+7(81456)2-6#-##',
  '+7(81456)2-7#-##',
  '+7(81456)2-8#-##',
  '+7(81456)2-9#-##',
  '+7(81457)#-##-##',
  '+7(81457)2-3#-##',
  '+7(81457)2-4#-##',
  '+7(81457)2-5#-##',
  '+7(81457)2-6#-##',
  '+7(81457)2-7#-##',
  '+7(81457)2-9#-##',
  '+7(81457)3-6#-##',
  '+7(81458)#-##-##',
  '+7(81458)3-1#-##',
  '+7(81458)3-2#-##',
  '+7(81458)3-4#-##',
  '+7(81458)3-5#-##',
  '+7(81458)3-6#-##',
  '+7(81458)3-7#-##',
  '+7(81458)3-8#-##',
  '+7(81458)3-9#-##',
  '+7(81459)#-##-##',
  '+7(81459)9-3#-##',
  '+7(81459)9-5#-##',
  '+7(815)###-##-##',
  '+7(8152)##-##-##',
  '+7(81530)#-##-##',
  '+7(81531)#-##-##',
  '+7(81532)#-##-##',
  '+7(81533)#-##-##',
  '+7(81535)#-##-##',
  '+7(81536)#-##-##',
  '+7(81537)#-##-##',
  '+7(81538)#-##-##',
  '+7(81539)#-##-##',
  '+7(81551)#-##-##',
  '+7(81552)#-##-##',
  '+7(81553)#-##-##',
  '+7(81554)#-##-##',
  '+7(81555)#-##-##',
  '+7(81556)#-##-##',
  '+7(81558)#-##-##',
  '+7(81559)#-##-##',
  '+7(816)###-##-##',
  '+7(8162)##-##-##',
  '+7(81650)#-##-##',
  '+7(81651)#-##-##',
  '+7(81652)#-##-##',
  '+7(81653)#-##-##',
  '+7(81654)#-##-##',
  '+7(81655)#-##-##',
  '+7(81656)#-##-##',
  '+7(81657)#-##-##',
  '+7(81658)#-##-##',
  '+7(81659)#-##-##',
  '+7(81660)#-##-##',
  '+7(81661)#-##-##',
  '+7(81662)#-##-##',
  '+7(81663)#-##-##',
  '+7(81664)#-##-##',
  '+7(81665)#-##-##',
  '+7(81666)#-##-##',
  '+7(81667)#-##-##',
  '+7(81668)#-##-##',
  '+7(81669)#-##-##',
  '+7(817)###-##-##',
  '+7(8172)##-##-##',
  '+7(81732)#-##-##',
  '+7(81733)#-##-##',
  '+7(81737)#-##-##',
  '+7(81738)#-##-##',
  '+7(81739)#-##-##',
  '+7(81740)#-##-##',
  '+7(81741)#-##-##',
  '+7(81742)#-##-##',
  '+7(81743)#-##-##',
  '+7(81744)#-##-##',
  '+7(81745)#-##-##',
  '+7(81746)#-##-##',
  '+7(81747)#-##-##',
  '+7(81748)#-##-##',
  '+7(81749)#-##-##',
  '+7(81751)#-##-##',
  '+7(81752)#-##-##',
  '+7(81753)#-##-##',
  '+7(81754)#-##-##',
  '+7(81755)#-##-##',
  '+7(81756)#-##-##',
  '+7(81757)#-##-##',
  '+7(81758)#-##-##',
  '+7(81759)#-##-##',
  '+7(818)###-##-##',
  '+7(81830)#-##-##',
  '+7(81831)#-##-##',
  '+7(81832)#-##-##',
  '+7(81833)#-##-##',
  '+7(81834)#-##-##',
  '+7(81835)9-0#-##',
  '+7(81836)#-##-##',
  '+7(81837)#-##-##',
  '+7(81838)#-##-##',
  '+7(81839)#-##-##',
  '+7(81840)#-##-##',
  '+7(81841)#-##-##',
  '+7(81842)#-##-##',
  '+7(81843)#-##-##',
  '+7(81848)#-##-##',
  '+7(81850)#-##-##',
  '+7(81851)#-##-##',
  '+7(81852)#-##-##',
  '+7(81853)#-##-##',
  '+7(81854)#-##-##',
  '+7(81855)#-##-##',
  '+7(81856)#-##-##',
  '+7(81858)#-##-##',
  '+7(81859)#-##-##',
  '+7(820)###-##-##',
  '+7(8202)##-##-##',
  '+7(821)###-##-##',
  '+7(8212)##-##-##',
  '+7(82130)#-##-##',
  '+7(82131)#-##-##',
  '+7(82132)#-##-##',
  '+7(82133)#-##-##',
  '+7(82134)#-##-##',
  '+7(82135)#-##-##',
  '+7(82136)#-##-##',
  '+7(82137)#-##-##',
  '+7(82138)#-##-##',
  '+7(82139)#-##-##',
  '+7(82140)#-##-##',
  '+7(82141)#-##-##',
  '+7(82142)#-##-##',
  '+7(82144)#-##-##',
  '+7(82145)#-##-##',
  '+7(82146)#-##-##',
  '+7(82149)#-##-##',
  '+7(82151)#-##-##',
  '+7(8216)##-##-##',
  '+7(831)###-##-##',
  '+7(8313)##-##-##',
  '+7(83130)#-##-##',
  '+7(83134)#-##-##',
  '+7(83136)#-##-##',
  '+7(83137)#-##-##',
  '+7(83138)#-##-##',
  '+7(83139)#-##-##',
  '+7(83140)#-##-##',
  '+7(83144)#-##-##',
  '+7(83145)#-##-##',
  '+7(83147)#-##-##',
  '+7(83148)#-##-##',
  '+7(83149)#-##-##',
  '+7(83150)#-##-##',
  '+7(83151)#-##-##',
  '+7(83152)#-##-##',
  '+7(83153)#-##-##',
  '+7(83154)#-##-##',
  '+7(83155)#-##-##',
  '+7(83156)#-##-##',
  '+7(83157)#-##-##',
  '+7(83158)#-##-##',
  '+7(83159)#-##-##',
  '+7(83160)#-##-##',
  '+7(83161)#-##-##',
  '+7(83162)#-##-##',
  '+7(83163)#-##-##',
  '+7(83164)#-##-##',
  '+7(83165)#-##-##',
  '+7(83166)#-##-##',
  '+7(83167)#-##-##',
  '+7(83168)#-##-##',
  '+7(83169)#-##-##',
  '+7(83170)#-##-##',
  '+7(83171)#-##-##',
  '+7(83172)#-##-##',
  '+7(83173)#-##-##',
  '+7(83174)#-##-##',
  '+7(83175)#-##-##',
  '+7(83176)#-##-##',
  '+7(83177)#-##-##',
  '+7(83178)#-##-##',
  '+7(83179)#-##-##',
  '+7(83190)#-##-##',
  '+7(83191)#-##-##',
  '+7(83192)#-##-##',
  '+7(83193)#-##-##',
  '+7(83194)#-##-##',
  '+7(83195)#-##-##',
  '+7(83196)#-##-##',
  '+7(83197)#-##-##',
  '+7(833)###-##-##',
  '+7(8332)##-##-##',
  '+7(83330)#-##-##',
  '+7(83331)#-##-##',
  '+7(83332)#-##-##',
  '+7(83333)#-##-##',
  '+7(83334)#-##-##',
  '+7(83335)#-##-##',
  '+7(83336)#-##-##',
  '+7(83337)#-##-##',
  '+7(83338)#-##-##',
  '+7(83339)#-##-##',
  '+7(83340)#-##-##',
  '+7(83341)#-##-##',
  '+7(83342)#-##-##',
  '+7(83343)#-##-##',
  '+7(83344)#-##-##',
  '+7(83345)#-##-##',
  '+7(83346)#-##-##',
  '+7(83347)#-##-##',
  '+7(83348)#-##-##',
  '+7(83349)#-##-##',
  '+7(83350)#-##-##',
  '+7(83351)#-##-##',
  '+7(83352)#-##-##',
  '+7(83353)#-##-##',
  '+7(83354)#-##-##',
  '+7(83355)#-##-##',
  '+7(83357)#-##-##',
  '+7(83358)#-##-##',
  '+7(83359)#-##-##',
  '+7(83361)#-##-##',
  '+7(83362)#-##-##',
  '+7(83363)#-##-##',
  '+7(83364)#-##-##',
  '+7(83365)#-##-##',
  '+7(83366)#-##-##',
  '+7(83367)#-##-##',
  '+7(83368)#-##-##',
  '+7(83369)#-##-##',
  '+7(83375)#-##-##',
  '+7(834)###-##-##',
  '+7(8342)##-##-##',
  '+7(83431)#-##-##',
  '+7(83432)#-##-##',
  '+7(83433)#-##-##',
  '+7(83434)#-##-##',
  '+7(83436)#-##-##',
  '+7(83437)#-##-##',
  '+7(83438)#-##-##',
  '+7(83439)#-##-##',
  '+7(83441)#-##-##',
  '+7(83442)#-##-##',
  '+7(83443)#-##-##',
  '+7(83444)#-##-##',
  '+7(83445)#-##-##',
  '+7(83446)#-##-##',
  '+7(83447)#-##-##',
  '+7(83448)#-##-##',
  '+7(83449)#-##-##',
  '+7(83451)#-##-##',
  '+7(83453)#-##-##',
  '+7(83454)#-##-##',
  '+7(83456)#-##-##',
  '+7(83457)#-##-##',
  '+7(83458)#-##-##',
  '+7(835)###-##-##',
  '+7(8352)##-##-##',
  '+7(8352)7#-##-##',
  '+7(83530)#-##-##',
  '+7(83531)#-##-##',
  '+7(83532)#-##-##',
  '+7(83533)#-##-##',
  '+7(83534)#-##-##',
  '+7(83535)#-##-##',
  '+7(83536)#-##-##',
  '+7(83537)#-##-##',
  '+7(83538)#-##-##',
  '+7(83539)#-##-##',
  '+7(83540)#-##-##',
  '+7(83541)#-##-##',
  '+7(83542)#-##-##',
  '+7(83543)#-##-##',
  '+7(83544)#-##-##',
  '+7(83545)#-##-##',
  '+7(83546)#-##-##',
  '+7(83547)#-##-##',
  '+7(83548)#-##-##',
  '+7(83549)#-##-##',
  '+7(83551)#-##-##',
  '+7(836)###-##-##',
  '+7(8362)##-##-##',
  '+7(83631)#-##-##',
  '+7(83632)#-##-##',
  '+7(83633)#-##-##',
  '+7(83634)#-##-##',
  '+7(83635)#-##-##',
  '+7(83636)#-##-##',
  '+7(83637)#-##-##',
  '+7(83638)#-##-##',
  '+7(83639)#-##-##',
  '+7(83641)#-##-##',
  '+7(83643)#-##-##',
  '+7(83644)#-##-##',
  '+7(83645)#-##-##',
  '+7(840)###-##-##',
  '+7(840)22#-##-##',
  '+7(840)23#-##-##',
  '+7(840)24#-##-##',
  '+7(840)25#-##-##',
  '+7(840)26#-##-##',
  '+7(840)27#-##-##',
  '+7(840)28#-##-##',
  '+7(841)###-##-##',
  '+7(8412)##-##-##',
  '+7(84140)#-##-##',
  '+7(84141)#-##-##',
  '+7(84142)#-##-##',
  '+7(84143)#-##-##',
  '+7(84144)#-##-##',
  '+7(84145)#-##-##',
  '+7(84146)#-##-##',
  '+7(84147)#-##-##',
  '+7(84148)#-##-##',
  '+7(84150)#-##-##',
  '+7(84151)#-##-##',
  '+7(84152)#-##-##',
  '+7(84153)#-##-##',
  '+7(84154)#-##-##',
  '+7(84155)#-##-##',
  '+7(84156)#-##-##',
  '+7(84157)#-##-##',
  '+7(84158)#-##-##',
  '+7(84159)#-##-##',
  '+7(84161)#-##-##',
  '+7(84162)#-##-##',
  '+7(84163)#-##-##',
  '+7(84164)#-##-##',
  '+7(84165)#-##-##',
  '+7(84167)#-##-##',
  '+7(84168)#-##-##',
  '+7(84169)#-##-##',
  '+7(842)###-##-##',
  '+7(8422)##-##-##',
  '+7(84230)#-##-##',
  '+7(84231)#-##-##',
  '+7(84232)#-##-##',
  '+7(84233)#-##-##',
  '+7(84234)#-##-##',
  '+7(84235)#-##-##',
  '+7(84237)#-##-##',
  '+7(84238)#-##-##',
  '+7(84239)#-##-##',
  '+7(84240)#-##-##',
  '+7(84241)#-##-##',
  '+7(84242)#-##-##',
  '+7(84243)#-##-##',
  '+7(84244)#-##-##',
  '+7(84245)#-##-##',
  '+7(84246)#-##-##',
  '+7(84247)#-##-##',
  '+7(84248)#-##-##',
  '+7(84249)#-##-##',
  '+7(84253)#-##-##',
  '+7(84254)#-##-##',
  '+7(84255)#-##-##',
  '+7(843)###-##-##',
  '+7(84341)#-##-##',
  '+7(84342)#-##-##',
  '+7(84344)#-##-##',
  '+7(84345)#-##-##',
  '+7(84346)#-##-##',
  '+7(84347)#-##-##',
  '+7(84348)#-##-##',
  '+7(84360)#-##-##',
  '+7(84361)#-##-##',
  '+7(84362)#-##-##',
  '+7(84364)#-##-##',
  '+7(84365)#-##-##',
  '+7(84366)#-##-##',
  '+7(84367)#-##-##',
  '+7(84368)#-##-##',
  '+7(84369)#-##-##',
  '+7(84370)#-##-##',
  '+7(84371)#-##-##',
  '+7(84373)#-##-##',
  '+7(84374)#-##-##',
  '+7(84375)#-##-##',
  '+7(84376)#-##-##',
  '+7(84377)#-##-##',
  '+7(84378)#-##-##',
  '+7(84379)#-##-##',
  '+7(84396)#-##-##',
  '+7(844)###-##-##',
  '+7(844)2##-##-##',
  '+7(844)70#-##-##',
  '+7(8443)##-##-##',
  '+7(84442)#-##-##',
  '+7(84443)#-##-##',
  '+7(84444)#-##-##',
  '+7(84445)#-##-##',
  '+7(84446)#-##-##',
  '+7(84447)#-##-##',
  '+7(84452)#-##-##',
  '+7(84453)#-##-##',
  '+7(84454)#-##-##',
  '+7(84455)#-##-##',
  '+7(84456)#-##-##',
  '+7(84457)#-##-##',
  '+7(84458)#-##-##',
  '+7(84461)#-##-##',
  '+7(84462)#-##-##',
  '+7(84463)#-##-##',
  '+7(84464)#-##-##',
  '+7(84465)#-##-##',
  '+7(84466)#-##-##',
  '+7(84467)#-##-##',
  '+7(84468)#-##-##',
  '+7(84472)#-##-##',
  '+7(84473)#-##-##',
  '+7(84474)#-##-##',
  '+7(84475)#-##-##',
  '+7(84476)#-##-##',
  '+7(84477)#-##-##',
  '+7(84478)#-##-##',
  '+7(84479)#-##-##',
  '+7(84492)#-##-##',
  '+7(84493)#-##-##',
  '+7(84494)#-##-##',
  '+7(84495)#-##-##',
  '+7(845)###-##-##',
  '+7(8452)##-##-##',
  '+7(8453)2#-##-##',
  '+7(8453)3#-##-##',
  '+7(8453)4#-##-##',
  '+7(8453)5#-##-##',
  '+7(8453)7#-##-##',
  '+7(8453)9#-##-##',
  '+7(84540)#-##-##',
  '+7(84542)#-##-##',
  '+7(84543)#-##-##',
  '+7(84544)#-##-##',
  '+7(84545)#-##-##',
  '+7(84548)#-##-##',
  '+7(84549)#-##-##',
  '+7(84550)#-##-##',
  '+7(84551)#-##-##',
  '+7(84552)#-##-##',
  '+7(84554)#-##-##',
  '+7(84555)#-##-##',
  '+7(84557)#-##-##',
  '+7(84558)#-##-##',
  '+7(84560)#-##-##',
  '+7(84561)#-##-##',
  '+7(84562)#-##-##',
  '+7(84563)#-##-##',
  '+7(84564)#-##-##',
  '+7(84565)#-##-##',
  '+7(84566)#-##-##',
  '+7(84567)#-##-##',
  '+7(84568)#-##-##',
  '+7(84573)#-##-##',
  '+7(84574)#-##-##',
  '+7(84575)#-##-##',
  '+7(84576)#-##-##',
  '+7(84577)#-##-##',
  '+7(84578)#-##-##',
  '+7(84579)#-##-##',
  '+7(84591)#-##-##',
  '+7(84592)#-##-##',
  '+7(84593)#-##-##',
  '+7(84595)#-##-##',
  '+7(84596)#-##-##',
  '+7(846)###-##-##',
  '+7(846)2##-##-##',
  '+7(846)300-##-##',
  '+7(846)302-##-##',
  '+7(846)303-##-##',
  '+7(846)309-##-##',
  '+7(846)31#-##-##',
  '+7(846)33#-##-##',
  '+7(846)34#-##-##',
  '+7(846)37#-##-##',
  '+7(846)9##-##-##',
  '+7(84630)5-##-##',
  '+7(84635)#-##-##',
  '+7(84639)#-##-##',
  '+7(8464)3#-##-##',
  '+7(8464)4#-##-##',
  '+7(8464)9#-##-##',
  '+7(84646)#-##-##',
  '+7(84647)#-##-##',
  '+7(84648)#-##-##',
  '+7(84650)#-##-##',
  '+7(84651)#-##-##',
  '+7(84652)#-##-##',
  '+7(84653)#-##-##',
  '+7(84654)#-##-##',
  '+7(84655)#-##-##',
  '+7(84656)#-##-##',
  '+7(84657)#-##-##',
  '+7(84658)#-##-##',
  '+7(84660)#-##-##',
  '+7(84661)#-##-##',
  '+7(84663)#-##-##',
  '+7(84664)#-##-##',
  '+7(84666)#-##-##',
  '+7(84667)#-##-##',
  '+7(84670)#-##-##',
  '+7(84671)#-##-##',
  '+7(84672)#-##-##',
  '+7(84673)#-##-##',
  '+7(84674)#-##-##',
  '+7(84675)#-##-##',
  '+7(84676)#-##-##',
  '+7(84677)#-##-##',
  '+7(847)###-##-##',
  '+7(84722)#-##-##',
  '+7(84731)#-##-##',
  '+7(84732)#-##-##',
  '+7(84733)#-##-##',
  '+7(84734)#-##-##',
  '+7(84735)#-##-##',
  '+7(84736)#-##-##',
  '+7(84741)#-##-##',
  '+7(84742)#-##-##',
  '+7(84743)#-##-##',
  '+7(84744)#-##-##',
  '+7(84745)#-##-##',
  '+7(84746)#-##-##',
  '+7(84747)#-##-##',
  '+7(848)###-##-##',
  '+7(8482)##-##-##',
  '+7(84862)#-##-##',
  '+7(851)###-##-##',
  '+7(8512)##-##-##',
  '+7(85140)#-##-##',
  '+7(85141)#-##-##',
  '+7(85142)#-##-##',
  '+7(85143)#-##-##',
  '+7(85144)#-##-##',
  '+7(85145)#-##-##',
  '+7(85146)#-##-##',
  '+7(85147)#-##-##',
  '+7(85148)#-##-##',
  '+7(85149)#-##-##',
  '+7(85171)#-##-##',
  '+7(85172)#-##-##',
  '+7(855)###-##-##',
  '+7(8552)##-##-##',
  '+7(8553)##-##-##',
  '+7(85549)#-##-##',
  '+7(8555)3#-##-##',
  '+7(8555)4#-##-##',
  '+7(85551)#-##-##',
  '+7(85552)#-##-##',
  '+7(85555)#-##-##',
  '+7(85556)#-##-##',
  '+7(85557)#-##-##',
  '+7(85558)#-##-##',
  '+7(85559)#-##-##',
  '+7(85563)#-##-##',
  '+7(85569)#-##-##',
  '+7(8557)2#-##-##',
  '+7(8557)3#-##-##',
  '+7(85592)#-##-##',
  '+7(85593)#-##-##',
  '+7(85594)#-##-##',
  '+7(85595)#-##-##',
  '+7(861)###-##-##',
  '+7(86130)#-##-##',
  '+7(86131)#-##-##',
  '+7(86132)#-##-##',
  '+7(86133)#-##-##',
  '+7(86135)#-##-##',
  '+7(86137)#-##-##',
  '+7(86138)#-##-##',
  '+7(86140)#-##-##',
  '+7(86141)#-##-##',
  '+7(86142)#-##-##',
  '+7(86143)#-##-##',
  '+7(86144)#-##-##',
  '+7(86145)#-##-##',
  '+7(86146)#-##-##',
  '+7(86147)#-##-##',
  '+7(86148)#-##-##',
  '+7(86149)#-##-##',
  '+7(86150)#-##-##',
  '+7(86151)#-##-##',
  '+7(86152)#-##-##',
  '+7(86153)#-##-##',
  '+7(86154)#-##-##',
  '+7(86155)#-##-##',
  '+7(86156)#-##-##',
  '+7(86157)#-##-##',
  '+7(86158)#-##-##',
  '+7(86159)#-##-##',
  '+7(86160)#-##-##',
  '+7(86161)#-##-##',
  '+7(86162)#-##-##',
  '+7(86163)#-##-##',
  '+7(86164)#-##-##',
  '+7(86165)#-##-##',
  '+7(86166)#-##-##',
  '+7(86167)#-##-##',
  '+7(86168)#-##-##',
  '+7(86169)#-##-##',
  '+7(8617)##-##-##',
  '+7(86191)#-##-##',
  '+7(86192)#-##-##',
  '+7(86193)#-##-##',
  '+7(86195)#-##-##',
  '+7(86196)#-##-##',
  '+7(862)###-##-##',
  '+7(862)2##-##-##',
  '+7(862)23#-##-##',
  '+7(862)24#-##-##',
  '+7(862)247-##-##',
  '+7(862)252-##-##',
  '+7(862)27#-##-##',
  '+7(862)295-##-##',
  '+7(863)###-##-##',
  '+7(863)2##-##-##',
  '+7(863)3##-##-##',
  '+7(8634)3#-##-##',
  '+7(8634)43-1#-##',
  '+7(8634)6#-##-##',
  '+7(86340)#-##-##',
  '+7(86341)#-##-##',
  '+7(86342)#-##-##',
  '+7(86345)#-##-##',
  '+7(86347)#-##-##',
  '+7(86348)#-##-##',
  '+7(86349)#-##-##',
  '+7(8635)2#-##-##',
  '+7(86350)#-##-##',
  '+7(86351)#-##-##',
  '+7(86353)#-##-##',
  '+7(86354)#-##-##',
  '+7(86355)#-##-##',
  '+7(86356)#-##-##',
  '+7(86357)#-##-##',
  '+7(86358)#-##-##',
  '+7(86359)#-##-##',
  '+7(8636)2#-##-##',
  '+7(86360)#-##-##',
  '+7(86361)#-##-##',
  '+7(86363)#-##-##',
  '+7(86364)#-##-##',
  '+7(86365)#-##-##',
  '+7(86367)#-##-##',
  '+7(86368)#-##-##',
  '+7(86369)#-##-##',
  '+7(86370)#-##-##',
  '+7(86371)#-##-##',
  '+7(86372)#-##-##',
  '+7(86373)#-##-##',
  '+7(86374)#-##-##',
  '+7(86375)#-##-##',
  '+7(86376)#-##-##',
  '+7(86377)#-##-##',
  '+7(86378)#-##-##',
  '+7(86379)#-##-##',
  '+7(86382)#-##-##',
  '+7(86383)#-##-##',
  '+7(86384)#-##-##',
  '+7(86385)#-##-##',
  '+7(86386)#-##-##',
  '+7(86387)#-##-##',
  '+7(86388)#-##-##',
  '+7(86389)#-##-##',
  '+7(8639)2#-##-##',
  '+7(86391)#-##-##',
  '+7(86393)#-##-##',
  '+7(86394)#-##-##',
  '+7(86395)#-##-##',
  '+7(86396)#-##-##',
  '+7(86397)#-##-##',
  '+7(865)###-##-##',
  '+7(8652)##-##-##',
  '+7(86540)#-##-##',
  '+7(86541)#-##-##',
  '+7(86542)#-##-##',
  '+7(86543)#-##-##',
  '+7(86544)#-##-##',
  '+7(86545)#-##-##',
  '+7(86546)#-##-##',
  '+7(86547)#-##-##',
  '+7(86548)#-##-##',
  '+7(86549)#-##-##',
  '+7(86550)#-##-##',
  '+7(86552)#-##-##',
  '+7(86553)#-##-##',
  '+7(86554)#-##-##',
  '+7(86555)#-##-##',
  '+7(86556)#-##-##',
  '+7(86557)#-##-##',
  '+7(86558)#-##-##',
  '+7(86559)#-##-##',
  '+7(86560)#-##-##',
  '+7(86563)#-##-##',
  '+7(86565)#-##-##',
  '+7(866)###-##-##',
  '+7(8662)##-##-##',
  '+7(86630)#-##-##',
  '+7(86631)#-##-##',
  '+7(86632)#-##-##',
  '+7(86633)#-##-##',
  '+7(86634)#-##-##',
  '+7(86635)#-##-##',
  '+7(86636)#-##-##',
  '+7(86637)#-##-##',
  '+7(86638)#-##-##',
  '+7(867)###-##-##',
  '+7(8672)##-##-##',
  '+7(86731)#-##-##',
  '+7(86732)#-##-##',
  '+7(86733)#-##-##',
  '+7(86734)#-##-##',
  '+7(86735)#-##-##',
  '+7(86736)#-##-##',
  '+7(86737)#-##-##',
  '+7(86738)#-##-##',
  '+7(86739)#-##-##',
  '+7(871)###-##-##',
  '+7(8712)##-##-##',
  '+7(87132)#-##-##',
  '+7(87134)#-##-##',
  '+7(87135)#-##-##',
  '+7(87136)#-##-##',
  '+7(87142)#-##-##',
  '+7(87143)#-##-##',
  '+7(87145)#-##-##',
  '+7(87146)#-##-##',
  '+7(87147)#-##-##',
  '+7(87148)#-##-##',
  '+7(87152)#-##-##',
  '+7(87154)#-##-##',
  '+7(87155)#-##-##',
  '+7(87156)#-##-##',
  '+7(87164)#-##-##',
  '+7(872)###-##-##',
  '+7(8722)##-##-##',
  '+7(87230)#-##-##',
  '+7(87231)#-##-##',
  '+7(87232)#-##-##',
  '+7(87233)#-##-##',
  '+7(87234)#-##-##',
  '+7(87235)#-##-##',
  '+7(87236)#-##-##',
  '+7(87237)#-##-##',
  '+7(87238)#-##-##',
  '+7(87239)#-##-##',
  '+7(87240)#-##-##',
  '+7(87242)#-##-##',
  '+7(87243)#-##-##',
  '+7(87244)#-##-##',
  '+7(87245)#-##-##',
  '+7(87246)#-##-##',
  '+7(87247)#-##-##',
  '+7(87248)#-##-##',
  '+7(87249)#-##-##',
  '+7(87252)#-##-##',
  '+7(87254)#-##-##',
  '+7(87255)#-##-##',
  '+7(87256)#-##-##',
  '+7(87257)#-##-##',
  '+7(87258)#-##-##',
  '+7(87259)#-##-##',
  '+7(87260)#-##-##',
  '+7(87261)#-##-##',
  '+7(87262)#-##-##',
  '+7(87263)#-##-##',
  '+7(87264)#-##-##',
  '+7(87265)#-##-##',
  '+7(87266)#-##-##',
  '+7(87267)#-##-##',
  '+7(87268)#-##-##',
  '+7(87271)#-##-##',
  '+7(87272)#-##-##',
  '+7(87273)#-##-##',
  '+7(87274)#-##-##',
  '+7(87275)#-##-##',
  '+7(87276)#-##-##',
  '+7(87279)#-##-##',
  '+7(873)###-##-##',
  '+7(8732)##-##-##',
  '+7(87341)#-##-##',
  '+7(87342)#-##-##',
  '+7(87343)#-##-##',
  '+7(87344)#-##-##',
  '+7(87345)#-##-##',
  '+7(877)###-##-##',
  '+7(8772)##-##-##',
  '+7(87770)#-##-##',
  '+7(87771)#-##-##',
  '+7(87772)#-##-##',
  '+7(87773)#-##-##',
  '+7(87777)#-##-##',
  '+7(87778)#-##-##',
  '+7(87779)#-##-##',
  '+7(878)###-##-##',
  '+7(8782)##-##-##',
  '+7(87870)#-##-##',
  '+7(87872)#-##-##',
  '+7(87873)#-##-##',
  '+7(87874)#-##-##',
  '+7(87875)#-##-##',
  '+7(87876)#-##-##',
  '+7(87877)#-##-##',
  '+7(87878)#-##-##',
  '+7(87879)#-##-##',
  '+7(879)###-##-##',
  '+7(87922)#-##-##',
  '+7(8793)##-##-##',
  '+7(87932)#-##-##',
  '+7(87934)#-##-##',
  '+7(87935)#-##-##',
  '+7(87937)#-##-##',
  '+7(87938)#-##-##',
  '+7(87951)#-##-##',
  '+7(87961)#-##-##',
  '+7(87964)#-##-##',
  '+7(9##)###-##-##',
  '8(301)###-##-##',
  '8(3012)##-##-##',
  '8(30130)#-##-##',
  '8(30131)#-##-##',
  '8(30132)#-##-##',
  '8(30133)#-##-##',
  '8(30134)#-##-##',
  '8(30135)#-##-##',
  '8(30136)#-##-##',
  '8(30137)#-##-##',
  '8(30138)#-##-##',
  '8(30140)#-##-##',
  '8(30141)#-##-##',
  '8(30142)#-##-##',
  '8(30143)#-##-##',
  '8(30144)#-##-##',
  '8(30145)#-##-##',
  '8(30146)#-##-##',
  '8(30147)#-##-##',
  '8(30148)#-##-##',
  '8(30149)#-##-##',
  '8(30150)#-##-##',
  '8(30153)#-##-##',
  '8(302)###-##-##',
  '8(3022)##-##-##',
  '8(30230)#-##-##',
  '8(30231)#-##-##',
  '8(30232)#-##-##',
  '8(30233)#-##-##',
  '8(30234)#-##-##',
  '8(30235)#-##-##',
  '8(30236)#-##-##',
  '8(30237)#-##-##',
  '8(30238)#-##-##',
  '8(30239)#-##-##',
  '8(30240)#-##-##',
  '8(30241)#-##-##',
  '8(30242)#-##-##',
  '8(30243)#-##-##',
  '8(30244)#-##-##',
  '8(30245)#-##-##',
  '8(30246)#-##-##',
  '8(30247)#-##-##',
  '8(30248)#-##-##',
  '8(30249)#-##-##',
  '8(30251)#-##-##',
  '8(30252)#-##-##',
  '8(30253)#-##-##',
  '8(30255)#-##-##',
  '8(30256)#-##-##',
  '8(30257)#-##-##',
  '8(30261)#-##-##',
  '8(30262)#-##-##',
  '8(30264)#-##-##',
  '8(30265)#-##-##',
  '8(30266)#-##-##',
  '8(341)###-##-##',
  '8(3412)##-##-##',
  '8(34126)#-##-##',
  '8(34130)#-##-##',
  '8(34132)#-##-##',
  '8(34133)#-##-##',
  '8(34134)#-##-##',
  '8(34136)#-##-##',
  '8(34138)#-##-##',
  '8(34139)#-##-##',
  '8(34141)#-##-##',
  '8(34145)#-##-##',
  '8(34147)#-##-##',
  '8(34150)#-##-##',
  '8(34151)#-##-##',
  '8(34152)#-##-##',
  '8(34153)#-##-##',
  '8(34154)#-##-##',
  '8(34155)#-##-##',
  '8(34157)#-##-##',
  '8(34158)#-##-##',
  '8(34159)#-##-##',
  '8(34161)#-##-##',
  '8(34162)#-##-##',
  '8(34163)#-##-##',
  '8(34164)#-##-##',
  '8(34166)#-##-##',
  '8(342)###-##-##',
  '8(342)2##-##-##',
  '8(342)3##-##-##',
  '8(3424)2#-##-##',
  '8(34240)#-##-##',
  '8(34241)#-##-##',
  '8(34243)#-##-##',
  '8(34244)#-##-##',
  '8(34245)#-##-##',
  '8(34246)#-##-##',
  '8(34248)#-##-##',
  '8(34249)#-##-##',
  '8(34250)#-##-##',
  '8(34251)#-##-##',
  '8(34252)#-##-##',
  '8(34253)#-##-##',
  '8(34254)#-##-##',
  '8(34255)#-##-##',
  '8(34256)#-##-##',
  '8(34257)#-##-##',
  '8(34258)#-##-##',
  '8(34259)#-##-##',
  '8(34260)#-##-##',
  '8(34261)#-##-##',
  '8(34262)#-##-##',
  '8(34263)#-##-##',
  '8(34265)#-##-##',
  '8(34266)#-##-##',
  '8(34268)#-##-##',
  '8(34269)#-##-##',
  '8(34271)#-##-##',
  '8(34272)#-##-##',
  '8(34273)#-##-##',
  '8(34274)#-##-##',
  '8(34275)#-##-##',
  '8(34276)#-##-##',
  '8(34277)#-##-##',
  '8(34278)#-##-##',
  '8(34279)#-##-##',
  '8(34291)#-##-##',
  '8(34292)#-##-##',
  '8(34293)#-##-##',
  '8(34294)#-##-##',
  '8(34296)#-##-##',
  '8(34297)#-##-##',
  '8(34298)#-##-##',
  '8(343)###-##-##',
  '8(343)2##-##-##',
  '8(343)3##-##-##',
  '8(34341)#-##-##',
  '8(34342)2-##-##',
  '8(34342)3-##-##',
  '8(34342)5-##-##',
  '8(34342)6-##-##',
  '8(34343)#-##-##',
  '8(34344)#-##-##',
  '8(34345)#-##-##',
  '8(34346)#-##-##',
  '8(34347)#-##-##',
  '8(34349)#-##-##',
  '8(3435)##-##-##',
  '8(34350)#-##-##',
  '8(34355)#-##-##',
  '8(34356)#-##-##',
  '8(34357)#-##-##',
  '8(34358)#-##-##',
  '8(34360)#-##-##',
  '8(34361)#-##-##',
  '8(34362)#-##-##',
  '8(34363)#-##-##',
  '8(34364)#-##-##',
  '8(34365)#-##-##',
  '8(34367)#-##-##',
  '8(34368)#-##-##',
  '8(34369)#-##-##',
  '8(34370)#-##-##',
  '8(34371)#-##-##',
  '8(34372)#-##-##',
  '8(34373)#-##-##',
  '8(34374)#-##-##',
  '8(34375)#-##-##',
  '8(34376)#-##-##',
  '8(34377)#-##-##',
  '8(34380)#-##-##',
  '8(34383)#-##-##',
  '8(34383)5-##-##',
  '8(34384)#-##-##',
  '8(34385)#-##-##',
  '8(34386)#-##-##',
  '8(34387)#-##-##',
  '8(34388)#-##-##',
  '8(34389)#-##-##',
  '8(3439)2#-##-##',
  '8(3439)3#-##-##',
  '8(3439)54-##-##',
  '8(3439)6#-##-##',
  '8(34391)#-##-##',
  '8(34394)#-##-##',
  '8(34395)#-##-##',
  '8(34397)#-##-##',
  '8(34398)#-##-##',
  '8(345)###-##-##',
  '8(3452)##-##-##',
  '8(34531)#-##-##',
  '8(34533)#-##-##',
  '8(34535)#-##-##',
  '8(34537)#-##-##',
  '8(34539)#-##-##',
  '8(34541)#-##-##',
  '8(34542)#-##-##',
  '8(34543)#-##-##',
  '8(34544)#-##-##',
  '8(34545)#-##-##',
  '8(34546)#-##-##',
  '8(34547)#-##-##',
  '8(34550)#-##-##',
  '8(34551)#-##-##',
  '8(34553)#-##-##',
  '8(34554)#-##-##',
  '8(34555)#-##-##',
  '8(34556)#-##-##',
  '8(34557)#-##-##',
  '8(3456)##-##-##',
  '8(34561)#-##-##',
  '8(346)###-##-##',
  '8(3462)##-##-##',
  '8(3463)##-##-##',
  '8(34634)#-##-##',
  '8(34638)#-##-##',
  '8(34643)#-##-##',
  '8(3466)##-##-##',
  '8(34667)#-##-##',
  '8(34668)#-##-##',
  '8(34669)#-##-##',
  '8(34670)#-##-##',
  '8(34672)#-##-##',
  '8(34673)#-##-##',
  '8(34674)#-##-##',
  '8(34675)#-##-##',
  '8(34676)#-##-##',
  '8(34677)#-##-##',
  '8(34678)#-##-##',
  '8(347)###-##-##',
  '8(347)2##-##-##',
  '8(3473)##-##-##',
  '8(34731)#-##-##',
  '8(34739)#-##-##',
  '8(34740)#-##-##',
  '8(34741)#-##-##',
  '8(34742)#-##-##',
  '8(34743)#-##-##',
  '8(34744)#-##-##',
  '8(34745)#-##-##',
  '8(34746)#-##-##',
  '8(34747)#-##-##',
  '8(34748)#-##-##',
  '8(34749)#-##-##',
  '8(34750)#-##-##',
  '8(34751)#-##-##',
  '8(34752)#-##-##',
  '8(34753)#-##-##',
  '8(34754)#-##-##',
  '8(34755)#-##-##',
  '8(34756)#-##-##',
  '8(34757)#-##-##',
  '8(34758)#-##-##',
  '8(34759)#-##-##',
  '8(34760)#-##-##',
  '8(34761)#-##-##',
  '8(34762)#-##-##',
  '8(34763)#-##-##',
  '8(34764)#-##-##',
  '8(34765)#-##-##',
  '8(34766)#-##-##',
  '8(34767)#-##-##',
  '8(34768)#-##-##',
  '8(34769)#-##-##',
  '8(34770)#-##-##',
  '8(34771)#-##-##',
  '8(34772)#-##-##',
  '8(34773)#-##-##',
  '8(34774)#-##-##',
  '8(34775)#-##-##',
  '8(34776)#-##-##',
  '8(34777)#-##-##',
  '8(34778)#-##-##',
  '8(34779)#-##-##',
  '8(34780)#-##-##',
  '8(34781)#-##-##',
  '8(34782)#-##-##',
  '8(34783)#-##-##',
  '8(34784)#-##-##',
  '8(34785)#-##-##',
  '8(34786)#-##-##',
  '8(34787)#-##-##',
  '8(34788)#-##-##',
  '8(34789)#-##-##',
  '8(34791)#-##-##',
  '8(34792)#-##-##',
  '8(34794)#-##-##',
  '8(34795)#-##-##',
  '8(34796)#-##-##',
  '8(34797)#-##-##',
  '8(34798)#-##-##',
  '8(349)###-##-##',
  '8(34922)#-##-##',
  '8(34932)#-##-##',
  '8(34934)#-##-##',
  '8(34936)#-##-##',
  '8(34938)#-##-##',
  '8(3494)##-##-##',
  '8(34940)#-##-##',
  '8(34948)#-##-##',
  '8(34949)#-##-##',
  '8(3496)##-##-##',
  '8(34992)#-##-##',
  '8(34993)#-##-##',
  '8(34994)#-##-##',
  '8(34995)#-##-##',
  '8(34996)#-##-##',
  '8(34997)#-##-##',
  '8(351)###-##-##',
  '8(351)2##-##-##',
  '8(351)301-##-##',
  '8(351)7##-##-##',
  '8(351)90#-##-##',
  '8(3513)2#-##-##',
  '8(3513)5#-##-##',
  '8(3513)6#-##-##',
  '8(3513)7#-##-##',
  '8(35130)2-##-##',
  '8(35130)4-##-##',
  '8(35130)6-##-##',
  '8(35130)7-##-##',
  '8(35130)9-##-##',
  '8(35131)#-##-##',
  '8(35133)#-##-##',
  '8(35134)#-##-##',
  '8(35138)#-##-##',
  '8(35139)#-##-##',
  '8(35140)#-##-##',
  '8(35141)#-##-##',
  '8(35142)#-##-##',
  '8(35143)#-##-##',
  '8(35144)#-##-##',
  '8(35145)#-##-##',
  '8(35146)#-##-##',
  '8(35147)#-##-##',
  '8(35148)#-##-##',
  '8(35149)#-##-##',
  '8(35150)#-##-##',
  '8(35151)#-##-##',
  '8(35152)#-##-##',
  '8(35153)#-##-##',
  '8(35154)#-##-##',
  '8(35155)#-##-##',
  '8(35156)#-##-##',
  '8(35157)#-##-##',
  '8(35158)#-##-##',
  '8(35159)#-##-##',
  '8(35160)#-##-##',
  '8(35161)#-##-##',
  '8(35163)#-##-##',
  '8(35164)#-##-##',
  '8(35165)#-##-##',
  '8(35166)#-##-##',
  '8(35167)#-##-##',
  '8(35168)#-##-##',
  '8(35169)#-##-##',
  '8(3519)##-##-##',
  '8(35191)#-##-##',
  '8(352)###-##-##',
  '8(3522)##-##-##',
  '8(35230)#-##-##',
  '8(35231)#-##-##',
  '8(35232)#-##-##',
  '8(35233)#-##-##',
  '8(35234)#-##-##',
  '8(35235)#-##-##',
  '8(35236)#-##-##',
  '8(35237)#-##-##',
  '8(35238)#-##-##',
  '8(35239)#-##-##',
  '8(35240)#-##-##',
  '8(35241)#-##-##',
  '8(35242)#-##-##',
  '8(35243)#-##-##',
  '8(35244)#-##-##',
  '8(35245)#-##-##',
  '8(35247)#-##-##',
  '8(35248)#-##-##',
  '8(35249)#-##-##',
  '8(35251)#-##-##',
  '8(35252)#-##-##',
  '8(35253)#-##-##',
  '8(35256)#-##-##',
  '8(35257)#-##-##',
  '8(353)###-##-##',
  '8(3532)##-##-##',
  '8(35330)#-##-##',
  '8(35331)#-##-##',
  '8(35332)#-##-##',
  '8(35333)#-##-##',
  '8(35334)#-##-##',
  '8(35335)#-##-##',
  '8(35336)#-##-##',
  '8(35337)#-##-##',
  '8(35338)#-##-##',
  '8(35339)#-##-##',
  '8(35341)#-##-##',
  '8(35342)#-##-##',
  '8(35344)#-##-##',
  '8(35345)#-##-##',
  '8(35346)#-##-##',
  '8(35347)#-##-##',
  '8(35348)#-##-##',
  '8(35349)#-##-##',
  '8(35351)#-##-##',
  '8(35352)#-##-##',
  '8(35354)#-##-##',
  '8(35355)#-##-##',
  '8(35356)#-##-##',
  '8(35357)#-##-##',
  '8(35358)#-##-##',
  '8(35359)#-##-##',
  '8(35361)#-##-##',
  '8(35362)#-##-##',
  '8(35363)#-##-##',
  '8(35364)#-##-##',
  '8(35365)#-##-##',
  '8(35366)#-##-##',
  '8(35367)#-##-##',
  '8(35368)#-##-##',
  '8(3537)2#-##-##',
  '8(3537)3#-##-##',
  '8(3537)4#-##-##',
  '8(3537)6#-##-##',
  '8(35379)#-##-##',
  '8(381)###-##-##',
  '8(3812)##-##-##',
  '8(38141)#-##-##',
  '8(38150)#-##-##',
  '8(38151)#-##-##',
  '8(38152)#-##-##',
  '8(38153)#-##-##',
  '8(38154)#-##-##',
  '8(38155)#-##-##',
  '8(38156)#-##-##',
  '8(38157)#-##-##',
  '8(38158)#-##-##',
  '8(38159)#-##-##',
  '8(38160)#-##-##',
  '8(38161)#-##-##',
  '8(38162)#-##-##',
  '8(38163)#-##-##',
  '8(38164)#-##-##',
  '8(38165)#-##-##',
  '8(38166)#-##-##',
  '8(38167)#-##-##',
  '8(38168)#-##-##',
  '8(38169)#-##-##',
  '8(38170)#-##-##',
  '8(38171)#-##-##',
  '8(38172)#-##-##',
  '8(38173)#-##-##',
  '8(38174)#-##-##',
  '8(38175)#-##-##',
  '8(38176)#-##-##',
  '8(38177)#-##-##',
  '8(38178)#-##-##',
  '8(38179)#-##-##',
  '8(382)###-##-##',
  '8(3822)##-##-##',
  '8(3823)##-##-##',
  '8(38241)#-##-##',
  '8(38243)#-##-##',
  '8(38244)#-##-##',
  '8(38245)#-##-##',
  '8(38246)#-##-##',
  '8(38247)#-##-##',
  '8(38249)#-##-##',
  '8(38250)#-##-##',
  '8(38251)#-##-##',
  '8(38252)#-##-##',
  '8(38253)#-##-##',
  '8(38254)#-##-##',
  '8(38255)#-##-##',
  '8(38256)#-##-##',
  '8(38257)#-##-##',
  '8(38258)#-##-##',
  '8(38259)#-##-##',
  '8(383)###-##-##',
  '8(38340)#-##-##',
  '8(38341)#-##-##',
  '8(38343)#-##-##',
  '8(38345)#-##-##',
  '8(38346)#-##-##',
  '8(38347)#-##-##',
  '8(38348)#-##-##',
  '8(38349)#-##-##',
  '8(38350)#-##-##',
  '8(38351)#-##-##',
  '8(38352)#-##-##',
  '8(38353)#-##-##',
  '8(38354)#-##-##',
  '8(38355)#-##-##',
  '8(38356)#-##-##',
  '8(38357)#-##-##',
  '8(38358)#-##-##',
  '8(38359)#-##-##',
  '8(38360)#-##-##',
  '8(38361)#-##-##',
  '8(38362)#-##-##',
  '8(38363)#-##-##',
  '8(38364)#-##-##',
  '8(38365)#-##-##',
  '8(38366)#-##-##',
  '8(38367)#-##-##',
  '8(38368)#-##-##',
  '8(38369)#-##-##',
  '8(38371)#-##-##',
  '8(38372)#-##-##',
  '8(38373)#-##-##',
  '8(384)###-##-##',
  '8(3842)##-##-##',
  '8(3843)##-##-##',
  '8(38441)#-##-##',
  '8(38442)#-##-##',
  '8(38443)#-##-##',
  '8(38444)#-##-##',
  '8(38445)#-##-##',
  '8(38446)#-##-##',
  '8(38447)#-##-##',
  '8(38448)#-##-##',
  '8(38449)#-##-##',
  '8(38451)#-##-##',
  '8(38452)#-##-##',
  '8(38453)#-##-##',
  '8(38454)#-##-##',
  '8(38455)#-##-##',
  '8(38456)#-##-##',
  '8(38459)#-##-##',
  '8(3846)##-##-##',
  '8(38463)#-##-##',
  '8(38464)#-##-##',
  '8(38471)#-##-##',
  '8(38472)#-##-##',
  '8(38473)#-##-##',
  '8(38474)#-##-##',
  '8(38475)#-##-##',
  '8(385)###-##-##',
  '8(3852)##-##-##',
  '8(38530)#-##-##',
  '8(38531)#-##-##',
  '8(38532)#-##-##',
  '8(38533)#-##-##',
  '8(38534)#-##-##',
  '8(38535)#-##-##',
  '8(38536)#-##-##',
  '8(38537)#-##-##',
  '8(38538)#-##-##',
  '8(38539)#-##-##',
  '8(3854)##-##-##',
  '8(38550)#-##-##',
  '8(38551)#-##-##',
  '8(38552)#-##-##',
  '8(38553)#-##-##',
  '8(38554)#-##-##',
  '8(38555)#-##-##',
  '8(38556)#-##-##',
  '8(38557)#-##-##',
  '8(38558)#-##-##',
  '8(38559)#-##-##',
  '8(38560)#-##-##',
  '8(38561)#-##-##',
  '8(38562)#-##-##',
  '8(38563)#-##-##',
  '8(38564)#-##-##',
  '8(38565)#-##-##',
  '8(38566)#-##-##',
  '8(38567)#-##-##',
  '8(38568)#-##-##',
  '8(38569)#-##-##',
  '8(38570)#-##-##',
  '8(38571)#-##-##',
  '8(38572)#-##-##',
  '8(38573)#-##-##',
  '8(38574)#-##-##',
  '8(38575)#-##-##',
  '8(38576)#-##-##',
  '8(38577)#-##-##',
  '8(38578)#-##-##',
  '8(38579)#-##-##',
  '8(38580)#-##-##',
  '8(38581)#-##-##',
  '8(38582)#-##-##',
  '8(38583)#-##-##',
  '8(38584)#-##-##',
  '8(38585)#-##-##',
  '8(38586)#-##-##',
  '8(38587)#-##-##',
  '8(38588)#-##-##',
  '8(38589)#-##-##',
  '8(38590)#-##-##',
  '8(38591)#-##-##',
  '8(38592)#-##-##',
  '8(38593)#-##-##',
  '8(38594)#-##-##',
  '8(38595)#-##-##',
  '8(38596)#-##-##',
  '8(38597)#-##-##',
  '8(38598)#-##-##',
  '8(38599)#-##-##',
  '8(388)###-##-##',
  '8(3882)##-##-##',
  '8(38840)#-##-##',
  '8(38841)#-##-##',
  '8(38842)#-##-##',
  '8(38843)#-##-##',
  '8(38844)#-##-##',
  '8(38845)#-##-##',
  '8(38846)#-##-##',
  '8(38847)#-##-##',
  '8(38848)#-##-##',
  '8(38849)#-##-##',
  '8(390)###-##-##',
  '8(3902)##-##-##',
  '8(39031)#-##-##',
  '8(39032)#-##-##',
  '8(39033)#-##-##',
  '8(39034)#-##-##',
  '8(39035)#-##-##',
  '8(39036)#-##-##',
  '8(39041)#-##-##',
  '8(39042)#-##-##',
  '8(39044)#-##-##',
  '8(39045)#-##-##',
  '8(39046)#-##-##',
  '8(39047)#-##-##',
  '8(391)###-##-##',
  '8(391)2##-##-##',
  '8(39131)#-##-##',
  '8(39132)#-##-##',
  '8(39133)#-##-##',
  '8(39134)#-##-##',
  '8(39135)#-##-##',
  '8(39136)#-##-##',
  '8(39137)#-##-##',
  '8(39138)#-##-##',
  '8(39139)#-##-##',
  '8(39140)#-##-##',
  '8(39141)#-##-##',
  '8(39142)#-##-##',
  '8(39143)#-##-##',
  '8(39144)#-##-##',
  '8(39145)#-##-##',
  '8(39146)#-##-##',
  '8(39147)#-##-##',
  '8(39148)#-##-##',
  '8(39149)#-##-##',
  '8(39150)#-##-##',
  '8(39151)#-##-##',
  '8(39152)#-##-##',
  '8(39153)#-##-##',
  '8(39154)#-##-##',
  '8(39155)#-##-##',
  '8(39156)#-##-##',
  '8(39157)#-##-##',
  '8(39158)#-##-##',
  '8(39159)#-##-##',
  '8(39160)#-##-##',
  '8(39161)#-##-##',
  '8(39162)#-##-##',
  '8(39163)#-##-##',
  '8(39164)#-##-##',
  '8(39165)#-##-##',
  '8(39166)#-##-##',
  '8(39167)#-##-##',
  '8(39168)#-##-##',
  '8(39169)#-##-##',
  '8(39170)#-##-##',
  '8(39171)#-##-##',
  '8(39172)#-##-##',
  '8(39173)#-##-##',
  '8(39174)#-##-##',
  '8(39175)#-##-##',
  '8(39176)#-##-##',
  '8(39177)#-##-##',
  '8(39178)#-##-##',
  '8(39179)#-##-##',
  '8(3919)2#-##-##',
  '8(3919)4#-##-##',
  '8(39190)#-##-##',
  '8(39191)#-##-##',
  '8(39193)#-##-##',
  '8(39195)#-##-##',
  '8(39196)#-##-##',
  '8(39197)#-##-##',
  '8(39198)#-##-##',
  '8(39199)#-##-##',
  '8(394)###-##-##',
  '8(3942)##-##-##',
  '8(39432)#-##-##',
  '8(39433)#-##-##',
  '8(39434)#-##-##',
  '8(39435)#-##-##',
  '8(39436)#-##-##',
  '8(39437)#-##-##',
  '8(39438)#-##-##',
  '8(39439)#-##-##',
  '8(39441)#-##-##',
  '8(39442)#-##-##',
  '8(39444)#-##-##',
  '8(39445)#-##-##',
  '8(39450)#-##-##',
  '8(39451)#-##-##',
  '8(395)###-##-##',
  '8(3952)##-##-##',
  '8(3953)##-##-##',
  '8(39530)#-##-##',
  '8(39535)#-##-##',
  '8(39536)#-##-##',
  '8(39537)#-##-##',
  '8(39538)#-##-##',
  '8(39539)#-##-##',
  '8(39540)#-##-##',
  '8(39541)#-##-##',
  '8(39542)#-##-##',
  '8(39543)#-##-##',
  '8(39544)#-##-##',
  '8(39545)#-##-##',
  '8(39546)#-##-##',
  '8(39548)#-##-##',
  '8(39549)#-##-##',
  '8(3955)##-##-##',
  '8(39550)#-##-##',
  '8(39551)#-##-##',
  '8(39552)#-##-##',
  '8(39553)#-##-##',
  '8(39554)#-##-##',
  '8(39557)#-##-##',
  '8(39558)#-##-##',
  '8(39559)#-##-##',
  '8(39560)#-##-##',
  '8(39561)#-##-##',
  '8(39562)#-##-##',
  '8(39563)#-##-##',
  '8(39564)#-##-##',
  '8(39565)#-##-##',
  '8(39566)#-##-##',
  '8(39567)#-##-##',
  '8(39568)#-##-##',
  '8(39569)#-##-##',
  '8(401)###-##-##',
  '8(4012)##-##-##',
  '8(40141)#-##-##',
  '8(40142)#-##-##',
  '8(40143)#-##-##',
  '8(40144)#-##-##',
  '8(40145)#-##-##',
  '8(40150)#-##-##',
  '8(40151)#-##-##',
  '8(40152)#-##-##',
  '8(40153)#-##-##',
  '8(40155)#-##-##',
  '8(40156)#-##-##',
  '8(40157)#-##-##',
  '8(40158)#-##-##',
  '8(40159)#-##-##',
  '8(40161)#-##-##',
  '8(40162)#-##-##',
  '8(40163)#-##-##',
  '8(40164)#-##-##',
  '8(411)###-##-##',
  '8(4112)##-##-##',
  '8(41131)#-##-##',
  '8(41132)#-##-##',
  '8(41133)#-##-##',
  '8(41134)#-##-##',
  '8(41135)#-##-##',
  '8(41136)#-##-##',
  '8(41137)#-##-##',
  '8(41138)#-##-##',
  '8(41140)#-##-##',
  '8(41141)#-##-##',
  '8(41142)#-##-##',
  '8(41143)#-##-##',
  '8(41144)#-##-##',
  '8(41145)#-##-##',
  '8(41147)#-##-##',
  '8(41150)#-##-##',
  '8(41151)#-##-##',
  '8(41152)#-##-##',
  '8(41153)#-##-##',
  '8(41154)#-##-##',
  '8(41155)#-##-##',
  '8(41156)#-##-##',
  '8(41157)#-##-##',
  '8(41158)#-##-##',
  '8(41159)#-##-##',
  '8(41160)#-##-##',
  '8(41161)#-##-##',
  '8(41162)#-##-##',
  '8(41163)#-##-##',
  '8(41164)#-##-##',
  '8(41165)#-##-##',
  '8(41166)#-##-##',
  '8(41167)#-##-##',
  '8(41168)#-##-##',
  '8(41169)#-##-##',
  '8(413)###-##-##',
  '8(4132)##-##-##',
  '8(41341)#-##-##',
  '8(41342)#-##-##',
  '8(41343)#-##-##',
  '8(41344)#-##-##',
  '8(41345)#-##-##',
  '8(41346)#-##-##',
  '8(41347)#-##-##',
  '8(41348)#-##-##',
  '8(415)###-##-##',
  '8(4152)##-##-##',
  '8(41531)#-##-##',
  '8(41532)#-##-##',
  '8(41533)#-##-##',
  '8(41534)#-##-##',
  '8(41535)#-##-##',
  '8(41536)#-##-##',
  '8(41537)#-##-##',
  '8(41538)#-##-##',
  '8(41539)#-##-##',
  '8(41541)#-##-##',
  '8(41542)#-##-##',
  '8(41543)#-##-##',
  '8(41544)#-##-##',
  '8(41545)#-##-##',
  '8(41546)#-##-##',
  '8(41547)#-##-##',
  '8(41548)#-##-##',
  '8(416)###-##-##',
  '8(4162)##-##-##',
  '8(41631)2-0#-##',
  '8(41632)3-0#-##',
  '8(41633)3-0#-##',
  '8(41634)#-##-##',
  '8(41637)#-##-##',
  '8(41638)#-##-##',
  '8(41639)#-##-##',
  '8(41641)#-##-##',
  '8(41642)#-##-##',
  '8(41643)#-##-##',
  '8(41644)#-##-##',
  '8(41645)#-##-##',
  '8(41646)#-##-##',
  '8(41647)#-##-##',
  '8(41648)#-##-##',
  '8(41649)#-##-##',
  '8(41651)#-##-##',
  '8(41652)#-##-##',
  '8(41653)#-##-##',
  '8(41654)#-##-##',
  '8(41655)#-##-##',
  '8(41656)#-##-##',
  '8(41658)#-##-##',
  '8(421)###-##-##',
  '8(4212)##-##-##',
  '8(42135)#-##-##',
  '8(41636)#-##-##',
  '8(41636)#-##-##',
  '8(41636)#-##-##',
  '8(42137)#-##-##',
  '8(42138)#-##-##',
  '8(42141)#-##-##',
  '8(42142)#-##-##',
  '8(42143)#-##-##',
  '8(42144)#-##-##',
  '8(42146)#-##-##',
  '8(42147)#-##-##',
  '8(42149)#-##-##',
  '8(42151)#-##-##',
  '8(42153)#-##-##',
  '8(42154)#-##-##',
  '8(42155)#-##-##',
  '8(42156)#-##-##',
  '8(4217)##-##-##',
  '8(423)###-##-##',
  '8(42331)#-##-##',
  '8(42334)#-##-##',
  '8(42335)#-##-##',
  '8(42337)#-##-##',
  '8(42339)#-##-##',
  '8(4234)##-##-##',
  '8(42344)#-##-##',
  '8(42345)#-##-##',
  '8(42346)#-##-##',
  '8(42347)#-##-##',
  '8(42349)#-##-##',
  '8(42351)#-##-##',
  '8(42352)#-##-##',
  '8(42354)#-##-##',
  '8(42355)#-##-##',
  '8(42356)#-##-##',
  '8(42357)#-##-##',
  '8(42359)#-##-##',
  '8(4236)##-##-##',
  '8(42361)#-##-##',
  '8(42362)#-##-##',
  '8(42363)#-##-##',
  '8(42365)#-##-##',
  '8(42371)#-##-##',
  '8(42372)#-##-##',
  '8(42373)#-##-##',
  '8(42374)#-##-##',
  '8(42375)#-##-##',
  '8(42376)#-##-##',
  '8(42377)#-##-##',
  '8(424)###-##-##',
  '8(4242)##-##-##',
  '8(42431)#-##-##',
  '8(42432)#-##-##',
  '8(42433)#-##-##',
  '8(42434)#-##-##',
  '8(42435)#-##-##',
  '8(42436)#-##-##',
  '8(42437)#-##-##',
  '8(42441)#-##-##',
  '8(42442)#-##-##',
  '8(42443)#-##-##',
  '8(42444)#-##-##',
  '8(42446)#-##-##',
  '8(42447)#-##-##',
  '8(42452)#-##-##',
  '8(42453)#-##-##',
  '8(42454)#-##-##',
  '8(42455)#-##-##',
  '8(426)###-##-##',
  '8(42622)#-##-##',
  '8(42632)#-##-##',
  '8(42663)#-##-##',
  '8(42665)#-##-##',
  '8(42666)#-##-##',
  '8(427)###-##-##',
  '8(42722)#-##-##',
  '8(42732)#-##-##',
  '8(42733)#-##-##',
  '8(42734)#-##-##',
  '8(42735)#-##-##',
  '8(42736)#-##-##',
  '8(42737)#-##-##',
  '8(42738)#-##-##',
  '8(42739)#-##-##',
  '8(471)###-##-##',
  '8(4712)##-##-##',
  '8(47131)#-##-##',
  '8(47132)#-##-##',
  '8(47133)#-##-##',
  '8(47134)#-##-##',
  '8(47135)#-##-##',
  '8(47136)#-##-##',
  '8(47137)#-##-##',
  '8(47140)#-##-##',
  '8(47141)#-##-##',
  '8(47142)#-##-##',
  '8(47143)#-##-##',
  '8(47144)#-##-##',
  '8(47145)#-##-##',
  '8(47146)#-##-##',
  '8(47147)#-##-##',
  '8(47148)#-##-##',
  '8(47149)#-##-##',
  '8(47150)#-##-##',
  '8(47151)#-##-##',
  '8(47152)#-##-##',
  '8(47153)#-##-##',
  '8(47154)#-##-##',
  '8(47155)#-##-##',
  '8(47156)#-##-##',
  '8(47157)#-##-##',
  '8(47158)#-##-##',
  '8(47159)#-##-##',
  '8(472)###-##-##',
  '8(4722)##-##-##',
  '8(47231)#-##-##',
  '8(47232)#-##-##',
  '8(47233)#-##-##',
  '8(47234)#-##-##',
  '8(47235)#-##-##',
  '8(47236)#-##-##',
  '8(47237)#-##-##',
  '8(47238)#-##-##',
  '8(47241)#-##-##',
  '8(47242)#-##-##',
  '8(47243)#-##-##',
  '8(47244)#-##-##',
  '8(47245)#-##-##',
  '8(47246)#-##-##',
  '8(47247)#-##-##',
  '8(47248)#-##-##',
  '8(4725)##-##-##',
  '8(47261)#-##-##',
  '8(47262)#-##-##',
  '8(47263)#-##-##',
  '8(473)###-##-##',
  '8(47340)#-##-##',
  '8(47341)#-##-##',
  '8(47342)#-##-##',
  '8(47343)#-##-##',
  '8(47344)#-##-##',
  '8(47345)#-##-##',
  '8(47346)#-##-##',
  '8(47347)#-##-##',
  '8(47348)#-##-##',
  '8(47350)#-##-##',
  '8(47352)#-##-##',
  '8(47353)#-##-##',
  '8(47354)#-##-##',
  '8(47355)#-##-##',
  '8(47356)#-##-##',
  '8(47357)#-##-##',
  '8(47361)#-##-##',
  '8(47362)#-##-##',
  '8(47363)#-##-##',
  '8(47364)#-##-##',
  '8(47365)#-##-##',
  '8(47366)#-##-##',
  '8(47367)#-##-##',
  '8(47370)#-##-##',
  '8(47371)#-##-##',
  '8(47372)#-##-##',
  '8(47374)#-##-##',
  '8(47375)#-##-##',
  '8(47376)#-##-##',
  '8(47391)#-##-##',
  '8(47394)#-##-##',
  '8(47395)#-##-##',
  '8(47396)#-##-##',
  '8(474)###-##-##',
  '8(4742)##-##-##',
  '8(47461)#-##-##',
  '8(47462)#-##-##',
  '8(47463)#-##-##',
  '8(47464)#-##-##',
  '8(47465)#-##-##',
  '8(47466)#-##-##',
  '8(47467)#-##-##',
  '8(47468)#-##-##',
  '8(47469)#-##-##',
  '8(47471)#-##-##',
  '8(47472)#-##-##',
  '8(47473)#-##-##',
  '8(47474)#-##-##',
  '8(47475)#-##-##',
  '8(47476)#-##-##',
  '8(47477)#-##-##',
  '8(47478)#-##-##',
  '8(475)###-##-##',
  '8(4752)##-##-##',
  '8(47531)#-##-##',
  '8(47532)#-##-##',
  '8(47533)#-##-##',
  '8(47534)#-##-##',
  '8(47535)#-##-##',
  '8(47536)#-##-##',
  '8(47537)#-##-##',
  '8(47541)#-##-##',
  '8(47542)#-##-##',
  '8(47543)#-##-##',
  '8(47544)#-##-##',
  '8(47545)#-##-##',
  '8(47546)#-##-##',
  '8(47548)#-##-##',
  '8(47551)#-##-##',
  '8(47552)#-##-##',
  '8(47553)#-##-##',
  '8(47554)#-##-##',
  '8(47555)#-##-##',
  '8(47556)#-##-##',
  '8(47557)#-##-##',
  '8(47558)#-##-##',
  '8(47559)#-##-##',
  '8(481)###-##-##',
  '8(4812)##-##-##',
  '8(48130)#-##-##',
  '8(48131)#-##-##',
  '8(48132)#-##-##',
  '8(48133)#-##-##',
  '8(48134)#-##-##',
  '8(48135)#-##-##',
  '8(48136)#-##-##',
  '8(48137)#-##-##',
  '8(48138)#-##-##',
  '8(48139)#-##-##',
  '8(48140)#-##-##',
  '8(48141)#-##-##',
  '8(48142)#-##-##',
  '8(48143)#-##-##',
  '8(48144)#-##-##',
  '8(48145)#-##-##',
  '8(48146)#-##-##',
  '8(48147)#-##-##',
  '8(48148)#-##-##',
  '8(48149)#-##-##',
  '8(48153)#-##-##',
  '8(48155)#-##-##',
  '8(48165)#-##-##',
  '8(48166)#-##-##',
  '8(48167)#-##-##',
  '8(482)###-##-##',
  '8(4822)##-##-##',
  '8(48230)#-##-##',
  '8(48231)#-##-##',
  '8(48232)#-##-##',
  '8(48233)#-##-##',
  '8(48234)#-##-##',
  '8(48235)#-##-##',
  '8(48236)#-##-##',
  '8(48237)#-##-##',
  '8(48238)#-##-##',
  '8(48239)#-##-##',
  '8(48242)#-##-##',
  '8(48244)#-##-##',
  '8(48246)#-##-##',
  '8(48249)#-##-##',
  '8(48250)#-##-##',
  '8(48251)#-##-##',
  '8(48253)#-##-##',
  '8(48255)#-##-##',
  '8(48257)#-##-##',
  '8(48258)#-##-##',
  '8(48261)#-##-##',
  '8(48262)#-##-##',
  '8(48263)#-##-##',
  '8(48264)#-##-##',
  '8(48265)#-##-##',
  '8(48266)#-##-##',
  '8(48267)#-##-##',
  '8(48268)#-##-##',
  '8(48269)#-##-##',
  '8(48271)#-##-##',
  '8(48272)#-##-##',
  '8(48273)#-##-##',
  '8(48274)#-##-##',
  '8(48275)#-##-##',
  '8(48276)#-##-##',
  '8(483)###-##-##',
  '8(4832)##-##-##',
  '8(48330)#-##-##',
  '8(48331)#-##-##',
  '8(48332)#-##-##',
  '8(48333)#-##-##',
  '8(48334)#-##-##',
  '8(48335)#-##-##',
  '8(48336)#-##-##',
  '8(48338)#-##-##',
  '8(48339)#-##-##',
  '8(48340)#-##-##',
  '8(48341)#-##-##',
  '8(48342)#-##-##',
  '8(48343)#-##-##',
  '8(48344)#-##-##',
  '8(48345)#-##-##',
  '8(48346)#-##-##',
  '8(48347)#-##-##',
  '8(48348)#-##-##',
  '8(48349)#-##-##',
  '8(48351)#-##-##',
  '8(48352)#-##-##',
  '8(48353)#-##-##',
  '8(48354)#-##-##',
  '8(48355)#-##-##',
  '8(48356)#-##-##',
  '8(48358)#-##-##',
  '8(484)###-##-##',
  '8(4842)##-##-##',
  '8(48431)#-##-##',
  '8(48432)#-##-##',
  '8(48433)#-##-##',
  '8(48434)#-##-##',
  '8(48435)#-##-##',
  '8(48436)#-##-##',
  '8(48437)#-##-##',
  '8(48438)2-##-##',
  '8(48438)4-##-##',
  '8(48438)6-##-##',
  '8(48439)#-##-##',
  '8(48441)#-##-##',
  '8(48442)#-##-##',
  '8(48443)#-##-##',
  '8(48444)#-##-##',
  '8(48445)#-##-##',
  '8(48446)#-##-##',
  '8(48447)#-##-##',
  '8(48448)#-##-##',
  '8(48449)#-##-##',
  '8(48451)#-##-##',
  '8(48452)#-##-##',
  '8(48453)#-##-##',
  '8(48454)#-##-##',
  '8(48455)#-##-##',
  '8(48456)#-##-##',
  '8(48457)#-##-##',
  '8(485)###-##-##',
  '8(4852)##-##-##',
  '8(48531)#-##-##',
  '8(48532)#-##-##',
  '8(48533)#-##-##',
  '8(48534)#-##-##',
  '8(48535)#-##-##',
  '8(48536)#-##-##',
  '8(48538)#-##-##',
  '8(48539)#-##-##',
  '8(48542)#-##-##',
  '8(48543)#-##-##',
  '8(48544)#-##-##',
  '8(48545)#-##-##',
  '8(48546)#-##-##',
  '8(48547)#-##-##',
  '8(48549)#-##-##',
  '8(4855)##-##-##',
  '8(486)###-##-##',
  '8(4862)##-##-##',
  '8(48640)#-##-##',
  '8(48642)#-##-##',
  '8(48643)#-##-##',
  '8(48644)#-##-##',
  '8(48645)#-##-##',
  '8(48646)#-##-##',
  '8(48647)#-##-##',
  '8(48648)#-##-##',
  '8(48649)#-##-##',
  '8(48661)#-##-##',
  '8(48662)#-##-##',
  '8(48663)#-##-##',
  '8(48664)#-##-##',
  '8(48665)#-##-##',
  '8(48666)#-##-##',
  '8(48667)#-##-##',
  '8(48672)#-##-##',
  '8(48673)#-##-##',
  '8(48674)#-##-##',
  '8(48675)#-##-##',
  '8(48676)#-##-##',
  '8(48677)#-##-##',
  '8(48678)#-##-##',
  '8(48679)#-##-##',
  '8(487)###-##-##',
  '8(4872)##-##-##',
  '8(48731)#-##-##',
  '8(48732)#-##-##',
  '8(48733)#-##-##',
  '8(48734)#-##-##',
  '8(48735)#-##-##',
  '8(48736)#-##-##',
  '8(48741)#-##-##',
  '8(48742)#-##-##',
  '8(48743)#-##-##',
  '8(48744)#-##-##',
  '8(48745)#-##-##',
  '8(48746)#-##-##',
  '8(48751)#-##-##',
  '8(48752)#-##-##',
  '8(48753)#-##-##',
  '8(48754)#-##-##',
  '8(48755)#-##-##',
  '8(48756)#-##-##',
  '8(48761)#-##-##',
  '8(48762)#-##-##',
  '8(48763)#-##-##',
  '8(48766)#-##-##',
  '8(48767)#-##-##',
  '8(48768)#-##-##',
  '8(491)###-##-##',
  '8(4912)##-##-##',
  '8(49130)#-##-##',
  '8(49131)#-##-##',
  '8(49132)#-##-##',
  '8(49133)#-##-##',
  '8(49135)#-##-##',
  '8(49136)#-##-##',
  '8(49137)#-##-##',
  '8(49138)#-##-##',
  '8(49139)#-##-##',
  '8(49141)#-##-##',
  '8(49142)#-##-##',
  '8(49143)#-##-##',
  '8(49144)#-##-##',
  '8(49145)#-##-##',
  '8(49146)#-##-##',
  '8(49147)#-##-##',
  '8(49148)#-##-##',
  '8(49151)#-##-##',
  '8(49152)#-##-##',
  '8(49153)#-##-##',
  '8(49154)#-##-##',
  '8(49155)#-##-##',
  '8(49156)#-##-##',
  '8(49157)#-##-##',
  '8(49158)#-##-##',
  '8(492)###-##-##',
  '8(4922)##-##-##',
  '8(49231)#-##-##',
  '8(49232)#-##-##',
  '8(49233)#-##-##',
  '8(49234)#-##-##',
  '8(49235)#-##-##',
  '8(49236)#-##-##',
  '8(49237)#-##-##',
  '8(49238)#-##-##',
  '8(49241)#-##-##',
  '8(49242)#-##-##',
  '8(49243)2-##-##',
  '8(49243)6-##-##',
  '8(49244)#-##-##',
  '8(49245)#-##-##',
  '8(49246)#-##-##',
  '8(49247)#-##-##',
  '8(49248)#-##-##',
  '8(49254)#-##-##',
  '8(493)###-##-##',
  '8(4932)##-##-##',
  '8(49331)#-##-##',
  '8(49333)#-##-##',
  '8(49334)#-##-##',
  '8(49336)#-##-##',
  '8(49337)#-##-##',
  '8(49339)#-##-##',
  '8(49341)#-##-##',
  '8(49343)#-##-##',
  '8(49344)#-##-##',
  '8(49345)#-##-##',
  '8(49346)#-##-##',
  '8(49347)#-##-##',
  '8(49349)#-##-##',
  '8(49351)#-##-##',
  '8(49352)#-##-##',
  '8(49353)#-##-##',
  '8(49354)#-##-##',
  '8(49355)#-##-##',
  '8(49356)#-##-##',
  '8(49357)#-##-##',
  '8(494)###-##-##',
  '8(4942)##-##-##',
  '8(49430)#-##-##',
  '8(49431)#-##-##',
  '8(49432)#-##-##',
  '8(49433)#-##-##',
  '8(49434)#-##-##',
  '8(49435)#-##-##',
  '8(49436)#-##-##',
  '8(49437)#-##-##',
  '8(49438)#-##-##',
  '8(49439)#-##-##',
  '8(49440)#-##-##',
  '8(49441)#-##-##',
  '8(49442)#-##-##',
  '8(49443)#-##-##',
  '8(49444)#-##-##',
  '8(49445)#-##-##',
  '8(49446)#-##-##',
  '8(49447)#-##-##',
  '8(49448)#-##-##',
  '8(49449)#-##-##',
  '8(49450)#-##-##',
  '8(49451)#-##-##',
  '8(49452)#-##-##',
  '8(49453)#-##-##',
  '8(495)###-##-##',
  '8(495)323-8#-##',
  '8(495)323-9#-##',
  '8(495)338-##-##',
  '8(495)339-##-##',
  '8(495)355-9#-##',
  '8(495)408-##-##',
  '8(495)439-##-##',
  '8(495)50#-##-##',
  '8(495)500-##-##',
  '8(495)51#-##-##',
  '8(495)52#-##-##',
  '8(495)541-##-##',
  '8(495)542-##-##',
  '8(495)543-##-##',
  '8(495)544-##-##',
  '8(495)545-##-##',
  '8(495)546-##-##',
  '8(495)546-1#-##',
  '8(495)546-6#-##',
  '8(495)546-8#-##',
  '8(495)548-0#-##',
  '8(495)548-1#-##',
  '8(495)548-4#-##',
  '8(495)548-5#-##',
  '8(495)548-6#-##',
  '8(495)548-7#-##',
  '8(495)548-8#-##',
  '8(495)548-9#-##',
  '8(495)549-##-##',
  '8(495)55#-##-##',
  '8(495)552-##-##',
  '8(495)555-##-##',
  '8(495)56#-##-##',
  '8(495)57#-##-##',
  '8(495)573-##-##',
  '8(495)576-##-##',
  '8(495)577-##-##',
  '8(495)578-##-##',
  '8(495)579-##-##',
  '8(495)58#-##-##',
  '8(495)585-##-##',
  '8(495)589-##-##',
  '8(495)59#-##-##',
  '8(495)597-##-##',
  '8(496)###-##-##',
  '8(496)20#-##-##',
  '8(496)21#-##-##',
  '8(496)22#-##-##',
  '8(496)24#-##-##',
  '8(496)25#-##-##',
  '8(496)26#-##-##',
  '8(496)27#-##-##',
  '8(496)28#-##-##',
  '8(496)30#-##-##',
  '8(496)31#-##-##',
  '8(496)34#-##-##',
  '8(496)36#-##-##',
  '8(496)37#-##-##',
  '8(496)38#-##-##',
  '8(496)40#-##-##',
  '8(496)41#-##-##',
  '8(496)42#-##-##',
  '8(496)43#-##-##',
  '8(496)44#-##-##',
  '8(496)45#-##-##',
  '8(496)46#-##-##',
  '8(496)51#-##-##',
  '8(496)52#-##-##',
  '8(496)53#-##-##',
  '8(496)54#-##-##',
  '8(496)55#-##-##',
  '8(496)56#-##-##',
  '8(496)57#-##-##',
  '8(496)61#-##-##',
  '8(496)63#-##-##',
  '8(496)64#-##-##',
  '8(496)66#-##-##',
  '8(496)67#-##-##',
  '8(496)69#-##-##',
  '8(496)70#-##-##',
  '8(496)72#-##-##',
  '8(496)73#-##-##',
  '8(496)75#-##-##',
  '8(496)76#-##-##',
  '8(496)77#-##-##',
  '8(496)79#-##-##',
  '8(498)###-##-##',
  '8(498)48#-##-##',
  '8(498)54#-##-##',
  '8(498)617-##-##',
  '8(498)657-##-##',
  '8(498)664-##-##',
  '8(498)68#-##-##',
  '8(498)713-##-##',
  '8(498)744-##-##',
  '8(499)###-##-##',
  '8(499)39#-##-##',
  '8(499)50#-##-##',
  '8(499)755-##-##',
  '8(811)###-##-##',
  '8(8112)##-##-##',
  '8(81131)#-##-##',
  '8(81132)#-##-##',
  '8(81133)#-##-##',
  '8(81134)#-##-##',
  '8(81135)#-##-##',
  '8(81136)#-##-##',
  '8(81137)#-##-##',
  '8(81138)#-##-##',
  '8(81139)#-##-##',
  '8(81140)#-##-##',
  '8(81141)#-##-##',
  '8(81142)#-##-##',
  '8(81143)#-##-##',
  '8(81144)#-##-##',
  '8(81145)#-##-##',
  '8(81146)#-##-##',
  '8(81147)#-##-##',
  '8(81148)#-##-##',
  '8(81149)#-##-##',
  '8(81150)#-##-##',
  '8(81151)#-##-##',
  '8(81152)#-##-##',
  '8(81153)#-##-##',
  '8(812)###-##-##',
  '8(813)###-##-##',
  '8(81361)#-##-##',
  '8(81362)#-##-##',
  '8(81363)#-##-##',
  '8(81364)#-##-##',
  '8(81365)#-##-##',
  '8(81366)#-##-##',
  '8(81367)#-##-##',
  '8(81368)#-##-##',
  '8(81369)#-##-##',
  '8(81370)#-##-##',
  '8(81371)#-##-##',
  '8(81372)#-##-##',
  '8(81373)#-##-##',
  '8(81374)#-##-##',
  '8(81375)#-##-##',
  '8(81376)#-##-##',
  '8(81378)#-##-##',
  '8(81379)#-##-##',
  '8(814)###-##-##',
  '8(8142)##-##-##',
  '8(81430)#-##-##',
  '8(81430)3-##-##',
  '8(81430)3-1#-##',
  '8(81430)3-3#-##',
  '8(81430)3-4#-##',
  '8(81430)3-5#-##',
  '8(81430)3-6#-##',
  '8(81430)3-7#-##',
  '8(81431)#-##-##',
  '8(81431)3-0#-##',
  '8(81431)3-1#-##',
  '8(81431)3-2#-##',
  '8(81431)3-3#-##',
  '8(81431)3-6#-##',
  '8(81431)3-7#-##',
  '8(81431)3-8#-##',
  '8(81433)#-##-##',
  '8(81433)2-4#-##',
  '8(81433)2-5#-##',
  '8(81433)2-6#-##',
  '8(81433)2-7#-##',
  '8(81434)#-##-##',
  '8(81434)3-3#-##',
  '8(81434)3-4#-##',
  '8(81434)3-5#-##',
  '8(81434)3-8#-##',
  '8(81434)3-9#-##',
  '8(81434)4-2#-##',
  '8(81434)4-3#-##',
  '8(81434)4-4#-##',
  '8(81434)4-7#-##',
  '8(81436)#-##-##',
  '8(81436)2-3#-##',
  '8(81436)2-4#-##',
  '8(81436)2-5#-##',
  '8(81436)2-6#-##',
  '8(81436)2-7#-##',
  '8(81436)2-8#-##',
  '8(81436)2-9#-##',
  '8(81437)#-##-##',
  '8(81437)3-0#-##',
  '8(81437)3-4#-##',
  '8(81437)3-5#-##',
  '8(81437)3-6#-##',
  '8(81437)3-7#-##',
  '8(81437)3-8#-##',
  '8(81437)3-9#-##',
  '8(81439)#-##-##',
  '8(81439)2-6#-##',
  '8(81439)2-7#-##',
  '8(81439)2-8#-##',
  '8(81439)3-3#-##',
  '8(81439)4-1#-##',
  '8(81439)4-4#-##',
  '8(81439)45#-##',
  '8(81450)#-##-##',
  '8(81450)2-3#-##',
  '8(81450)2-4#-##',
  '8(81450)2-6#-##',
  '8(81451)#-##-##',
  '8(81451)2-4#-##',
  '8(81451)3-1#-##',
  '8(81451)3-2#-##',
  '8(81451)3-3#-##',
  '8(81451)3-4#-##',
  '8(81451)3-5#-##',
  '8(81451)3-7#-##',
  '8(81451)3-9#-##',
  '8(81452)#-##-##',
  '8(81452)2-3#-##',
  '8(81452)2-4#-##',
  '8(81452)2-5#-##',
  '8(81452)2-6#-##',
  '8(81452)2-7#-##',
  '8(81452)2-8#-##',
  '8(81452)2-9#-##',
  '8(81454)#-##-##',
  '8(81454)5-3#-##',
  '8(81454)5-4#-##',
  '8(81454)5-7#-##',
  '8(81455)#-##-##',
  '8(81455)2-3#-##',
  '8(81455)2-4#-##',
  '8(81455)2-5#-##',
  '8(81455)2-6#-##',
  '8(81455)2-7#-##',
  '8(81455)2-8#-##',
  '8(81455)2-9#-##',
  '8(81456)#-##-##',
  '8(81456)2-3#-##',
  '8(81456)2-4#-##',
  '8(81456)2-5#-##',
  '8(81456)2-6#-##',
  '8(81456)2-7#-##',
  '8(81456)2-8#-##',
  '8(81456)2-9#-##',
  '8(81457)#-##-##',
  '8(81457)2-3#-##',
  '8(81457)2-4#-##',
  '8(81457)2-5#-##',
  '8(81457)2-6#-##',
  '8(81457)2-7#-##',
  '8(81457)2-9#-##',
  '8(81457)3-6#-##',
  '8(81458)#-##-##',
  '8(81458)3-1#-##',
  '8(81458)3-2#-##',
  '8(81458)3-4#-##',
  '8(81458)3-5#-##',
  '8(81458)3-6#-##',
  '8(81458)3-7#-##',
  '8(81458)3-8#-##',
  '8(81458)3-9#-##',
  '8(81459)#-##-##',
  '8(81459)9-3#-##',
  '8(81459)9-5#-##',
  '8(815)###-##-##',
  '8(8152)##-##-##',
  '8(81530)#-##-##',
  '8(81531)#-##-##',
  '8(81532)#-##-##',
  '8(81533)#-##-##',
  '8(81535)#-##-##',
  '8(81536)#-##-##',
  '8(81537)#-##-##',
  '8(81538)#-##-##',
  '8(81539)#-##-##',
  '8(81551)#-##-##',
  '8(81552)#-##-##',
  '8(81553)#-##-##',
  '8(81554)#-##-##',
  '8(81555)#-##-##',
  '8(81556)#-##-##',
  '8(81558)#-##-##',
  '8(81559)#-##-##',
  '8(816)###-##-##',
  '8(8162)##-##-##',
  '8(81650)#-##-##',
  '8(81651)#-##-##',
  '8(81652)#-##-##',
  '8(81653)#-##-##',
  '8(81654)#-##-##',
  '8(81655)#-##-##',
  '8(81656)#-##-##',
  '8(81657)#-##-##',
  '8(81658)#-##-##',
  '8(81659)#-##-##',
  '8(81660)#-##-##',
  '8(81661)#-##-##',
  '8(81662)#-##-##',
  '8(81663)#-##-##',
  '8(81664)#-##-##',
  '8(81665)#-##-##',
  '8(81666)#-##-##',
  '8(81667)#-##-##',
  '8(81668)#-##-##',
  '8(81669)#-##-##',
  '8(817)###-##-##',
  '8(8172)##-##-##',
  '8(81732)#-##-##',
  '8(81733)#-##-##',
  '8(81737)#-##-##',
  '8(81738)#-##-##',
  '8(81739)#-##-##',
  '8(81740)#-##-##',
  '8(81741)#-##-##',
  '8(81742)#-##-##',
  '8(81743)#-##-##',
  '8(81744)#-##-##',
  '8(81745)#-##-##',
  '8(81746)#-##-##',
  '8(81747)#-##-##',
  '8(81748)#-##-##',
  '8(81749)#-##-##',
  '8(81751)#-##-##',
  '8(81752)#-##-##',
  '8(81753)#-##-##',
  '8(81754)#-##-##',
  '8(81755)#-##-##',
  '8(81756)#-##-##',
  '8(81757)#-##-##',
  '8(81758)#-##-##',
  '8(81759)#-##-##',
  '8(818)###-##-##',
  '8(81830)#-##-##',
  '8(81831)#-##-##',
  '8(81832)#-##-##',
  '8(81833)#-##-##',
  '8(81834)#-##-##',
  '8(81835)9-0#-##',
  '8(81836)#-##-##',
  '8(81837)#-##-##',
  '8(81838)#-##-##',
  '8(81839)#-##-##',
  '8(81840)#-##-##',
  '8(81841)#-##-##',
  '8(81842)#-##-##',
  '8(81843)#-##-##',
  '8(81848)#-##-##',
  '8(81850)#-##-##',
  '8(81851)#-##-##',
  '8(81852)#-##-##',
  '8(81853)#-##-##',
  '8(81854)#-##-##',
  '8(81855)#-##-##',
  '8(81856)#-##-##',
  '8(81858)#-##-##',
  '8(81859)#-##-##',
  '8(820)###-##-##',
  '8(8202)##-##-##',
  '8(821)###-##-##',
  '8(8212)##-##-##',
  '8(82130)#-##-##',
  '8(82131)#-##-##',
  '8(82132)#-##-##',
  '8(82133)#-##-##',
  '8(82134)#-##-##',
  '8(82135)#-##-##',
  '8(82136)#-##-##',
  '8(82137)#-##-##',
  '8(82138)#-##-##',
  '8(82139)#-##-##',
  '8(82140)#-##-##',
  '8(82141)#-##-##',
  '8(82142)#-##-##',
  '8(82144)#-##-##',
  '8(82145)#-##-##',
  '8(82146)#-##-##',
  '8(82149)#-##-##',
  '8(82151)#-##-##',
  '8(8216)##-##-##',
  '8(831)###-##-##',
  '8(8313)##-##-##',
  '8(83130)#-##-##',
  '8(83134)#-##-##',
  '8(83136)#-##-##',
  '8(83137)#-##-##',
  '8(83138)#-##-##',
  '8(83139)#-##-##',
  '8(83140)#-##-##',
  '8(83144)#-##-##',
  '8(83145)#-##-##',
  '8(83147)#-##-##',
  '8(83148)#-##-##',
  '8(83149)#-##-##',
  '8(83150)#-##-##',
  '8(83151)#-##-##',
  '8(83152)#-##-##',
  '8(83153)#-##-##',
  '8(83154)#-##-##',
  '8(83155)#-##-##',
  '8(83156)#-##-##',
  '8(83157)#-##-##',
  '8(83158)#-##-##',
  '8(83159)#-##-##',
  '8(83160)#-##-##',
  '8(83161)#-##-##',
  '8(83162)#-##-##',
  '8(83163)#-##-##',
  '8(83164)#-##-##',
  '8(83165)#-##-##',
  '8(83166)#-##-##',
  '8(83167)#-##-##',
  '8(83168)#-##-##',
  '8(83169)#-##-##',
  '8(83170)#-##-##',
  '8(83171)#-##-##',
  '8(83172)#-##-##',
  '8(83173)#-##-##',
  '8(83174)#-##-##',
  '8(83175)#-##-##',
  '8(83176)#-##-##',
  '8(83177)#-##-##',
  '8(83178)#-##-##',
  '8(83179)#-##-##',
  '8(83190)#-##-##',
  '8(83191)#-##-##',
  '8(83192)#-##-##',
  '8(83193)#-##-##',
  '8(83194)#-##-##',
  '8(83195)#-##-##',
  '8(83196)#-##-##',
  '8(83197)#-##-##',
  '8(833)###-##-##',
  '8(8332)##-##-##',
  '8(83330)#-##-##',
  '8(83331)#-##-##',
  '8(83332)#-##-##',
  '8(83333)#-##-##',
  '8(83334)#-##-##',
  '8(83335)#-##-##',
  '8(83336)#-##-##',
  '8(83337)#-##-##',
  '8(83338)#-##-##',
  '8(83339)#-##-##',
  '8(83340)#-##-##',
  '8(83341)#-##-##',
  '8(83342)#-##-##',
  '8(83343)#-##-##',
  '8(83344)#-##-##',
  '8(83345)#-##-##',
  '8(83346)#-##-##',
  '8(83347)#-##-##',
  '8(83348)#-##-##',
  '8(83349)#-##-##',
  '8(83350)#-##-##',
  '8(83351)#-##-##',
  '8(83352)#-##-##',
  '8(83353)#-##-##',
  '8(83354)#-##-##',
  '8(83355)#-##-##',
  '8(83357)#-##-##',
  '8(83358)#-##-##',
  '8(83359)#-##-##',
  '8(83361)#-##-##',
  '8(83362)#-##-##',
  '8(83363)#-##-##',
  '8(83364)#-##-##',
  '8(83365)#-##-##',
  '8(83366)#-##-##',
  '8(83367)#-##-##',
  '8(83368)#-##-##',
  '8(83369)#-##-##',
  '8(83375)#-##-##',
  '8(834)###-##-##',
  '8(8342)##-##-##',
  '8(83431)#-##-##',
  '8(83432)#-##-##',
  '8(83433)#-##-##',
  '8(83434)#-##-##',
  '8(83436)#-##-##',
  '8(83437)#-##-##',
  '8(83438)#-##-##',
  '8(83439)#-##-##',
  '8(83441)#-##-##',
  '8(83442)#-##-##',
  '8(83443)#-##-##',
  '8(83444)#-##-##',
  '8(83445)#-##-##',
  '8(83446)#-##-##',
  '8(83447)#-##-##',
  '8(83448)#-##-##',
  '8(83449)#-##-##',
  '8(83451)#-##-##',
  '8(83453)#-##-##',
  '8(83454)#-##-##',
  '8(83456)#-##-##',
  '8(83457)#-##-##',
  '8(83458)#-##-##',
  '8(835)###-##-##',
  '8(8352)##-##-##',
  '8(8352)7#-##-##',
  '8(83530)#-##-##',
  '8(83531)#-##-##',
  '8(83532)#-##-##',
  '8(83533)#-##-##',
  '8(83534)#-##-##',
  '8(83535)#-##-##',
  '8(83536)#-##-##',
  '8(83537)#-##-##',
  '8(83538)#-##-##',
  '8(83539)#-##-##',
  '8(83540)#-##-##',
  '8(83541)#-##-##',
  '8(83542)#-##-##',
  '8(83543)#-##-##',
  '8(83544)#-##-##',
  '8(83545)#-##-##',
  '8(83546)#-##-##',
  '8(83547)#-##-##',
  '8(83548)#-##-##',
  '8(83549)#-##-##',
  '8(83551)#-##-##',
  '8(836)###-##-##',
  '8(8362)##-##-##',
  '8(83631)#-##-##',
  '8(83632)#-##-##',
  '8(83633)#-##-##',
  '8(83634)#-##-##',
  '8(83635)#-##-##',
  '8(83636)#-##-##',
  '8(83637)#-##-##',
  '8(83638)#-##-##',
  '8(83639)#-##-##',
  '8(83641)#-##-##',
  '8(83643)#-##-##',
  '8(83644)#-##-##',
  '8(83645)#-##-##',
  '8(840)###-##-##',
  '8(840)22#-##-##',
  '8(840)23#-##-##',
  '8(840)24#-##-##',
  '8(840)25#-##-##',
  '8(840)26#-##-##',
  '8(840)27#-##-##',
  '8(840)28#-##-##',
  '8(841)###-##-##',
  '8(8412)##-##-##',
  '8(84140)#-##-##',
  '8(84141)#-##-##',
  '8(84142)#-##-##',
  '8(84143)#-##-##',
  '8(84144)#-##-##',
  '8(84145)#-##-##',
  '8(84146)#-##-##',
  '8(84147)#-##-##',
  '8(84148)#-##-##',
  '8(84150)#-##-##',
  '8(84151)#-##-##',
  '8(84152)#-##-##',
  '8(84153)#-##-##',
  '8(84154)#-##-##',
  '8(84155)#-##-##',
  '8(84156)#-##-##',
  '8(84157)#-##-##',
  '8(84158)#-##-##',
  '8(84159)#-##-##',
  '8(84161)#-##-##',
  '8(84162)#-##-##',
  '8(84163)#-##-##',
  '8(84164)#-##-##',
  '8(84165)#-##-##',
  '8(84167)#-##-##',
  '8(84168)#-##-##',
  '8(84169)#-##-##',
  '8(842)###-##-##',
  '8(8422)##-##-##',
  '8(84230)#-##-##',
  '8(84231)#-##-##',
  '8(84232)#-##-##',
  '8(84233)#-##-##',
  '8(84234)#-##-##',
  '8(84235)#-##-##',
  '8(84237)#-##-##',
  '8(84238)#-##-##',
  '8(84239)#-##-##',
  '8(84240)#-##-##',
  '8(84241)#-##-##',
  '8(84242)#-##-##',
  '8(84243)#-##-##',
  '8(84244)#-##-##',
  '8(84245)#-##-##',
  '8(84246)#-##-##',
  '8(84247)#-##-##',
  '8(84248)#-##-##',
  '8(84249)#-##-##',
  '8(84253)#-##-##',
  '8(84254)#-##-##',
  '8(84255)#-##-##',
  '8(843)###-##-##',
  '8(84341)#-##-##',
  '8(84342)#-##-##',
  '8(84344)#-##-##',
  '8(84345)#-##-##',
  '8(84346)#-##-##',
  '8(84347)#-##-##',
  '8(84348)#-##-##',
  '8(84360)#-##-##',
  '8(84361)#-##-##',
  '8(84362)#-##-##',
  '8(84364)#-##-##',
  '8(84365)#-##-##',
  '8(84366)#-##-##',
  '8(84367)#-##-##',
  '8(84368)#-##-##',
  '8(84369)#-##-##',
  '8(84370)#-##-##',
  '8(84371)#-##-##',
  '8(84373)#-##-##',
  '8(84374)#-##-##',
  '8(84375)#-##-##',
  '8(84376)#-##-##',
  '8(84377)#-##-##',
  '8(84378)#-##-##',
  '8(84379)#-##-##',
  '8(84396)#-##-##',
  '8(844)###-##-##',
  '8(844)2##-##-##',
  '8(844)70#-##-##',
  '8(8443)##-##-##',
  '8(84442)#-##-##',
  '8(84443)#-##-##',
  '8(84444)#-##-##',
  '8(84445)#-##-##',
  '8(84446)#-##-##',
  '8(84447)#-##-##',
  '8(84452)#-##-##',
  '8(84453)#-##-##',
  '8(84454)#-##-##',
  '8(84455)#-##-##',
  '8(84456)#-##-##',
  '8(84457)#-##-##',
  '8(84458)#-##-##',
  '8(84461)#-##-##',
  '8(84462)#-##-##',
  '8(84463)#-##-##',
  '8(84464)#-##-##',
  '8(84465)#-##-##',
  '8(84466)#-##-##',
  '8(84467)#-##-##',
  '8(84468)#-##-##',
  '8(84472)#-##-##',
  '8(84473)#-##-##',
  '8(84474)#-##-##',
  '8(84475)#-##-##',
  '8(84476)#-##-##',
  '8(84477)#-##-##',
  '8(84478)#-##-##',
  '8(84479)#-##-##',
  '8(84492)#-##-##',
  '8(84493)#-##-##',
  '8(84494)#-##-##',
  '8(84495)#-##-##',
  '8(845)###-##-##',
  '8(8452)##-##-##',
  '8(8453)2#-##-##',
  '8(8453)3#-##-##',
  '8(8453)4#-##-##',
  '8(8453)5#-##-##',
  '8(8453)7#-##-##',
  '8(8453)9#-##-##',
  '8(84540)#-##-##',
  '8(84542)#-##-##',
  '8(84543)#-##-##',
  '8(84544)#-##-##',
  '8(84545)#-##-##',
  '8(84548)#-##-##',
  '8(84549)#-##-##',
  '8(84550)#-##-##',
  '8(84551)#-##-##',
  '8(84552)#-##-##',
  '8(84554)#-##-##',
  '8(84555)#-##-##',
  '8(84557)#-##-##',
  '8(84558)#-##-##',
  '8(84560)#-##-##',
  '8(84561)#-##-##',
  '8(84562)#-##-##',
  '8(84563)#-##-##',
  '8(84564)#-##-##',
  '8(84565)#-##-##',
  '8(84566)#-##-##',
  '8(84567)#-##-##',
  '8(84568)#-##-##',
  '8(84573)#-##-##',
  '8(84574)#-##-##',
  '8(84575)#-##-##',
  '8(84576)#-##-##',
  '8(84577)#-##-##',
  '8(84578)#-##-##',
  '8(84579)#-##-##',
  '8(84591)#-##-##',
  '8(84592)#-##-##',
  '8(84593)#-##-##',
  '8(84595)#-##-##',
  '8(84596)#-##-##',
  '8(846)###-##-##',
  '8(846)2##-##-##',
  '8(846)300-##-##',
  '8(846)302-##-##',
  '8(846)303-##-##',
  '8(846)309-##-##',
  '8(846)31#-##-##',
  '8(846)33#-##-##',
  '8(846)34#-##-##',
  '8(846)37#-##-##',
  '8(846)9##-##-##',
  '8(84630)5-##-##',
  '8(84635)#-##-##',
  '8(84639)#-##-##',
  '8(8464)3#-##-##',
  '8(8464)4#-##-##',
  '8(8464)9#-##-##',
  '8(84646)#-##-##',
  '8(84647)#-##-##',
  '8(84648)#-##-##',
  '8(84650)#-##-##',
  '8(84651)#-##-##',
  '8(84652)#-##-##',
  '8(84653)#-##-##',
  '8(84654)#-##-##',
  '8(84655)#-##-##',
  '8(84656)#-##-##',
  '8(84657)#-##-##',
  '8(84658)#-##-##',
  '8(84660)#-##-##',
  '8(84661)#-##-##',
  '8(84663)#-##-##',
  '8(84664)#-##-##',
  '8(84666)#-##-##',
  '8(84667)#-##-##',
  '8(84670)#-##-##',
  '8(84671)#-##-##',
  '8(84672)#-##-##',
  '8(84673)#-##-##',
  '8(84674)#-##-##',
  '8(84675)#-##-##',
  '8(84676)#-##-##',
  '8(84677)#-##-##',
  '8(847)###-##-##',
  '8(84722)#-##-##',
  '8(84731)#-##-##',
  '8(84732)#-##-##',
  '8(84733)#-##-##',
  '8(84734)#-##-##',
  '8(84735)#-##-##',
  '8(84736)#-##-##',
  '8(84741)#-##-##',
  '8(84742)#-##-##',
  '8(84743)#-##-##',
  '8(84744)#-##-##',
  '8(84745)#-##-##',
  '8(84746)#-##-##',
  '8(84747)#-##-##',
  '8(848)###-##-##',
  '8(8482)##-##-##',
  '8(84862)#-##-##',
  '8(851)###-##-##',
  '8(8512)##-##-##',
  '8(85140)#-##-##',
  '8(85141)#-##-##',
  '8(85142)#-##-##',
  '8(85143)#-##-##',
  '8(85144)#-##-##',
  '8(85145)#-##-##',
  '8(85146)#-##-##',
  '8(85147)#-##-##',
  '8(85148)#-##-##',
  '8(85149)#-##-##',
  '8(85171)#-##-##',
  '8(85172)#-##-##',
  '8(855)###-##-##',
  '8(8552)##-##-##',
  '8(8553)##-##-##',
  '8(85549)#-##-##',
  '8(8555)3#-##-##',
  '8(8555)4#-##-##',
  '8(85551)#-##-##',
  '8(85552)#-##-##',
  '8(85555)#-##-##',
  '8(85556)#-##-##',
  '8(85557)#-##-##',
  '8(85558)#-##-##',
  '8(85559)#-##-##',
  '8(85563)#-##-##',
  '8(85569)#-##-##',
  '8(8557)2#-##-##',
  '8(8557)3#-##-##',
  '8(85592)#-##-##',
  '8(85593)#-##-##',
  '8(85594)#-##-##',
  '8(85595)#-##-##',
  '8(861)###-##-##',
  '8(86130)#-##-##',
  '8(86131)#-##-##',
  '8(86132)#-##-##',
  '8(86133)#-##-##',
  '8(86135)#-##-##',
  '8(86137)#-##-##',
  '8(86138)#-##-##',
  '8(86140)#-##-##',
  '8(86141)#-##-##',
  '8(86142)#-##-##',
  '8(86143)#-##-##',
  '8(86144)#-##-##',
  '8(86145)#-##-##',
  '8(86146)#-##-##',
  '8(86147)#-##-##',
  '8(86148)#-##-##',
  '8(86149)#-##-##',
  '8(86150)#-##-##',
  '8(86151)#-##-##',
  '8(86152)#-##-##',
  '8(86153)#-##-##',
  '8(86154)#-##-##',
  '8(86155)#-##-##',
  '8(86156)#-##-##',
  '8(86157)#-##-##',
  '8(86158)#-##-##',
  '8(86159)#-##-##',
  '8(86160)#-##-##',
  '8(86161)#-##-##',
  '8(86162)#-##-##',
  '8(86163)#-##-##',
  '8(86164)#-##-##',
  '8(86165)#-##-##',
  '8(86166)#-##-##',
  '8(86167)#-##-##',
  '8(86168)#-##-##',
  '8(86169)#-##-##',
  '8(8617)##-##-##',
  '8(86191)#-##-##',
  '8(86192)#-##-##',
  '8(86193)#-##-##',
  '8(86195)#-##-##',
  '8(86196)#-##-##',
  '8(862)###-##-##',
  '8(862)2##-##-##',
  '8(862)23#-##-##',
  '8(862)24#-##-##',
  '8(862)247-##-##',
  '8(862)252-##-##',
  '8(862)27#-##-##',
  '8(862)295-##-##',
  '8(863)###-##-##',
  '8(863)2##-##-##',
  '8(863)3##-##-##',
  '8(8634)3#-##-##',
  '8(8634)43-1#-##',
  '8(8634)6#-##-##',
  '8(86340)#-##-##',
  '8(86341)#-##-##',
  '8(86342)#-##-##',
  '8(86345)#-##-##',
  '8(86347)#-##-##',
  '8(86348)#-##-##',
  '8(86349)#-##-##',
  '8(8635)2#-##-##',
  '8(86350)#-##-##',
  '8(86351)#-##-##',
  '8(86353)#-##-##',
  '8(86354)#-##-##',
  '8(86355)#-##-##',
  '8(86356)#-##-##',
  '8(86357)#-##-##',
  '8(86358)#-##-##',
  '8(86359)#-##-##',
  '8(8636)2#-##-##',
  '8(86360)#-##-##',
  '8(86361)#-##-##',
  '8(86363)#-##-##',
  '8(86364)#-##-##',
  '8(86365)#-##-##',
  '8(86367)#-##-##',
  '8(86368)#-##-##',
  '8(86369)#-##-##',
  '8(86370)#-##-##',
  '8(86371)#-##-##',
  '8(86372)#-##-##',
  '8(86373)#-##-##',
  '8(86374)#-##-##',
  '8(86375)#-##-##',
  '8(86376)#-##-##',
  '8(86377)#-##-##',
  '8(86378)#-##-##',
  '8(86379)#-##-##',
  '8(86382)#-##-##',
  '8(86383)#-##-##',
  '8(86384)#-##-##',
  '8(86385)#-##-##',
  '8(86386)#-##-##',
  '8(86387)#-##-##',
  '8(86388)#-##-##',
  '8(86389)#-##-##',
  '8(8639)2#-##-##',
  '8(86391)#-##-##',
  '8(86393)#-##-##',
  '8(86394)#-##-##',
  '8(86395)#-##-##',
  '8(86396)#-##-##',
  '8(86397)#-##-##',
  '8(865)###-##-##',
  '8(8652)##-##-##',
  '8(86540)#-##-##',
  '8(86541)#-##-##',
  '8(86542)#-##-##',
  '8(86543)#-##-##',
  '8(86544)#-##-##',
  '8(86545)#-##-##',
  '8(86546)#-##-##',
  '8(86547)#-##-##',
  '8(86548)#-##-##',
  '8(86549)#-##-##',
  '8(86550)#-##-##',
  '8(86552)#-##-##',
  '8(86553)#-##-##',
  '8(86554)#-##-##',
  '8(86555)#-##-##',
  '8(86556)#-##-##',
  '8(86557)#-##-##',
  '8(86558)#-##-##',
  '8(86559)#-##-##',
  '8(86560)#-##-##',
  '8(86563)#-##-##',
  '8(86565)#-##-##',
  '8(866)###-##-##',
  '8(8662)##-##-##',
  '8(86630)#-##-##',
  '8(86631)#-##-##',
  '8(86632)#-##-##',
  '8(86633)#-##-##',
  '8(86634)#-##-##',
  '8(86635)#-##-##',
  '8(86636)#-##-##',
  '8(86637)#-##-##',
  '8(86638)#-##-##',
  '8(867)###-##-##',
  '8(8672)##-##-##',
  '8(86731)#-##-##',
  '8(86732)#-##-##',
  '8(86733)#-##-##',
  '8(86734)#-##-##',
  '8(86735)#-##-##',
  '8(86736)#-##-##',
  '8(86737)#-##-##',
  '8(86738)#-##-##',
  '8(86739)#-##-##',
  '8(871)###-##-##',
  '8(8712)##-##-##',
  '8(87132)#-##-##',
  '8(87134)#-##-##',
  '8(87135)#-##-##',
  '8(87136)#-##-##',
  '8(87142)#-##-##',
  '8(87143)#-##-##',
  '8(87145)#-##-##',
  '8(87146)#-##-##',
  '8(87147)#-##-##',
  '8(87148)#-##-##',
  '8(87152)#-##-##',
  '8(87154)#-##-##',
  '8(87155)#-##-##',
  '8(87156)#-##-##',
  '8(87164)#-##-##',
  '8(872)###-##-##',
  '8(8722)##-##-##',
  '8(87230)#-##-##',
  '8(87231)#-##-##',
  '8(87232)#-##-##',
  '8(87233)#-##-##',
  '8(87234)#-##-##',
  '8(87235)#-##-##',
  '8(87236)#-##-##',
  '8(87237)#-##-##',
  '8(87238)#-##-##',
  '8(87239)#-##-##',
  '8(87240)#-##-##',
  '8(87242)#-##-##',
  '8(87243)#-##-##',
  '8(87244)#-##-##',
  '8(87245)#-##-##',
  '8(87246)#-##-##',
  '8(87247)#-##-##',
  '8(87248)#-##-##',
  '8(87249)#-##-##',
  '8(87252)#-##-##',
  '8(87254)#-##-##',
  '8(87255)#-##-##',
  '8(87256)#-##-##',
  '8(87257)#-##-##',
  '8(87258)#-##-##',
  '8(87259)#-##-##',
  '8(87260)#-##-##',
  '8(87261)#-##-##',
  '8(87262)#-##-##',
  '8(87263)#-##-##',
  '8(87264)#-##-##',
  '8(87265)#-##-##',
  '8(87266)#-##-##',
  '8(87267)#-##-##',
  '8(87268)#-##-##',
  '8(87271)#-##-##',
  '8(87272)#-##-##',
  '8(87273)#-##-##',
  '8(87274)#-##-##',
  '8(87275)#-##-##',
  '8(87276)#-##-##',
  '8(87279)#-##-##',
  '8(873)###-##-##',
  '8(8732)##-##-##',
  '8(87341)#-##-##',
  '8(87342)#-##-##',
  '8(87343)#-##-##',
  '8(87344)#-##-##',
  '8(87345)#-##-##',
  '8(877)###-##-##',
  '8(8772)##-##-##',
  '8(87770)#-##-##',
  '8(87771)#-##-##',
  '8(87772)#-##-##',
  '8(87773)#-##-##',
  '8(87777)#-##-##',
  '8(87778)#-##-##',
  '8(87779)#-##-##',
  '8(878)###-##-##',
  '8(8782)##-##-##',
  '8(87870)#-##-##',
  '8(87872)#-##-##',
  '8(87873)#-##-##',
  '8(87874)#-##-##',
  '8(87875)#-##-##',
  '8(87876)#-##-##',
  '8(87877)#-##-##',
  '8(87878)#-##-##',
  '8(87879)#-##-##',
  '8(879)###-##-##',
  '8(87922)#-##-##',
  '8(8793)##-##-##',
  '8(87932)#-##-##',
  '8(87934)#-##-##',
  '8(87935)#-##-##',
  '8(87937)#-##-##',
  '8(87938)#-##-##',
  '8(87951)#-##-##',
  '8(87961)#-##-##',
  '8(87964)#-##-##',
  '8(9##)###-##-##',
];

import { Component } from 'react';
import * as session from 'modules/session';
import Card from '../../Products/Card/Card';
import Emitter from '../../NewCartOrder/Emitters';

class CardComponent extends Component {
  constructor(props) {
    super(props);
    const { items = [] } = session.get('cart');
    const { cartId, product } = this.props;
    const cart_item = items.find((item) => item.id === (cartId || product.id));
    this.state = {
      cart_item,
    };
  }

  componentDidMount() {
    Emitter.addListener('CART_CHANGE', this.cartChangeHandler);
  }

  componentWillUnmount() {
    Emitter.removeListener('CART_CHANGE', this.cartChangeHandler);
  }

  cartChangeHandler = () => {
    const { items = [] } = session.get('cart');
    let { cart_item = {} } = this.state;
    const { product } = this.props;
    if (!product) return;
    cart_item = items.find((item) => item.id === (cart_item.id || product.id));
    this.setState({
      cart_item,
    });
  };

  render() {
    const { cart_item } = this.state;
    const { product, position } = this.props;
    return (
      <Card
        product={product}
        key={`product-${product.id}`}
        view_type="single"
        cart_item={cart_item}
        position={position}
      />
    );
  }
}
export default CardComponent;

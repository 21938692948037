import cn from 'classnames';
import { ReactNode } from 'react';
import { useSidebarModal } from '../model/useSidebarModal';
import { useStickySidebarEffect } from '../model/useStickySidebar';
import styles from './sideBar.module.scss';

function SideBar({ children }: { children: ReactNode }) {
  const sidebarRef = useStickySidebarEffect();
  const modal = useSidebarModal();

  return (
    <aside
      itemScope
      itemType="http://schema.org/WPSideBar"
      className={cn(styles.root, { [styles.rootActive]: modal.visible })}
      ref={sidebarRef}
    >
      {children}
    </aside>
  );
}

export { SideBar };

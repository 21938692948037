export function getTargetValue(target, model) {
  model = { ...model };

  const trimmedValue = target.value.trim();
  const value = trimmedValue.length > 0 ? target.value : '';

  switch (target.type) {
    case 'checkbox':
      model = Object.assign(model, { [target.name]: target.checked });
      break;
    case 'radio':
      model = Object.assign(model, { [target.name]: value });
      break;
    case 'select-one':
      model = Object.assign(model, { [target.name]: value });
      break;
    case 'text':
      model = Object.assign(model, { [target.name]: value });
      break;
    case 'textarea':
      model = Object.assign(model, { [target.name]: value });
      break;
    default:
      break;
  }

  return model;
}

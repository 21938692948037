import './Tags.scss';
import { Component } from 'react';
import autobind from 'autobind-decorator';
import { cardsEmitter, filterPanelEmitter } from 'modules/event-emitters';
import { replaceQuery, getQueryObject } from 'modules/router-query-utils';
import FilterCheckbox from '../Components/Checkbox/FilterCheckbox/FilterCheckbox';

class Tag extends Component {
  constructor(props) {
    super(props);
    const { tags, checkedTags } = props;
    this.state = {
      tags,
      checkedTags: checkedTags || {},
    };
  }

  componentDidMount() {
    filterPanelEmitter.addListener('reset_all', this.reset);
  }

  componentWillReceiveProps(props) {
    let { checkedTags } = props;
    const { tags } = props;
    const checkedTagsObj = {};
    checkedTags.forEach((tag) => {
      checkedTagsObj[tag] = true;
    });
    checkedTags = checkedTagsObj;

    const mergedTags = {};
    const tagsKeys = Object.keys(tags);

    for (const tagId in checkedTags) {
      const finded = tagsKeys.find((tag_id) => tag_id === tagId);

      if (finded) {
        mergedTags[tagId] = true;
      }
    }

    this.setState({
      tags,
      checkedTags: mergedTags,
    });

    const oldTags = checkedTags;
    const newTags = mergedTags;

    const oldJson = JSON.stringify(oldTags);
    const newJson = JSON.stringify(newTags);

    if (oldJson !== newJson) {
      cardsEmitter.emit('ProductList.CheckedTags', mergedTags);
    }
  }

  componentWillUnmount() {
    filterPanelEmitter.removeListener('reset_all', this.reset);
  }

  @autobind
  onToggleTag({ name, checked }) {
    // if (name === '1') {
    //   eventBus.emitEvent('product_filter', [
    //     {
    //       type: 'hot',
    //     },
    //   ]);
    // } else if (name === '6') {
    //   eventBus.emitEvent('product_filter', [
    //     {
    //       type: 'vegan',
    //     },
    //   ]);
    // }

    const { checkedTags } = this.state;

    const tags = JSON.parse(JSON.stringify(checkedTags));

    if (checked) {
      checkedTags[name] = true;
      tags[name] = true;
    } else {
      delete checkedTags[name];
      tags[name] = false;
    }

    const query = getQueryObject(tags);
    for (const key in query) {
      if (!query[key]) delete query[key];
    }

    replaceQuery(query);
    this.setState({ checkedTags });
    cardsEmitter.emit('ProductList.CheckedTags', checkedTags);
  }

  @autobind
  reset() {
    const { checkedTags } = this.state;
    Object.keys(checkedTags).forEach((key) => {
      this.onToggleTag({ name: key, checked: false });
    });
  }

  renderTags(tags) {
    const { checkedTags } = this.state;
    const tagsKeys = Object.keys(tags);
    return tagsKeys.map((tagId) => {
      const { tag_id, title } = tags[tagId];
      const id = `tag-filter${tag_id}`;
      return (
        <FilterCheckbox
          name={tag_id}
          key={id}
          title={title}
          checked={!!checkedTags[tagId]}
          onToggle={this.onToggleTag}
        />
      );
    });
  }

  render() {
    const { tags } = this.state;
    return (
      <div className="tags">{tags ? this.renderTags(tags) : undefined}</div>
    );
  }
}

export default Tag;

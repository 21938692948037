const Do = (aNumber) => ({
  times: (aFunction) => {
    for (let i = 0; i < aNumber; i += 1) {
      aFunction(i);
    }
  },

  collect: (aFunction) => {
    const result = [];
    Do(aNumber).times((i) => result.push(aFunction(i)));
    return result;
  },
});

export default Do;

import { MouseEvent, memo } from 'react';
import * as session from 'modules/session';
import useFavorites from 'frontend/data/user/favorites/useFavorites';
import { getCurrentProduct } from 'src/helpers/productHelpers';
import { IProduct } from 'types/product';
import cn from 'classnames';
import { useSelectedCountry } from 'src/entities/countries';
import Emitter from '../../NewCartOrder/Emitters';
import styles from './favoritesControl.module.scss';

function FavoritesControl({
  product,
  view_type,
}: {
  product: IProduct;
  view_type: string;
}) {
  const { data: favorites, toggle } = useFavorites();
  const { isUkraine } = useSelectedCountry();
  const openAuthModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const user = session.get('user');

    if (typeof user === 'undefined' || user === null) {
      Emitter.emit('MODAL_CALL', { modal: 'AuthModal' });
    }
  };

  const control = cn(styles.control, {
    [styles.ua]: isUkraine,
  });

  const fav = cn(styles.fav, {
    [styles.ua]: isUkraine,
    [styles.grid]: view_type === 'grid',
  });

  if (!favorites) {
    return (
      <button type="button" className={control} onClick={openAuthModal}>
        <span className={fav} />
      </button>
    );
  }

  const favoriteToggleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    toggle(product);
  };

  const isFavorite = favorites.some(
    (favorite) => favorite.id === getCurrentProduct(favorites, product).id
  );

  const favorite = cn({
    [styles.ua]: isUkraine,
    [styles.fav]: !isFavorite,
    [styles.favin]: isFavorite,
    [styles.grid]: view_type === 'grid',
  });

  return (
    <button
      type="button"
      className={styles.control}
      onClick={favoriteToggleClick}
    >
      <span className={favorite} />
    </button>
  );
}
export default memo(FavoritesControl);

import { TFunction } from 'i18next';
import escapeReg from 'modules/helpers/escape-regexp';
import { ICart } from 'types/cartTypes';
import Input from '../Fields/Input';
import DateTimeSelect from '../Fields/DateTimeSelect/DateTimeSelect';
import Textarea from '../Fields/Textarea';
import Buttons from '../Fields/Buttons';
import Radio from '../Fields/Radio';
import { Phone } from '../Fields/Phone';
import SuggestionField from '../Fields/suggestionField/SuggestionField';

type MapTypes = {
  isYandexMap: boolean;
  isGoogleMap: boolean;
};
const getCommentPlaceholder = (
  { isYandexMap, isGoogleMap }: MapTypes,
  t: TFunction
) => {
  if (isYandexMap) {
    return t('BlockForm.textareaPlaceholderYandex');
  }

  if (isGoogleMap) {
    return t('BlockForm.textareaPlaceholderGoogle');
  }
  return '';
};

const cartFormFields = (
  mapTypes: MapTypes,
  t: TFunction,
  isMapDisabled: boolean
) => {
  return [
    {
      Component: isMapDisabled ? Input : SuggestionField,
      name: 'address',
      required: true,
      type: 'text',
      label: t('locale.address'),
      placeholder: isMapDisabled && t('locale.copyMapLink'),
      fieldsetClassName: 'large',
      pickup: false,
    },
    {
      Component: Input,
      name: 'name',
      maxLength: 100,
      required: true,
      type: 'text',
      label: t('locale.name'),
      re: escapeReg(' -ЄєІіЇї'),
      pickup: true,
    },
    {
      Component: Input,
      name: 'entrance',
      required: !isMapDisabled,
      type: 'number',
      label: t('locale.entrance'),
      fieldsetClassName: 'small',
      maxLength: 4,
      pickup: false,
    },
    {
      Component: Input,
      name: 'floor',
      required: !isMapDisabled,
      type: 'number',
      label: t('locale.floor'),
      fieldsetClassName: 'small',
      maxLength: 2,
      pickup: false,
    },
    {
      Component: Input,
      name: 'apartment',
      type: 'number',
      label: t('locale.apartment'),
      fieldsetClassName: isMapDisabled ? '' : 'small',
      maxLength: 5,
      required: true,
      re: '0-9',
      pickup: false,
    },
    {
      Component: Phone,
      type: 'phone',
      name: 'phone',
      required: true,
      label: t('locale.phone'),
      pickup: true,
    },
    {
      Component: Input,
      name: 'email',
      type: 'email',
      label: t('locale.email'),
      placeholder: '@',
      pickup: true,
      required: false,
    },
    {
      Component: Radio,
      name: 'delivery_type',
      type: 'radio',
      label: t('locale.as_soon_as_possible'),
      className: 'sw-radio',
      radio: 1,
      fieldsetClassName: 'small-margin',
      pickup: true,
      checked: true,
      required: false,
    },
    {
      Component: Radio,
      name: 'delivery_type',
      type: 'radio',
      label: t('locale.byACertainTime'),
      className: 'sw-radio',
      radio: 2,
      fieldsetClassName: 'small-margin',
      pickup: true,
      required: false,
    },
    {
      Component: DateTimeSelect,
      label: t('locale.take'),
      name: 'select_date',
      pickup: true,
      required: false,
    },
    {
      Component: DateTimeSelect,
      name: 'select_time',
      label: '',
      pickup: true,
      fieldsetClassName: 'select-time',
      required: false,
    },
    {
      Component: Buttons,
      label: t('locale.number_of_instruments'),
      name: 'cutlery_qty',
      required: true,
      pickup: true,
      fieldsetClassName: 'instruments-count',
    },
    {
      name: 'comment',
      Component: Textarea,
      label: t('locale.comment_to_the_order'),
      pickup: true,
      fieldsetClassName: 'order-comment',
      required: false,
      placeholder: getCommentPlaceholder(mapTypes, t),
    },
    {
      tagName: undefined,
      name: 'store_id',
      label: '',
      pickup: true,
      required: false,
    },
  ] as const;
};

const getFields = (
  isPickup: boolean,
  t: TFunction,
  mapTypes: MapTypes,
  isMapDisabled: boolean
) => {
  const hiddenFieldsNames = ['entrance', 'floor'];

  const fields = cartFormFields(mapTypes, t, isMapDisabled)
    .filter((field) => (isPickup ? field.pickup : true))
    .filter((field) =>
      isMapDisabled ? !hiddenFieldsNames.includes(field.name) : true
    );

  return fields;
};

const getUserFields = (user: any = {}) => {
  if (!Object.keys(user).length) return {};
  const { name } = user;
  if (name) return { name };
  return {};
};

const fillFields = (
  fields: ReturnType<typeof getFields>,
  cart: ICart,
  user: any
) => {
  const filledFields = cart.order || {};
  const userFields = getUserFields(user) || {};
  return fields.map((field) => {
    const newField = {
      ...field,
      value:
        filledFields[field.name as keyof typeof filledFields] ||
        userFields[field.name as keyof typeof userFields],
    };

    return newField;
  });
};

export { getFields, fillFields };

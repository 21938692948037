/**
 * Abstract class
 *
 * Need to override this.sendHandler
 */
import { Component } from 'react';
import autobind from 'autobind-decorator';

class AbstractSendSmsComponent extends Component {
  tickId = null;

  // Default settings
  tagName = 'button';

  className = 'phone-send-code-button';

  value = 'Request code';

  constructor(props) {
    super(props);
    this.state = {
      data: {
        phone: props.phone || null,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    let { data } = this.state;
    data = Object.assign(data, nextProps);
    this.setState({
      data,
    });
  }

  componentWillUnmount() {
    clearInterval(this.tickId);
  }

  @autobind
  sendHandler(e) {
    if (this.props.disabled) return;
    if (this.props.onClick) {
      this.props.onClick(e);
    }

    return true;
  }

  @autobind
  renderValue() {
    return this.props.value || this.value;
  }

  render() {
    const { disabled = false } = this.props;
    const Tag = this.props.tagName || this.tagName;
    return (
      <Tag
        onClick={disabled ? () => {} : this.sendHandler}
        className={`${this.props.className || this.className} ${
          disabled ? 'disabled' : ''
        }`}
        style={this.props.style}
        type={this.props.type}
        disabled={disabled}
      >
        {this.renderValue()}
      </Tag>
    );
  }
}
export default AbstractSendSmsComponent;

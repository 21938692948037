import cn from 'classnames';
import loadable from '@loadable/component';
import { SmartLink } from 'shared/ui/smart-link';
import { useIntervalIndex } from '../../model/useIntervalIndex';
import { INTERVAL } from '../../config/constants';
import styles from './DesktopMenu.module.scss';
import { useSelectMenu } from '../../model/selectors';

const Icon = loadable(() => import('../icon/Icon').then((i) => i), {
  ssr: false,
});

export function DesktopMenu({ mainDomain }: { mainDomain: string }) {
  const menu = useSelectMenu();

  const menuList = menu.filter(({ visible }) => visible);
  const animatedIndex = useIntervalIndex(menuList.length, INTERVAL);
  return (
    <div className={styles.root}>
      {menuList.map((item, index) => {
        return (
          <SmartLink
            href={`/menu/${item.text_id}`}
            className={cn(styles.box, {
              [styles.iconAnimated]: index === animatedIndex,
            })}
            activeClassName={styles.iconActive}
            key={item.text_id}
          >
            <Icon item={item} mainDomain={mainDomain} />
            <div className={styles.iconDescr}>
              <span className={styles.iconDescrTitle}>{item.title}</span>
            </div>
          </SmartLink>
        );
      })}
    </div>
  );
}

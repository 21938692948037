import { useTranslation } from 'react-i18next';

const ssr = (OriginalComponent) => {
  const initialSSRData = async (fetch, params, store) => {
    if (!OriginalComponent.initialData) {
      return [];
    }

    try {
      await OriginalComponent.initialData(fetch, params, store);

      if (OriginalComponent.LOADED_KEY) {
        store.set(OriginalComponent.LOADED_KEY, true);
      }

      return [];
    } catch (e) {
      console.error(e.message);
      return [];
    }
  };

  function EnhancedComponent(props) {
    const { t } = useTranslation();
    return <OriginalComponent {...props} t={t} />;
  }

  EnhancedComponent.initialSSRData = initialSSRData;

  return EnhancedComponent;
};

export default ssr;

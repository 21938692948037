import styles from './orderInfoComplectation.module.scss';

interface IOrderInfoComplectation {
  items: { id: number; title: string; qty: number }[];
  currency: string;
}

function OrderInfoComplectation({ items, currency }: IOrderInfoComplectation) {
  if (!items.length) return null;
  return (
    <div className={styles.complectation}>
      {items.map(({ id, title, qty }) => {
        return (
          <div key={`item-${id}`}>
            <div className={styles.item}>
              <div className={styles.title}>{title}</div>
              <div className={styles.price}>{`${qty} x 0 ${currency}`}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default OrderInfoComplectation;

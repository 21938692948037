import './Template.scss';
import '../../Components/Form/Form.scss';
import 'src/shared/ui/Radio/Radio.scss';
import api from 'modules/helpers/api';
import autobind from 'autobind-decorator';
import * as session from 'modules/session';
import globalStore from 'modules/global-store';
import { parseUrl, stripCity } from 'modules/helpers/url-helper';
import {
  modalEmitter,
  historyEmitter,
  seoEmitter,
} from 'modules/event-emitters';
import findYaCounter from 'modules/yandexCounter/find-ya-counter';
import { OnlyClient } from 'src/shared/OnlyClient';
import MobileMenu from 'frontend/Components/MainMenu/MobileMenu/MobileMenu';
import { Footer } from 'src/frontend/widgets/footer';
import { DesktopMenu } from 'src/entities/menu';
import { SideBar } from 'src/frontend/widgets/sideBar';
import { Media } from 'src/shared/lib/media';
import { ToppingsModal } from 'features/toppings-modal';
import { Header } from 'frontend/widgets/header';
import TemplateHelmet from 'app/helmet/TemplateHelmet';
import { ModalProvider } from 'app/providers/modal-provider';
import { Component } from 'react';
import { RoutesWithSubRoutes } from 'shared/lib/routes-with-sub-routes';
import { connect } from 'react-redux';
import InfoModals from '../../Components/Modals/InfoModal/InfoModals';
import SubscribeModal from '../../Components/Modals/SubscribeModal/SubscribeForm';
import ErrorModal from '../../Components/Modals/ErrorModal/ErrorModal';
import MovieModal from '../../Components/Modals/MovieModal/MovieModal';
import MainCartModal from '../../Components/NewCartOrder/Modals/main-card-modal/MainCartModal';
import Emitter from '../../Components/NewCartOrder/Emitters';
import PageScroller from '../../helpers/PageScroller';
import historyHelper from '../../helpers/history-helper';
import Banner from '../../Components/Banner/Banner';
import styles from './template.module.scss';

class Template extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // user: session.get('user'),
      device: '',
      modal: false,
      modalValue: undefined,
      modalLinks: undefined,
      modalInfo: undefined,
      // bannerForApp: [],
    };
  }

  getCurrentDevice() {
    const { userAgent } = navigator;
    return (
      (/iPhone/i.test(userAgent) && 'iPhone') ||
      (/android/i.test(userAgent) && 'android') ||
      'another'
    );
  }

  getBanner() {
    api('seo.page', {
      url: stripCity(window.location.pathname, session.get('city')),
    })
      .then(({ banner }) => {
        const currentDevice = this.getCurrentDevice();

        const { ios, android } = banner || {};
        const bannerForDevice =
          (currentDevice === 'iPhone' && ios) ||
          (currentDevice === 'android' && android) ||
          {};
        this.setState({ device: currentDevice });
        this.setState({ bannerForDownloadApp: bannerForDevice });
      })
      .catch((error) => {
        console.error('error', error);
      });
  }

  componentDidMount() {
    const { location: { pathname = '' } = {} } = this.props;
    const pageScroller = new PageScroller(pathname);
    this.getBanner();

    const { history } = this.props;
    let lastLocation = history.location;
    this.unlisten = history.listen((location) => {
      this.getBanner();

      if (historyHelper.isSame(lastLocation, location)) {
        return;
      }
      lastLocation = location;

      this.webvisorListener(location.pathname);
      pageScroller.update(location.pathname);

      api('seo.page', {
        url: stripCity(location.pathname, session.get('city')),
      })
        .then(({ seo }) => {
          if (seo.redirect) {
            let hostname;
            let pathname;
            const match = parseUrl(seo.redirect);

            if (match && match.length) {
              hostname = parseUrl(seo.redirect)[1];
              pathname = parseUrl(seo.redirect)[2];
            } else {
              hostname = seo.redirect;
            }

            if (location.hostname !== hostname) {
              location.href = seo.redirect;
            } else {
              history.push({
                pathname: pathname || '/',
              });
            }
          }
          globalStore.set('seo', seo || {});
          seoEmitter.emit('SEO_UPDATE');
        })
        .catch((error) => {
          console.error(error);
        });
    });

    Emitter.addListener('MODAL_CALL', this.modalCall);
    Emitter.addListener('MODAL_CLOSE', this.modalClose);
    historyEmitter.addListener('HISTORY_PUSH', this.historyPush);
    this.OpenModal();
    window.reRender = () => this.forceUpdate();
  }

  componentWillUnmount() {
    Emitter.removeListener('MODAL_CALL', this.modalCall);
    Emitter.removeListener('MODAL_CLOSE', this.modalClose);
    historyEmitter.removeListener('HISTORY_PUSH', this.historyPush);
    if (this.unlisten) {
      this.unlisten();
    }
  }

  componentDidUpdate() {
    this.OpenModal();
  }

  historyPush = (push) => {
    if (push) {
      this.props.history.push(push);
    }
  };

  @autobind
  modalCall({ modal, value, info, buttons = [], links }) {
    this.setState({
      modal,
      modalValue: value,
      modalLinks: links,
      modalInfo: info,
      modalButtons: buttons,
    });
  }

  @autobind
  modalClose() {
    this.setState({ modal: false, modalValue: undefined });
  }

  OpenModal() {
    const city = `/${session.get('city')}`;
    const pathName = this.props.location.pathname.replace(city, '');
    api('modal.page', { url: pathName }).then((modal) => {
      if (!modal || !Object.keys(modal).length) {
        return;
      }
      modalEmitter.emit('Template.Modals.InfoModal', modal);
    });
  }

  @autobind
  webvisorListener(pathname) {
    const { counters = [] } = this.state;
    if (!counters.length) {
      const counters = findYaCounter();
      if (counters.length) {
        this.setState({ counters });
        counters.forEach((ya) => {
          ya.hit(pathname);
        });
      }
    } else {
      counters.forEach((ya) => ya.hit(pathname));
    }
  }

  render() {
    const { routes, mainDomain } = this.props;
    const {
      modal,
      modalValue,
      device,
      modalLinks,
      modalInfo,
      modalButtons,
      bannerForDownloadApp: { image, link } = {},
    } = this.state;
    const isModal = !!modal;

    return (
      <ModalProvider>
        <TemplateHelmet />
        <SubscribeModal />
        <ErrorModal />
        <MovieModal videoLink={modalValue} />
        <OnlyClient>
          <Banner src={image} link={link} device={device} />
        </OnlyClient>
        <Media greaterThanOrEqual="notebook">
          <SideBar>
            <DesktopMenu mainDomain={mainDomain} />
          </SideBar>
        </Media>
        <Header />
        <MobileMenu />
        <RoutesWithSubRoutes routes={routes} />
        <div className={styles.footerContainer}>
          <Footer />
        </div>
        <ToppingsModal />
        <InfoModals />
        {isModal && (
          <MainCartModal
            {...{
              tag: modal,
              value: modalValue,
              info: modalInfo,
              buttons: modalButtons,
              links: modalLinks,
            }}
          />
        )}
      </ModalProvider>
    );
  }
}

const mapStateToProps = ({ countries }) => ({
  mainDomain: countries.country.main_domain,
});

export default connect(mapStateToProps)(Template);

import { сurrencies } from '../constants';
import { useSelectCountryCurrency } from './selectors';

export const useCurrency = () => {
  const currency = useSelectCountryCurrency();
  return сurrencies[currency];
};

export const withCurrency = <T extends object>(
  Component: React.ComponentType<T & { currency: string }>
) =>
  function (props: T) {
    const currency = useCurrency();
    return <Component {...props} currency={currency} />;
  };

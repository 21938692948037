import './VacancyContactsLine.scss';
import { useTranslation, withTranslation } from 'react-i18next';

function VacancyContactsLine({ vacancyEmail }: { vacancyEmail: string }) {
  const { t } = useTranslation();
  return (
    <div className="vacancies-contacts-line">
      {t('VacancyContactsLine.Vacancies-contacts')}&nbsp;
      <a
        className="vacancies-contacts-line__link"
        href={`mailto:${vacancyEmail}`}
      >
        {vacancyEmail}
      </a>
    </div>
  );
}

export default withTranslation()(VacancyContactsLine);

import cn from 'classnames';
import style from './button.module.scss';

interface IButton
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  text?: string;
  variant?: 'contained' | 'outlined';
  isFullWidth?: boolean;
}

function Button({
  className,
  text,
  children,
  isFullWidth = false,
  variant = 'outlined',
  ...props
}: IButton) {
  return (
    <button
      {...props}
      type="button"
      className={cn(style.swButton, className, {
        [style.fullWidth]: isFullWidth,
        [style.contained]: variant === 'contained',
        [style.outlined]: variant === 'outlined',
      })}
    >
      {text}
      {children}
    </button>
  );
}

export default Button;

import { TFunction } from 'i18next';

export const getScheme = (t: TFunction) => ({
  address: { title: t('Table.Address'), sorting: true },
  phone: { title: '', sorting: false },
  workingHours: { title: t('Table.Working hours'), sorting: true },
  review: { title: '', sorting: false },
});

export const getSchemeWithMetro = (t: TFunction) => ({
  address: { title: t('Table.Address'), sorting: true },
  metro: { title: t('Table.Metro'), sorting: true },
  phone: { title: '', sorting: false },
  workingHours: { title: t('Table.Working hours'), sorting: true },
  review: { title: '', sorting: false },
});

export type SchemeType =
  | ReturnType<typeof getScheme>
  | ReturnType<typeof getSchemeWithMetro>;

import {
  addDays,
  dayOfWeek,
  formatHelper,
  getDateInSeconds,
  parse,
} from 'shared/lib/date';
import { TFunction } from 'i18next';
import type { IStore, IStoresFromApi, IStoreWorkDay } from '../model/types';

function getAddress(store: IStore) {
  if (!store) {
    return '';
  }
  if (typeof store.store_name_site === 'string') {
    return store.store_name_site;
  }

  if (typeof store.address === 'string') {
    return store.address;
  }
  return '';
}

function validWorktime(
  worktime: IStoreWorkDay | undefined
): worktime is IStoreWorkDay {
  if (worktime && worktime.worktime_from && worktime.worktime_to) {
    return true;
  }
  return false;
}

function formatWorktime(currentDay: IStoreWorkDay) {
  const formated = { ...currentDay };
  formated.worktime_from = currentDay.worktime_from.slice(0, 5);
  formated.worktime_to = currentDay.worktime_to.slice(0, 5);
  return formated;
}

function parseTime(time: string, date: Date = new Date()) {
  const arr = time.split(':');
  switch (arr.length) {
    case 2: {
      return parse(time || '00:00', formatHelper.time, date);
    }
    case 3: {
      return parse(time || '00:00:00', formatHelper.time, date);
    }
    default: {
      throw Error(String('incorrect date format'));
    }
  }
}

/**
 * returns valid work time for the store on a given reference date (today by default).
 *
 * @param {IStore} store - The store object.
 * @param {Date} [refDate=new Date()] - The reference date. Defaults to the current date.
 * @return {string} The formatted work time.
 */
function getWorkTime(store: IStore, refDate: Date = new Date()) {
  const currentWeekday = dayOfWeek(refDate);
  const worktime = store.worktime.find(
    (current) => current.day === currentWeekday
  );
  if (!validWorktime(worktime)) {
    return undefined;
  }
  return formatWorktime(worktime);
}

function getWorktimeFromToInSeconds(
  store: IStore,
  fromTo: 'from' | 'to',
  refDate: Date = new Date()
) {
  const todayWorktime = getWorkTime(store, refDate);
  if (!validWorktime(todayWorktime)) {
    return 0;
  }
  const worktimeTo = parseTime(
    fromTo === 'from' ? todayWorktime.worktime_from : todayWorktime.worktime_to,
    refDate
  );
  const totalSeconds = getDateInSeconds(worktimeTo);
  return totalSeconds;
  // return moment.duration(todayWorktime.worktime_to).asSeconds();
}

function isCloseAfterMidnight(store: IStore) {
  const worktimeFrom = getWorktimeFromToInSeconds(store, 'from');
  const worktimeTo = getWorktimeFromToInSeconds(store, 'to');
  return worktimeTo < worktimeFrom;
}

function isEverydaySameTime(store: IStore) {
  return store.worktime_everyday;
}

function isAllDay(store: IStore) {
  const worktimeFrom = getWorktimeFromToInSeconds(store, 'from');
  const worktimeTo = getWorktimeFromToInSeconds(store, 'to');
  const todayWorktime = getWorkTime(store);
  return todayWorktime && worktimeFrom === worktimeTo;
}

function calculateWorktimeToInSeconds(store: IStore) {
  const worktimeFrom = getWorktimeFromToInSeconds(store, 'from');
  const worktimeTo = getWorktimeFromToInSeconds(store, 'to');
  const closeTomorrow = worktimeTo < worktimeFrom;
  const currentSecond = getDateInSeconds(new Date());

  if (closeTomorrow && currentSecond >= worktimeFrom) {
    return worktimeTo + 86400;
  }
  return worktimeTo;
}

function convertTimeStringToSeconds(string: string) {
  const pickupBeforeClose = string.split(':');
  const hours = Number(pickupBeforeClose[0]);
  const minutes = Number(pickupBeforeClose[1]);
  const seconds = Number(pickupBeforeClose[2]);
  return hours * 3600 + minutes * 60 + seconds;
  // return moment.duration(store.pickup_before_close).asSeconds();
}

function isStillClosed(store: IStore) {
  if (isAllDay(store)) return false;
  const currentTime = getDateInSeconds(new Date());
  const worktimeFrom = getWorktimeFromToInSeconds(store, 'from');
  if (!isCloseAfterMidnight(store)) return currentTime < worktimeFrom;
  const worktimeTo = getWorktimeFromToInSeconds(store, 'to');
  return currentTime > worktimeTo && currentTime < worktimeFrom;
}

function isAlreadyClosed(store: IStore) {
  if (isAllDay(store)) return false;
  const currentTime = getDateInSeconds(new Date());
  const worktimeTo = calculateWorktimeToInSeconds(store);
  if (!isCloseAfterMidnight(store)) return currentTime >= worktimeTo;
  const worktimeFrom = getWorktimeFromToInSeconds(store, 'from');
  return currentTime > worktimeTo && currentTime < worktimeFrom;

  return currentTime >= worktimeTo;
}

function isWillCloseSoon(store: IStore) {
  if (isAllDay(store)) return false;
  const currentTime = getDateInSeconds(new Date());
  const pickupBeforeClose = convertTimeStringToSeconds(
    store.pickup_before_close
  );
  const cookingTime = convertTimeStringToSeconds(store.cooking_time);
  const worktimeTo = calculateWorktimeToInSeconds(store);
  const secondsBeforeClose = worktimeTo - currentTime;
  return (
    secondsBeforeClose > 0 &&
    secondsBeforeClose <= pickupBeforeClose + cookingTime
  );
}

function getWorktimeShedule(store: IStore) {
  const weekDays = [1, 2, 3, 4, 5, 6, 7];
  return weekDays.map((weekDay) => {
    const index = store.worktime.findIndex(
      (current) => current.day === weekDay
    );
    if (index !== -1) {
      const day = store.worktime[index];
      if (validWorktime(day)) {
        return formatWorktime(day);
      }
    }

    return {
      day: weekDay,
      worktime_from: '-',
      worktime_to: '-',
    };
  });
}

const findNearestPickupDate = (store: IStore, addCookingTime?: boolean) => {
  const pickupBeforeClose = convertTimeStringToSeconds(
    store.pickup_before_close
  );
  const nowDate = getDateInSeconds(new Date());
  const isStill = isStillClosed(store);
  const isAlready = isAlreadyClosed(store);
  const isWill = isWillCloseSoon(store);
  if (!isStill && !isAlready && !isWill) {
    return new Date(nowDate + pickupBeforeClose);
  }
  if (isStill) {
    const worktimeFrom = getWorktimeFromToInSeconds(store, 'from') * 1000;
    return new Date(
      addCookingTime
        ? worktimeFrom + convertTimeStringToSeconds(store.cooking_time) * 1000
        : worktimeFrom
    );
  }
  for (let i = 1; i < 8; i += 1) {
    const availableDay = addDays(new Date(), i);
    const tommorowWorktime = getWorkTime(store, availableDay);
    if (validWorktime(tommorowWorktime))
      return new Date(
        getWorktimeFromToInSeconds(store, 'from', availableDay) * 1000
      );
  }
  return null;
};

const flatMetro = (stores: IStoresFromApi[]): IStore[] => {
  return stores.map((store) => {
    return {
      ...store,
      subway: store.subway.map((sw) => sw.title).join(', '),
    };
  });
};

const getPickupButtonTexts = ({
  t,
  store,
}: {
  t: TFunction;
  store?: IStore;
}) => {
  if (!store) return { infoText: '', buttonText: '' };
  if (isStillClosed(store)) {
    return {
      infoText: t('Map.still_closed'),
      buttonText: t('Map.will_take_here'),
    };
  }
  if (isAlreadyClosed(store)) {
    return {
      infoText: t('Map.already_closed'),
      buttonText: '',
    };
  }
  if (isWillCloseSoon(store)) {
    return {
      infoText: t('Map.will_close_soon'),
      buttonText: '',
    };
  }
  return {
    infoText: '',
    buttonText: t('Map.will_take_here'),
  };
};

const storeHelper = {
  getAddress,
  isStillClosed,
  isAlreadyClosed,
  isWillCloseSoon,
  isCloseAfterMidnight,
  isEverydaySameTime,
  getWorktimeShedule,
  getWorkTime,
  findNearestPickupDate,
  flatMetro,
  getPickupButtonTexts,
};

export default storeHelper;

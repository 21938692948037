import { parseCity } from 'modules/helpers/url-helper';

import * as session from 'modules/session';
import globalStore from 'modules/global-store';
import escapeRegexp from 'modules/helpers/escape-regexp';

// let __cities = null;

function findCity(city) {
  const cities = globalStore.get('cities');
  return cities.find((item) => item.text_id === city);
}

function _route(path = null, params = {}) {
  if (params && typeof params === 'object') {
    let reg;
    for (const key in params) {
      reg = new RegExp(`{${escapeRegexp(key)}}`, 'gi');
      if (reg.test(path)) {
        path = path.replace(reg, params[key]);
        delete params[key];
      }
    }
    const queryParsed = (path || '/').split('?');
    path = queryParsed.shift();

    path = path.replace(/(\/)*$/, '/');

    const query = [];

    if (queryParsed.length) {
      query.push(queryParsed.join('&'));
    }

    for (const key in params) {
      query.push(`${key}=${params[key]}`);
    }

    if (query.length) {
      path += `?${query.join('&')}`;
    }
  }
  return path.replace(/^(\/?)/, '/');
}

function isAbsoluteUrl(path) {
  return /^(?:http(?:s)?:)?\/\//i.test(path);
}
function route(path, params = {}) {
  if (isAbsoluteUrl(path)) {
    return path;
  }
  const url = _route(path, params);
  const city = parseCity(url);
  if (city === null || !findCity(city)) {
    return `/${session.get('city')}${url}`;
  }
  return url;
}

route.isAbsoluteUrl = isAbsoluteUrl;
export { route };

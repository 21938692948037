import { TFunction } from 'i18next';

export function getFullAddress(form: Record<string, string>, t: TFunction) {
  const {
    city,
    district,
    street,
    house,
    building,
    entrance,
    floor,
    apartment,
  } = form;

  const fullAddress = [
    city && `${t('BlockTotal.order_city')} ${city}`,
    district && district,
    street && street,
    house && `${t('BlockTotal.order_house')} ${house}`,
    building && `${t('BlockTotal.order_building')} ${building}`,
    entrance && `${t('BlockTotal.order_entrance')} ${entrance}`,
    floor && `${t('BlockTotal.order_floor')} ${floor}`,
    apartment && `${t('BlockTotal.order_apartment')} ${apartment}`,
  ]
    .filter(Boolean)
    .join(', ');

  return fullAddress;
}

import { Component } from 'react';
import bind from 'autobind-decorator';
import { withTranslation } from 'react-i18next';
import Button from 'src/shared/ui/Button/Button';
import cn from 'classnames';
import RMCarousel from 'react-multi-carousel';
import WokSlider from './WokSlider';
import 'react-multi-carousel/lib/styles.css';

class Slider extends Component {
  constructor(props) {
    super(props);
    this._slider = WokSlider.withLength(this.slides.length);
  }

  @bind onTotalPriceClick(event) {
    if (this._slider.isLastSlideSelected()) {
      this.props.onAddWokToCartClick(event);
      setTimeout(() => {
        this.track.goToSlide(0);
        this.reRender();
      }, 1000);
    } else {
      this.track.next();
      this.reRender();
    }
  }

  @bind onResetWokClick(event) {
    this.props.onResetWokClick(event);
    this.track.goToSlide(0);
    this.reRender();
  }

  get slides() {
    return this.props.children.reduce((slides, c) => slides.concat(c), []);
  }

  setActiveSlide(index) {
    this.track.goToSlide(index);
    this.reRender();
  }

  @bind reRender() {
    this.forceUpdate();
  }

  @bind next() {
    this.track.next();
    this.reRender();
  }

  @bind prev() {
    this.track.previous();
    this.reRender();
  }

  renderBuyButton() {
    const { t, totalPrice, currency } = this.props;

    const resetBtn =
      'card__button card__button--no-plus card__button--mr20 card__button--hide-on-mobile card__button--no-hover card__button--default';
    const totalSumBtn = cn('card__button', {
      'card__button--no-plus': this._slider.isNotLastSlideSelected(),
      'card__button--hover-no-hidden': !this._slider.isNotLastSlideSelected(),
    });
    return (
      <div className="card__buy-action button-for-wok">
        <Button
          className={resetBtn}
          onClick={this.onResetWokClick}
          text={t('CardWok.drop')}
        />

        <Button
          className={totalSumBtn}
          onClick={this.onTotalPriceClick}
          text={t('CardWok.Total_sum')}
        >
          <span className="card__total">
            {totalPrice}&nbsp;{currency}
          </span>
          <span className="card__button-add" />
        </Button>
      </div>
    );
  }

  render() {
    const pagerControlPrev = cn('pager-control pager-control--prev', {
      hidden: this._slider.isFirstSlideSelected(),
    });
    const pagerControlNext = cn('pager-control pager-control--next', {
      hidden: this._slider.isLastSlideSelected(),
    });
    return (
      <>
        <div className="card-wok__items">
          <button
            type="button"
            className={pagerControlPrev}
            onClick={this.prev}
            aria-label="previous"
          />
          <button
            type="button"
            className={pagerControlNext}
            onClick={this.next}
            aria-label="next"
          />
          <RMCarousel
            arrows={false}
            containerClass="containerClass"
            sliderClass="sliderClass"
            itemClass="vacancy__card__carousel__item"
            beforeChange={(index) => {
              this._slider.goToSlideWithIndex(index);
              this.forceUpdate();
            }}
            responsive={{
              allDesktops: {
                breakpoint: { max: 3000, min: 100 },
                items: 1,
              },
            }}
            ref={(c) => (this.track = c)}
            deviceType="allDesktops"
            ssr
          >
            {this.slides.map((s, i) => (
              <div key={i} className="view">
                {s}
              </div>
            ))}
          </RMCarousel>
        </div>
        <div className="card-wok__bottom">
          <div className="card-wok-mobile__step-container">
            <ul>
              {this._slider.collectIndexes((index) => (
                <li
                  onClick={() => this.setActiveSlide(index)}
                  className={cn({
                    active: this._slider.isSlideSelected(index),
                  })}
                  key={`slide-${index}`}
                >
                  {index + 1}
                </li>
              ))}
            </ul>
            <div className="bdr" />
          </div>
          {this.renderBuyButton()}
        </div>
      </>
    );
  }
}
export default withTranslation()(Slider);

// Styles
import './ReorderModal.scss';

import './ReorderModalMobile.scss';
import { Component } from 'react';
import Modal from 'react-modal';

// import * as session from 'modules/session';

// Emitters
import { modalEmitter, orderEmitter } from 'modules/event-emitters';

// Components
import { withTranslation } from 'react-i18next';
import Button from 'src/shared/ui/Button/Button';
import { withBreakpoints } from 'frontend/hocs';

import OrderList from '../../Profile/Order/OrderList';
import modernModal from '../ModernModal';

const customStyles = {
  overlay: {
    zIndex: 20,
    backgroundColor: 'rgba(252, 252, 252, 0.7)',
    overflowY: 'auto',
  },
  content: {
    maxWidth: '790px',
    top: '20%',
    left: '0',
    right: '0',
    bottom: 'auto',
    margin: 'auto',
    overflow: 'visible',
    padding: '18px 60px 36px',
    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 16px 60px 20px',
    boxSizing: 'border-box',
    marginBottom: '50px',
  },
};

const mobileStyles = {
  overlay: {
    zIndex: 20,
    backgroundColor: '#fff',
    overflowY: 'auto',
  },
  content: {
    maxWidth: '790px',
    top: '0',
    left: '0',
    right: '0',
    bottom: 'auto',
    margin: 'auto',
    overflow: 'visible',
    padding: '30px 20px',
    border: 'none',
    boxSizing: 'border-box',
    marginBottom: '0',
  },
};

class ReorderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      order: null,
    };
  }

  componentDidMount() {
    Modal.setAppElement('body');
    modalEmitter.addListener('Reorder.Modal.Show', this.modalShow);
    modalEmitter.addListener('Reorder.Modal.Close', this.modalClose);
  }

  componentWillUnmount() {
    modalEmitter.removeListener('Reorder.Modal.Show', this.modalShow);
    modalEmitter.removeListener('Reorder.Modal.Close', this.modalClose);
  }

  modalShow = (order) => {
    modernModal.openModal();
    this.setState({
      order,
      modalIsOpen: true,
    });
  };

  modalClose = () => {
    modernModal.closeModal();
    this.setState({
      order: null,
      modalIsOpen: false,
    });
  };

  reorderAdd = (e) => {
    e.preventDefault();
    orderEmitter.emit('Order.Reorder.Add', this.prepareCart());
  };

  reorderNew = (e) => {
    e.preventDefault();
    orderEmitter.emit('Order.Reorder.New', this.prepareCart());
  };

  prepareCart = () => {
    const { order } = this.state;
    return {
      ...order,
      ...order.items.filter((item) => item.price !== 0 && item.price !== null),
    };
  };

  render() {
    const { order } = this.state;

    if (!order) {
      return null;
    }

    const { t, isMobile } = this.props;

    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        onRequestClose={this.modalClose}
        style={isMobile ? mobileStyles : customStyles}
        contentLabel="modal-reorder"
      >
        <div className="reorder-modal">
          <OrderList orders={[order]} type="modal" />
          <div className="reorder-modal__button">
            <div className="button__case">
              <Button
                className="sw-button"
                style={{ width: '100%' }}
                onClick={this.reorderAdd}
                text={t('ReorderModal.Merge cart button')}
              />
            </div>
            <div className="button__case">
              <Button
                className="sw-button"
                style={{ width: '100%' }}
                onClick={this.reorderNew}
                text={t('ReorderModal.NewCartButton')}
              />
            </div>
          </div>
        </div>
        <div
          className="close-modal-btn close-modal-btn--reorder"
          data-action="close"
          onClick={this.modalClose}
        />
      </Modal>
    );
  }
}
export default withTranslation()(withBreakpoints(ReorderModal));

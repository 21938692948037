import './Reviews.scss';
import classnames from 'classnames';
import { Component } from 'react';
import api from 'modules/helpers/api';
import ssr from 'modules/decorators/ssr';
import { reviewsFilterEmitter, historyEmitter } from 'modules/event-emitters';
import globalStore from 'modules/global-store';
import { route } from 'modules/route';
import { Breadcrumbs } from 'shared/ui/breadcrumbs';
import { PageTitle } from 'shared/ui/page-title';
import { PageContainer } from 'shared/ui/page-container';
import ErrorComponent from '../../Components/ErrorComponent/ErrorComponent';
import ReviewsList from '../../Components/Reviews/List/List';
import ReviewsFilter from '../../Components/Reviews/Filter/Filter';
import ReviewsAdd from '../../Components/Reviews/Add/Add';
import PageScroller from '../../helpers/PageScroller';
import CustomText from '../../Components/CustomText/CustomText';
import CustomHelmet from '../../Components/CustomHelmet';

const DEFAULT_LIMIT = 10;

class Reviews extends Component {
  static LOADED_KEY = 'reviews_loaded';

  static initialData = (fetch, params = {}, globalStore) => {
    const { page = 0, limit = DEFAULT_LIMIT } = params;
    return Promise.all([
      fetch('store.get_list_city')
        .then((result) => globalStore.set('stores', result))
        .catch((e) => {
          console.error('Reviews 1', e);
          return [];
        }),
      fetch('review.list', { page, limit })
        .then((result) => globalStore.set('reviews', result))
        .catch((e) => {
          console.error('Reviews 2', e);
          return [];
        }),
    ])
      .then(() => [])
      .catch((e) => {
        console.error(e);
        return [];
      });
  };

  static isLast(entities = []) {
    return entities.some((n) => n.last);
  }

  constructor(props) {
    super(props);
    const {
      params: { page },
    } = this.props.match;
    const reviews = globalStore.get('reviews', []);

    const numberPage = parseInt(page, 10) || 1;
    const isLast = Reviews.isLast(reviews);

    this.state = {
      city_stores: globalStore.get('stores', []),
      reviews: reviews || undefined,
      reviewsByStore: undefined,
      stringPage: page,
      store_id: 0,
      numberPage,
      isLast,
      inProgress: false,
    };
  }

  componentDidMount() {
    if (globalStore.get(Reviews.LOADED_KEY)) {
      globalStore.unlink(Reviews.LOADED_KEY);
    } else {
      Reviews.initialData(api, {}, globalStore).then(() => {
        this.setState({
          reviews: globalStore.get('reviews', []),
          city_stores: globalStore.get('stores', []),
        });
      });
    }
    reviewsFilterEmitter.addListener('filter', this.filterHandler);
  }

  componentWillUnmount() {
    reviewsFilterEmitter.removeListener('filter', this.filterHandler);
  }

  // @todo чезахерня??
  filterHandler = ({ city, store_id }) => {
    if (store_id > 0) {
      api('review.list', { city, store_id }).then((reviews) => {
        this.setState({
          reviewsByStore: reviews,
          store_id,
        });
      });
    } else {
      this.setState({
        reviewsByStore: undefined,
        store_id: 0,
      });
    }
  };

  loadMoreReviews = (event) => {
    event.preventDefault();
    const { reviews: oldReviews = [], numberPage } = this.state;

    this.setState({ inProgress: true });

    api('review.list', { limit: DEFAULT_LIMIT, page: numberPage }).then(
      (reviews) => {
        if (reviews.length) {
          this.setState({
            reviews: oldReviews.concat(reviews),
            numberPage: numberPage + 1,
            isLast: Reviews.isLast(reviews),
            inProgress: false,
          });
          const pageScroller = new PageScroller();
          pageScroller.skipNextScroll();
          historyEmitter.emit(
            'HISTORY_PUSH',
            route(`reviews/${numberPage + 1}`)
          );
        } else {
          this.setState({
            isLast: true,
            inProgress: false,
          });
        }
      }
    );
  };

  render() {
    const {
      inProgress,
      isLast,
      reviews,
      reviewsByStore,
      city_stores,
      store_id,
      numberPage,
      stringPage,
    } = this.state;
    const store = city_stores.find((store) => store.id === +store_id);
    const { t, history: { location } = {} } = this.props;

    if (stringPage && Number.isNaN(parseInt(stringPage, 10))) {
      return <ErrorComponent />;
    }

    if (parseInt(stringPage, 10) <= 0) {
      return <ErrorComponent />;
    }

    const classNameEventsMore = classnames(
      'page-reviews__more',
      { 'page-reviews__more--inactive': inProgress },
      { 'page-reviews__more--shown': !isLast }
    );

    return (
      <PageContainer>
        <CustomHelmet title={t('localization.Reviews')} />
        <Breadcrumbs
          links={[
            {
              text: t('localization.Reviews'),
            },
          ]}
        />
        <PageTitle>{t('Reviews.reviews_title')}</PageTitle>
        <div className="page-reviews">
          <div className="panel-container">
            <ReviewsFilter city_stores={city_stores} />
            <div className="reviews-tabs">
              <div className="reviews-tabs__item item__review-list reviews-tabs__item--active">
                {t('Reviews.reviews_list')}
              </div>
              <ReviewsAdd
                city_stores={city_stores}
                store={store}
                location={location}
              />
            </div>
          </div>
          <ReviewsList reviews={reviewsByStore || reviews} store={store} />
          {reviews && reviews.length && !isLast && !reviewsByStore ? (
            <div className="page-reviews__more-wrap">
              <a
                href={route(`reviews/${numberPage + 1}`)}
                className={classNameEventsMore}
                onClick={this.loadMoreReviews}
              >
                {t('Reviews.show_more')}
              </a>
            </div>
          ) : undefined}
        </div>
        <CustomText />
      </PageContainer>
    );
  }
}

export default ssr(Reviews);

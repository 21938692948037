import { useTranslation } from 'react-i18next';

function DeliveryFormTitle() {
  const { t } = useTranslation();
  return (
    <div className="block-form__title-container">
      <h3>{t('BlockForm.clearance_delivery')}</h3>
    </div>
  );
}

export default DeliveryFormTitle;

import dateValidator from './Validators/date';
import phoneValidator from './Validators/phone';
import emailValidator from './Validators/email';
import defaultValidator from './Validators/default';

export default function (props) {
  const { type = 'default' } = props;
  let Validator;

  switch (type) {
    case 'phone':
      Validator = phoneValidator;
      break;
    case 'date':
      Validator = dateValidator;
      break;
    case 'email':
      Validator = emailValidator;
      break;
    default:
      Validator = defaultValidator;
  }

  return new Validator(props);
}

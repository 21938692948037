import { MouseEventHandler } from 'react';
import cn from 'classnames';
import MinusIcon from './assets/minusNoFill.svg';
import PlusIcon from './assets/plusNoFill.svg';
import style from './counter.module.scss';

export interface ICounterProps {
  qty: number;
  step: number;
  className?: string;
  disabled?: boolean;
  setQty: (
    count: number,
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

function Counter({
  qty,
  step,
  className = '',
  disabled,
  setQty,
}: ICounterProps) {
  const countDecr: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (qty > 0) setQty(-step, e);
  };
  const countIncr: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!disabled) setQty(step, e);
  };

  const container = cn(style.plusMinusContainer, { [className]: className });

  return (
    <div role="spinbutton" className={container}>
      <button
        type="button"
        className={cn(style.button, style.minus, {
          [style.disabled]: qty < 1,
        })}
        onClick={countDecr}
      >
        <MinusIcon width={14} height={14} />
      </button>

      <input className={style.count} value={qty} type="text" readOnly />

      <button
        type="button"
        className={cn(style.button, style.plus, {
          [style.disabled]: disabled,
        })}
        onClick={countIncr}
      >
        <PlusIcon width={14} height={14} />
      </button>
    </div>
  );
}

export default Counter;

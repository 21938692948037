import { createSlice } from '@reduxjs/toolkit';
import { IHeaderMenu } from './types';

const initState = [] as IHeaderMenu[];

export const headerMenuSlice = createSlice({
  name: 'header_menu',
  initialState: initState,
  reducers: {},
});

const selectHeaderMenu = (state: RootState) => {
  return state.header_menu;
};

export const headerMenuSliceSelectors = {
  selectHeaderMenu,
};

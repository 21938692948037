import { Component } from 'react';
import autobind from 'autobind-decorator';
import * as session from 'modules/session';
import { withSelectedCountry } from 'src/entities/countries';
import cn from 'classnames';
import * as cartHelper from 'modules/helpers/cart-helper';
import { withTranslation } from 'react-i18next';

class Buttons extends Component {
  constructor(props) {
    super(props);
    const cart = session.get('cart');
    const cutleryMaxQty = cartHelper.getCutleryMaxQty(cart);
    const { order: { cutlery_qty: cutleryQty = 0 } = {} } = cart;
    this.state = {
      cutleryQty,
      cutleryMaxQty,
    };
  }

  @autobind
  changeQuantity(action) {
    let { cutleryQty } = this.state;
    const { handleChangeForm } = this.props;
    if (action === 'inc' && !this.isLimitReached()) cutleryQty += 1;
    else if (action === 'dec' && cutleryQty >= 1) cutleryQty -= 1;

    this.setState({ cutleryQty });
    cartHelper.sendOrderField('cutlery_qty', cutleryQty);
    handleChangeForm({ target: this.input });
  }

  isWithoutCutlery() {
    const { cutleryMaxQty } = this.state;
    return !cutleryMaxQty;
  }

  isLimitReached() {
    const { cutleryQty, cutleryMaxQty } = this.state;
    return cutleryQty >= cutleryMaxQty;
  }

  render() {
    const { cutleryQty } = this.state;
    const { field } = this.props;
    const { label, fieldsetClassName = '', invalid } = field;
    const { t, isUkraine } = this.props;
    const isLimitReached = this.isLimitReached();
    const isWithoutCutlery = this.isWithoutCutlery();

    const cutleryLimit = cn('bottom-label cutlery_limit', {
      hidden: !isLimitReached,
    });
    const cutleryAdvise = cn('bottom-label cutlery_limit', {
      hidden: +cutleryQty > 0,
    });
    const minusBtn = cn('round-btn minus', {
      disabled: +cutleryQty === 0,
      ua: isUkraine,
    });
    const plusBtn = cn('round-btn plus', {
      disabled: isLimitReached,
      ua: isUkraine,
    });

    return (
      !isWithoutCutlery && (
        <fieldset
          name="counter-buttons"
          className={`buttons-row ${fieldsetClassName || ''} ${
            invalid ? 'invalid' : ''
          }`}
        >
          <div className="labels-container">
            <label htmlFor="">{label}</label>
            <label className={cutleryLimit}>
              {t('locale.cutlery_limit_message')}
            </label>
            <label className={cutleryAdvise}>
              {t('locale.cutlery_advise_message')}
            </label>
          </div>
          <div className="btns-container">
            <span
              className={minusBtn}
              onClick={() => {
                this.changeQuantity('dec');
              }}
            />
            <span className="value">{cutleryQty}</span>
            <input
              type="hidden"
              name="cutlery_qty"
              value={cutleryQty}
              ref={(x) => (this.input = x)}
            />
            <span
              className={plusBtn}
              onClick={() => {
                this.changeQuantity('inc');
              }}
            />
          </div>
        </fieldset>
      )
    );
  }
}

export default withTranslation()(withSelectedCountry(Buttons));

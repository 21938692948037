import RMCarousel, { ButtonGroupProps } from 'react-multi-carousel';
import styles from './carousel.module.scss';
import { ITrioItem } from '../types/trioOnMainPage';
import TrioItem from '../trio-item/TrioItem';
import 'react-multi-carousel/lib/styles.css';
import Control from './Control';

function ButtonGroup({ next, previous, carouselState }: ButtonGroupProps) {
  if (carouselState === undefined) return null;
  return (
    <>
      {carouselState.currentSlide !== 0 && (
        <Control onClick={previous} direction="left" />
      )}
      {carouselState.currentSlide + carouselState.slidesToShow !==
        carouselState.totalItems && (
        <Control onClick={next} direction="right" />
      )}
    </>
  );
}
function Carousel({ items }: { items: ITrioItem[] }) {
  if (!items || !items.length) return null;
  const responsive = {
    mobile: { breakpoint: { max: 430, min: 0 }, items: 1 },
    tablet: { breakpoint: { max: 768, min: 431 }, items: 2 },
    laptop: { breakpoint: { max: 1024, min: 769 }, items: 3 },
  };
  return (
    <RMCarousel
      arrows={false}
      renderButtonGroupOutside
      customButtonGroup={<ButtonGroup />}
      removeArrowOnDeviceType={['laptop']}
      responsive={responsive}
      deviceType="mobile"
      ssr
      sliderClass={styles.sliderClass}
    >
      {items.map((item) => {
        return <TrioItem key={item.id} item={item} />;
      })}
    </RMCarousel>
  );
}

export default Carousel;

import Template from '../frontend/Pages/Template/Template';
import ProductList from '../frontend/Pages/ProductList/ProductList';
import { Menu } from '../frontend/Pages/menu';
import ProductSingle from '../frontend/Pages/ProductSingle/ProductSingle';
import About from '../frontend/Pages/About/About';
import Arguments from '../frontend/Pages/Arguments/Arguments';
import Franchise from '../frontend/Pages/franchise/Franchise';
import { MainPage } from '../frontend/Pages/main';
import Reviews from '../frontend/Pages/Reviews/Reviews';
import { Stores } from '../frontend/Pages/stores';
import Vacancies from '../frontend/Pages/Vacancies/Vacancies';
import Vacancy from '../frontend/Pages/Vacancy/Vacancy';
import NewsAll from '../frontend/Pages/News/News';
import NewsPage from '../frontend/Pages/OneNews/OneNews';
import Articles from '../frontend/Pages/Articles/Articles';
import Article from '../frontend/Pages/Article/Article';
import Stocks from '../frontend/Pages/Stocks/Stocks';
import Stock from '../frontend/Pages/Stock/Stock';
import QualityPage from '../frontend/Pages/Quality/QualityPage';
import StaticPage from '../frontend/Pages/StaticPage/StaticPage';
import Cart from '../frontend/Pages/cart/Cart';
import OrderingForm from '../frontend/Components/NewCartOrder/Components/BlockForm/OrderingForm';
import BlockMap from '../frontend/Components/NewCartOrder/Components/BlockMap/BlockMap';
import ErrorPage from '../frontend/Pages/ErrorPage/ErrorPage';
import SubscribePage from '../frontend/Pages/Subscribe/Subscribe';
import OrderPage from '../frontend/Pages/Order/Info/OrderInfoPage';
import OrderFeedback from '../frontend/Pages/Order/Feedback/OrderFeedback';
import Profile from '../frontend/Pages/Profile/Profile';
import OrdersPage from '../frontend/Pages/Profile/Orders/Orders';
import FavoritesPage from '../frontend/Pages/Profile/favorites-page/FavoritesPage';
import ProfilePage from '../frontend/Pages/Profile/Profile/Profile';
import OrganizationContactInformation from '../frontend/Pages/OrganizationContactInformation/OrganizationContactInformation';
import { Delivery } from '../frontend/Pages/delivery';
// import Game from '../frontend/Pages/Game/Game';
// import ValentinesOracle from '../frontend/Pages/Game/valentinesDay/ValentinesOracle';
// import Drum from '../frontend/Pages/Game/Drum/Drum';
// import Oracle from '../frontend/Pages/Game/NewYear/Oracle';
export default [
  {
    path: '/:city',
    component: Template,
    routes: [
      { path: '/:city/', exact: true, component: MainPage },
      { path: '/:city/about', exact: true, component: About }, // without page-container
      { path: '/:city/arguments', exact: true, component: Arguments }, // without page-container
      { path: '/:city/franchise', exact: true, component: Franchise },
      // method /review/submit_quality don`t work
      { path: '/:city/quality', exact: true, component: QualityPage },
      { path: '/:city/reviews/:page?', component: Reviews },
      {
        path: '/:city/delivery',
        exact: true,
        component: Delivery,
      },
      { path: '/:city/addresses', exact: true, component: Stores },

      {
        path: '/:city/menu/:menu/:product',
        exact: true,
        component: ProductSingle,
      },

      { path: '/:city/menu/:menu', exact: true, component: ProductList },
      { path: '/:city/menu', exact: true, component: Menu },

      { path: '/:city/news/:news_item', exact: true, component: NewsPage },
      { path: '/:city/news', exact: true, component: NewsAll },

      { path: '/:city/articles/:article', exact: true, component: Article },
      { path: '/:city/articles', exact: true, component: Articles },
      {
        path: '/:city/vakansii/:vacancies_item',
        exact: true,
        component: Vacancy,
      },
      { path: '/:city/vakansii', exact: true, component: Vacancies },

      { path: '/:city/akcii/:stock', exact: true, component: Stock },
      { path: '/:city/akcii', exact: true, component: Stocks },
      { path: '/:city/page/:static_id', exact: true, component: StaticPage },
      { path: '/:city/subscribe/email/:action', component: SubscribePage },

      // order
      {
        path: '/:city/order/feedback/:order_hash/:order_salt',
        exact: true,
        component: OrderFeedback,
      },
      {
        path: '/:city/order/:order_hash/:order_salt',
        exact: true,
        component: OrderPage,
      },

      // profile
      {
        path: '/:city/profile',
        component: Profile,
        routes: [
          { path: '/:city/profile/orders', exact: true, component: OrdersPage },
          {
            path: '/:city/profile/favorites',
            exact: true,
            component: FavoritesPage,
          },
          { path: '/:city/profile', exact: true, component: ProfilePage },
        ],
      },

      // cart
      {
        path: '/:city/cart',
        component: Cart,
        routes: [
          {
            path: '/:city/cart/delivery',
            exact: true,
            component: OrderingForm,
          },
          { path: '/:city/cart/pickup', exact: true, component: BlockMap },
          {
            path: '/:city/cart/pickup/:store_coords',
            exact: true,
            component: OrderingForm,
          },
        ],
      },

      // organization contact information
      {
        path: '/:city/dannye-prodavca',
        exact: true,
        component: OrganizationContactInformation,
      },
      // {
      //   path: '/:city/game',
      //   component: Game,
      //   routes: [
      //     {
      //       path: '/:city/game/wheel-of-fortune',
      //       exact: true,
      //       component: Drum,
      //     },
      //     {
      //       path: '/:city/game/new-year-oracle',
      //       exact: true,
      //       component: Oracle,
      //     },
      //     {
      //       path: '/:city/game/orakul-romashka-8m',
      //       exact: true,
      //       component: ValentinesOracle,
      //     },
      //   ],
      // },
      // don`t touch pls, must always be the lowest
      { component: ErrorPage },
    ],
  },
];

import { Component } from 'react';
import { cardsEmitter, filterPanelEmitter } from 'modules/event-emitters';
import autobind from 'autobind-decorator';
import * as session from 'modules/session';
import { replaceQuery, getQueryObject } from 'modules/router-query-utils';
import { withTranslation } from 'react-i18next';
import SortCheckbox from '../Components/Checkbox/SortCheckbox/SortCheckbox';

class Price extends Component {
  constructor(props) {
    super(props);
    const query = session.get('query');
    const price = query.price ? +query.price : SortCheckbox.STATE.NONE;
    this.state = {
      sortState: price,
    };
  }

  componentDidMount() {
    cardsEmitter.addListener(
      'ProductList.sortPopular',
      this.filterByPopularHandler
    );
    filterPanelEmitter.addListener('reset_all', this.reset);
  }

  componentWillUnmount() {
    cardsEmitter.removeListener(
      'ProductList.sortPopular',
      this.filterByPopularHandler
    );
    filterPanelEmitter.removeListener('reset_all', this.reset);
  }

  @autobind
  onTogglePrice({ sortState }) {
    this.setState({ sortState });
    const query = getQueryObject({ price: sortState });
    delete query.popular;
    replaceQuery(query);
    cardsEmitter.emit('ProductList.sortPrice', sortState);

    // eventBus.emitEvent('product_filter', [
    //   {
    //     type: 'price',
    //   },
    // ]);
  }

  @autobind
  filterByPopularHandler(sortState) {
    if (sortState !== SortCheckbox.STATE.NONE) {
      this.setState({
        sortState: SortCheckbox.STATE.NONE,
      });
    }
  }

  @autobind
  reset() {
    this.onTogglePrice({ name: 'price', sortState: SortCheckbox.STATE.NONE });
  }

  render() {
    const { sortState } = this.state;
    const { t } = this.props;
    return (
      <SortCheckbox
        name="price"
        title={t('Price.ByPrice')}
        sortState={sortState}
        onToggle={this.onTogglePrice}
      />
    );
  }
}

export default withTranslation()(Price);

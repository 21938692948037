import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelectedCountry } from 'src/entities/countries';
import { SubscribeModalButton } from './subscribe-modal-button';
import { TimeAndPhoneInfo } from './time-and-phone-info';
import { FooterLinks } from './footer-links';
import { SocialLinks } from './social-links';
import { Copyright } from './copyright';
import styles from './footer.module.scss';
import { UaPayment, ByPayment } from './payment';

export const Footer = memo(() => {
  const location = useLocation();
  const { isUkraine, isBelarus } = useSelectedCountry();
  const isRenderFooter = () => {
    const renderConditions = ['arguments', 'about', 'hall', 'kitchen'];

    const [, name] = location.pathname.match(/([A-z]+)(\/?)$/) || [];

    return !renderConditions.includes(name);
  };

  if (!isRenderFooter()) return null;

  return (
    <footer
      itemScope
      itemType="http://schema.org/WPFooter"
      className={styles.root}
    >
      <FooterLinks />
      <div className={styles.socialsAndTimeWrapper}>
        <SocialLinks />
        <TimeAndPhoneInfo />
      </div>
      {isUkraine && <UaPayment />}
      {isBelarus && <ByPayment />}
      <div className={styles.additioinal}>
        <Copyright />
        {!isUkraine && <SubscribeModalButton />}
      </div>
    </footer>
  );
});

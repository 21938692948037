import { useEffect } from 'react';
import * as session from 'modules/session';
import globalStore from 'modules/global-store';
import { eventBus } from 'modules/event-emitters';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Button from 'src/shared/ui/Button/Button';
import { IProduct } from 'types/product';
import { IItems } from 'types/items';

import Emitter from '../../../Emitters';
import Card from '../card/Card';
import modalStyles from '../../main-card-modal/mainCartModal.module.scss';
import styles from './promocodeCartModal.module.scss';

const getDiscountProducts = (items: IItems[] = []) => {
  const products: IProduct[] = globalStore.take('products');
  return items.map((item) => {
    const product = JSON.parse(
      JSON.stringify(products.find((p) => +p.id === +item.product_id))
    );
    if (product) {
      product.discount_price = Math.floor(
        product.price - (product.price * item.discount) / 100
      );
      product.discount = item.discount;
    }
    return product;
  });
};

const getProducts = (items = []) => {
  const products = globalStore.take('products');
  if (!Array.isArray(products)) {
    return [];
  }
  return items
    .map((id) => {
      return products.find((product) => +product.id === id);
    })
    .filter((item) => item);
};

function PromocodeCartModal() {
  const { t } = useTranslation();
  const {
    promo_code,
    promo_code_bonuses,
    promo_applied: isAccepted,
  } = session.get('cart');

  const {
    discount = null,
    product_discount = [],
    product_ids = [],
    message,
  } = promo_code_bonuses || {};
  const isPromocode = !!promo_code;
  const isDiscount = !!discount;
  const isProductDiscount = !!product_discount.length;
  const productsDiscount = getDiscountProducts(product_discount);
  const products = getProducts(product_ids);
  const isProducts = !!product_ids.length && !!products.length;

  useEffect(() => {
    if (promo_code && isAccepted) {
      eventBus.emitEvent('appliedPromocode');
    }
  }, [isAccepted, promo_code]);

  const getText = () => {
    return isPromocode ? (
      <span>{message}</span>
    ) : (
      t('PromocodeAnswered.the_code_reset')
    );
  };

  const confirm = () => {
    Emitter.emit('MODAL_CALL', { modal: false });
  };

  return (
    <div className={styles.promocodeModal}>
      <div className={modalStyles.titleContainer}>
        <p>{getText()}</p>
      </div>
      {isPromocode && (
        <div>
          {isDiscount && (
            <div className={styles.discountRow}>
              <p>
                {t('PromocodeAnswered.got_discount')} {discount}%
              </p>
            </div>
          )}
          {isProducts && (
            <Card {...{ products, title: t('PromocodeAnswered.gift') }} />
          )}
          {isProductDiscount && (
            <Card
              {...{
                products: productsDiscount,
                title: t('PromocodeAnswered.discount'),
              }}
            />
          )}
        </div>
      )}

      <div className={modalStyles.btnContainer}>
        <Button
          className={cn('sw-button', styles.swButton)}
          onClick={confirm}
          text={t('locale.good')}
        />
      </div>
    </div>
  );
}

export default PromocodeCartModal;

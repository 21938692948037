import { useSelectedCountry } from 'src/entities/countries';
import { useTranslation } from 'react-i18next';
import { useSelectCity } from 'src/entities/cities';

function SupportChat() {
  const { isRussia, isUkraine } = useSelectedCountry();
  const isChatEnabled = useSelectCity()?.chat_enabled;
  const {
    i18n: { language },
  } = useTranslation();
  if (!isChatEnabled) return null;

  return (
    <>
      {isUkraine && (
        <script
          async
          type="text/javascript"
          src="//widgets.binotel.com/chat/widgets/DKLefGNgIaKgVeYLgVyL.js"
        />
      )}
      {isRussia && (
        <script
          async
          src="//cdn5.helpdeskeddy.com//js/contact-widget.js"
          id="hde-contact-widget"
          data-assets-host="//cdn5.helpdeskeddy.com/"
          data-host="incall.helpdeskeddy.com"
          data-lang={language}
        />
      )}
    </>
  );
}

export default SupportChat;

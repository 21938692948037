/**
 * Created by soldovkij on 04.07.17.
 */

// @todo possible delete this

import { createBrowserHistory } from 'history';

import queryString from 'query-string';

let _history;

const getHistory = () => {
  if (typeof _history === 'undefined') {
    _history = createBrowserHistory();
  }
  return _history;
};

const addQuery = (query) => {
  const { pathname, search } = getHistory().location;
  const _query = queryString.parse(search);
  Object.assign(_query, query);
  getHistory().push({
    pathname,
    search: queryString.stringify(_query),
  });
};

const removeQuery = (...queryNames) => {
  const { pathname, search } = getHistory().location;
  const query = queryString.parse(search);
  queryNames.forEach((q) => {
    if (query[q]) {
      delete query[q];
    }
  });
  getHistory().push({
    pathname,
    search: queryString.stringify(query),
  });
};

const replaceQuery = (query) => {
  const { pathname } = getHistory().location;
  // const location = Object.assign({}, history.location);
  // location.query = query;
  getHistory().push({
    pathname,
    search: queryString.stringify(query),
  });
};

const getQuery = () => {
  return queryString.parse(getHistory().location.search);
  // const location = Object.assign({}, history.location);
  // return location.query;
};

const getQueryObject = (query) => {
  const fullQuery = Object.assign(
    queryString.parse(getHistory().search),
    query
  );
  // @TODO possible nested object ??
  // for(let q in fullQuery) {
  //     // fullQuery[q] = stringHelper.Boolean(fullQuery[q]);
  //     try {
  //         let str = JSON.parse(fullQuery[q]);
  //         fullQuery[q] = str;
  //     }
  //     catch(e) {
  //
  //     }
  // }
  return fullQuery;
};

export { addQuery, removeQuery, getQuery, replaceQuery, getQueryObject };

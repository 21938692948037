/**
 * Created by rebu on 07.02.17.
 */
import debug from '../debug';

function config(_config) {
  return function (key, defaultValue) {
    const parsed = key.split('.');
    const name = parsed.shift();
    function getConfig(key) {
      if (typeof _config[key] === 'undefined') {
        return null;
      }
      return _config[key];
    }
    const config = getConfig(name);

    if (!parsed.length) {
      return config;
    }
    function getValue(names, context, logName, defaultValue) {
      const name = names.shift();
      const value = context && context[name] ? context[name] : null;

      logName += `.${name}`;

      if (!value) {
        debug(`${logName} not found`);
        return defaultValue || null;
      }
      if (names.length) {
        if (typeof value !== 'object') {
          // debug('Context has not childs');
          return defaultValue || null;
        }
        return getValue(names, value, logName, defaultValue);
      }
      return value;
    }
    return getValue(parsed, config, name, defaultValue);
  };
}

export default config;

import { useEffect, useState } from 'react';

function Textarea(props) {
  const [value, setValue] = useState('');

  const {
    field: {
      name,
      label,
      invalid,
      fieldsetClassName = '',
      placeholder = '',
      newValue = '',
    },
  } = props;

  useEffect(() => {
    setValue(newValue);
  }, [newValue]);

  const onChangeHandler = ({ target }) => {
    const { value: changeValue = '' } = target;
    setValue(changeValue);
  };

  return (
    <fieldset
      className={`textarea-row ${invalid ? ' invalid' : ''} ${
        fieldsetClassName || ''
      }`}
    >
      <label htmlFor={`field-${name}`}>{label}</label>
      <textarea
        onChange={onChangeHandler}
        value={value}
        id={`field-${name}`}
        name={name}
        placeholder={placeholder}
      />
    </fieldset>
  );
}

export default Textarea;

import * as Yup from 'yup';
import { nameValidete } from './lib/nameValidete';

export const pickupValidationScheme = Yup.object().shape({
  email: Yup.string().email('Неправильно введён e-mail'),
  name: Yup.string().test(nameValidete).required('Поле должно быть заполнено'),
  phone: Yup.string()
    .trim()
    .required('Поле должно быть заполнено')
    .transform((value) => value.replace(/\D/g, ''))
    .min(11, 'Минимально количество должно быть.'),
});

import './VacanciesInfo.scss';
import { useTranslation } from 'react-i18next';
import TitleCentered from 'src/shared/ui/title-with-lines/title-with-lines';

function VacanciesInfo() {
  const { t } = useTranslation();
  return (
    <div className="vacancies-info">
      <TitleCentered>{t('VacanciesInfo.AdvantagesOfWorking')}</TitleCentered>

      <ul className="vacancies-info__advantages">
        <li>
          <div className="vacancies-info__advantages__title">
            {t('VacanciesInfo.Development')}
          </div>
          <div className="vacancies-info__advantages__text">
            {t('VacanciesInfo.DevelopmentDescription')}.
          </div>
        </li>
        <li>
          <div className="vacancies-info__advantages__title">
            {t('VacanciesInfo.Training')}
          </div>
          <div className="vacancies-info__advantages__text">
            {t('VacanciesInfo.TrainingDescription')}.
          </div>
        </li>
        <li>
          <div className="vacancies-info__advantages__title">
            {t('VacanciesInfo.Capabilities')}
          </div>
          <div className="vacancies-info__advantages__text">
            {t('VacanciesInfo.CapabilitiesDescriptionHead')}:{' '}
            {t('VacanciesInfo.CapabilitiesDescription')}.
          </div>
        </li>
      </ul>
    </div>
  );
}

export default VacanciesInfo;

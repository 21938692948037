import { useEffect, useState } from 'react';
import { modalEmitter } from 'modules/event-emitters';
import { useTranslation } from 'react-i18next';
import SubscribeForm from 'frontend/Components/Modals/SubscribeModal/SubscribeForm';
import styles from './subscribe-modal-button.module.scss';

export function SubscribeModalButton() {
  const { t } = useTranslation();
  const [subscribeModalIsOpen, setSubscribeModalIsOpen] = useState(false);

  const openModal = () => {
    setSubscribeModalIsOpen(true);
  };

  const closeModal = () => {
    setSubscribeModalIsOpen(false);
  };

  useEffect(() => {
    modalEmitter.addListener('Modal.SubscribeModalButton.Open', openModal);

    return () => {
      modalEmitter.removeListener('Modal.SubscribeModalButton.Open', openModal);
    };
  }, []);

  return (
    <>
      <button type="button" className={styles.btn} onClick={openModal}>
        {t('SubscribeModalButton.Subscribe')}
      </button>
      <SubscribeForm opened={subscribeModalIsOpen} onClose={closeModal} />
    </>
  );
}

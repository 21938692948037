/* eslint-disable import/no-duplicates */
import {
  addDays,
  addMinutes,
  eachMinuteOfInterval,
  eachDayOfInterval,
  endOfDay,
  isSameDay,
  max,
  roundToNearestMinutes,
  startOfDay,
  subMinutes,
  format,
  parseISO,
  isValid,
  parse,
  getUnixTime,
  isAfter,
  isBefore,
  isSameMinute,
  getISODay,
  setDay,
  isWithinInterval,
  addMilliseconds,
  differenceInDays,
  differenceInMilliseconds,
} from 'date-fns';
import { ru, uk, pl, srLatn as me, enUS as en, hy } from 'date-fns/locale';
import { get } from 'lodash';

export const formaterDate = (date: string | Date | unknown, view: string) => {
  if (date instanceof Date && isValid(date)) {
    return format(date, view);
  }
  if (typeof date !== 'string') {
    return date;
  }
  return format(parseISO(date), view);
};

const getDateInSeconds = (date: Date) => {
  return getUnixTime(date);
};

const locales = { ru, ua: uk, pl, me, en, hy };

const formatDayOfWeekToString = (dayOfWeek: number, lang: string) => {
  const referenceDate = setDay(new Date(), dayOfWeek);
  const locale = get(locales, lang);
  // Т.к. в русском и украинском принято сокрашение из 2х букв а в остальных из 3х
  const currentFormat = locale === ru || locale === uk ? 'iiiiii' : 'iii';
  return format(referenceDate, currentFormat, { locale });
};

export const formatDateWithLocale = (
  date: string | Date,
  formatValue: string,
  lang: string
) => {
  return format(typeof date === 'string' ? new Date(date) : date, formatValue, {
    locale: get(locales, lang),
  });
};

const dayOfWeek = getISODay;

const formatHelper = {
  fullDate: 'yyyy-MM-dd HH:mm',
  fullDateWithSeconds: 'yyyy-MM-dd HH:mm:ss',
  time: 'HH:mm',
  timeWithSeconds: 'HH:mm:ss',
  month: 'MM',
  day: 'yyyy-MM-dd',
  dayText: 'EEEE, d MMMM',
  date: 'dd.MM.yyyy',
  dateWithShortYear: 'dd.MM.yy',
  monthText: 'd MMMM yyyy',
  timeWithDate: 'HH:mm dd.MM',
} as const;

const stringToDate = (date: string) => {
  return parse(date, formatHelper.date, new Date());
};

const formatDate = (
  stringDate: string,
  inputFormat: string,
  outputFormat: string,
  lang?: string
): string => {
  const date = parse(stringDate, inputFormat, new Date());
  if (!isValid(date)) {
    console.error('Incorrect date format');
    return '';
  }
  return format(date, outputFormat, {
    locale: lang && get(locales, lang),
  });
};

const formatMonthWithLocale = (stringDate: string, lang: string) => {
  return formatDate(
    stringDate,
    formatHelper.fullDateWithSeconds,
    formatHelper.monthText,
    lang
  );
};

const formatDateForAttribute = (stringDate: string) => {
  return formatDate(
    stringDate,
    formatHelper.fullDateWithSeconds,
    formatHelper.day
  );
};

const formaterBirthday = (stringDate: string) => {
  return formatDate(stringDate, formatHelper.day, formatHelper.date);
};

const formaterBirthdayToISO = (stringDate: string) => {
  return formatDate(stringDate, formatHelper.date, formatHelper.day);
};

const formatToTime = (stringDate: string) => {
  return formatDate(
    stringDate,
    formatHelper.fullDateWithSeconds,
    formatHelper.time
  );
};

const formatToTimeWithDate = (stringDate: string) => {
  return formatDate(
    stringDate,
    formatHelper.fullDateWithSeconds,
    formatHelper.timeWithDate
  );
};

const formatToDate = (stringDate: string) => {
  return formatDate(
    stringDate,
    formatHelper.fullDateWithSeconds,
    formatHelper.dateWithShortYear
  );
};

const formaterTime = (parsedDate: Date) => {
  return formaterDate(parsedDate, formatHelper.time);
};

const formaterTimeWithSeconds = (date: Date) => {
  return format(date, formatHelper.timeWithSeconds);
};

const parseDate = (stringDate: string) => {
  return parse(stringDate, formatHelper.date, new Date());
};

const parseTimeWithSeconds = (stringDate: string) => {
  const date = parse(stringDate, formatHelper.timeWithSeconds, new Date());
  if (!isValid(date)) {
    console.error('Incorrect date format');
    return parse('00:00', formatHelper.timeWithSeconds, new Date());
  }
  return date;
};

export {
  addDays,
  addMinutes,
  eachMinuteOfInterval,
  formatDayOfWeekToString,
  eachDayOfInterval,
  endOfDay,
  isSameDay,
  max,
  roundToNearestMinutes,
  startOfDay,
  subMinutes,
  isValid,
  isAfter,
  isBefore,
  format,
  parse,
  isSameMinute,
  dayOfWeek,
  getDateInSeconds,
  stringToDate,
  formatMonthWithLocale,
  formatDateForAttribute,
  formatHelper,
  formatToTimeWithDate,
  isWithinInterval,
  parseTimeWithSeconds,
  parseDate,
  formaterTime,
  formaterTimeWithSeconds,
  formatToTime,
  formatToDate,
  formaterBirthday,
  formaterBirthdayToISO,
  addMilliseconds,
  differenceInDays,
  differenceInMilliseconds,
};

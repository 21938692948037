import './Additional.scss';
import { Component } from 'react';
import * as session from 'modules/session';
import api from 'modules/helpers/api';
import productHelper from 'modules/helpers/product-helper';
import { withTranslation } from 'react-i18next';
import viewedProducts from '../../../helpers/viewedProducts';
import Card from '../../Products/Card/Card';
import Emitter from '../../NewCartOrder/Emitters';

class Additional extends Component {
  constructor(props) {
    super(props);
    const { items = [] } = session.get('cart');
    this.state = {
      viewed_products: [],
      recomendated_products: [],
      items,
    };
  }

  componentDidMount() {
    const { product } = this.props;
    Emitter.addListener('CART_CHANGE', this.cartChangeHandler);
    this.getRecomendatedProducts(product.id);
    this.viewedProducts(product);
  }

  componentWillUnmount() {
    Emitter.removeListener('CART_CHANGE', this.cartChangeHandler);
  }

  getRecomendatedProducts(product_id) {
    api('product.recommendations', { product_id, quantity: 3 }).then(
      (result = []) => {
        const recomendated_products = [];
        result.forEach((id) => {
          const product = productHelper.get(id);
          if (product) recomendated_products.push(product);
        });

        if (recomendated_products.length > 0) {
          this.setState({ recomendated_products });
        }
      }
    );
  }

  cartChangeHandler = () => {
    const { items = [] } = session.get('cart');
    this.setState({ items });
  };

  viewedProducts = (product) => {
    api('product.viewed', {
      product_id: product.id,
      menu_id: product.menu_id,
    }).then((viewed) => {
      viewedProducts.set(viewed);
      viewedProducts.add(product);
      const viewed_products = viewedProducts.get(product.id, 3);
      this.setState({
        viewed_products,
      });
    });
  };

  render() {
    const { recomendated_products, viewed_products } = this.state;
    const { t } = this.props;
    const { items = [] } = this.state;
    return (
      <div className="card-additional">
        {viewed_products.length ? (
          <div className="card-additional__wrap">
            <div className="card-additional__title">
              {t('CardModal.recently_viewed')}
            </div>
            <div className="page-product-single__addition-content">
              {viewed_products.map((product) => {
                const cart_item = items.find((item) => item.id === product.id);
                return (
                  <Card
                    key={`recently-${product.id}`}
                    product={product}
                    view_type="widget"
                    widgetName="menu"
                    position="recentlyViewed"
                    cart_item={cart_item}
                  />
                );
              })}
            </div>
          </div>
        ) : undefined}
        {recomendated_products.length ? (
          <div className="card-additional__wrap">
            <div className="card-additional__title">
              {t('CardModal.recommendations')}
            </div>
            <div className="page-product-single__addition-content">
              {recomendated_products.map((product) => {
                const cart_item = items.find((item) => item.id === product.id);
                return (
                  <Card
                    key={`recomend-${product.id}`}
                    product={product}
                    view_type="widget"
                    widgetName="menu"
                    position="productRecommendation"
                    cart_item={cart_item}
                  />
                );
              })}
            </div>
          </div>
        ) : undefined}
      </div>
    );
  }
}
export default withTranslation()(Additional);

import './NeedPromocodeModal.scss';
import '../../Form/Form.scss';
import productHelper from 'modules/helpers/product-helper';
import { Component } from 'react';
import api from 'modules/helpers/api';
import { withTranslation } from 'react-i18next';
import Emitter from '../../NewCartOrder/Emitters';
import * as session from '../../../../modules/session';

class NeedPromocodeModal extends Component {
  constructor(props) {
    super(props);
    const { value: { item: { id } = {} } = {} } = props;
    const promocodes = {};
    promocodes[id] = '';

    this.state = {
      promocodes,
      id,
    };
  }

  closeModal = () => {
    Emitter.emit('MODAL_CLOSE', { modal: 'NeedPromocodeModal' });
  };

  onChangePromocode = (event, productId) => {
    const {
      target: { value },
    } = event;
    const { promocodes } = this.state;
    promocodes[productId] = value;
    this.setState({
      promocodes,
    });
  };

  submitPromocode = () => {
    this.sendPromocodes()
      .then((cart) => {
        session.set('cart', cart);
        Emitter.emit('CART_CHANGE');
        this.closeModal();
      })
      .catch(this.handleError);
  };

  sendPromocodes = () => {
    const { value: { item } = {} } = this.props;
    const { promocodes } = this.state;
    const preparedPromocodes = Object.values(promocodes);
    if (item) {
      return this.addItemWithPromocodes(item, preparedPromocodes);
    }
    return this.addPromocodes(preparedPromocodes);
  };

  addItemWithPromocodes = (item, preparedPromocodes) => {
    return api('cart.item', {
      id: item.id,
      qty: item.qty,
      child: item.child,
      promocodes: preparedPromocodes,
    });
  };

  addPromocodes = (preparedPromocodes) => {
    return api('cart.product_promocode', {
      promocodes: preparedPromocodes,
    });
  };

  handleError = (e) => {
    const { message, errors: { product_ids = [] } = {} } = e.data || {};
    this.setState({
      error: message,
    });
    console.error('handleError ', product_ids);
  };

  renderProduct = () => {
    const { id } = this.state;
    const product = productHelper.getCartProduct(id);
    if (!product) {
      return null;
    }
    const { t } = this.props;
    const { error } = this.state;
    const [image] = product.images;
    return (
      <div key={`product-${id}`} className="product-container">
        <div className="image-container">
          <img src={`img/${image.filename}100x75`} alt="product" />
        </div>
        <div
          className={`input-container ${
            error && error === 'need_product_promocode' ? 'error' : ''
          }`}
        >
          <label className="form__label">
            {error
              ? t('NeedPromocodeModal.promocode_invalid')
              : t('NeedPromocodeModal.enter_promocode')}
          </label>
          <input
            onChange={(e) => this.onChangePromocode(e, id)}
            type="text"
            className="material"
            autoComplete="false"
            placeholder={t('NeedPromocodeModal.promocode')}
            value={this.state.promocodes[id]}
          />
        </div>
      </div>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <div className="need_promocode_modal">
        <button
          type="button"
          className="main-cart-modal__close-btn"
          onClick={this.closeModal}
        />
        <div className="form__title">
          {t('NeedPromocodeModal.need_promocode_text')}
        </div>
        <div className="form__text">
          {t('NeedPromocodeModal.need_promocode_label')}
        </div>
        <div className="products">{this.renderProduct()}</div>
        <div className="button-container">
          <button
            type="button"
            className="sw-button"
            onClick={this.submitPromocode}
          >
            {t('NeedPromocodeModal.submit')}
          </button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(NeedPromocodeModal);

import { useTranslation } from 'react-i18next';
import CardList from 'src/frontend/Components/Products/CardList/CardList';
import productHelper from 'modules/helpers/product-helper';
import { IProduct } from 'types/product';

function StockProducts({ products }: { products: IProduct[] }) {
  const { t } = useTranslation();
  if (!products?.length) return null;
  const toppings = productHelper.getToppingsIdFromProducts(products);
  const filteredProducts = products.filter(
    (product) => !toppings.includes(product.id)
  ); // чистим коллекцию от продуктов если они уже включены в суперпродукт

  return (
    <CardList
      products={filteredProducts}
      notFilterPanel
      title={t('Stock.products_title')}
      position="promotion"
    />
  );
}

export default StockProducts;

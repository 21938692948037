import { useEffect, useState } from 'react';
import cx from 'classnames';
import productHelper from 'modules/helpers/product-helper';
import { useTranslation } from 'react-i18next';
import { ExternalImage } from 'shared/ui/image';
import Counter from 'shared/ui/Counter/Counter';
import { IActionGiftSelect } from 'types/cartTypes';
import { IProduct } from 'types/product';
import Radio from 'shared/ui/Radio/Radio';
import {
  useCurrency,
  useSelectCountryMainDomain,
} from 'src/entities/countries';
import { getToppings } from '../../../../Helpers';
import styles from './selectGiftCard.module.scss';

interface ISelectGiftCard {
  giftOption: IActionGiftSelect[];
  index: number;
  stockId: number;
  isExact: boolean; // признак того что мы находимся точно на роуте "/cart"
  isChecked: boolean; // признак того, что данная карточка выбрана (при actionQty < 2)
  isSelect: boolean; // признак того, что карточка является выриантом выбора в акции (если выбора нет то false)
  actionQty: number; // количество доступных применений акции
  moreAvaliable: boolean; // признак того, что данную карточку можно добавить ещё минимум один раз
  qtyState: number; // количество применений акции на данную карточку (при actionQty >= 2)
  chandeQty: (index: number, value: number) => void;
  onClickSelect: (actionId: number, index: number) => void;
}

function SelectGiftCard({
  giftOption,
  index,
  stockId,
  isExact,
  isChecked,
  isSelect,
  actionQty,
  moreAvaliable,
  qtyState,
  chandeQty,
  onClickSelect,
}: ISelectGiftCard) {
  const { t } = useTranslation();
  const mainDomain = useSelectCountryMainDomain();
  const [isCounter, setIsCounter] = useState(actionQty > 1);

  useEffect(() => {
    setIsCounter(actionQty > 1);
  }, [actionQty]);

  const onClickSelectHandler = (actionId: number, index: number) => {
    onClickSelect(actionId, index);
  };
  const currency = useCurrency();
  const isSelected = actionQty > 1 ? qtyState > 0 : isChecked;

  return (
    <li
      className={cx(styles.selectGiftRow, { [styles.noPadding]: !isExact })}
      onClick={(e) => {
        e.preventDefault();
        onClickSelectHandler(stockId, index);
      }}
    >
      {isExact && !isCounter && isSelect ? (
        <div className={styles.checkboxContainer}>
          <Radio
            id={`${index}-${stockId}`}
            name={`select-gift-checkbox-${index}-${stockId}`}
            checked={isChecked}
          />
        </div>
      ) : undefined}
      {giftOption.length
        ? giftOption.map(({ qty, child = [], price, product_id }) => {
            const product: IProduct = productHelper.getCartProduct(product_id);
            if (!isExact && !isSelected) return undefined;
            if (!product) return undefined;

            const {
              images = [],
              title = '',
              tags = [],
              is_delivery,
              is_pickup,
              product_type: { name = '' } = {},
            } = product || {};

            return (
              <div
                key={`select-gift-product-${product.id}`}
                className={styles.productContainer}
              >
                <ExternalImage
                  mainDomain={mainDomain}
                  className={cx(styles.imgContainer, {
                    [styles.longcart]: name === 'longcart',
                  })}
                  src={`${images[0]?.filename}/80x80`}
                  alt={title}
                  width={80}
                  height={80}
                />
                <div className={styles.primeContainer}>
                  <div className={styles.textContainer}>
                    <span className={styles.title}>
                      <p>{title}</p>
                      {child.length ? (
                        <span className={styles.toppings}>
                          {t('CartGifts.with_toppings')}
                          {getToppings(child)}
                        </span>
                      ) : undefined}
                      {!is_delivery ? (
                        <span className={styles.pickup}>{`${t(
                          'CartGifts.pickup'
                        )}${child.length ? ', ' : ''}`}</span>
                      ) : undefined}
                      {!is_pickup ? (
                        <span className={styles.pickup}>{`${t(
                          'CartGifts.delivery'
                        )}${child.length ? ', ' : ''}`}</span>
                      ) : undefined}
                    </span>
                  </div>
                  <div className={styles.priceAndQty}>
                    <div className={styles.price}>
                      {tags.length ? (
                        <div className={styles.iconsContainer}>
                          {tags.map(({ tag_id }) => (
                            <i
                              key={`icon-${tag_id}`}
                              className={cx({
                                [styles.iconHot]: tag_id === 1,
                                [styles.iconVegetarian]: tag_id === 6,
                              })}
                            />
                          ))}
                        </div>
                      ) : undefined}
                      {price ? (
                        <span>
                          {' '}
                          {price}&nbsp;{currency}
                          {!!product.price && (
                            <small>
                              {product.price}&nbsp;{currency}
                            </small>
                          )}
                        </span>
                      ) : (
                        <strong>{t('CartGifts.free')}</strong>
                      )}
                    </div>
                    {isCounter && isExact ? (
                      <Counter
                        qty={qtyState * qty}
                        step={qty}
                        disabled={!moreAvaliable}
                        className={styles.qtyContainer}
                        setQty={(value) => chandeQty(index, value)}
                      />
                    ) : (
                      <div className={styles.qtyContainer}>
                        <span>x {isCounter ? qty * qtyState : qty}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        : undefined}
    </li>
  );
}

export default SelectGiftCard;

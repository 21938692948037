import { Component } from 'react';
import api from 'modules/helpers/api';
import ssr from 'modules/decorators/ssr';
import globalStore from 'modules/global-store';
import * as session from 'modules/session';
import { LinkButton } from 'shared/ui/link-button';
import { Breadcrumbs } from 'shared/ui/breadcrumbs';
import { PageTitle } from 'shared/ui/page-title';
import { PageContainer } from 'shared/ui/page-container';
import ErrorComponent from '../../Components/ErrorComponent/ErrorComponent';
import { EventSingle } from '../../Components/EventSingle/EventSingle';
import CustomText from '../../Components/CustomText/CustomText';
import CustomHelmet from '../../Components/CustomHelmet';
import styles from './article.module.scss';

class Article extends Component {
  static LOADED_KEY = 'article_loaded';

  static initialData = (fetch, params = {}, globalStore) => {
    const { article } = params;
    return fetch('articles.get', { text_id: article })
      .then((result) => {
        if (!result || !Array.isArray(result) || !result[0]) {
          globalStore.set('is404', true);
          globalStore.set('article', false);
          return false;
        }
        globalStore.set('article', result[0]);
      })
      .catch((e) => {
        console.error('Article', e);
        return [];
      });
  };

  constructor(props) {
    super(props);
    const article = globalStore.get('article', false);
    this.state = {
      article,
      notFound: false,
    };
    if (!article) {
      globalStore.put('is404', true, true);
    }
  }

  componentDidMount() {
    const { article } = this.state;
    if (globalStore.get(Article.LOADED_KEY)) {
      globalStore.unlink(Article.LOADED_KEY);
      this.setState({
        notFound: !article,
      });
    } else {
      Article.initialData(api, this.props.match.params, globalStore).then(
        () => {
          const article = globalStore.get('article', false);
          this.setState({
            article,
            notFound: !article,
          });
        }
      );
    }
    if (globalStore.get('article', false)) {
      globalStore.put('is404', undefined, true);
    }
  }

  componentWillUnmount() {
    globalStore.set('article', null);
  }

  render() {
    const { article = {}, notFound } = this.state;
    const { t } = this.props;
    if (notFound) {
      return (
        <ErrorComponent
          action_link="/articles"
          action_text={t('Article.articlesGetAll')}
        />
      );
    }
    const { title = '', images = [] } = article;
    const isLoad = !!Object.keys(article).length;
    return (
      <PageContainer>
        <CustomHelmet
          title={title}
          type="article"
          image={`${session.get('protocol')}://${session.get('location')}/img/${
            images[0] && images[0].filename
          }`}
          ogTitle={title}
          ogDescription={article.announcement_text}
        />
        <Breadcrumbs
          links={[
            {
              href: '/articles',
              text: t('Articles.articlesTitle'),
            },
            {
              text: title,
            },
          ]}
        />
        <PageTitle isCenterOnMobile>{title}</PageTitle>
        <div
          className={styles.event}
          itemScope
          itemType="http://schema.org/Article"
        >
          <meta itemProp="headline" content={title} />
          <EventSingle content={article} type="articles" />
        </div>
        {isLoad ? (
          <div className={styles.linkBtnContainer}>
            <LinkButton href="/articles">
              {t('Article.articlesGetAll')}
            </LinkButton>
          </div>
        ) : undefined}
        <CustomText />
      </PageContainer>
    );
  }
}

export default ssr(Article);

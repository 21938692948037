import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import cn from 'classnames';
import {
  IResolutions,
  buildResponsiveImageUrl,
  useImageSrc,
} from './image-src';
import styles from './style.module.scss';

const defaultBlurDataUrl =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM8UQ8AAhUBSQV8WJQAAAAASUVORK5CYII=';

interface IExternalImage {
  src: string;
  mainDomain: string;
  width: number;
  height: number;
  alt: string;
  sizes?: IResolutions;
  className?: string;
  visibleByDefault?: boolean;
}

export function ExternalImage({
  src,
  mainDomain,
  className,
  sizes,
  ...props
}: IExternalImage) {
  const { srcData, switchSrc } = useImageSrc({ path: src, mainDomain });
  const imgResolution =
    srcData.type === 'error'
      ? undefined
      : buildResponsiveImageUrl({
          src: srcData.href,
          sizes,
        });

  return (
    <LazyLoadImage
      {...props}
      {...imgResolution}
      src={srcData.href}
      wrapperClassName={cn(className, {
        [styles.error]: srcData.type === 'error',
      })}
      effect="blur"
      onError={switchSrc}
      wrapperProps={{
        style: {
          display: 'block',
        },
      }}
      placeholder={
        <img
          width={props.width}
          height={props.height}
          alt="placeholder"
          src={defaultBlurDataUrl}
        />
      }
    />
  );
}

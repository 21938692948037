import { useSelectedCountry } from 'src/entities/countries';
import { Helmet } from 'react-helmet';
import { themeCssVariables as defaultTheme } from './default-theme';
import { themeCssVariables as uaTheme } from './ua-theme';

export function Theme() {
  const { isUkraine } = useSelectedCountry();
  const rootStyles = Object.entries(isUkraine ? uaTheme : defaultTheme)
    .map(([variable, value]) => `${variable}: ${value};`)
    .join('\n');

  return (
    <Helmet>
      <style>{`:root { ${rootStyles} }`}</style>
    </Helmet>
  );
}

import { useState, useEffect } from 'react';
import {
  addMilliseconds,
  differenceInDays,
  differenceInMilliseconds,
  formaterTimeWithSeconds,
  startOfDay,
} from 'shared/lib/date';
import { toZonedTime } from 'date-fns-tz';

interface IUseTimerProps {
  action_to: string;
  locale: string;
}
//
export const useTimer = ({ action_to, locale }: IUseTimerProps) => {
  const [timer, setTimer] = useState<string | number>();
  const [timerType, setTimerType] = useState<string>();

  const pretifyTime = (
    diffInMilliseconds: number,
    diffInDays: number
  ): string | number => {
    const timeDate = addMilliseconds(
      startOfDay(new Date()),
      diffInMilliseconds
    );

    if (diffInDays < 1) {
      return formaterTimeWithSeconds(timeDate);
    }
    return diffInDays;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const now = toZonedTime(new Date(), locale);
      const diffInMilliseconds = differenceInMilliseconds(action_to, now);
      const diffInDays = differenceInDays(action_to, now);

      const formatType = diffInDays < 1 ? 'hours' : 'days';
      setTimerType(formatType);

      if (diffInMilliseconds <= 0) {
        clearInterval(interval);
      }
      setTimer(pretifyTime(diffInMilliseconds, diffInDays));
    }, 1000);
  }, [action_to, locale]);

  return { timer, timerType };
};

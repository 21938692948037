import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from 'react';
import styles from './styles.module.scss';

export function PageContainer({
  children,
}: PropsWithChildren<
  DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>
>) {
  return (
    <div className={styles.root}>
      <article className={styles.content}>{children}</article>
    </div>
  );
}

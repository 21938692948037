import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';
import 'whatwg-fetch';

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      headers.set('X-Requested-With', 'XMLHttpRequest');
    },
    paramsSerializer: (params) => {
      return queryString.stringify(params);
    },
  }),
  refetchOnFocus: false,
  refetchOnReconnect: false,
  endpoints: () => ({}),
});

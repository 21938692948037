import { createSlice } from '@reduxjs/toolkit';
import { IFooterLink } from './types';

const initState = [] as IFooterLink[];

export const footerLinksSlice = createSlice({
  name: 'footerLinks',
  initialState: initState,
  reducers: {},
});

const selectFooterLinks = (state: RootState) => {
  return state.footerLinks;
};

export const footerLinksSliceSelectors = {
  selectFooterLinks,
};

import globalStore from 'modules/global-store';
import cn from 'classnames';
import styles from './ua-payment.module.scss';
import { uaPaymentScheme } from '../../config/ua-payment-scheme';

export function UaPayment() {
  const { text_id } = globalStore.get('current_city');
  const isKiev = ['kiev'].includes(text_id);

  if (!isKiev) return null;

  return (
    <ul className={styles.list}>
      {uaPaymentScheme.map(({ id, link, disabled }) => {
        const paymentLink = cn(styles.paymentLink, {
          [styles[id]]: !!id,
          [styles.disabled]: disabled,
        });
        return (
          <li key={id} className={styles.element}>
            <a
              className={paymentLink}
              href={link}
              target="_blank"
              rel="noreferrer"
              style={{ textIndent: '-10000px' }}
            >
              {id}
            </a>
          </li>
        );
      })}
    </ul>
  );
}

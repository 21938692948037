function closest(node, selector) {
  while (node) {
    const matches = node.matches ? node.matches : node.msMatchesSelector;
    if (matches.call(node, selector)) return node;
    node = node.parentElement;
  }
  return null;
}

export default (e) => {
  const { currentTarget } = e;
  const card =
    closest(currentTarget, '.card--single') ||
    closest(currentTarget, '.card--grid') ||
    closest(currentTarget, '.card-for-widget') ||
    closest(currentTarget, '.button-for-wok');
  if (!card) return;

  const img =
    card.querySelector('.card__image > img') ||
    card.querySelector('.card-widget__image > img');

  if (!img) return;

  const params = img.getBoundingClientRect();

  const clone = img.cloneNode(true);

  const style = `
                  position: fixed;
                  z-index: 10000;
                  width: ${params.width}px;
                  height: ${params.height}px;
                  top: ${params.top}px;
                  right: ${
                    document.body.clientWidth - (params.left + params.width)
                  }px;
                  margin: 0 !important;
                  `;

  clone.setAttribute('style', style);

  document.body.appendChild(clone);

  setTimeout(() => {
    clone.classList.add('active-product-fly');
  }, 0);

  setTimeout(() => {
    if (clone.parentNode) {
      clone.parentNode.removeChild(clone);
    }
  }, 1000);
};

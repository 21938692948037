import { Link, useLocation, useParams } from 'react-router-dom';
import cn from 'classnames';

function isAbsoluteUrl(path: string) {
  return /^(?:http|https):\/\//i.test(path);
}
export interface ISmartLink
  extends Omit<
    React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
    'ref' // for Link
  > {
  activeClassName?: string;
  className?: string;
  href?: string;
}
export function SmartLink({
  activeClassName,
  className,
  children,
  href = '#',
  ...props
}: ISmartLink) {
  const cleanHref = href.replace(/\\/g, '');
  const { pathname } = useLocation();
  const { city } = useParams<{ city: string }>();
  const hrefWithCity = `/${city}/${cleanHref}`
    .replace(/\/{2,}/g, '/')
    .replace(/\/?$/, '/');
  return isAbsoluteUrl(cleanHref) ? (
    <a
      className={cn(className)}
      href={cleanHref}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  ) : (
    <Link
      to={hrefWithCity}
      className={cn(className, {
        [activeClassName as string]: pathname === hrefWithCity,
      })}
      {...props}
    >
      {children}
    </Link>
  );
}

import { useContext } from 'react';
import { SideBarContext } from './SideBarContext';

export const useSidebarModal = () => {
  const modal = useContext(SideBarContext);

  return {
    ...modal,
    show: () => modal.show(),
    hide: () => modal.hide(),
  };
};

import { route } from 'modules/route';
import { useHistory } from 'react-router-dom';
import useUserIsExist from 'frontend/data/user/useUserExist';
import Emitters from 'frontend/Components/NewCartOrder/Emitters';
import Uavatar from './assets/uavatar.svg';
import styles from './profileButton.module.scss';

function ProfileButton() {
  const { isUserExist } = useUserIsExist();
  const history = useHistory();

  const loginHandler = () => {
    if (isUserExist) {
      history.push(route('profile'));
    } else {
      Emitters.emit('MODAL_CALL', { modal: 'AuthModal' });
    }
  };

  return (
    <button type="button" onClick={loginHandler} className={styles.button}>
      <Uavatar width={30} height={30} className={styles.svg} />
    </button>
  );
}
export { ProfileButton };

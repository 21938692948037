import escapeRegexp from 'modules/helpers/escape-regexp';

function parseCity(url) {
  const urlPartials = url.split('?');
  let path = urlPartials[0];
  path = path.replace(/^\/+/, '');

  if (path === '') {
    return null;
  }

  path = path.toLowerCase();

  const pos = path.indexOf('/');
  if (pos !== -1) {
    return path.slice(0, pos);
  }

  return path;
}

function stripCity(url, city) {
  if (!city) {
    return url;
  }
  const re = new RegExp(`^/?${escapeRegexp(city)}`);
  return url.replace(re, '');
}

function parseUrl(fullUrl) {
  return /https?:\/\/(.+?)(\/.*)/.exec(fullUrl);
}

export { parseCity, stripCity, parseUrl };

// import escape from 'modules/helpers/escape-regexp';

export default function (props) {
  const defaultPlaceholder = 'dd.mm.yyyy';
  const defaultFormat = 'dd.mm.yyyy';
  const { format = defaultFormat, onValidate, required = false } = props;
  let { placeholder } = props;
  const maskReg =
    /^(y{1,2}|y{4}|m{1,2}|d{1,2})([./-]{1})(y{1,2}|y{4}|m{1,2}|d{1,2})([./-]{1})(y{1,2}|y{4}|m{1,2}|d{1,2})$/;
  const parsers = [];
  let _value = '';

  let matched = format.match(maskReg);
  if (!matched) {
    matched = defaultFormat.match(maskReg);
  }

  for (let i = 1; i <= 5; i += 1) {
    parsers.push(matched[i]);
  }

  if (!placeholder) {
    placeholder = format || defaultPlaceholder;
  }

  function validate(event) {
    const {
      target: { value },
    } = event;
    const prepared = [];

    _value = value.replace(/[^0-9]/g, '').split('');

    function stripMonth() {
      if (!_value.length) {
        return null;
      }
      let month = _value.shift();
      function fill() {
        return `0${month}`;
      }
      if (!['0', '1'].includes(month)) {
        return fill();
      }
      if (!_value.length) {
        return month;
      }
      if (month === '1' && !['0', '1', '2'].includes(_value[0])) {
        return fill();
      }
      month += _value.shift();
      return month;
    }

    function stripYear() {
      if (!_value.length) {
        return null;
      }
      let year = _value.shift();

      if (!['1', '2'].includes(year)) {
        return null;
      }

      if (_value.length) {
        if (year === '1' && _value[0] !== '9') {
          return year;
        }
        for (let i = 0; i < 3; i += 1) {
          if (!_value.length) {
            return year;
          }
          year += _value.shift();
        }
      }
      return year;
    }

    function stripDay() {
      if (!_value.length) {
        return null;
      }
      let day = _value.shift();
      function fill() {
        return `0${day}`;
      }
      if (!['0', '1', '2', '3'].includes(day)) {
        return fill();
      }
      if (!_value.length) {
        return day;
      }
      if (day === '3' && !['0', '1'].includes(_value[0])) {
        return fill();
      }
      day += _value.shift();
      return day;
    }

    function parse(parser) {
      if (!parser) {
        return null;
      }
      switch (parser.toLowerCase()) {
        case 'm':
        case 'mm':
          return stripMonth();
        case 'd':
        case 'dd':
          return stripDay();
        case 'y':
        case 'yy':
        case 'yyy':
        case 'yyyy':
          return stripYear();
        case '.':
        case '-':
        case '/':
          if (!_value.length) {
            return null;
          }
          return parser;
        default:
          return '';
      }
    }

    parsers.forEach((parser) => {
      const value = parse(parser);
      if (!value) {
        return false;
      }
      prepared.push(value);
    });

    function _isValid(prepared) {
      let isValid = true;
      if (!parsers || !Array.isArray(parsers) || !parsers.length) {
        return true;
      }
      if (!prepared || !Array.isArray(prepared) || !prepared.length) {
        return !required;
      }
      if (prepared.length !== parsers.length) {
        return false;
      }
      parsers.forEach((parser, i) => {
        switch (parser.toLowerCase()) {
          case 'd':
          case 'dd':
            if (
              /(?:^0[1-9]$)|(?:^[1-2][0-9]$)|(?:^3[0-1]$)|(?:^[1-9]$)/.test(
                prepared[i]
              )
            ) {
              isValid = true;
            } else {
              isValid = false;
              return false;
            }
            break;
          case 'm':
          case 'mm':
            if (/(?:^0[1-9]$)|(?:^1[0-2]$)|(?:^[1-9]$)/.test(prepared[i])) {
              isValid = true;
            } else {
              isValid = false;
              return false;
            }
            break;
          case 'y':
          case 'yy':
          case 'yyy':
          case 'yyyy':
            if (/(?:^19[0-9]{2}$)|(?:^2[0-9]{3}$)/.test(prepared[i])) {
              isValid = true;
            } else {
              isValid = false;
              return false;
            }
            break;
          case '-':
          case '/':
          case '.':
            break;
          default:
        }
      });

      return isValid;
    }

    if (onValidate && typeof onValidate === 'function') {
      onValidate(_isValid(prepared));
    }

    return prepared.join('');
  }

  return {
    validate,
    placeholder,
  };
}

import { useState } from 'react';
import classNames from 'classnames';

function Input({ field, handleChangeForm }) {
  const [value, setValue] = useState(field.value || '');
  const {
    name,
    label,
    type,
    required,
    placeholder,
    className,
    fieldsetClassName,
    invalid,
    maxLength,
    autoComplete,
  } = field;

  let { pattern } = field;

  const handleOnChange = (e) => {
    let text;

    const re = /[^a-zA-Zа-яА-ЯёЁієїЄІЇ\s-]+/gi;
    const { value: inputValue } = e.target;

    switch (name) {
      case 'name':
        text = inputValue && inputValue.replace(re, '').trim();
        break;
      case 'address':
        text = inputValue;
        break;
      default:
        text = inputValue.trim();
    }

    setValue(text);
    handleChangeForm(e);
  };

  if (type === 'number' && !pattern) {
    pattern = '[0-9]*';
  }

  const fieldSetClass = classNames({
    'input-row': true,
    [fieldsetClassName]: fieldsetClassName,
    invalid,
  });

  const labelClass = classNames({
    required,
  });

  return (
    <fieldset className={fieldSetClass}>
      <label htmlFor={`field-${name}`} className={labelClass}>
        {label}
      </label>
      <input
        id={`field-${name}`}
        type={type}
        required={required}
        name={name}
        placeholder={placeholder || ''}
        className={className || ''}
        maxLength={maxLength}
        value={value}
        pattern={pattern}
        autoComplete={autoComplete}
        onChange={(e) => handleOnChange(e)}
      />
    </fieldset>
  );
}

export default Input;

import useSWR from 'swr';
import api from 'modules/helpers/api';
import { IUserBonuses } from './types';
import useUserIsExist from '../useUserExist';

export default function useUserBonuses() {
  const { isUserExist } = useUserIsExist();
  const { data, error } = useSWR<IUserBonuses>(
    isUserExist ? 'user.get_bonuses' : null,
    api
  );

  return {
    data,
    error,
  };
}

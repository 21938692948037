function findYaCounter() {
  const keys = Object.keys(window).filter((key) => !!key.includes('yaCounter'));
  const arr = [];
  keys.forEach((key) => {
    arr.push(window[key]);
  });
  return arr;
}

export default findYaCounter;

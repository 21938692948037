import { useEffect, useRef, useState } from 'react';
import '../RecommendedProducts.scss';
import 'flickity/css/flickity.css';
import { useResizeWindow } from 'frontend/hooks';
import productHelper from 'modules/helpers/product-helper';
import { IProduct } from 'types/product';
import type flickityType from 'flickity';
import Card from 'src/frontend/Components/Products/Card/Card';
import styles from './styles.module.scss';

function ListRecommendation({
  recommendations,
}: {
  recommendations: IProduct[];
}) {
  const [flick, setFlick] = useState<flickityType | null>(null);
  const slider = useRef(null);
  const { isMobile, isTablet } = useResizeWindow();
  const mobileView = isMobile || isTablet;
  const toppings = productHelper.getToppingsIdFromProducts(recommendations);
  const filteredRecommendations = recommendations.filter(
    (product) => !toppings.includes(product.id)
  );

  useEffect(() => {
    if (!mobileView && filteredRecommendations.length < 4) return;
    if (mobileView && filteredRecommendations.length < 2) return;

    if (!slider.current) return;

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const Flickity = require('flickity');

    const flickity = new Flickity(slider.current, {
      wrapAround: true,
      prevNextButtons: false,
      pageDots: false,
      setGallerySize: true,
    });
    setFlick(flickity);
  }, []);

  return (
    <section className={styles.root}>
      {flick && (
        <button
          type="button"
          aria-label="next slider"
          className="prev slider-button"
          onClick={() => flick && flick.previous()}
        />
      )}
      <div className="carousel-wrapper slider" ref={slider}>
        {filteredRecommendations.map((product) => {
          return (
            <Card
              key={`card-${product.id}`}
              product={product}
              view_type="recomend"
              position="cartRecommendation"
            />
          );
        })}
      </div>
      {flick && (
        <button
          type="button"
          aria-label="next slider"
          className="next slider-button"
          onClick={() => flick && flick.next()}
        />
      )}
    </section>
  );
}

export default ListRecommendation;

import './SocialElements.scss';
import SocialShare from './SocialShare/SocialShare';

function SocialElements(props) {
  let class_name = '';
  if (typeof props.direction !== 'undefined') {
    class_name = `social-elements--${props.direction}`;
  }

  return (
    <div className={`social-elements ${class_name}`}>
      <SocialShare {...props} />
    </div>
  );
}

export default SocialElements;

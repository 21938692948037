import globalStore from 'modules/global-store';
import styles from './headerPhone.module.scss';

function HeaderPhone() {
  const { phone } = globalStore.get('current_city');

  if (!phone) return null;

  return (
    <a className={styles.phone} href={`tel:${phone}`}>
      {phone}
    </a>
  );
}

export { HeaderPhone };

import autobind from 'autobind-decorator';
import { Component, Fragment } from 'react';
import Modal from 'react-modal';
import { withTranslation } from 'react-i18next';
import modernModal from '../Modals/ModernModal';
import Button from '../Elements/Buttons/Button';

import './urlShortener.scss';

const customStyles = {
  overlay: {
    zIndex: 20,
    backgroundColor: 'rgba(252, 252, 252, 0.7)',
    overflowY: 'auto',
  },
  content: {
    width: '400px',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '0',
    right: '0',
    bottom: 'auto',
    margin: 'auto',
    marginBottom: '30px',
    overflow: 'visible',
    padding: '20px',
    border: 'none',
    textAlign: 'center',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 16px 60px 20px',
    boxSizing: 'border-box',
  },
};

class UrlShortener extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      shortUrl: '',
    };
  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  componentWillUnmount() {}

  @autobind
  getUrlShortener() {
    const key = 'AIzaSyDzEEhlEJ4oaugZzN1jDkhUd_zfjFrQHds';
    const dynamicLinkUrl = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${key}`;

    fetch(dynamicLinkUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        longDynamicLink: `https://sushiwok.page.link/?link=${encodeURIComponent(
          window.location.href
        )}`,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const { shortLink = '' } = data;
        this.setState({
          modalIsOpen: true,
          shortUrl: shortLink,
        });
        this.openModal();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  @autobind
  closeModal() {
    this.setState({
      modalIsOpen: false,
    });
    modernModal.closeModal();
  }

  @autobind
  openModal() {
    modernModal.openModal();
  }

  render() {
    const { shortUrl, modalIsOpen } = this.state;
    const { t } = this.props;
    return (
      <>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
        >
          <button
            type="button"
            onClick={this.closeModal}
            className="close-modal-btn"
          />
          <h2 className="url-shorterner-title">{t('UrlShortener.send')}</h2>
          {shortUrl}
        </Modal>
        <Button
          className="url-shorterner-button"
          onClick={this.getUrlShortener}
        >
          {t('UrlShortener.send')}
        </Button>
      </>
    );
  }
}
export default withTranslation()(UrlShortener);

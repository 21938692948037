import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { IResolutions, buildResponsiveImageUrl } from './image-src';

interface IInternalImage {
  src: string;
  width: number;
  height: number;
  sizes?: IResolutions;
  className?: string;
  alt: string;
}

export function InternalImage({
  src,
  className,
  sizes,
  ...props
}: IInternalImage) {
  const imgResolution = buildResponsiveImageUrl({
    src,
    sizes,
  });
  return (
    <LazyLoadImage
      {...props}
      {...imgResolution}
      src={src}
      wrapperClassName={className}
      effect="blur"
      wrapperProps={{
        style: {
          display: 'flex',
          alignItems: 'center',
        },
      }}
    />
  );
}

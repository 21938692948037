export function createSolutionTree(productToppings) {
  const tree = {};
  if (!productToppings.length) {
    return tree;
  }

  const priority = productToppings[0].params.map((p) => p.name);
  const priorityOf = ({ name }) => priority.indexOf(name);

  for (const { params, product_id } of productToppings) {
    if (!params || !Array.isArray(params)) {
      continue;
    }
    const len = params.length;
    let subtree = tree;

    params.sort((a, b) => priorityOf(a) - priorityOf(b));

    for (let i = 0; i < len; i += 1) {
      const { value } = params[i];

      const isLastParam = i === len - 1;
      if (isLastParam) {
        subtree[value] = product_id;
      } else {
        if (!subtree[value]) {
          subtree[value] = {};
        }
        subtree = subtree[value];
      }
    }
  }

  return tree;
}

export function createReverseSolutionTree(productToppings) {
  const tree = {};
  if (!productToppings.length) {
    return tree;
  }

  const priority = productToppings[0].params.map((p) => p.name);
  const priorityOf = ({ name }) => priority.indexOf(name);

  for (const { params, product_id } of productToppings) {
    const path = params
      .sort((a, b) => priorityOf(a) - priorityOf(b))
      .map((p) => p.value);
    tree[product_id] = path;
  }

  return tree;
}

export function getProductId(tree, path) {
  let result = null;
  let subtree = tree;

  for (const key of path) {
    result = subtree[key] || Object.values(subtree)[0];
    if (!result) {
      return null;
    }
    subtree = result;
  }

  return +result;
}

export function getDefaultProductId(productToppings = []) {
  return (productToppings[0] || {}).product_id;
}

export function getAvailableOptions(tree, path) {
  const result = [];
  let subtree = tree;

  for (const key of path) {
    result.push(Object.keys(subtree));
    subtree = subtree[key];
  }

  return result;
}

export function isSuperProduct(product) {
  const name = product && product.product_type && product.product_type.name;
  return name === 'supercard' || name === 'pizza';
}

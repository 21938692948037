import { useAppSelector } from 'shared/lib/store';
import { SmartLink } from 'shared/ui/smart-link';
import { headerMenuSliceSelectors } from '../model/slice';
import styles from './horizontalHeaderMenu.module.scss';

export function HorizontalHeaderMenu() {
  const headerMenu = useAppSelector(headerMenuSliceSelectors.selectHeaderMenu);

  return (
    <ul
      role="navigation"
      itemScope
      itemType="http://schema.org/SiteNavigationElement"
      className={styles.root}
    >
      {headerMenu.map((item) => {
        return (
          <li key={item.id} className={styles.item}>
            <SmartLink
              href={item.url}
              activeClassName={styles.active}
              className={styles.link}
            >
              {item.title}
            </SmartLink>
          </li>
        );
      })}
    </ul>
  );
}

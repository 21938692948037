import './OrderComponent.scss';
import { Component } from 'react';
import { route } from 'modules/route';
import { withTranslation } from 'react-i18next';
import { ExternalImage } from 'shared/ui/image';
import { withCurrency } from 'src/entities/countries';
import { connect } from 'react-redux';

class OrderComponent extends Component {
  render() {
    const {
      item,
      item: { toppings },
      profile,
      t,
      currency,
      mainDomain,
    } = this.props;

    function toppingRender(topping, index, array) {
      if (topping.title) {
        return (
          topping.title.trim().toLowerCase() +
          (topping.qty > 1 ? `x ${topping.qty}` : '') +
          (index === array.length - 1 ? '' : ', ')
        );
      }
    }

    return (
      <div>
        {!item.parent_id ? (
          <div className="cart-block__content--preview">
            <a
              href={route(
                `/menu/${item.category_id || ''}/${item.text_id || ''}`
              )}
              className="cart-block__content--preview__block"
              target="_blank"
              rel="noreferrer"
            >
              <ExternalImage
                mainDomain={mainDomain}
                src={`${item?.images?.[0]?.filename}/80x80`}
                width={80}
                height={80}
                alt={
                  item.title
                    ? item.title
                    : t('OrderComponent.cart_block_preview_content')
                }
                className="cart-block__content--preview__img"
              />
              <div className="cart-block__content--preview__list">
                <div className="card-for-cart__name">
                  <p> {item.title} </p>
                  {toppings ? (
                    <p className="card-for-cart__topping">
                      {t('OrderComponent.withToppings')}:&nbsp;
                      {toppings.map((topping, index, array) =>
                        toppingRender(topping, index, array)
                      )}
                    </p>
                  ) : undefined}
                </div>
                <div className="card-for-cart__count">x {item.qty}</div>
                {profile ? undefined : (
                  <div className="card-for-cart__price">
                    {toppings
                      ? `${
                          item.price +
                          toppings.reduce((a, b) => {
                            return a + b.price * b.qty;
                          }, 0)
                        } ${currency}`
                      : item.price
                      ? `${item.price} ${currency}`
                      : t('OrderComponent.free')}
                  </div>
                )}
              </div>
            </a>
          </div>
        ) : undefined}
      </div>
    );
  }
}
const mapStateToProps = ({ countries }) => ({
  mainDomain: countries.country.main_domain,
});

export default connect(mapStateToProps)(
  withTranslation()(withCurrency(OrderComponent))
);

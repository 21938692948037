import { mobileMenuEmitter } from 'modules/event-emitters';
import styles from './burger.module.scss';

function Burger() {
  const activeMenu = () => {
    mobileMenuEmitter.emit('MainMenu.OpenMenu');
  };

  return (
    <button className={styles.root} onClick={activeMenu} type="button">
      <span />
      <span />
      <span />
    </button>
  );
}

export { Burger };

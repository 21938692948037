import './InfoModals.scss';
import autobind from 'autobind-decorator';
import { Component } from 'react';
import Modal from 'react-modal';
import { modalEmitter } from 'modules/event-emitters';
import Button from 'src/shared/ui/Button/Button';
import * as session from 'modules/session';
import { withTranslation } from 'react-i18next';
import modernModal from '../ModernModal';

const modalStylesMobile = {
  overlay: {
    zIndex: 20,
    backgroundColor: 'rgba(252, 252, 252, 0.7)',
    overflowY: {
      value: 'auto',
    },
  },
  content: {
    maxWidth: '420px',
    top: '50px',
    left: '0',
    right: '0',
    margin: 'auto',
    bottom: 'auto',
    border: 'none',
    boxShadow: '0px 2px 44px 0px rgba(0, 0, 0, 0.09)',
    boxSizing: 'border-box',
    overflow: 'visible',
    padding: '20px',
    width: 'auto',
    marginBottom: '10px',
  },
};
const modalStyles = {
  overlay: {
    zIndex: 20,
    backgroundColor: 'rgba(252, 252, 252, 0.7)',
    overflowY: {
      value: 'auto',
    },
  },
  content: {
    top: '130px',
    left: '0',
    right: '0',
    margin: 'auto',
    bottom: 'auto',
    border: 'none',
    boxShadow: '0px 2px 44px 0px rgba(0, 0, 0, 0.09)',
    boxSizing: 'border-box',
    overflow: 'visible',
    padding: '20px',
    width: '800px',
    marginBottom: '20px',
  },
};

class InfoModals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modal: {},
    };
  }

  componentDidMount() {
    Modal.setAppElement('body');
    modalEmitter.addListener('Template.Modals.InfoModal', this.openModal);
    Modal.setAppElement('body');
  }

  componentWillUnmount() {
    modalEmitter.removeListener('Template.Modals.InfoModal', this.openModal);
  }

  @autobind
  openModal(modal) {
    setTimeout(() => {
      this.setState({
        modalIsOpen: !!modal,
        modal,
      });
    }, 0);

    if (modal) {
      modernModal.openModal();
    }
  }

  @autobind
  closeModal() {
    this.setState({ modalIsOpen: false });
    modernModal.closeModal();
    modalEmitter.emit('Cart.Close.Address');
  }

  render() {
    const { modalIsOpen, modal } = this.state;
    const { isMobile } = session.get();
    const { t } = this.props;

    return (
      <Modal
        isOpen={modalIsOpen}
        style={isMobile ? modalStylesMobile : modalStyles}
        contentLabel="confirm-street"
      >
        <div className="confirm-street">
          <div className="confirm-street__title">
            <h2> {modal.title} </h2>
          </div>
          <div
            className="confirm-street__desc"
            dangerouslySetInnerHTML={{ __html: modal.description }}
          />
          {!modal.no_close ? (
            <div className="info-modal__btns-container">
              <Button
                className="sw-button sw-button--big"
                onClick={this.closeModal}
                text={t('InfoModals.YesButton')}
              />
            </div>
          ) : undefined}
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(InfoModals);

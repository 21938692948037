export const errorCodes = {
  invalidForm: 400,
  invalidPromoCode: 400,
  invalidCountryCode: 4,
  notFoundTopping: 105,
  notFoundProduct: 105,
  filterCityIdRequired: 500,
  filterBaseIdRequired: 500,
  needProductPromocode: 117,
  storeNotWork: 104,
  pickupNotAvailable: 112,
  deliveryNotAvailable: 113,
  notDelivery: 101,
  bonusPhoneCodeInvalid: 201,
  emptyData: 300,
  invalidData: 300,
} as const;

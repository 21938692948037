import './OrderList.scss';
import './OrderListMobile.scss';
import { Component } from 'react';
import menuHelper from 'modules/helpers/menu-helper';
import { withTranslation } from 'react-i18next';
import { withCurrency } from 'src/entities/countries';
import OrderComponent from '../../OrderComponent/OrderComponent';

class OrderList extends Component {
  activeOrder() {
    const { orders, type, t, currency } = this.props;
    let orderFilter;
    let statusMessage;

    if (!orders.length) {
      return;
    }

    if (type === 'inline') {
      orderFilter = orders.filter((order) => {
        if (order.status_id < 4) return order;
      });
    } else {
      orderFilter = orders;
    }
    if (!orderFilter[0]) return;
    const { status_id, hash, total, items, total_dlv } = orderFilter[0];
    const itemProducts = items.filter((itemProduct) => !itemProduct.parent_id);
    const itemToppings = items.filter((itemTopping) => !!itemTopping.parent_id);
    const noPrice = items.reduce((s, a) => {
      return s + a.price;
    }, 0);
    let itemPrice = itemProducts.filter(
      (item) => item.price !== 0 && item.product_id !== 1139
    );
    let itemDiscount = itemProducts.filter(
      (item) => item.price === 0 && item.product_id !== 1139
    );
    itemPrice = itemPrice.map((item, index) => {
      const { product } = item;
      if (product) {
        const { text_id, menu_id } = product;
        if (menu_id) {
          const selected_category = menuHelper.get(menu_id);
          if (selected_category) {
            item.text_id = text_id || '';
            item.category_id = selected_category.text_id || '';
          }
        }
      }
      return (
        <OrderComponent
          item={item}
          key={`order-list-price${index}`}
          profile={!item.price}
        />
      );
    });
    itemDiscount = itemDiscount.map((item, index) => (
      <OrderComponent item={item} key={`order-list-disc${index}`} />
    ));

    itemProducts.forEach((p) => {
      itemToppings.forEach((topping) => {
        if (p.id === topping.parent_id) {
          if (!p.toppings) {
            p.toppings = [];
          }

          if (!p.toppings.find((t) => t.product_id === topping.product_id)) {
            p.toppings.push(topping);
          }
        }
      });
    });

    switch (status_id) {
      case 4:
        statusMessage = t('OrderList.delivered');
        break;
      case 5:
        statusMessage = t('OrderList.canceled');
        break;
      case 2:
        statusMessage = t('OrderList.preparing');
        break;
      case 3:
        statusMessage = t('OrderList.delivery');
        break;
      case 1:
        statusMessage = t('OrderList.accepted');
        break;
      default:
        break;
    }

    return (
      <div className="order-list">
        {orderFilter.length ? (
          <div>
            {type === 'inline' ? (
              <div className="input-container">
                <label>{t('OrderList.active_order')}</label>
              </div>
            ) : undefined}

            <div className="cart-block__content--preview">
              <span className="order-list__hash">
                {t('OrderList.order')} № {hash}
              </span>

              {type === 'inline' ? (
                <span className="order-list__status ">
                  {` ${statusMessage}`}
                </span>
              ) : undefined}
            </div>

            <div className="cart-block__content--preview">
              <div className="order-list">
                {itemPrice}
                {itemDiscount.length ? (
                  <div className="order-list__discount">
                    {t('OrderList.gift_items')}
                  </div>
                ) : undefined}

                {itemDiscount.length ? itemDiscount : undefined}
              </div>
              {noPrice ? (
                <div>
                  <div className="order-list__delivery-box">
                    <div className="order-list__delivery-box__delivery">
                      {t('OrderList.delivery_amount')}:
                    </div>
                    <div className="order-list__delivery-box__delivery">
                      {' '}
                      {total_dlv}&nbsp;{currency}
                    </div>
                  </div>
                  <div className="order-list__cost-box">
                    <div className="order-list__cost-box__cost">
                      {t('OrderList.total_sum')}:
                    </div>
                    <div className="order-list__cost-box__cost">
                      {' '}
                      {total + total_dlv}&nbsp;{currency}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="order-list__no-total" />
              )}
            </div>
          </div>
        ) : undefined}
      </div>
    );
  }

  render() {
    return <div>{this.activeOrder()}</div>;
  }
}

export default withTranslation()(withCurrency(OrderList));

import { Component } from 'react';
import cn from 'classnames';
import { withSelectedCountry } from 'src/entities/countries';

class Radio extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { name, label, type, className, radio, fieldsetClassName } =
      this.props.field;
    const { handleChangeForm, isUkraine } = this.props;

    return (
      <fieldset className={`input-row ${fieldsetClassName || ''}`}>
        <input
          id={`field-${name}${radio}`}
          type={type}
          name={name}
          className={cn(className, { ua: isUkraine })}
          value={radio}
          onChange={handleChangeForm}
        />
        <label htmlFor={`field-${name}${radio}`}>{label}</label>
      </fieldset>
    );
  }
}

export default withSelectedCountry(Radio);

export const byRequisites = {
  glubokoe:
    'ООО «ДуоФэм»\n211793, Витебская обл.,\n г.Глубокое, пл. 17 Сентября д.16, помещение кафе «СушиWok» УНП391537520, р/с BY73AKBB30120594280062300000 ЦБУ № 208 ОАО "АСБ Беларусбанк" в г.Глубокое Код AKBBBY2Х E-mail: krupev87@yandex.ru\nДиректор Крупский Евгений Юрьевич',
  grodno:
    'ООО «ИнстаФуд» УНП 591041175, Свидетельство о регистрации №591041175 выд. Гродненским горисполкомом 11/10/2022\nЮридический и почтовый адрес: 230025, Республика Беларусь, Гродненская обл., г. Гродно, ул. Калючинская, 21 пом. 4, Директор Кучковский Алексей Генрихович, на основании Устава, тел/факс +375296632859, e-mail: kuchkovski@gmail.com\nОбъект общественного питания зарегистрирован в торговом реестре РБ 25.10.2022 г №543871, р/с BY65POIS30120143765201933001\nРеквизиты Банка:\nОткрытое акционерное общество «Паритетбанк», УНП: 100233809, БИК: POISBY2X Юридический адрес банка: Республика Беларусь, 220002, г. Минск, ул. Киселева, 61а',
  postavy:
    'ООО «ДуоФэм» УНП 391537520\nЮридический адрес: РБ, 211793, Витебская обл., г. Глубокое, ул.Московская, 7 р/с BY73AKBB30120594280062300000 ЦБУ № 208 ОАО «АСБ Беларусбанк» в г. Глубокое Код AKBBBY2Х\nСвидетельство о государственной регистрации № 0081190 от 21.07.2014 выдано Глубокским райисполкомом 07.07.2017\nОбьект общественного питания зарегистрирован в торговом реестре РБ 12.09.2018 №426232 — Поставы',
};

export const requiredCities = Object.keys(byRequisites);

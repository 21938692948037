import store from 'modules/global-store';
import productHelper from '../../../../../modules/helpers/product-helper';

export default class WokRepository {
  static new(products) {
    return new WokRepository(products || []);
  }

  constructor(products) {
    this._emptyWok = { product_toppings: [], product_type: {} };
    this._emptyDefaultBaseAndSauce = { title: '', price: 0 };
    this._wokBox = products.find(WokRepository.isWokBox) || this._emptyWok;
    if (!this._wokBox.product_toppings) this._wokBox.product_toppings = [];
    this.toppingsId = productHelper.getToppingsIdFromProducts(products);
    this._woks = products.filter(
      (product) =>
        !WokRepository.isWokBox(product) &&
        !this.toppingsId.includes(product.id)
    );
    this._toppingsByType = {};
    this._initToppings();
  }

  _initToppings() {
    this._wokBox.product_toppings.forEach((topping) => {
      const type = topping.type.name;
      const fullToppingInfo = store.getToppingWithId(topping.product_id);

      if (!fullToppingInfo) {
        return;
      }

      if (!this._toppingsByType[type]) {
        this._toppingsByType[type] = [];
      }
      this._toppingsByType[type].push(fullToppingInfo);
      fullToppingInfo.product_type = topping.type;
    });
  }

  // [Incorrect responsibility] This method should be inside Product object.
  static isWokBox(aProduct) {
    return (aProduct.product_type || {}).name === 'wok';
  }

  hasNotWokForConstructor() {
    return !this.hasWokForConstructor();
  }

  hasWokForConstructor() {
    return (
      this.defaultWok !== this._emptyWok &&
      this.defaultBase !== this._emptyDefaultBaseAndSauce &&
      this.defaultSauce !== this._emptyDefaultBaseAndSauce &&
      this._wokBox.max_toppings !== 0
    );
  }

  get defaultWok() {
    return this._wokBox;
  }

  get defaultBase() {
    return this._bases[0] || this._emptyDefaultBaseAndSauce;
  }

  get _bases() {
    return this._toppingsByType.wok_base || [];
  }

  get defaultSauce() {
    return this._sauces[0] || this._emptyDefaultBaseAndSauce;
  }

  get _sauces() {
    return this._toppingsByType.wok_sauce || [];
  }

  get _vegetables() {
    return this._toppingsByType.wok_vegetables || [];
  }

  get defaultVegetable() {
    return this._vegetables[0] || this._emptyDefaultBaseAndSauce;
  }

  collectBases(aFunction) {
    return this._bases.map(aFunction);
  }

  collectSauces(aFunction) {
    return this._sauces.map(aFunction);
  }

  collectVegetables(aFunction) {
    return this._vegetables.map(aFunction);
  }

  hasNotWoks() {
    return !this.hasWoks();
  }

  isVegetables(id) {
    if (this._vegetables.length === 1) return true;
    const finded = this._vegetables.find((item) => item.id === id) || {};
    const { text_id } = finded;
    return finded && text_id !== 'bez-ovo';
  }

  hasWoks() {
    return Boolean(this._woks.length);
  }

  collectWoks(aFunction) {
    return this._woks.map(aFunction);
  }

  get toppingsDividedByGroups() {
    return {
      wok_topping: this._toppingsByType.wok_topping || [],
      wok_meat: this._toppingsByType.wok_meat || [],
      wok_add_sauce: this._toppingsByType.wok_add_sauce || [],
    };
  }

  baseWithId(aBaseId) {
    return (
      this._bases.find((base) => String(base.id) === String(aBaseId)) ||
      this.defaultBase
    );
  }

  sauceWithId(aSauceId) {
    return (
      this._sauces.find((s) => String(s.id) === String(aSauceId)) ||
      this.defaultSauce
    );
  }

  vegetablesWithId(aVegId) {
    return (
      this._vegetables.find((s) => String(s.id) === String(aVegId)) ||
      this.defaultVegetable
    );
  }
}

import { ReactNode } from 'react';
import cn from 'classnames';
import { IProduct } from 'types/product';
import Counter from 'src/shared/ui/Counter/Counter';
import { useRouteMatch } from 'react-router-dom';
import styles from './complectationItem.module.scss';

interface IComplectationItem {
  product: IProduct;
  qty: number;
  moreAvaliable: boolean;
  summPrice: ReactNode;
  setQty: (id: number, qty: number) => void;
}

function ComplectationItem({
  product,
  qty,
  moreAvaliable,
  summPrice,
  setQty,
}: IComplectationItem) {
  const { isExact } = useRouteMatch();
  return (
    <div className={styles.compRow}>
      <div
        className={cn({
          [styles.containerWithQty]: isExact,
          [styles.containerInfo]: !isExact,
        })}
      >
        <div className={styles.textContainer}>
          <div className={styles.title}>
            {`${product.title} ${product.weight}${product.weight_type}`}
          </div>
          {summPrice}
        </div>
        {!isExact ? (
          <div className={styles.qtyContainer}>
            <span>x {qty}</span>
          </div>
        ) : (
          <Counter
            qty={qty}
            className={styles.qtyContainer}
            step={1}
            disabled={!moreAvaliable}
            setQty={(count) => setQty(product.id, count)}
          />
        )}
      </div>
    </div>
  );
}

export default ComplectationItem;

import { isWithinInterval } from 'shared/lib/date';
import { toZonedTime } from 'date-fns-tz';

export const checkAvailableNow = (start: Date, end: Date, locale: string) => {
  const now = toZonedTime(new Date(), locale);
  return isWithinInterval(now, {
    start,
    end,
  });
};

import { useEffect, useState } from 'react';

const useShowPaid = ({
  noFree,
  paidItemsCount,
  isExact,
}: {
  noFree: boolean;
  paidItemsCount: number;
  isExact: boolean;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [wantMoreRedirect, setWantMoreRedirect] = useState<
    null | 'wantMore' | 'delete'
  >(null);

  useEffect(() => {
    if (isExact) {
      setShow(noFree || !!paidItemsCount);
    } else {
      setShow(!!paidItemsCount);
    }
  }, [noFree, paidItemsCount, isExact]);

  useEffect(() => {
    if (wantMoreRedirect === null) return;
    setWantMoreRedirect(null);
    if (wantMoreRedirect === 'delete') {
      setShow(false);
      return;
    }
    setShow(true);
  }, [wantMoreRedirect]);

  const wantMoreClick = () => {
    if (!isExact) {
      setWantMoreRedirect('wantMore');
      return;
    }
    setShow(true);
  };

  const closeClick = () => {
    if (!isExact) {
      setWantMoreRedirect('delete');
    }
    setShow(false);
  };

  return { show, closeClick, wantMoreClick };
};

export default useShowPaid;

import { Helmet } from 'react-helmet';
import { PageContainer } from 'shared/ui/page-container';
import ErrorComponent from '../../Components/ErrorComponent/ErrorComponent';

function ErrorPage() {
  return (
    <PageContainer>
      <Helmet title="404" />
      <ErrorComponent />
    </PageContainer>
  );
}

export default ErrorPage;

import './Orders.scss';
import './OrdersMobile.scss';
import { Component } from 'react';
import autobind from 'autobind-decorator';
import axios from 'axios';
import { modalEmitter, orderEmitter } from 'modules/event-emitters';
import ResponseError from 'modules/response/error';
import errors from 'modules/response/error-codes';

import { withTranslation } from 'react-i18next';
import ReorderModal from '../../Modals/ReorderModal/ReorderModal';
import OrderList from '../Order/OrderList';
import Order from '../Order/Order';

class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inProcess: false,
    };
  }

  // @todo !!!!вынести все в один компонено (см Info)
  componentDidMount() {
    orderEmitter.addListener('Order.Reorder', this.reorder);
    orderEmitter.addListener('Order.Reorder.New', this.reorderNew);
    orderEmitter.addListener('Order.Reorder.Add', this.reorderAdd);
  }

  componentWillUnmount() {
    orderEmitter.removeListener('Order.Reorder', this.reorder);
    orderEmitter.removeListener('Order.Reorder.New', this.reorderNew);
    orderEmitter.removeListener('Order.Reorder.Add', this.reorderAdd);
  }

  @autobind
  reorder(order) {
    this.reorderHandler(order);
  }

  @autobind
  reorderNew(order) {
    this.reorderHandler(order, true);
  }

  @autobind
  reorderAdd(order) {
    this.reorderHandler(order, false);
  }

  reorderHandler(order, isNew = null) {
    if (this.state.inProcess) {
      return true;
    }

    const form = {
      order_hash: order.hash,
      order_salt: order.salt,
    };

    if (typeof isNew !== 'undefined' || isNew !== null) {
      form.is_new = isNew;
    }

    this.inProcess(true);
    axios
      .post('/order/reorder', form)
      .then(() => {
        this.inProcess(false);
        window.location.href = '/cart';
      })
      .catch((error) => {
        modalEmitter.emit('Reorder.Modal.Close');
        this.inProcess(false);
        const responseError = new ResponseError(error);
        if (
          responseError.isApiError() &&
          responseError.getCode() === errors.REORDER_CART_NOT_EMPTY
        ) {
          modalEmitter.emit('Reorder.Modal.Show', order);
          return;
        }
        modalEmitter.emit('Modal.Error.Open', responseError.getMessage());
      });
  }

  inProcess(toggle) {
    this.setState({
      inProcess: !!toggle,
    });
  }

  render() {
    const { orders, t } = this.props;

    return (
      <div className="order__container">
        <OrderList orders={orders} type="inline" />
        {!orders.length ? (
          <p className="order__container__default">
            {t('Orders.when_you_make')},<br /> {t('Orders.order_will_shown')}{' '}
          </p>
        ) : (
          <h2 className="order__container__header">
            {' '}
            {t('Orders.my_orders')} {orders.length ? `(${orders.length})` : ''}{' '}
          </h2>
        )}

        {orders.length ? (
          <div className="order__container__table">
            <table className="order-table">
              <thead className="order-table__items-header">
                <tr>
                  <th className="order-table__num-header">
                    {t('Orders.order_number_header')}
                  </th>
                  <th className="order-table__status-header">
                    {t('Orders.order_status_header')}
                  </th>
                  <th className="order-table__sum-header">
                    {t('Orders.order_sum_header')}
                  </th>
                  <th className="order-table__date-header">
                    {t('Orders.order_date_header')}
                  </th>
                </tr>
              </thead>
              {orders.map((order, index) => (
                <Order key={`order-${index}`} order={order} />
              ))}
            </table>
          </div>
        ) : undefined}
        <ReorderModal />
      </div>
    );
  }
}
export default withTranslation()(Orders);

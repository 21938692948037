import { Media } from 'shared/lib/media';
import cn from 'classnames';
import { IMineSlider } from '../model/types';
import { SliderItem } from './SliderItem';
import { MobileSlider } from './mobile-slider/MobileSlider';
import { DesktopSlider } from './desktop-slider/DesktopSlider';
import style from './mainSlider.module.scss';

export function MainSlider({ banner }: { banner: IMineSlider[] }) {
  if (!banner?.length) return null;

  const bannersWithImage = banner.filter((slide) => !!slide.image);

  if (bannersWithImage.length === 1) {
    return (
      <div className={cn(style.container, style.single)}>
        <SliderItem slide={bannersWithImage[0]} />
      </div>
    );
  }

  return (
    <>
      <Media greaterThanOrEqual="notebook">
        {(className, renderDesktop) =>
          renderDesktop && (
            <DesktopSlider
              banners={bannersWithImage}
              className={cn(className, style.container)}
            />
          )
        }
      </Media>
      <Media lessThan="notebook">
        {(className, renderMobile) =>
          renderMobile && (
            <MobileSlider
              banners={bannersWithImage}
              className={cn(className, style.container)}
            />
          )
        }
      </Media>
    </>
  );
}

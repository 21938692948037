import { useTranslation } from 'react-i18next';
import ClockIcon from '../../assets/clock.svg';
import { IWorkingHours } from '../../model/types';
import styles from './hourCell.module.scss';

interface IWorkingHoursCell {
  hours: IWorkingHours | null;
  isEveryday: boolean;
  onClick: () => void;
}

function HoursCell({ hours, isEveryday, onClick }: IWorkingHoursCell) {
  const { t } = useTranslation();

  const isButton = !isEveryday;

  const working = isEveryday ? t('Table.every_day') : t('Table.today');
  return (
    <div className={styles.workingHours}>
      <span>{working}: </span>
      <div className={styles.hourCellContainer}>
        {hours ? (
          <>
            <span className={styles.from}>{t('Table.from')}</span>
            {hours.from}
            <span className={styles.from}>{t('Table.to')}</span>
            {hours.to}
          </>
        ) : (
          <span className={styles.notWorking}>{t('Table.not_work')}</span>
        )}
        {isButton && (
          <button
            type="button"
            className={styles.scheduleButton}
            onClick={onClick}
          >
            <ClockIcon />
          </button>
        )}
      </div>
    </div>
  );
}

export default HoursCell;

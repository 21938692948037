import phones from 'frontend/Components/Masked/Masks/Validators/Data/phones.min';
import globalStore from '../../../../../modules/global-store';

let _masks = null;

export default function (props) {
  const defaultPlaceholder = '';
  const _patterns = {
    '#': /[0-9]/,
  };

  const { onValidate, required = false } = props;
  let { placeholder } = props;

  if (!placeholder) {
    placeholder = defaultPlaceholder;
  }

  function _onValidate(b) {
    if (onValidate && typeof onValidate === 'function') {
      onValidate(b);
    }
  }

  function masked(mask, value) {
    if (!_patterns || !Object.keys(_patterns).length) {
      return false;
    }

    for (const key in _patterns) {
      if (_patterns[key].test(value)) {
        return mask[key];
      }
    }

    return false;
  }

  function getMask(mask, key) {
    if (!mask) {
      return false;
    }

    if (mask[key]) {
      return mask[key];
    }

    return masked(mask, key);
  }

  function isFixed(symbol) {
    return !_patterns || !_patterns[symbol];
  }

  function validate(event) {
    let {
      target: { value },
    } = event;
    let result = '';

    if (!value) {
      if (!required) {
        _onValidate(true);
      }
      return result;
    }
    const current_city = globalStore.get('current_city', {});
    if (current_city.domain === 'ru') {
      if (value[0] === '8') {
        value = `7${value.substr(1)}`;
      } else if (value[0] === '9' && current_city.country_id !== 6) {
        value = `79${value.substr(1)}`;
      }
    }
    let i = 0;
    let mask = _masks;

    while (value[i]) {
      // if (typeof mask === 'object' && Object.keys(mask).length === 0) {
      //   onValidate(true);
      //   return result;
      // }
      const key = value[i];
      let _mask = getMask(mask, key);

      if (_mask) {
        result += key;
        mask = _mask;
      } else {
        // check fo fixed symbols
        while (
          Object.keys(mask).length === 1 &&
          isFixed(Object.keys(mask)[0])
        ) {
          result += Object.keys(mask)[0];
          mask = mask[Object.keys(mask)[0]];
        }
        // check for masked
        _mask = getMask(mask, key);
        if (_mask) {
          result += key;
          mask = _mask;
        }
      }
      i++;
    }

    if (typeof mask === 'object' && Object.keys(mask).length === 0) {
      onValidate(true);
    } else {
      onValidate(false);
    }

    return result;
  }

  function onMount() {
    if (_masks !== null) {
      return;
    }
    _masks = {};
    if (phones) {
      phones.forEach((phone) => {
        let mask = _masks;
        for (let i = 0; i < phone.length; i += 1) {
          if (!mask[phone[i]]) {
            mask[phone[i]] = {};
          }
          mask = mask[phone[i]];
        }
      });
    }
  }

  function onUnmount() {
    //
  }

  return {
    validate,
    placeholder,
    onMount,
    onUnmount,
  };
}

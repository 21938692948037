/**
 * Base exception class
 */
class RuntimeException extends Error {
  static DEFAULT_CODE = 500;

  static DEFAULT_MESSAGE = 'Unknown message';

  constructor(message, code, errors, data) {
    super(message, code, errors, data);

    this._message = message || RuntimeException.DEFAULT_MESSAGE;
    this._code = code || RuntimeException.DEFAULT_CODE;
    this._errors = errors || null;
    this._data = data || undefined;
  }

  get message() {
    return this._message;
  }

  get code() {
    return this._code;
  }

  get errors() {
    return this._errors;
  }

  get data() {
    return this._data;
  }

  get object() {
    const obj = {
      code: this._code,
      message: this._code === 403 ? 'STOP FLOOD' : this._message,
      errors: this._errors,
    };

    if (this._data) {
      obj.data = this._data;
    }
    return obj;
  }

  toString() {
    return this.message;
  }
}

export default RuntimeException;

import Do from 'frontend/Components/Products/CardWok/wok-constructor/Do';
import store from 'modules/global-store';

export default class WokToppings {
  constructor() {
    this._maxNumberOfAllToppings = 8;
    this._limitForGroup = {};
    this._groupForToppingId = {};
    this._toppingsNumberById = {};
    this._toppingsIdsOrder = [];
    this._selectedVegetables = {};
    this._initLimitForGroup();
    this._initGroupForToppingId();
  }

  get _products() {
    return Object.values(store.get('products', {}));
  }

  _initLimitForGroup() {
    this._availableToppingsDo((t) => {
      const group = t.type.name;
      const limit = Number(t.type.max_toppings);
      if (!this._limitForGroup[group] || limit > this._limitForGroup[group]) {
        this._limitForGroup[group] = limit;
      }
    });
  }

  _initGroupForToppingId() {
    this._availableToppingsDo((t) => {
      const group = t.type.name;
      const id = t.product_id;
      this._groupForToppingId[id] = group;
    });
  }

  _maxNumberOfOneTopping(id) {
    let maxnumber;
    this._availableToppingsDo((t) => {
      if (t.product_id === id) {
        maxnumber = t.type.max_toppings;
      }
    });
    return maxnumber;
  }

  _availableToppingsDo(aFunction) {
    const wokBox = this._products.find(
      (p) => (p.product_type || {}).name === 'wok'
    ) || { product_toppings: [] };
    this._maxNumberOfAllToppings = wokBox.max_toppings;
    wokBox.product_toppings.forEach((t) => aFunction(t));
  }

  addToppingsFromQueryString(aQueryString) {
    this.deleteAllToppings();
    aQueryString.split('_').forEach((idAndNumberString) => {
      const [id, number] = idAndNumberString.split('-');
      this.changeNumberOfToppingsWithId(id, { to: number });
    });
  }

  deleteOneToppingWithId(anId) {
    const newNumber = this.numberOfToppingWithId(anId) - 1;
    if (newNumber > 0) {
      this._toppingsNumberById[anId] = newNumber;
    } else {
      this.deleteAllToppingsWithId(anId);
    }
  }

  deleteAllToppings() {
    this._toppingsNumberById = {};
    this._toppingsIdsOrder = [];
  }

  toggleToppingWithId(anId) {
    return this.hasToppingWithId(anId)
      ? this.deleteAllToppingsWithId(anId)
      : this.addOneToppingWithId(anId);
  }

  deleteAllToppingsWithId(anId) {
    delete this._toppingsNumberById[anId];
    this._toppingsIdsOrder = this._toppingsIdsOrder.filter(
      (id) => String(id) !== String(anId)
    );
  }

  addOneToppingWithId(anId) {
    if (this.canNotAddNewToppingsFor(this._groupForToppingId[anId])) {
      return;
    }
    if (this.canNotAddToppingWithId(anId)) {
      return;
    }

    if (this.hasNotToppingWithId(anId)) {
      this._toppingsIdsOrder.push(anId);
    }
    this._toppingsNumberById[anId] = this.numberOfToppingWithId(anId) + 1;
  }

  changeNumberOfToppingsWithId(anId, { to: aNumber }) {
    if (aNumber <= 0) {
      this.deleteAllToppingsWithId(anId);
    }

    const currentNumber = this.numberOfToppingWithId(anId);
    if (aNumber > currentNumber) {
      Do(aNumber - currentNumber).times(() => this.addOneToppingWithId(anId));
    } else {
      Do(currentNumber - aNumber).times(() =>
        this.deleteOneToppingWithId(anId)
      );
    }
  }

  canNotAddNewToppingsFor(aGroupName) {
    return !this.canAddNewToppingsFor(aGroupName);
  }

  canAddNewToppingsFor(aGroupName) {
    return this.remainingNumberOfToppingsFor(aGroupName) > 0;
  }

  remainingNumberOfToppingsFor(aGroupName) {
    return Math.min(
      this._limitForGroup[aGroupName] - this.numberOfAllToppingsFor(aGroupName),
      this.remainingNumberOfAllToppings()
    );
  }

  numberOfAllToppingsFor(aGroupName) {
    return this.toppingsIdsFor(aGroupName).reduce(
      (sum, id) => sum + (this._toppingsNumberById[id] || 0),
      0
    );
  }

  toppingsIdsFor(aGroupName) {
    return Object.entries(this._groupForToppingId).reduce(
      (ids, [id, groupName]) => (aGroupName === groupName ? [...ids, id] : ids),
      []
    );
  }

  remainingNumberOfAllToppings() {
    return this._maxNumberOfAllToppings - this.numberOfAllToppings();
  }

  numberOfAllToppings() {
    return (
      Object.values(this._toppingsNumberById).reduce((sum, n) => sum + n, 0) +
      this.numberofBaseToppings()
    );
  }

  numberofBaseToppings() {
    const { text_id } = this._selectedVegetables || {};
    const vegetablesCount = text_id !== 'bez-ovo' ? 1 : 0;
    return 2 + vegetablesCount;
  }

  setSelectedVegetables(veg) {
    this._selectedVegetables = veg;
  }

  maxNumberOfToppingWithId(anId) {
    const groupName = this._groupForToppingId[anId];
    return Math.min(
      this.remainingNumberOfOneToppingFor(groupName, anId) +
        this.numberOfToppingWithId(anId),
      this._maxNumberOfOneTopping(anId)
    );
  }

  remainingNumberOfOneToppingFor(aGroupName, anId) {
    const remainingNumberOfToppings =
      this.remainingNumberOfToppingsFor(aGroupName);
    return remainingNumberOfToppings > this._maxNumberOfOneTopping(anId)
      ? this._maxNumberOfOneTopping(anId)
      : remainingNumberOfToppings;
  }

  canNotAddToppingWithId(anId) {
    return this.remainingNumberOfToppingWithId(anId) === 0;
  }

  remainingNumberOfToppingWithId(anId) {
    const result =
      this._maxNumberOfOneTopping(anId) - this.numberOfToppingWithId(anId);
    return Math.max(0, result);
  }

  countTotalPrice() {
    return Object.entries(this._toppingsNumberById).reduce((total, [id, n]) => {
      const topping = store.getToppingWithId(id, { price: 0 });
      return total + topping.price * n;
    }, 0);
  }

  deleteToppingsIf(aPredicateFunction) {
    Object.keys(this._toppingsNumberById).forEach((id) => {
      if (aPredicateFunction(id)) {
        this.deleteOneToppingWithId(id);
      }
    });
  }

  asQueryObject() {
    if (this.isEmpty()) {
      return {};
    }
    return { toppings: this.asQueryString() };
  }

  isEmpty() {
    return this._toppingsIdsOrder.length === 0;
  }

  asQueryString() {
    return this._toppingsIdsOrder
      .map((id) => `${id}-${this._toppingsNumberById[id]}`)
      .join('_');
  }

  asEventPayload() {
    return this._toppingsIdsOrder.map((id) => ({
      product_id: id,
      qty: this._toppingsNumberById[id],
    }));
  }

  numberOfToppingWithId(anId) {
    return this._toppingsNumberById[anId] || 0;
  }

  hasNotToppingWithId(anId) {
    return !this.hasToppingWithId(anId);
  }

  hasToppingWithId(anId) {
    return Boolean(this._toppingsNumberById[anId]);
  }

  collect(aFunction) {
    const res = [];
    for (const id of this._toppingsIdsOrder) {
      const topping = store.getToppingWithId(id);
      if (topping) {
        res.push(aFunction(topping));
      }
    }
    return res;
  }
}

class ModernModal {
  static getDimensions() {
    const windowWidth = window.innerWidth;
    const bodyWidth = document.body.clientWidth;
    const scrollWidth = windowWidth - bodyWidth;
    const scrollTop = window.pageYOffset;
    return { windowWidth, bodyWidth, scrollWidth, scrollTop };
  }

  toggleModal(action, bool = true) {
    let { scrollWidth } = ModernModal.getDimensions();
    document.body.classList[action]('hidden');

    if (action === 'remove' && bool) scrollWidth = 0;

    ['.main-menu__wrap'].forEach((selector) => {
      if (document.querySelector(selector))
        document.querySelector(selector).style.marginRight = `${scrollWidth}px`;
    });
    document.body.style.marginRight = `${scrollWidth}px`;
  }

  openModal() {
    this.toggleModal('add');
  }

  closeModal(bool = true) {
    this.toggleModal('remove', bool);
  }
}

export default new ModernModal();

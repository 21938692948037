import { useEffect } from 'react';
import { useCityTextIdMemorizer } from '../lib/city-id-memorizer';
import { useConfirmCityModal } from './confirm-city-modal-handler';

export const useFirstShowConfirmCityModal = () => {
  const { show } = useConfirmCityModal();
  const confirmCityMemorizer = useCityTextIdMemorizer();
  const memorizeCityTextId = confirmCityMemorizer.get();

  useEffect(() => {
    if (!memorizeCityTextId) {
      show();
    }
  }, [show, memorizeCityTextId]);
};

import parse, { attributesToProps, domToReact } from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';

const allowedTags = ['img', 'font'];
const allowedAttributes = Object.assign(
  sanitizeHtml.defaults.allowedAttributes,
  {
    a: ['href', 'name', 'target', 'color', 'style'],
    img: ['src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
    span: ['font-size', 'font-weight', 'otline', 'style'],
    font: ['font-size', 'font-weight', 'otline', 'color', 'style'],
  }
);

const htmlParser = (data, replaceData) => {
  if (!data) return null;
  try {
    const cleanData = sanitizeHtml(data, {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat(allowedTags),
      allowedAttributes,
    });
    const replace = (domNode) => {
      if (domNode.attribs && domNode.name === replaceData.from) {
        const props = attributesToProps(domNode.attribs);
        return replaceData.to(props, domToReact(domNode.children, { replace }));
      }
      return domNode;
    };

    return parse(cleanData, replaceData ? { replace } : undefined);
  } catch (e) {
    throw new Error(e);
  }
};

export default htmlParser;

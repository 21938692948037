import * as session from 'modules/session';
import Emitter from 'frontend/Components/NewCartOrder/Emitters';
import api from 'modules/helpers/api';

export async function resetCart() {
  if (!session.get('cart').items.length) return;
  await api('cart.reset');
  session.set('cart', { items: [] });
  session.set('userGifts', []);
  Emitter.emit('CART_CHANGE');
}

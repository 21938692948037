import { baseApi } from 'shared/api';
import type { IOrder } from '../model/types';

export const getOrderApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getOrder: build.query<
      {
        data?: { order: IOrder };
        message?: string;
        code: number;
        result: string;
      },
      { order_hash: string; order_salt: string; city: string; lang: string }
    >({
      query: (params) => ({
        url: '/endpoint/order2/get_order',
        method: 'GET',
        headers: { salted: 'true' },
        params,
      }),
    }),
  }),
});

export const { useGetOrderQuery } = getOrderApi;
